import { gql } from '@apollo/client';
import { PICTURE_FRAGMENT } from 'modules/donor/graphql/fragments';
import { DISCARDED_PRODUCT_DATA_FRAGMENT } from 'modules/products/graphql/fragments';

export const HOLDER_FRAGMENT = gql`
  fragment Holder on HolderOutput {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    shortId
  }
`;

export const DONATION_PRODUCT_FRAGMENT = gql`
  fragment DonationProduct on DonationProductOutput {
    anticoagulant
    createdAt
    id
    shortId
    status
    storageRequirements
    updatedAt
    value
    woocommerceProductId
    woocommerceCategoryId
    expirationDate
  }
`;

export const DONATION_DONOR_FRAGMENT = gql`
  fragment DonationDonor on DonationDonor {
    id
    age
    gender
    hasTransfusion
    name
    neutered
    primaryVeterinarian
    species
    takeMedications
    weight
    bloodType
    status
    type
    location
    distinguishableMarks
    microchipNumber
    medicationCourse
    comment
    breed
    color
    createdAt
    shortId
    firstCertificatePrinted
    secondCertificatePrinted
    shortId
    lastDonationDate
    lastLabworkDate
    nextDonationDate
  }
`;

export const ADDITIONAL_DONATION_INFO_FRAGMENT = gql`
  fragment AdditionalDonationInfo on AdditionalDonationInfoOutput {
    id
    numberOfCollectionUnitsUsed
    veinUsed
    vascularStructure
    flowRate
    donationPosition
    holdersPosition
    donorMovement
    typeOfMovement
    emotionalSupport
    dislikes
    likes
    treatPreferences
    totalCollectedMls
    primaryHolder {
      ...Holder
    }
    secondaryHolder {
      ...Holder
    }
    poker {
      ...Holder
    }
  }
  ${HOLDER_FRAGMENT}
`;

//TODO: split this fragment on small fragments
export const DONATION_FRAGMENT = gql`
  fragment Donation on DonationOutput {
    id
    collectionSet
    capillaryRefillTime
    approvedDonationsMl
    jugularVein
    maxLimit
    mucousMembrane
    packedCellVolume
    pulse
    respiration
    shouldDonate
    temperature
    totalSolids
    unit
    actual
    weatherTemperature
    weatherTypes
    weight
    examinationNotes
    preferences
    createdAt
    comment
    shortId
    donationDate
    ineligible
    ineligibleReason
    ineligibleReasonDetails
    products {
      ...DonationProduct
      discardedProduct {
        ...DiscardedProductData
      }
    }
    donor {
      ...DonationDonor
      team {
        id
        title
      }
      user {
        ...Holder
      }
      pictures {
        ...Picture
      }
    }
    additionalInfo {
      ...AdditionalDonationInfo
    }
  }
  ${DONATION_PRODUCT_FRAGMENT}
  ${PICTURE_FRAGMENT}
  ${DONATION_DONOR_FRAGMENT}
  ${ADDITIONAL_DONATION_INFO_FRAGMENT}
  ${HOLDER_FRAGMENT}
  ${DISCARDED_PRODUCT_DATA_FRAGMENT}
`;
