import { Col } from 'antd';
import { ColumnGroupType, ColumnType, ColumnsType } from 'antd/lib/table';
import config from 'config';
import { Routes } from 'config/routes';
import { format } from 'date-fns';
import { Link } from 'modules/common/components';
import RestrictedLink from 'modules/common/components/RestrictedLink';
import DonationDropdownActions from 'modules/donations/components/DonationDropdownActions';
import {
  bloodTypesFilters,
  locationsFilters,
} from 'modules/donations/components/DonationsTable/filters';
import OutcomeCell from 'modules/donations/components/OutcomeCell';
import {
  DonationListFilterInput,
  DonationListSort,
  DonationOutput,
  PermissionsEnum,
} from 'types.d';
import { getTranslation } from 'utils/getTranslation';

import { TableColumns } from './enum';

const t = getTranslation('donation.DonationsTable');

interface IColumnsProps {
  canAddProducts: boolean;
  productCategories?: Record<number, string>;
  filtersValues?: DonationListFilterInput;
  showSorterTooltip?: boolean;
}

export const getColumns = ({
  canAddProducts,
  productCategories,
  filtersValues,
  showSorterTooltip,
}: IColumnsProps): ColumnsType<DonationOutput> => {
  const columns: ((
    | ColumnGroupType<DonationOutput>
    | ColumnType<DonationOutput>
  ) &
    Record<string, any>)[] = [
    {
      title: t('donationId'),
      align: 'center',
      render: ({ shortId }: DonationOutput) => (
        <Link to={`${Routes.Donations}/${shortId}`}>{shortId}</Link>
      ),
    },
    {
      title: t('donorName'),
      align: 'center',
      key: DonationListSort.DonorName,
      sorter: true,
      showSorterTooltip,
      render: ({ donor }: DonationOutput) => (
        <RestrictedLink
          to={`${Routes.Donors}/${donor.shortId}`}
          permissions={[PermissionsEnum.ViewTheListOfDonors]}
        >
          {donor.name}
        </RestrictedLink>
      ),
    },
    {
      title: t('ownerLastName'),
      align: 'center',
      sorter: true,
      showSorterTooltip,
      key: DonationListSort.OwnerLastName,
      render: (row: DonationOutput) => (
        <RestrictedLink
          to={Routes.EditOwner.replace(':id', `${row.donor.user?.shortId}`)}
          permissions={[PermissionsEnum.ViewTheListOfOwners]}
        >
          {row.donor.user?.lastName || ''}
        </RestrictedLink>
      ),
    },
    {
      title: t('bloodType'),
      align: 'center',
      key: TableColumns.bloodTypes,
      render: (row: DonationOutput) => (
        <>{row.donor.bloodType && t(row.donor.bloodType?.toLowerCase())}</>
      ),
      filters: bloodTypesFilters,
      filteredValue: filtersValues?.bloodTypes || null,
    },
    {
      title: t('outcome'),
      align: 'center',
      render: (row: DonationOutput) => {
        const isCellFilled = Boolean(row.products?.length) || row.ineligible;
        const isDiscarded = row.products?.some(({ discardedProduct }) =>
          Boolean(discardedProduct)
        );
        return (
          <>
            {isCellFilled ? (
              <OutcomeCell
                donation={row}
                productCategories={productCategories}
                isDiscarded={isDiscarded}
              />
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: t('location'),
      align: 'center',
      render: (row: DonationOutput) => row.donor.location,
      key: TableColumns.locations,
      filters: locationsFilters,
      filteredValue: filtersValues?.locations || null,
    },
    {
      title: t('dateOfLastDonation'),
      align: 'center',
      key: DonationListSort.LastDonationDate,
      sorter: true,
      showSorterTooltip,
      defaultSortOrder: 'descend',
      render: (row: DonationOutput) => (
        <Col>{format(new Date(row.donationDate), config.DATE_FORMAT)}</Col>
      ),
    },
    {
      render: (row: DonationOutput) => (
        <DonationDropdownActions donation={row} />
      ),
      filter: !canAddProducts,
    },
  ];

  return columns.reduce((previousValue, currentValue) => {
    const { filter, ...columnData } = currentValue;
    if (currentValue.filter) {
      return previousValue;
    }
    return [...previousValue, columnData as any];
  }, [] as ColumnsType<DonationOutput>);
};
