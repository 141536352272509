import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Image, Card, Row, Col } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { Spin } from 'modules/common/components';
import PushpinIcon from 'modules/common/components/PushpinIcon';
import { getPictureUrl } from 'modules/donor/components/DonorPicturesList/utils';
import UploadDonorPhoto from 'modules/donor/components/UploadDonorPhoto';
import { DonorPictureType } from 'modules/donor/types';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect, useState } from 'react';
import {
  PermissionsEnum,
  useGetDonorPicturesLazyQuery,
  useMakeDonorPicturePrimaryMutation,
  useResetPrimaryDonorPicturesMutation,
} from 'types.d';
import { fetchWithAuth } from 'utils/fetchWithAuth';

import styles from './styles.module.scss';

const { UploadDonorsPictures, DeleteDonorsPictures } = PermissionsEnum;

type Props = {
  donorId: string;
};

export const DonorPicturesList: FC<Props> = ({ donorId }) => {
  const [pictures, setPictures] = useState<DonorPictureType[]>([]);
  const [isFetchLoading, setIsfetchLoading] = useState<boolean>(true);
  const [getDonorPictures, getDonorPicturesQuery] =
    useGetDonorPicturesLazyQuery();
  const [makePicturePrimary, makePicturePrimaryMutaion] =
    useMakeDonorPicturePrimaryMutation({
      refetchQueries: ['getDonorPictures'],
    });
  const [resetPrimaryPictures, resetPrimaryPicturesMutaion] =
    useResetPrimaryDonorPicturesMutation({
      refetchQueries: ['getDonorPictures'],
    });
  const currentUser = useCurrentUser();
  const canUploadDonorPictures = currentUser.isCan(UploadDonorsPictures);
  const canDeleteDonorPictures = currentUser.isCan(DeleteDonorsPictures);
  const donorPictures = getDonorPicturesQuery.data?.getDonorPictures;
  const isLoading =
    isFetchLoading ||
    [
      getDonorPicturesQuery,
      makePicturePrimaryMutaion,
      resetPrimaryPicturesMutaion,
    ].some(({ loading }) => loading);

  const onChangeUploadFile = (uploadParams: UploadChangeParam) => {
    if (uploadParams.file.status === 'done') {
      getDonorPicturesQuery.refetch();
    }
  };

  useEffect(() => {
    getDonorPictures({
      variables: {
        input: {
          id: donorId,
        },
      },
    });
  }, [donorId]);

  const deletePhotoHandler = async (id: string) => {
    setIsfetchLoading(true);
    const response = await fetchWithAuth(
      `${process.env.REACT_APP_SERVER_URI}/donor/files/${id}`,
      {
        method: 'DELETE',
      }
    );
    if (response.ok) {
      getDonorPicturesQuery.refetch();
    }
  };

  const makePicturePrimaryHandler = (pictureId: string) => {
    makePicturePrimary({
      variables: { input: { pictureId } },
    });
  };

  const resetPrimaryPicturesHandler = () => {
    resetPrimaryPictures({
      variables: { input: { donorId } },
    });
  };

  useEffect(() => {
    setIsfetchLoading(true);
    const updateUrls = async () => {
      const newPictures =
        donorPictures &&
        (await Promise.all(
          donorPictures.map(async ({ file, id, isPrimary }) => {
            const url = await getPictureUrl(file);
            return { id, url, isPrimary };
          })
        ));
      setPictures(newPictures || []);
      setIsfetchLoading(false);
    };
    updateUrls();
  }, [donorPictures]);

  return (
    <Spin spinning={isLoading} size="large">
      {pictures && pictures.length > 0 && (
        <Image.PreviewGroup>
          <Row gutter={[10, 10]} className={styles.imagesWrapper}>
            {pictures.map((picture) => (
              <Col>
                <Card
                  hoverable
                  key={picture.id}
                  className={styles.imageCard}
                  cover={
                    <div className={styles.imageContainer}>
                      <Image
                        alt="example"
                        src={picture.url}
                        className={styles.image}
                      />
                    </div>
                  }
                  bodyStyle={{ padding: 0 }}
                  actions={[
                    <PushpinIcon
                      filled={picture.isPrimary}
                      onClick={(filled) => {
                        if (filled) {
                          resetPrimaryPicturesHandler();
                        } else {
                          makePicturePrimaryHandler(picture.id);
                        }
                      }}
                    />,
                    canUploadDonorPictures && (
                      <UploadDonorPhoto
                        data={{
                          donorId,
                          pictureId: picture.id,
                        }}
                        action="update"
                        onChange={onChangeUploadFile}
                      >
                        <EditOutlined key="edit" className={styles.edit} />
                      </UploadDonorPhoto>
                    ),
                    canDeleteDonorPictures && (
                      <DeleteOutlined
                        key="delete"
                        onClick={() => deletePhotoHandler(picture.id)}
                      />
                    ),
                  ]}
                />
              </Col>
            ))}
          </Row>
        </Image.PreviewGroup>
      )}
    </Spin>
  );
};
