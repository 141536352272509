import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Modal, Row, message, Alert } from 'antd';
import { FormItem, Spin } from 'modules/common/components';
import { BaseModalProps } from 'modules/common/types';
import CategorySelect from 'modules/products/components/CategorySelect';
import ProductExpirationDates from 'modules/products/components/ProductExpirationDates';
import ProductTypeSelect from 'modules/products/components/ProductTypeSelect';
import StorageRecommendations from 'modules/products/components/StorageRecommendations';
import { CreateProductModalExtraProps } from 'modules/products/types';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ProductOutput,
  useGetDonationLazyQuery,
  useCreateProductsMutation,
  CreateProductInput,
  DonationOutput,
  ReasonDiscardProduct,
  ProcessingProductType,
} from 'types.d';

import DiscardedSection from './components/DiscardedSection';
import DonationDataSection from './components/DonationDataSection';
import { createProductSchema, CreateProductSchema } from './schema';
import styles from './styles.module.scss';

type PropTypes = BaseModalProps & CreateProductModalExtraProps;

const CreateProductModal: FC<PropTypes> = ({
  hide,
  onCancel,
  onOk,
  onProductsCreation,
  donationId = '',
  ...restModalProps
}) => {
  const { t } = useTranslation('products.CreateProductModal');
  const user = useCurrentUser();
  const productRegion = user.data?.productRegionPreference;
  const [getDonation, getDonationQuery] = useGetDonationLazyQuery({
    onError: (error) => {
      message.error(error.message);
    },
  });
  const donation = getDonationQuery.data?.getDonation;
  const donationDate = donation?.donationDate
    ? new Date(donation?.donationDate)
    : undefined;
  const createProductForm = useForm<CreateProductSchema>({
    resolver: yupResolver(createProductSchema),
    mode: 'onChange',
  });
  const {
    control,
    handleSubmit,
    watch,
    resetField,
    getValues,
    reset,
    formState,
    setValue,
  } = createProductForm;
  const [createProducts, createProductsMutation] = useCreateProductsMutation({
    refetchQueries: ['getProducts'],
    onCompleted: (data) => {
      const createdProducts = (data.createProducts as ProductOutput[]) || [];
      message.success(t('productHasBeenCreated'));
      reset({ values: [] });
      hide();
      onProductsCreation && onProductsCreation(createdProducts);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const isLoading = getDonationQuery.loading || createProductsMutation.loading;
  const quantity = watch('quantity');
  const woocommerceCategoryId = watch('woocommerceCategoryId');
  const discarded = watch('discarded');

  useEffect(() => {
    if (discarded) {
      setValue('quantity', 1);
      setValue('values', [0]);
    } else {
      resetField('quantity');
      setValue('values', null as any);
    }
  }, [discarded]);

  const onCancelHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onCancel && onCancel(e);
    reset({ values: [] });
    hide();
  };

  const onOkHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    createProductHandler(e);
    onOk?.(e);
  };

  const createProductHandler = handleSubmit(
    async ({
      values,
      quantity: _,
      discarded,
      discardedReason,
      discardReasonDetails,
      processingType,
      processorId,
      ...restFormData
    }) => {
      const products: CreateProductInput[] = values.map((value) => {
        return {
          value,
          donationId,
          regionPreferenceId: productRegion?.id,
          discardedData: discarded
            ? {
                notes: discardReasonDetails,
                processorId: processorId || '',
                processingType: processingType as ProcessingProductType,
                reason: discardedReason as ReasonDiscardProduct,
              }
            : undefined,
          ...restFormData,
        };
      });
      createProducts({
        variables: {
          input: {
            products,
          },
        },
      });
    }
  );

  useEffect(() => {
    resetField('woocommerceProductId');
  }, [woocommerceCategoryId]);

  useEffect(() => {
    const productValues = getValues('values');
    if (productValues && quantity) {
      resetField('values', { defaultValue: productValues.slice(0, quantity) });
    }
  }, [quantity]);

  useEffect(() => {
    if (donationId) {
      getDonation({
        variables: {
          input: {
            id: donationId,
          },
        },
      });
    }
  }, [donationId]);

  return (
    <Modal
      {...restModalProps}
      className={styles.modal}
      onCancel={onCancelHandler}
      onOk={onOkHandler}
      width="100%"
      okText={t('createProduct')}
      okButtonProps={{ disabled: !formState.isValid || !donationId }}
    >
      <Spin spinning={isLoading}>
        <Form>
          <Row gutter={[0, 16]}>
            {!donationId && (
              <Col span={24}>
                <Alert
                  className={styles.errorAlert}
                  message={t('donationDataIsWrong')}
                  type="error"
                />
              </Col>
            )}

            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <FormItem className={styles.categories}>
                    <CategorySelect
                      controlled={{ control, name: 'woocommerceCategoryId' }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <ProductTypeSelect
                    donorBloodType={donation?.donor.bloodType || undefined}
                    productCategoryId={woocommerceCategoryId}
                    controlled={{ control, name: 'woocommerceProductId' }}
                    onChangeDefaultValue={({ value }) => {
                      setValue('woocommerceProductId', value);
                    }}
                  />
                </Col>
                <Col span={8}>
                  {donationDate && (
                    <ProductExpirationDates
                      productCategoryId={woocommerceCategoryId}
                      donationDate={donationDate}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <FormProvider {...createProductForm}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col md={12} span={24}>
                    <Row gutter={[0, 16]}>
                      <Col span={24}>
                        <FormItem
                          className={styles.formItem}
                          label={t('storageRecommendations')}
                          colon
                        >
                          <StorageRecommendations
                            controlled={{
                              control,
                              name: 'storageRequirements',
                            }}
                            categoryId={woocommerceCategoryId}
                          />
                        </FormItem>
                      </Col>

                      {donation && (
                        <DonationDataSection
                          donation={donation as DonationOutput}
                        />
                      )}
                    </Row>
                  </Col>
                  <Col md={12} span={24}>
                    <DiscardedSection
                      productRegionPreferenceId={productRegion?.id}
                    />
                  </Col>
                </Row>
              </Col>
            </FormProvider>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateProductModal;
