import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AcceptInviteInput = {
  id: Scalars['String'];
  password: Scalars['String'];
};

export type AddDonorsToDaycareInput = {
  appointmentId: Scalars['String'];
  donorIds: Array<Scalars['String']>;
};

export type AdditionalDonationInfoInput = {
  dislikes?: InputMaybe<Array<Scalars['String']>>;
  donationPosition?: InputMaybe<Scalars['String']>;
  donationPositionFreeText?: InputMaybe<Scalars['String']>;
  donorMovement: Scalars['String'];
  emotionalSupport?: InputMaybe<Array<Scalars['String']>>;
  flowRate: FlowRate;
  holdersPosition: Array<Scalars['String']>;
  likes?: InputMaybe<Array<Scalars['String']>>;
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  shortPokerId?: InputMaybe<Scalars['String']>;
  shortPrimaryHolderId?: InputMaybe<Scalars['String']>;
  shortSecondaryHolderId?: InputMaybe<Scalars['String']>;
  totalCollectedMls?: InputMaybe<Scalars['Float']>;
  treatPreferences?: InputMaybe<Array<Scalars['String']>>;
  typeOfMovement?: InputMaybe<Scalars['String']>;
  vascularStructure?: InputMaybe<VascularStructure>;
  veinUsed: VeinUsed;
};

export type AdditionalDonationInfoOutput = {
  __typename?: 'AdditionalDonationInfoOutput';
  dislikes?: Maybe<Array<Scalars['String']>>;
  donationPosition?: Maybe<Scalars['String']>;
  donorMovement: Scalars['String'];
  emotionalSupport?: Maybe<Array<Scalars['String']>>;
  flowRate: FlowRate;
  holdersPosition?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  likes?: Maybe<Array<Scalars['String']>>;
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  poker?: Maybe<HolderOutput>;
  primaryHolder?: Maybe<HolderOutput>;
  secondaryHolder?: Maybe<HolderOutput>;
  totalCollectedMls?: Maybe<Scalars['Float']>;
  treatPreferences?: Maybe<Array<Scalars['String']>>;
  typeOfMovement?: Maybe<Scalars['String']>;
  vascularStructure?: Maybe<VascularStructure>;
  veinUsed: VeinUsed;
};

export type AdditionalDonationInfoReportOutput = {
  __typename?: 'AdditionalDonationInfoReportOutput';
  id: Scalars['String'];
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  poker?: Maybe<HolderOutput>;
  primaryHolder?: Maybe<HolderOutput>;
  totalCollectedMls?: Maybe<Scalars['Float']>;
};

export type AdditionalInfoInput = {
  dislikes?: InputMaybe<Array<Scalars['String']>>;
  donationPosition?: InputMaybe<Scalars['String']>;
  donationPositionFreeText?: InputMaybe<Scalars['String']>;
  donorMovement: Scalars['String'];
  emotionalSupport?: InputMaybe<Array<Scalars['String']>>;
  flowRate: FlowRate;
  holdersPosition: Array<Scalars['String']>;
  likes?: InputMaybe<Array<Scalars['String']>>;
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  pokerId?: InputMaybe<Scalars['String']>;
  primaryHolderId?: InputMaybe<Scalars['String']>;
  secondaryHolderId?: InputMaybe<Scalars['String']>;
  totalCollectedMls?: InputMaybe<Scalars['Float']>;
  treatPreferences?: InputMaybe<Array<Scalars['String']>>;
  typeOfMovement?: InputMaybe<Scalars['String']>;
  vascularStructure?: InputMaybe<VascularStructure>;
  veinUsed: VeinUsed;
};

export type AllIneligibleParticipantsOutput = {
  __typename?: 'AllIneligibleParticipantsOutput';
  ineligible: Array<IneligibleParticipantOutput>;
};

export enum AnimalGender {
  Female = 'Female',
  Male = 'Male',
}

export enum AnimalSpecies {
  Cat = 'Cat',
  Dog = 'Dog',
}

export enum Anticoagulant {
  Asd = 'ASD',
  Cpd = 'CPD',
  Cpda = 'CPDA',
  Cpda_1 = 'CPDA_1',
  CpdSagm = 'CPD_SAGM',
}

export type AppointmentDatesInfoOutput = {
  __typename?: 'AppointmentDatesInfoOutput';
  end: Scalars['DateTime'];
  id: Scalars['String'];
  start: Scalars['DateTime'];
};

export type AppointmentDonor = {
  __typename?: 'AppointmentDonor';
  acceptSimparicaTrio: Scalars['Boolean'];
  age: Scalars['Float'];
  approvedDonationML: Scalars['Float'];
  attendDaycareDays?: Maybe<Array<Scalars['String']>>;
  becameReservedDonorDate?: Maybe<Scalars['DateTime']>;
  bloodType?: Maybe<BloodType>;
  breed?: Maybe<Scalars['String']>;
  canRegularlyAttendDaycare?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth: Scalars['DateTime'];
  distemperDate?: Maybe<Scalars['DateTime']>;
  distinguishableMarks?: Maybe<Scalars['String']>;
  donationFrequency: Scalars['Float'];
  donationMLApprovedAmount?: Maybe<Scalars['Float']>;
  firstCertificatePrinted: Scalars['Boolean'];
  gender: AnimalGender;
  hasHeartMurmur?: Maybe<Scalars['Boolean']>;
  hasMicrochip?: Maybe<Scalars['Boolean']>;
  hasTransfusion?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isDaycare?: Maybe<Scalars['Boolean']>;
  lastDonationDate?: Maybe<Scalars['DateTime']>;
  lastLabworkDate?: Maybe<Scalars['DateTime']>;
  lastLabworkFile?: Maybe<Array<FileOutput>>;
  location: Scalars['String'];
  medicationCourse?: Maybe<Scalars['String']>;
  microchipNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  neutered: Scalars['Boolean'];
  nextDonationDate?: Maybe<Scalars['DateTime']>;
  pictures?: Maybe<Array<DonorPictureOutput>>;
  primaryVeterinarian: Scalars['String'];
  rabiesDate?: Maybe<Scalars['DateTime']>;
  reserveNotes?: Maybe<Array<ReserveDonorNoteOutput>>;
  retiredDate?: Maybe<Scalars['DateTime']>;
  retiredReason?: Maybe<RetiredReasons>;
  retiredReasonComment?: Maybe<Scalars['String']>;
  secondCertificatePrinted: Scalars['Boolean'];
  shortId: Scalars['Float'];
  species: AnimalSpecies;
  status: DonorStatus;
  takeMedications?: Maybe<Scalars['Boolean']>;
  type: DonorType;
  user?: Maybe<AppointmentUser>;
  weight: Scalars['Float'];
};

export type AppointmentGroupOutput = {
  __typename?: 'AppointmentGroupOutput';
  id: Scalars['String'];
  title: Scalars['String'];
  user?: Maybe<AppointmentUser>;
};

export type AppointmentOutput = {
  __typename?: 'AppointmentOutput';
  createdAt: Scalars['DateTime'];
  daycareInfo?: Maybe<Array<DaycareAppointmentResponseOutput>>;
  donor?: Maybe<AppointmentDonor>;
  end: Scalars['DateTime'];
  group?: Maybe<AppointmentGroupOutput>;
  id: Scalars['String'];
  isDaycare?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  location: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  team?: Maybe<AppointmentTeamOutput>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users: Array<AppointmentUser>;
};

export type AppointmentTeamOutput = {
  __typename?: 'AppointmentTeamOutput';
  id: Scalars['String'];
  title: Scalars['String'];
};

export enum AppointmentType {
  Daycare = 'Daycare',
  Grouped = 'Grouped',
  Individual = 'Individual',
}

export type AppointmentUser = {
  __typename?: 'AppointmentUser';
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  contactPreferences?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<ContactOutput>>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletionIntentionAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  locationPreferences?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  productRegionPreference?: Maybe<RegionOutput>;
  shortId: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AppointmentsFilterInput = {
  donorIds?: InputMaybe<Array<Scalars['String']>>;
  locations?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<AppointmentType>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ArchiveUserInput = {
  userId: Scalars['String'];
};

export type BillingOutput = {
  __typename?: 'BillingOutput';
  city: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  firstAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  postcode: Scalars['String'];
  secondAddress?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export enum BloodType {
  Dea1Negative = 'Dea1Negative',
  Dea1Positive = 'Dea1Positive',
  Unknown = 'Unknown',
}

export enum CapillaryRefillTime {
  BetweenOneAndTwo = 'BetweenOneAndTwo',
  LessThanTwo = 'LessThanTwo',
  MoreThanTwo = 'MoreThanTwo',
  NotApplicable = 'NotApplicable',
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['Float'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export enum CollectionSet {
  NotApplicable = 'NotApplicable',
  Quad = 'Quad',
  Single = 'Single',
}

export enum CollectionUnitsUsed {
  One = 'One',
  Three = 'Three',
  Two = 'Two',
  Zero = 'Zero',
}

export type ContactInput = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ContactOutput = {
  __typename?: 'ContactOutput';
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CreateAdditionalDonationInfoInput = {
  dislikes?: InputMaybe<Array<Scalars['String']>>;
  donationId: Scalars['String'];
  donationPosition?: InputMaybe<Scalars['String']>;
  donationPositionFreeText?: InputMaybe<Scalars['String']>;
  donorMovement: Scalars['String'];
  emotionalSupport?: InputMaybe<Array<Scalars['String']>>;
  flowRate: FlowRate;
  holdersPosition: Array<Scalars['String']>;
  likes?: InputMaybe<Array<Scalars['String']>>;
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  pokerId?: InputMaybe<Scalars['String']>;
  primaryHolderId?: InputMaybe<Scalars['String']>;
  secondaryHolderId?: InputMaybe<Scalars['String']>;
  totalCollectedMls?: InputMaybe<Scalars['Float']>;
  treatPreferences?: InputMaybe<Array<Scalars['String']>>;
  typeOfMovement?: InputMaybe<Scalars['String']>;
  vascularStructure?: InputMaybe<VascularStructure>;
  veinUsed: VeinUsed;
};

export type CreateAppointmentWithDonorInput = {
  dates: Array<IntervalInput>;
  donorId: Scalars['String'];
  isDaycare?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  location: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type CreateAppointmentWithGroupInput = {
  dates: Array<IntervalInput>;
  groupId: Scalars['String'];
  isDaycare?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  location: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type CreateContactInput = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CreateDaycareAppointmentWithDonorsInput = {
  dates: Array<IntervalInput>;
  donorIds: Array<Scalars['String']>;
  isDaycare?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  location: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type CreateDonationInput = {
  actual?: InputMaybe<Scalars['Float']>;
  approvedDonationsMl: Scalars['Float'];
  capillaryRefillTime: CapillaryRefillTime;
  collectionSet: CollectionSet;
  comment?: InputMaybe<Scalars['String']>;
  donationDate?: InputMaybe<Scalars['DateTime']>;
  donorId: Scalars['String'];
  examinationNotes?: InputMaybe<Scalars['String']>;
  ineligible?: InputMaybe<Scalars['Boolean']>;
  ineligibleReason?: InputMaybe<IneligibleReason>;
  ineligibleReasonDetails?: InputMaybe<Scalars['String']>;
  jugularVein: JugularVein;
  maxLimit: Scalars['Float'];
  mucousMembrane: Mm;
  packedCellVolume: Scalars['Float'];
  preferences?: InputMaybe<Scalars['String']>;
  pulse: Scalars['Float'];
  respiration: Scalars['Float'];
  shouldDonate: Scalars['Boolean'];
  temperature: Scalars['Float'];
  totalSolids: Scalars['Float'];
  unit?: InputMaybe<DonationUnit>;
  weatherTemperature: Scalars['Float'];
  weatherTypes: Array<WeatherType>;
  weight: Scalars['Float'];
  working?: InputMaybe<Scalars['Boolean']>;
};

export type CreateDonationWithAdditionalInfoInput = {
  additionalInfo: AdditionalInfoInput;
  vitals: CreateDonationInput;
};

export type CreateDonationsWithAdditionalInfoInput = {
  donations: Array<DonationWithAdditionalInfoInput>;
};

export type CreateDonorForUserInput = {
  donorData: CreateDonorInput;
  userId: Scalars['String'];
};

export type CreateDonorInput = {
  acceptSimparicaTrio?: InputMaybe<Scalars['Boolean']>;
  attendDaycareDays?: InputMaybe<Array<Scalars['String']>>;
  bloodType?: InputMaybe<BloodType>;
  breed?: InputMaybe<Scalars['String']>;
  canRegularlyAttendDaycare?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['DateTime'];
  distemperDate?: InputMaybe<Scalars['DateTime']>;
  distinguishableMarks?: InputMaybe<Scalars['String']>;
  gender: AnimalGender;
  hasHeartMurmur?: InputMaybe<Scalars['Boolean']>;
  hasMicrochip?: InputMaybe<Scalars['Boolean']>;
  hasTransfusion?: InputMaybe<Scalars['Boolean']>;
  location: Scalars['String'];
  medicationCourse?: InputMaybe<Scalars['String']>;
  microchipNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  neutered: Scalars['Boolean'];
  primaryVeterinarian: Scalars['String'];
  rabiesDate?: InputMaybe<Scalars['DateTime']>;
  retiredDate?: InputMaybe<Scalars['DateTime']>;
  retiredReason?: InputMaybe<RetiredReasons>;
  retiredReasonComment?: InputMaybe<Scalars['String']>;
  species: AnimalSpecies;
  status?: InputMaybe<DonorStatus>;
  takeMedications?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DonorType>;
  weight: Scalars['Float'];
};

export type CreateDonorNoteInput = {
  donorId: Scalars['String'];
  text: Scalars['String'];
};

export type CreateDonors = {
  donorsData: Array<CreateDonorInput>;
};

export type CreateDonorsForUserInput = {
  donorsData: Array<CreateDonorInput>;
  userId: Scalars['String'];
};

export type CreateEmptyAppointmentInput = {
  dates: Array<IntervalInput>;
  isDaycare?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  location: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type CreateGroupInput = {
  team?: InputMaybe<CreateTeamInput>;
  title: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateLabworkInput = {
  DVMExam: Scalars['Boolean'];
  collectionDate: Scalars['DateTime'];
  donorId: Scalars['String'];
};

export type CreateLocationInput = {
  name: Scalars['String'];
  regionId?: InputMaybe<Scalars['String']>;
  type: LocationType;
};

export type CreateProductInput = {
  anticoagulant?: InputMaybe<Anticoagulant>;
  discardedData?: InputMaybe<DiscardedProductDataInput>;
  donationId: Scalars['String'];
  regionPreferenceId?: InputMaybe<Scalars['String']>;
  storageRequirements: Array<Scalars['String']>;
  value: Scalars['Float'];
  woocommerceCategoryId: Scalars['Float'];
  woocommerceProductId: Scalars['Float'];
};

export type CreateProductsInput = {
  products: Array<CreateProductInput>;
};

export type CreateRegionInput = {
  accountingCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateTeamInput = {
  groupId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CurrentUserAppointmentsFilterInput = {
  locations?: InputMaybe<Array<Scalars['String']>>;
};

export type CurrentUserDonorListFilterInput = {
  isDaycare?: InputMaybe<Scalars['Boolean']>;
  locations?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  searchingQuery?: InputMaybe<Scalars['String']>;
};

export type CurrentUserDonorListInput = {
  filter?: InputMaybe<CurrentUserDonorListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortUserDonorListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type CustomerOutput = {
  __typename?: 'CustomerOutput';
  billing: BillingOutput;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  shipping: ShippingOutput;
  username: Scalars['String'];
};

export type DaycareAppointmentDetailOutput = {
  __typename?: 'DaycareAppointmentDetailOutput';
  appointment: AppointmentOutput;
  interactions: Array<DaycareAppointmentResponseOutput>;
  meta: DaycareAppointmentMetaOutput;
  requests: Array<DaycareAppointmentRequestOutput>;
};

export type DaycareAppointmentMetaOutput = {
  __typename?: 'DaycareAppointmentMetaOutput';
  maxDonorsCapacity: Scalars['Int'];
  registeredDonors: Scalars['Int'];
};

export type DaycareAppointmentRequestOutput = {
  __typename?: 'DaycareAppointmentRequestOutput';
  donor: DonorOutput;
  id: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  user: OwnerOutput;
};

export type DaycareAppointmentResponseOutput = {
  __typename?: 'DaycareAppointmentResponseOutput';
  id: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  owner: OwnerOutput;
  ownerResponse: Scalars['Boolean'];
  registeredDonors: Array<DonorOutput>;
};

export enum DaycareRegistrationStatus {
  Registered = 'Registered',
  RegisteredWithNo = 'RegisteredWithNo',
  Requested = 'Requested',
  Unregister = 'Unregister',
}

export type DaycareUnenrollDonorInput = {
  daycareId: Scalars['String'];
  donorId: Scalars['String'];
};

export type DeleteDonorInput = {
  id: Scalars['String'];
};

export type DeleteDonorNoteInput = {
  id: Scalars['String'];
};

export type DeleteLocationInput = {
  id: Scalars['String'];
};

export type DeleteProductInput = {
  id: Scalars['String'];
};

export type DeleteRegionInput = {
  id: Scalars['String'];
};

export type DetachLabworkFileInput = {
  fileId: Scalars['String'];
  id: Scalars['String'];
};

export type DetachUserAttachmentInput = {
  fileId: Scalars['String'];
  id: Scalars['String'];
};

export type DiscardedProductDataInput = {
  notes?: InputMaybe<Scalars['String']>;
  processingType: ProcessingProductType;
  processorId: Scalars['String'];
  reason: ReasonDiscardProduct;
};

export type DiscardedProductDataOutput = {
  __typename?: 'DiscardedProductDataOutput';
  notes?: Maybe<Scalars['String']>;
  processingType: ProcessingProductType;
  processor?: Maybe<ProductProcessorOutput>;
  reason: ReasonDiscardProduct;
};

export type DiscardedProductDataReportOutput = {
  __typename?: 'DiscardedProductDataReportOutput';
  notes?: Maybe<Scalars['String']>;
  processingType: ProcessingProductType;
  processor?: Maybe<ProductProcessorOutput>;
  reason: ReasonDiscardProduct;
};

export type DonationDonor = {
  __typename?: 'DonationDonor';
  acceptSimparicaTrio: Scalars['Boolean'];
  age: Scalars['Float'];
  approvedDonationML: Scalars['Float'];
  attendDaycareDays?: Maybe<Array<Scalars['String']>>;
  becameReservedDonorDate?: Maybe<Scalars['DateTime']>;
  bloodType?: Maybe<BloodType>;
  breed?: Maybe<Scalars['String']>;
  canRegularlyAttendDaycare?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth: Scalars['DateTime'];
  distemperDate?: Maybe<Scalars['DateTime']>;
  distinguishableMarks?: Maybe<Scalars['String']>;
  donationFrequency: Scalars['Float'];
  donationMLApprovedAmount?: Maybe<Scalars['Float']>;
  firstCertificatePrinted: Scalars['Boolean'];
  gender: AnimalGender;
  hasHeartMurmur?: Maybe<Scalars['Boolean']>;
  hasMicrochip?: Maybe<Scalars['Boolean']>;
  hasTransfusion?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isDaycare?: Maybe<Scalars['Boolean']>;
  lastDonationDate?: Maybe<Scalars['DateTime']>;
  lastLabworkDate?: Maybe<Scalars['DateTime']>;
  lastLabworkFile?: Maybe<Array<FileOutput>>;
  location: Scalars['String'];
  medicationCourse?: Maybe<Scalars['String']>;
  microchipNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  neutered: Scalars['Boolean'];
  nextDonationDate?: Maybe<Scalars['DateTime']>;
  pictures?: Maybe<Array<DonorPictureOutput>>;
  primaryVeterinarian: Scalars['String'];
  rabiesDate?: Maybe<Scalars['DateTime']>;
  reserveNotes?: Maybe<Array<ReserveDonorNoteOutput>>;
  retiredDate?: Maybe<Scalars['DateTime']>;
  retiredReason?: Maybe<RetiredReasons>;
  retiredReasonComment?: Maybe<Scalars['String']>;
  secondCertificatePrinted: Scalars['Boolean'];
  shortId: Scalars['Float'];
  species: AnimalSpecies;
  status: DonorStatus;
  takeMedications?: Maybe<Scalars['Boolean']>;
  team?: Maybe<TeamOutput>;
  type: DonorType;
  user?: Maybe<HolderOutput>;
  weight: Scalars['Float'];
};

export type DonationDonorReportOutput = {
  __typename?: 'DonationDonorReportOutput';
  acceptSimparicaTrio: Scalars['Boolean'];
  age: Scalars['Float'];
  annualDVMComplete?: Maybe<Scalars['Boolean']>;
  approvedDonationML: Scalars['Float'];
  becameReservedDonorDate?: Maybe<Scalars['DateTime']>;
  bloodType?: Maybe<BloodType>;
  breed?: Maybe<Scalars['String']>;
  canRegularlyAttendDaycare?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  distemperDate?: Maybe<Scalars['DateTime']>;
  donationFrequency: Scalars['Float'];
  donationMLApprovedAmount?: Maybe<Scalars['Float']>;
  donorStatusUpdatedAt: Scalars['DateTime'];
  gender: AnimalGender;
  id: Scalars['String'];
  isDaycare: Scalars['Boolean'];
  labWorkDue?: Maybe<Scalars['DateTime']>;
  location: Scalars['String'];
  microchipNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  neutered: Scalars['Boolean'];
  nextDonationDate?: Maybe<Scalars['DateTime']>;
  notes: Array<DonorNoteOutput>;
  primaryVeterinarian: Scalars['String'];
  rabiesDate?: Maybe<Scalars['DateTime']>;
  region?: Maybe<Scalars['String']>;
  retiredDate?: Maybe<Scalars['DateTime']>;
  shortId: Scalars['Float'];
  species: AnimalSpecies;
  status: DonorStatus;
  totalCollected?: Maybe<Scalars['Float']>;
  type: DonorType;
  user?: Maybe<DonationOwnerReportOutput>;
  weight: Scalars['Float'];
};

export type DonationListFilterInput = {
  bloodTypes?: InputMaybe<Array<BloodType>>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  locationQuery?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<Scalars['String']>>;
  searchQuery?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type DonationListOutput = {
  __typename?: 'DonationListOutput';
  data: Array<DonationOutput>;
  meta: MetaData;
};

export enum DonationListSort {
  DonorName = 'DonorName',
  LastDonationDate = 'LastDonationDate',
  OwnerLastName = 'OwnerLastName',
}

export type DonationOutput = {
  __typename?: 'DonationOutput';
  actual?: Maybe<Scalars['Float']>;
  additionalInfo?: Maybe<AdditionalDonationInfoOutput>;
  approvedDonationsMl: Scalars['Float'];
  capillaryRefillTime: CapillaryRefillTime;
  collectionSet: CollectionSet;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  donationDate: Scalars['DateTime'];
  donor: DonationDonor;
  examinationNotes?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ineligible: Scalars['Boolean'];
  ineligibleReason?: Maybe<IneligibleReason>;
  ineligibleReasonDetails?: Maybe<Scalars['String']>;
  jugularVein: JugularVein;
  maxLimit: Scalars['Float'];
  mucousMembrane: Mm;
  packedCellVolume: Scalars['Float'];
  preferences?: Maybe<Scalars['String']>;
  products?: Maybe<Array<DonationProductOutput>>;
  pulse: Scalars['Float'];
  respiration: Scalars['Float'];
  shortId: Scalars['Float'];
  shouldDonate?: Maybe<Scalars['Boolean']>;
  temperature: Scalars['Float'];
  totalSolids: Scalars['Float'];
  unit?: Maybe<DonationUnit>;
  weatherTemperature: Scalars['Float'];
  weatherTypes: Array<WeatherType>;
  weight: Scalars['Float'];
};

export type DonationOwnerReportOutput = {
  __typename?: 'DonationOwnerReportOutput';
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  contactPreferences?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  locationPreferences?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  regionPreferences?: Maybe<Scalars['String']>;
  shortId: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
  zipCode?: Maybe<Scalars['String']>;
};

export type DonationProductOutput = {
  __typename?: 'DonationProductOutput';
  anticoagulant?: Maybe<Anticoagulant>;
  createdAt: Scalars['DateTime'];
  discardedProduct?: Maybe<DiscardedProductDataOutput>;
  expirationDate: Scalars['DateTime'];
  id: Scalars['String'];
  publishable: Scalars['Boolean'];
  published: Scalars['Boolean'];
  regionPreference?: Maybe<RegionOutput>;
  shortId: Scalars['Float'];
  status: ProductStatus;
  storageRequirements: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
  woocommerceCategoryId: Scalars['Float'];
  woocommerceOrderId?: Maybe<Scalars['Float']>;
  woocommerceProductId: Scalars['Float'];
};

export type DonationReportOutput = {
  __typename?: 'DonationReportOutput';
  actual?: Maybe<Scalars['Float']>;
  additionalInfo?: Maybe<AdditionalDonationInfoReportOutput>;
  approvedDonationsMl: Scalars['Float'];
  capillaryRefillTime: CapillaryRefillTime;
  comment?: Maybe<Scalars['String']>;
  donationDate: Scalars['DateTime'];
  donationNumber: Scalars['Float'];
  donor?: Maybe<DonationDonorReportOutput>;
  id: Scalars['String'];
  ineligibleReason?: Maybe<IneligibleReason>;
  jugularVein: JugularVein;
  mucousMembrane: Mm;
  packedCellVolume: Scalars['Float'];
  products?: Maybe<Array<ProductReportOutput>>;
  pulse: Scalars['Float'];
  respiration: Scalars['Float'];
  shortId: Scalars['Float'];
  temperature: Scalars['Float'];
  totalSolids: Scalars['Float'];
  unit?: Maybe<DonationUnit>;
  weight: Scalars['Float'];
  working: Scalars['Boolean'];
};

export enum DonationUnit {
  Unit0 = 'Unit0',
  Unit1 = 'Unit1',
  Unit2 = 'Unit2',
}

export type DonationWithAdditionalInfoInput = {
  additional: AdditionalDonationInfoInput;
  vitals: VitalsDonationInfoInput;
};

export type DonorLastDonationIntervalInput = {
  from: Scalars['Float'];
  to: Scalars['Float'];
};

export type DonorListFilterInput = {
  bloodType?: InputMaybe<Array<BloodType>>;
  groupId?: InputMaybe<Scalars['String']>;
  isDaycare?: InputMaybe<Scalars['Boolean']>;
  lastDonationInterval?: InputMaybe<DonorLastDonationIntervalInput>;
  locations?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  searchingQuery?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<DonorStatus>>;
  teamId?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<DonorType>>;
};

export enum DonorListSort {
  LastName = 'LastName',
  Name = 'Name',
  ReservedDate = 'ReservedDate',
  Team = 'Team',
}

export type DonorNoteFileOutput = {
  __typename?: 'DonorNoteFileOutput';
  createdAt: Scalars['DateTime'];
  file: FileOutput;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DonorNoteOutput = {
  __typename?: 'DonorNoteOutput';
  createdAt: Scalars['DateTime'];
  files: Array<DonorNoteFileOutput>;
  id: Scalars['String'];
  isPinned: Scalars['Boolean'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: UserOutput;
};

export type DonorOutput = {
  __typename?: 'DonorOutput';
  acceptSimparicaTrio: Scalars['Boolean'];
  age: Scalars['Float'];
  approvedDonationML: Scalars['Float'];
  attendDaycareDays?: Maybe<Array<Scalars['String']>>;
  becameReservedDonorDate?: Maybe<Scalars['DateTime']>;
  bloodType?: Maybe<BloodType>;
  breed?: Maybe<Scalars['String']>;
  canRegularlyAttendDaycare?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth: Scalars['DateTime'];
  distemperDate?: Maybe<Scalars['DateTime']>;
  distinguishableMarks?: Maybe<Scalars['String']>;
  donationFrequency: Scalars['Float'];
  donationMLApprovedAmount?: Maybe<Scalars['Float']>;
  firstCertificatePrinted: Scalars['Boolean'];
  gender: AnimalGender;
  hasHeartMurmur?: Maybe<Scalars['Boolean']>;
  hasMicrochip?: Maybe<Scalars['Boolean']>;
  hasTransfusion?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isDaycare?: Maybe<Scalars['Boolean']>;
  lastDonationDate?: Maybe<Scalars['DateTime']>;
  lastLabworkDate?: Maybe<Scalars['DateTime']>;
  lastLabworkFile?: Maybe<Array<FileOutput>>;
  location: Scalars['String'];
  medicationCourse?: Maybe<Scalars['String']>;
  microchipNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  neutered: Scalars['Boolean'];
  nextDonationDate?: Maybe<Scalars['DateTime']>;
  pictures?: Maybe<Array<DonorPictureOutput>>;
  primaryVeterinarian: Scalars['String'];
  rabiesDate?: Maybe<Scalars['DateTime']>;
  reserveNotes?: Maybe<Array<ReserveDonorNoteOutput>>;
  retiredDate?: Maybe<Scalars['DateTime']>;
  retiredReason?: Maybe<RetiredReasons>;
  retiredReasonComment?: Maybe<Scalars['String']>;
  secondCertificatePrinted: Scalars['Boolean'];
  shortId: Scalars['Float'];
  species: AnimalSpecies;
  status: DonorStatus;
  takeMedications?: Maybe<Scalars['Boolean']>;
  team?: Maybe<TeamOutput>;
  type: DonorType;
  user?: Maybe<OwnerOutput>;
  weight: Scalars['Float'];
};

export type DonorOwnerReportOutput = {
  __typename?: 'DonorOwnerReportOutput';
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  contactPreferences?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  locationPreferences?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  regionPreferences?: Maybe<Scalars['String']>;
  shortId: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
  zipCode?: Maybe<Scalars['String']>;
};

export type DonorPictureOutput = {
  __typename?: 'DonorPictureOutput';
  donor: DonorOutput;
  file: FileOutput;
  id: Scalars['String'];
  isPrimary: Scalars['Boolean'];
};

export type DonorReportOutput = {
  __typename?: 'DonorReportOutput';
  acceptSimparicaTrio: Scalars['Boolean'];
  age: Scalars['Float'];
  annualDVMComplete?: Maybe<Scalars['Boolean']>;
  approvedDonationML: Scalars['Float'];
  becameReservedDonorDate?: Maybe<Scalars['DateTime']>;
  bloodType?: Maybe<BloodType>;
  breed?: Maybe<Scalars['String']>;
  canRegularlyAttendDaycare?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  distemperDate?: Maybe<Scalars['DateTime']>;
  donationFrequency: Scalars['Float'];
  donationMLApprovedAmount?: Maybe<Scalars['Float']>;
  donorStatusUpdatedAt: Scalars['DateTime'];
  gender: AnimalGender;
  id: Scalars['String'];
  isDaycare: Scalars['Boolean'];
  labWorkDue?: Maybe<Scalars['DateTime']>;
  location: Scalars['String'];
  microchipNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  neutered: Scalars['Boolean'];
  nextDonationDate?: Maybe<Scalars['DateTime']>;
  notes: Array<DonorNoteOutput>;
  primaryVeterinarian: Scalars['String'];
  rabiesDate?: Maybe<Scalars['DateTime']>;
  region?: Maybe<Scalars['String']>;
  retiredDate?: Maybe<Scalars['DateTime']>;
  shortId: Scalars['Float'];
  species: AnimalSpecies;
  status: DonorStatus;
  totalCollected?: Maybe<Scalars['Float']>;
  type: DonorType;
  user?: Maybe<DonorOwnerReportOutput>;
  weight: Scalars['Float'];
};

export enum DonorStatus {
  Active = 'Active',
  Affiliate = 'Affiliate',
  AnnualLabworkDue = 'AnnualLabworkDue',
  Approved = 'Approved',
  Archived = 'Archived',
  LabworkPending = 'LabworkPending',
  NotAccepted = 'NotAccepted',
  Pending = 'Pending',
  Registered = 'Registered',
  Requested = 'Requested',
  Retired = 'Retired',
  Trp = 'Trp',
}

export enum DonorType {
  Grouped = 'Grouped',
  Individual = 'Individual',
}

export type DonorsListForTeamInput = {
  filter?: InputMaybe<DonorListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortDonorListInput>>;
  take?: InputMaybe<Scalars['Float']>;
  teamId: Scalars['String'];
  userId: Scalars['String'];
};

export type DonorsListInput = {
  filter?: InputMaybe<DonorListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortDonorListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type DonorsListOutput = {
  __typename?: 'DonorsListOutput';
  data: Array<DonorOutput>;
  meta: MetaData;
};

export type EmailConfirmationInput = {
  code: Scalars['String'];
};

export type EnrollDonorInput = {
  donorId: Scalars['String'];
  id: Scalars['String'];
};

export type FeeLineOutput = {
  __typename?: 'FeeLineOutput';
  amount: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  taxClass: Scalars['String'];
  taxStatus: TaxStatus;
  total: Scalars['String'];
  totalTax: Scalars['String'];
};

export type FileOutput = {
  __typename?: 'FileOutput';
  filename: Scalars['String'];
  id: Scalars['String'];
};

export type FindGroupInput = {
  id: Scalars['String'];
};

export type FindTeamInput = {
  id: Scalars['String'];
};

export enum FlowRate {
  Fast = 'Fast',
  IntermittentStopping = 'IntermittentStopping',
  Medium = 'Medium',
  NotApplicable = 'NotApplicable',
  OccasionalFlutter = 'OccasionalFlutter',
  PersistentFlutter = 'PersistentFlutter',
  Slow = 'Slow',
}

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type GetAdditionalDonationInfoInput = {
  id: Scalars['String'];
};

export type GetAppointmentInput = {
  id: Scalars['String'];
};

export type GetAppointmentsForCurrentUserInput = {
  end: Scalars['DateTime'];
  filter?: InputMaybe<CurrentUserAppointmentsFilterInput>;
  start: Scalars['DateTime'];
};

export type GetAppointmentsInput = {
  end: Scalars['DateTime'];
  filter?: InputMaybe<AppointmentsFilterInput>;
  start: Scalars['DateTime'];
};

export type GetCustomerInput = {
  id: Scalars['String'];
};

export type GetDonationInput = {
  id: Scalars['String'];
};

export type GetDonationReportsInput = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type GetDonationsListInput = {
  filter?: InputMaybe<DonationListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortDonationListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetDonorInput = {
  id: Scalars['String'];
};

export type GetDonorPicturesInput = {
  id: Scalars['String'];
};

export type GetDonorsByIdsInput = {
  ids: Array<Scalars['String']>;
};

export type GetDonorsReportInput = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type GetLabworkAttachmentsListInput = {
  labworkId: Scalars['String'];
};

export type GetLabworkInput = {
  id: Scalars['String'];
};

export type GetLivesSavedByDonorInput = {
  donorId: Scalars['String'];
};

export type GetLocationsInput = {
  filter?: InputMaybe<LocationsFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetNotificationListInput = {
  filter?: InputMaybe<NotificationListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetOrderInput = {
  id: Scalars['Float'];
};

export type GetOrdersListInput = {
  filter?: InputMaybe<OrdersListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<OrdersListSortInput>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetOwnerListInput = {
  filter?: InputMaybe<OwnersListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortUserListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetOwnersReportsInput = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type GetPermissionsInput = {
  filter?: InputMaybe<PermissionsFilterInput>;
};

export type GetPreviousDonationInput = {
  donorId: Scalars['String'];
};

export type GetProductInput = {
  id: Scalars['Float'];
};

export type GetProductReportsInput = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type GetProductsCountInput = {
  bloodType?: InputMaybe<Array<BloodType>>;
  statuses?: InputMaybe<Array<ProductStatus>>;
  woocommerceCategory?: InputMaybe<Array<Scalars['Float']>>;
};

export type GetPublicLocationsInput = {
  filter?: InputMaybe<LocationsFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetRequestInput = {
  id: Scalars['String'];
};

export type GetSpecificDonorDonationsListInput = {
  filter?: InputMaybe<SpecificDonorDonationListFilter>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortDonationListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetUserAttachmentsListInput = {
  userId: Scalars['String'];
};

export type GetUserInput = {
  id: Scalars['String'];
};

export type GetUserListInput = {
  filter?: InputMaybe<UsersListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortUserListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetWoocommerceProductCategoriesInput = {
  exclude?: InputMaybe<Array<Scalars['Float']>>;
  include?: InputMaybe<Array<Scalars['Float']>>;
  order?: InputMaybe<Scalars['String']>;
  orderby?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  per_page?: InputMaybe<Scalars['Float']>;
};

export type GetWoocommerceProductsInput = {
  category?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<Scalars['String']>;
  orderby?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  per_page?: InputMaybe<Scalars['Float']>;
};

export type GetWoocommerceTopSellersInput = {
  period?: InputMaybe<TopSellersPeriod>;
};

export type GroupListFilterInput = {
  searchingQuery?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type GroupListInput = {
  filter?: InputMaybe<GroupListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortGroupListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GroupListItemOutput = {
  __typename?: 'GroupListItemOutput';
  donorCount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  teamCount: Scalars['Float'];
  teams?: Maybe<Array<GroupTeamOutput>>;
  title: Scalars['String'];
  user?: Maybe<GroupListItemUserOutput>;
};

export type GroupListItemUserOutput = {
  __typename?: 'GroupListItemUserOutput';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type GroupListOutput = {
  __typename?: 'GroupListOutput';
  data: Array<GroupListItemOutput>;
  meta: MetaData;
};

export enum GroupListSort {
  Donors = 'Donors',
  Owner = 'Owner',
  Teams = 'Teams',
  Title = 'Title',
}

export type GroupOutput = {
  __typename?: 'GroupOutput';
  id: Scalars['String'];
  teams: Array<TeamOutput>;
  title: Scalars['String'];
  user?: Maybe<OwnerOutput>;
};

export type GroupTeamOutput = {
  __typename?: 'GroupTeamOutput';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type HolderOutput = {
  __typename?: 'HolderOutput';
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  contactPreferences?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletionIntentionAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  locationPreferences?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  productRegionPreference?: Maybe<RegionOutput>;
  shortId: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
  zipCode?: Maybe<Scalars['String']>;
};

export type IneligibleParticipantOutput = {
  __typename?: 'IneligibleParticipantOutput';
  createdAt: Scalars['DateTime'];
  donorData: Scalars['String'];
  id: Scalars['String'];
  ownerData: Scalars['String'];
};

export enum IneligibleReason {
  Medical = 'Medical',
  Other = 'Other',
  Temperament = 'Temperament',
}

export type IntervalInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type InviteOwnerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type InviteOwnerWithDonorInput = {
  donor: CreateDonorInput;
  owner: InviteOwnerInput;
};

export type InviteUserInput = {
  additionalContact?: InputMaybe<CreateContactInput>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  city?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locationPreferences?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  role: InviteUserRole;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export enum InviteUserRole {
  Admin = 'Admin',
  CollectionTeam = 'CollectionTeam',
  DogHandler = 'DogHandler',
  InventoryTeam = 'InventoryTeam',
  MedicalDirector = 'MedicalDirector',
  Owner = 'Owner',
}

export enum JugularVein {
  Left = 'Left',
  NotApplicable = 'NotApplicable',
  Right = 'Right',
}

export type LabworkAttachmentOutput = {
  __typename?: 'LabworkAttachmentOutput';
  createdAt: Scalars['DateTime'];
  file: FileOutput;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LabworkListFilterInput = {
  donorId?: InputMaybe<Scalars['String']>;
  searchingQuery?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<LabworkStatus>>;
};

export type LabworkListInput = {
  filter?: InputMaybe<LabworkListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortLabworkListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type LabworkListOutput = {
  __typename?: 'LabworkListOutput';
  data: Array<LabworkOutput>;
  meta: MetaData;
};

export enum LabworkListSort {
  CollectionDate = 'CollectionDate',
  LastName = 'LastName',
  PetName = 'PetName',
}

export type LabworkOutput = {
  __typename?: 'LabworkOutput';
  DVMExam: Scalars['Boolean'];
  collectionDate: Scalars['DateTime'];
  donor: DonorOutput;
  id: Scalars['String'];
  status: LabworkStatus;
};

export enum LabworkStatus {
  AnnualDue = 'AnnualDue',
  AwaitingDvmApproval = 'AwaitingDvmApproval',
  Cleared = 'Cleared',
  Collected = 'Collected',
  FailedCbc = 'FailedCBC',
  FailedChem = 'FailedChem',
  FailedHealthConcern = 'FailedHealthConcern',
  FailedInfectious = 'FailedInfectious',
  FailedTemperament = 'FailedTemperament',
  PendingResult = 'PendingResult',
  ReCheck = 'ReCheck',
  Sent = 'Sent',
}

export type LivesSavedOutput = {
  __typename?: 'LivesSavedOutput';
  lives: Scalars['Int'];
};

export type LocationOutput = {
  __typename?: 'LocationOutput';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  region?: Maybe<RegionOutput>;
  type: LocationType;
  updatedAt: Scalars['DateTime'];
};

export enum LocationType {
  ForDaycareOwners = 'ForDaycareOwners',
  ForExternalUsers = 'ForExternalUsers',
  ForInternalUsers = 'ForInternalUsers',
}

export type LocationsFilterInput = {
  regionIds?: InputMaybe<Array<Scalars['String']>>;
  searchingQuery?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<LocationType>>;
};

export type LoginInput = {
  durable?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export enum Mm {
  Blue = 'Blue',
  DarkPink = 'DarkPink',
  Grey = 'Grey',
  LightPink = 'LightPink',
  NotApplicable = 'NotApplicable',
  Pigmented = 'Pigmented',
  Pink = 'Pink',
  Tacky = 'Tacky',
  White = 'White',
  Yellow = 'Yellow',
}

export type MakePicturePrimaryInput = {
  pictureId: Scalars['String'];
};

export type MakeProductAvailableInput = {
  id: Scalars['String'];
};

export type ManageDonorsInTeamInput = {
  donorIds: Array<Scalars['String']>;
  teamId: Scalars['String'];
};

export type MetaData = {
  __typename?: 'MetaData';
  total: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite: UserOutput;
  addDonorsToDaycare: Scalars['Boolean'];
  addNote: DonorNoteOutput;
  addNoteToReserveDonor: ReserveDonorNoteOutput;
  approveDaycareAppointmentRequest: Scalars['Boolean'];
  archivePendingDonors: Scalars['Boolean'];
  archiveUser: Scalars['Boolean'];
  cancelDaycareAppointmentRequest: Scalars['Boolean'];
  cancelDeletingCurrentOwner: Scalars['Boolean'];
  cleanExpiredDatabaseData: Scalars['Boolean'];
  confirmEmail: Scalars['Boolean'];
  createAdditionalDonationInfo: AdditionalDonationInfoOutput;
  createAppointments: Array<AppointmentOutput>;
  createAppointmentsWithDonor: Array<AppointmentOutput>;
  createAppointmentsWithGroup: Array<AppointmentOutput>;
  createContact: ContactOutput;
  createDaycareAppointmentsWithDonors: Scalars['Boolean'];
  createDonation: DonationOutput;
  createDonationWithAdditionalInfo: DonationOutput;
  createDonationsWithAdditionalInfo: Scalars['Boolean'];
  createDonor: DonorOutput;
  createDonorForUser: DonorOutput;
  createDonors: Scalars['Boolean'];
  createDonorsForUser: Scalars['Boolean'];
  createGroup: GroupOutput;
  createLabwork: LabworkOutput;
  createLocation: Array<LocationOutput>;
  createProduct: ProductOutput;
  createProducts: Array<ProductOutput>;
  createRegion: Array<RegionOutput>;
  createTeam: TeamOutput;
  deleteDonation: Scalars['Boolean'];
  deleteDonor: Scalars['Boolean'];
  deleteLocation: Scalars['Boolean'];
  deleteNote: Scalars['Boolean'];
  deleteNotification: Scalars['Boolean'];
  deleteProduct: Scalars['Boolean'];
  deleteRegion: Scalars['Boolean'];
  deleteReserveDonorNote: Scalars['Boolean'];
  detachLabworkFile: Scalars['Boolean'];
  detachUserFile: Scalars['Boolean'];
  disproveDaycareAppointmentRequest: Scalars['Boolean'];
  enrollDonorToAppointment: Scalars['Boolean'];
  enrollMyDonorToAppointment: Scalars['Boolean'];
  eraseDeletedUsers: Scalars['Boolean'];
  eraseUsersWithUnconfirmedEmails: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  inviteOwnerWithDonor: Scalars['Boolean'];
  inviteUser: Scalars['Boolean'];
  login: LoginOutput;
  logout: Scalars['Boolean'];
  makeDonorPicturePrimary: Scalars['Boolean'];
  makeNotificationsRead: Scalars['Boolean'];
  makeProductAvailable: ProductOutput;
  manageDonorsInTeam: Scalars['Boolean'];
  processRequest: Scalars['Boolean'];
  refreshToken: LoginOutput;
  registerOnDaycareAppointment: Scalars['Boolean'];
  removeAdditionalDonationInfo: Scalars['Boolean'];
  removeAppointment: Scalars['Boolean'];
  removeContact: Scalars['Boolean'];
  removeCurrentOwner: Scalars['Boolean'];
  removeDonation: Scalars['Boolean'];
  removeGroup: Scalars['Boolean'];
  removeLabwork: Scalars['Boolean'];
  removeMyAppointment: Scalars['Boolean'];
  removeOwner: Scalars['Boolean'];
  removeRequest: Scalars['Boolean'];
  removeTeam: Scalars['Boolean'];
  removeUser: Scalars['Boolean'];
  resendEmailConfirmationCode: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetPrimaryDonorPictures: Scalars['Boolean'];
  retireActiveDonorsWithNoDonations: Scalars['Boolean'];
  sendEmail: Scalars['Boolean'];
  sendLabworkExpirationEmail: Scalars['Boolean'];
  sendTaskSchedulesForInternalUsers: Scalars['Boolean'];
  shareLabworkResults: Scalars['Boolean'];
  signUp: UserOutput;
  signUpWithDonor: Scalars['Boolean'];
  unArchiveUser: Scalars['Boolean'];
  unenrollDonorFromDaycare: Scalars['Boolean'];
  unenrollMyDonorFromAppointment: Scalars['Boolean'];
  updateAdditionalDonationInfo: AdditionalDonationInfoOutput;
  updateAppointment: AppointmentOutput;
  updateContact: ContactOutput;
  updateContacts: Array<ContactOutput>;
  updateCurrentOwnerEmail: UserOutput;
  updateCurrentOwnerInfo: UserOutput;
  updateDonation: DonationOutput;
  updateDonationComment: DonationOutput;
  updateDonationDonor: DonationOutput;
  updateDonor: DonorOutput;
  updateDonorStatus: Scalars['Boolean'];
  updateDonorStatusWithDueLabwork: Scalars['Boolean'];
  updateDonorsOwner?: Maybe<DonorOutput>;
  updateGroup: GroupOutput;
  updateGroupOwner?: Maybe<GroupOutput>;
  updateLabwork: LabworkOutput;
  updateLabworkStatus: LabworkOutput;
  updateLocation: Array<LocationOutput>;
  updateNote: DonorNoteOutput;
  updateNotificationConfigList: Array<NotificationConfigOutput>;
  updateOwnerEmail: UserOutput;
  updateOwnerInfo: UserOutput;
  updatePermissions: Array<PermissionsOutput>;
  /** This endpoint alowes to update manually update the main product fields, except status. In case if user attaches woocommerceOrderId, product status changes to "Sold" */
  updateProduct: ProductOutput;
  updateProductStatus: ProductOutput;
  updateRegion: Array<RegionOutput>;
  updateReserveDonorNote: ReserveDonorNoteOutput;
  updateTeam: TeamOutput;
  updateUserEmail: UserOutput;
  updateUserInfo: UserOutput;
  updateUserRole: UserOutput;
  updateVitalsParameterList: Array<VitalsParameterOutput>;
};

export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};

export type MutationAddDonorsToDaycareArgs = {
  input: AddDonorsToDaycareInput;
};

export type MutationAddNoteArgs = {
  input: CreateDonorNoteInput;
};

export type MutationAddNoteToReserveDonorArgs = {
  input: CreateDonorNoteInput;
};

export type MutationApproveDaycareAppointmentRequestArgs = {
  requestId: Scalars['String'];
};

export type MutationArchiveUserArgs = {
  input: ArchiveUserInput;
};

export type MutationCancelDaycareAppointmentRequestArgs = {
  requestId: Scalars['String'];
};

export type MutationConfirmEmailArgs = {
  input: EmailConfirmationInput;
};

export type MutationCreateAdditionalDonationInfoArgs = {
  input: CreateAdditionalDonationInfoInput;
};

export type MutationCreateAppointmentsArgs = {
  input: CreateEmptyAppointmentInput;
};

export type MutationCreateAppointmentsWithDonorArgs = {
  input: CreateAppointmentWithDonorInput;
};

export type MutationCreateAppointmentsWithGroupArgs = {
  input: CreateAppointmentWithGroupInput;
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

export type MutationCreateDaycareAppointmentsWithDonorsArgs = {
  input: CreateDaycareAppointmentWithDonorsInput;
};

export type MutationCreateDonationArgs = {
  input: CreateDonationInput;
};

export type MutationCreateDonationWithAdditionalInfoArgs = {
  input: CreateDonationWithAdditionalInfoInput;
};

export type MutationCreateDonationsWithAdditionalInfoArgs = {
  input: CreateDonationsWithAdditionalInfoInput;
};

export type MutationCreateDonorArgs = {
  input: CreateDonorInput;
};

export type MutationCreateDonorForUserArgs = {
  input: CreateDonorForUserInput;
};

export type MutationCreateDonorsArgs = {
  input: CreateDonors;
};

export type MutationCreateDonorsForUserArgs = {
  input: CreateDonorsForUserInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateLabworkArgs = {
  input: CreateLabworkInput;
};

export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationCreateProductsArgs = {
  input: CreateProductsInput;
};

export type MutationCreateRegionArgs = {
  input: CreateRegionInput;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationDeleteDonationArgs = {
  donationId: Scalars['String'];
};

export type MutationDeleteDonorArgs = {
  input: DeleteDonorInput;
};

export type MutationDeleteLocationArgs = {
  input: DeleteLocationInput;
};

export type MutationDeleteNoteArgs = {
  input: DeleteDonorNoteInput;
};

export type MutationDeleteNotificationArgs = {
  input: RemoveNotificationInput;
};

export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};

export type MutationDeleteRegionArgs = {
  input: DeleteRegionInput;
};

export type MutationDeleteReserveDonorNoteArgs = {
  input: DeleteDonorNoteInput;
};

export type MutationDetachLabworkFileArgs = {
  input: DetachLabworkFileInput;
};

export type MutationDetachUserFileArgs = {
  input: DetachUserAttachmentInput;
};

export type MutationDisproveDaycareAppointmentRequestArgs = {
  requestId: Scalars['String'];
};

export type MutationEnrollDonorToAppointmentArgs = {
  input: EnrollDonorInput;
};

export type MutationEnrollMyDonorToAppointmentArgs = {
  input: EnrollDonorInput;
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationInviteOwnerWithDonorArgs = {
  input: InviteOwnerWithDonorInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationMakeDonorPicturePrimaryArgs = {
  input: MakePicturePrimaryInput;
};

export type MutationMakeProductAvailableArgs = {
  input: MakeProductAvailableInput;
};

export type MutationManageDonorsInTeamArgs = {
  input: ManageDonorsInTeamInput;
};

export type MutationProcessRequestArgs = {
  input: UpdateRequestInput;
};

export type MutationRegisterOnDaycareAppointmentArgs = {
  input: RegisterOnDaycareAppointmentInput;
};

export type MutationRemoveAdditionalDonationInfoArgs = {
  input: RemoveAdditionalDonationInfoInput;
};

export type MutationRemoveAppointmentArgs = {
  input: RemoveAppointmentInput;
};

export type MutationRemoveContactArgs = {
  input: RemoveContactInput;
};

export type MutationRemoveDonationArgs = {
  input: RemoveDonationInput;
};

export type MutationRemoveGroupArgs = {
  input: RemoveGroupInput;
};

export type MutationRemoveLabworkArgs = {
  input: RemoveLabworkInput;
};

export type MutationRemoveMyAppointmentArgs = {
  input: RemoveAppointmentInput;
};

export type MutationRemoveOwnerArgs = {
  input: RemoveUserInput;
};

export type MutationRemoveRequestArgs = {
  input: RemoveRequestInput;
};

export type MutationRemoveTeamArgs = {
  input: RemoveTeamInput;
};

export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};

export type MutationResendEmailConfirmationCodeArgs = {
  input: ResendEmailConfirmationCodeInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationResetPrimaryDonorPicturesArgs = {
  input: ResetPrimaryPicturesInput;
};

export type MutationSendEmailArgs = {
  input: SendEmailInput;
};

export type MutationSendLabworkExpirationEmailArgs = {
  input: SendLabworkExpirationEmailInput;
};

export type MutationShareLabworkResultsArgs = {
  input: ShareLabworkResultsWithOwnerInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationSignUpWithDonorArgs = {
  input: SignUpWithDonorInput;
};

export type MutationUnArchiveUserArgs = {
  input: ArchiveUserInput;
};

export type MutationUnenrollDonorFromDaycareArgs = {
  input: DaycareUnenrollDonorInput;
};

export type MutationUnenrollMyDonorFromAppointmentArgs = {
  input: UnenrollDonorInput;
};

export type MutationUpdateAdditionalDonationInfoArgs = {
  input: UpdateAdditionalDonationInfoInput;
};

export type MutationUpdateAppointmentArgs = {
  updateAppointmentInput: UpdateAppointmentInput;
};

export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};

export type MutationUpdateContactsArgs = {
  input: UpdateContactsInput;
};

export type MutationUpdateCurrentOwnerEmailArgs = {
  input: UpdateUserEmailInput;
};

export type MutationUpdateCurrentOwnerInfoArgs = {
  input: UpdateOwnerInput;
};

export type MutationUpdateDonationArgs = {
  input: UpdateDonationInput;
};

export type MutationUpdateDonationCommentArgs = {
  input: UpdateDonationCommentInput;
};

export type MutationUpdateDonationDonorArgs = {
  input: UpdateDonationDonorInput;
};

export type MutationUpdateDonorArgs = {
  input: UpdateDonorInput;
};

export type MutationUpdateDonorStatusArgs = {
  input: UpdateDonorStatusInput;
};

export type MutationUpdateDonorsOwnerArgs = {
  input: UpdateDonorsOwnerInput;
};

export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};

export type MutationUpdateGroupOwnerArgs = {
  input: UpdateGroupOwnerInput;
};

export type MutationUpdateLabworkArgs = {
  input: UpdateLabworkInput;
};

export type MutationUpdateLabworkStatusArgs = {
  input: UpdateLabworkStatusInput;
};

export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};

export type MutationUpdateNoteArgs = {
  input: UpdateDonorNoteInput;
};

export type MutationUpdateNotificationConfigListArgs = {
  input: UpdateNotificationConfigListInput;
};

export type MutationUpdateOwnerEmailArgs = {
  input: UpdateUserEmailInput;
};

export type MutationUpdateOwnerInfoArgs = {
  input: UpdateOwnerInput;
};

export type MutationUpdatePermissionsArgs = {
  input: PermissionsInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationUpdateProductStatusArgs = {
  input: UpdateProductStatusInput;
};

export type MutationUpdateRegionArgs = {
  input: UpdateRegionInput;
};

export type MutationUpdateReserveDonorNoteArgs = {
  input: UpdateDonorNoteInput;
};

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};

export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};

export type MutationUpdateUserInfoArgs = {
  input: UpdateUserInputPartial;
};

export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};

export type MutationUpdateVitalsParameterListArgs = {
  input: UpdateVitalsParametersListInput;
};

export enum Notification {
  AppointmentsCancellation = 'AppointmentsCancellation',
  AppointmentsReminders = 'AppointmentsReminders',
  DaycareAppointmentRequestCreation = 'DaycareAppointmentRequestCreation',
  DaycareAppointmentRequestRejected = 'DaycareAppointmentRequestRejected',
  DaycareAppointmentUnenrolled = 'DaycareAppointmentUnenrolled',
  DonorCreation = 'DonorCreation',
  DonorUnenrolled = 'DonorUnenrolled',
  MakingAppointments = 'MakingAppointments',
  OwnerCreation = 'OwnerCreation',
  RemindersToTakePartAgain = 'RemindersToTakePartAgain',
  RequestCreation = 'RequestCreation',
  UserDeleting = 'UserDeleting',
  WasRegisteredOnDaycare = 'WasRegisteredOnDaycare',
}

export type NotificationAddedSubscriptionInput = {
  userId: Scalars['String'];
};

export type NotificationConfigOutput = {
  __typename?: 'NotificationConfigOutput';
  name: Notification;
  value: Scalars['Boolean'];
};

export type NotificationListFilterInput = {
  isRead?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type NotificationListOutput = {
  __typename?: 'NotificationListOutput';
  data: Array<NotificationOutput>;
  meta: MetaData;
};

export type NotificationOutput = {
  __typename?: 'NotificationOutput';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  payload: NotificationPayloadOutput;
  type: NotificationType;
  user: NotificationUserOutput;
};

export type NotificationPayloadOutput = {
  __typename?: 'NotificationPayloadOutput';
  appointment?: Maybe<AppointmentOutput>;
  appointmentId?: Maybe<Scalars['String']>;
  request?: Maybe<PartialRequestOutput>;
};

export enum NotificationType {
  AppointmentCanceled = 'AppointmentCanceled',
  AppointmentCreated = 'AppointmentCreated',
  AppointmentForGroupCreated = 'AppointmentForGroupCreated',
  DonorEnrolled = 'DonorEnrolled',
  DonorUnenrolled = 'DonorUnenrolled',
  RequestCreated = 'RequestCreated',
}

export type NotificationUserOutput = {
  __typename?: 'NotificationUserOutput';
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  contactPreferences?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<ContactOutput>>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletionIntentionAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  locationPreferences?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  productRegionPreference?: Maybe<RegionOutput>;
  role: RoleWithoutPermissionsOutput;
  shortId: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
  zipCode?: Maybe<Scalars['String']>;
};

export type NotificationWebSocketOutput = {
  __typename?: 'NotificationWebSocketOutput';
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  type: NotificationType;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderItemOutput = {
  __typename?: 'OrderItemOutput';
  id: Scalars['Float'];
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
};

export type OrderListOutput = {
  __typename?: 'OrderListOutput';
  data: Array<OrderOutput>;
  meta: MetaData;
};

export type OrderOutput = {
  __typename?: 'OrderOutput';
  billing: BillingOutput;
  customerId: Scalars['String'];
  date: Scalars['String'];
  feeLines: Array<FeeLineOutput>;
  id: Scalars['String'];
  items: Array<OrderItemOutput>;
  number: Scalars['Float'];
  paymentMethod: Scalars['String'];
  shipping: ShippingOutput;
  shippingLines: Array<ShippingLineOutput>;
  status: Scalars['String'];
  total: Scalars['String'];
  trackingCode?: Maybe<Scalars['String']>;
};

export enum OrderStatusFilter {
  Any = 'Any',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Failed = 'Failed',
  OnHold = 'OnHold',
  Pending = 'Pending',
  Processing = 'Processing',
  Refunded = 'Refunded',
  Trash = 'Trash',
}

export type OrdersListFilterInput = {
  number?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Array<OrderStatusFilter>>;
};

export enum OrdersListSort {
  Date = 'Date',
}

export type OrdersListSortInput = {
  fieldName: OrdersListSort;
  order: Order;
};

export type OwnerDonorReportOutput = {
  __typename?: 'OwnerDonorReportOutput';
  name: Scalars['String'];
  status: DonorStatus;
};

export type OwnerOutput = {
  __typename?: 'OwnerOutput';
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  contactPreferences?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<ContactOutput>>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletionIntentionAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  locationPreferences?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  productRegionPreference?: Maybe<RegionOutput>;
  role: RoleWithoutPermissionsOutput;
  shortId: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OwnerReportOutput = {
  __typename?: 'OwnerReportOutput';
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  contactPreferences?: Maybe<Scalars['String']>;
  donors?: Maybe<Array<OwnerDonorReportOutput>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  locationPreferences?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  regionPreferences?: Maybe<Scalars['String']>;
  shortId: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
  zipCode?: Maybe<Scalars['String']>;
};

export enum OwnerType {
  Group = 'Group',
  Individual = 'Individual',
}

export type OwnersListFilterInput = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  ownerTypes?: InputMaybe<Array<OwnerType>>;
  searchingQuery?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type PaginatedLocationsOutput = {
  __typename?: 'PaginatedLocationsOutput';
  data: Array<LocationOutput>;
  meta: MetaData;
};

export type PaginatedUserListOutput = {
  __typename?: 'PaginatedUserListOutput';
  data: Array<UserOutput>;
  meta: MetaData;
};

export type PartialDonorOutput = {
  __typename?: 'PartialDonorOutput';
  acceptSimparicaTrio?: Maybe<Scalars['Boolean']>;
  age?: Maybe<Scalars['Float']>;
  approvedDonationML?: Maybe<Scalars['Float']>;
  attendDaycareDays?: Maybe<Array<Scalars['String']>>;
  becameReservedDonorDate?: Maybe<Scalars['DateTime']>;
  bloodType?: Maybe<BloodType>;
  breed?: Maybe<Scalars['String']>;
  canRegularlyAttendDaycare?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  distemperDate?: Maybe<Scalars['DateTime']>;
  distinguishableMarks?: Maybe<Scalars['String']>;
  donationFrequency?: Maybe<Scalars['Float']>;
  donationMLApprovedAmount?: Maybe<Scalars['Float']>;
  firstCertificatePrinted?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<AnimalGender>;
  hasHeartMurmur?: Maybe<Scalars['Boolean']>;
  hasMicrochip?: Maybe<Scalars['Boolean']>;
  hasTransfusion?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isDaycare?: Maybe<Scalars['Boolean']>;
  lastDonationDate?: Maybe<Scalars['DateTime']>;
  lastLabworkDate?: Maybe<Scalars['DateTime']>;
  lastLabworkFile?: Maybe<Array<FileOutput>>;
  location?: Maybe<Scalars['String']>;
  medicationCourse?: Maybe<Scalars['String']>;
  microchipNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  neutered?: Maybe<Scalars['Boolean']>;
  nextDonationDate?: Maybe<Scalars['DateTime']>;
  pictures?: Maybe<Array<DonorPictureOutput>>;
  primaryVeterinarian?: Maybe<Scalars['String']>;
  rabiesDate?: Maybe<Scalars['DateTime']>;
  reserveNotes?: Maybe<Array<ReserveDonorNoteOutput>>;
  retiredDate?: Maybe<Scalars['DateTime']>;
  retiredReason?: Maybe<RetiredReasons>;
  retiredReasonComment?: Maybe<Scalars['String']>;
  secondCertificatePrinted?: Maybe<Scalars['Boolean']>;
  shortId?: Maybe<Scalars['Float']>;
  species?: Maybe<AnimalSpecies>;
  status?: Maybe<DonorStatus>;
  takeMedications?: Maybe<Scalars['Boolean']>;
  team?: Maybe<TeamOutput>;
  type?: Maybe<DonorType>;
  user?: Maybe<OwnerOutput>;
  weight?: Maybe<Scalars['Float']>;
};

export type PartialRequestOutput = {
  __typename?: 'PartialRequestOutput';
  donor: PartialDonorOutput;
  id: Scalars['String'];
  number: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PermissionInput = {
  id: Scalars['String'];
  value: Scalars['Boolean'];
};

export enum PermissionsEnum {
  AddCommentsToTheDonationAtTheDonorProfile = 'addCommentsToTheDonationAtTheDonorProfile',
  AddDonorToAppointment = 'addDonorToAppointment',
  AddNewDonation = 'addNewDonation',
  AddProducts = 'addProducts',
  AddUsers = 'addUsers',
  ApproveDisapproveRequests = 'approveDisapproveRequests',
  ApproveDonationMl = 'approveDonationML',
  ArchiveDonors = 'archiveDonors',
  ArchiveOwners = 'archiveOwners',
  ChangeDonorStatus = 'changeDonorStatus',
  ChangeUserRole = 'changeUserRole',
  CreateLabels = 'createLabels',
  CreateTimeSlotOrAppointment = 'createTimeSlotOrAppointment',
  CreteLocation = 'creteLocation',
  DeleteAppointments = 'deleteAppointments',
  DeleteDonorsPictures = 'deleteDonorsPictures',
  DeleteLabworkData = 'deleteLabworkData',
  DeleteLocation = 'deleteLocation',
  DeleteProduct = 'deleteProduct',
  DeleteUsers = 'deleteUsers',
  DetachLabworkFile = 'detachLabworkFile',
  DonationFrequency = 'donationFrequency',
  MakeProductsAvailable = 'makeProductsAvailable',
  ManagePermissions = 'managePermissions',
  PrintTasksSchedule = 'printTasksSchedule',
  ReceiveEmailWithTaskScheduleEveryFriday = 'receiveEmailWithTaskScheduleEveryFriday',
  ReceiveNotifications = 'receiveNotifications',
  ShareLabworkResults = 'shareLabworkResults',
  UpdateDonationData = 'updateDonationData',
  UpdateDonorsData = 'updateDonorsData',
  UpdateLabworkData = 'updateLabworkData',
  UpdateLabworkStatus = 'updateLabworkStatus',
  UpdateLocation = 'updateLocation',
  UpdateOwnProfile = 'updateOwnProfile',
  UpdateOwnersData = 'updateOwnersData',
  UpdateOwnersEmail = 'updateOwnersEmail',
  UpdateProduct = 'updateProduct',
  UpdateProductLocationPreference = 'updateProductLocationPreference',
  UpdateProductStatus = 'updateProductStatus',
  UpdateTheTrainingReserveDonors = 'updateTheTrainingReserveDonors',
  UpdateUserEmail = 'updateUserEmail',
  UpdateUserInfo = 'updateUserInfo',
  UpdateVitalsChartParameters = 'updateVitalsChartParameters',
  UploadDonorsPictures = 'uploadDonorsPictures',
  ViewAppointments = 'viewAppointments',
  ViewDonationDataCollectionPage = 'viewDonationDataCollectionPage',
  ViewDonorsPictures = 'viewDonorsPictures',
  ViewLabworkResults = 'viewLabworkResults',
  ViewListOfOrders = 'viewListOfOrders',
  ViewProductList = 'viewProductList',
  ViewReports = 'viewReports',
  ViewRequestsForApproval = 'viewRequestsForApproval',
  ViewSpecificDonationDataAtDonorProfile = 'viewSpecificDonationDataAtDonorProfile',
  ViewTasksSchedule = 'viewTasksSchedule',
  ViewTasksScheduleOfSpecificUser = 'viewTasksScheduleOfSpecificUser',
  ViewTheHistoryOfAppointments = 'viewTheHistoryOfAppointments',
  ViewTheListOfDonors = 'viewTheListOfDonors',
  ViewTheListOfOwners = 'viewTheListOfOwners',
  ViewTheListOfTrainingReserveDonors = 'viewTheListOfTrainingReserveDonors',
  ViewUsersList = 'viewUsersList',
  ViewVitalsChart = 'viewVitalsChart',
}

export type PermissionsFilterInput = {
  roles?: InputMaybe<Array<UserRole>>;
};

export type PermissionsInput = {
  values: Array<PermissionInput>;
};

export type PermissionsOutput = {
  __typename?: 'PermissionsOutput';
  id: Scalars['String'];
  permission: PermissionsEnum;
  role: RoleOutput;
  value: Scalars['Boolean'];
};

export enum ProcessingProductType {
  Nextday = 'Nextday',
  Sameday = 'Sameday',
}

export type ProductAdditionlaDonationInfoReportOutput = {
  __typename?: 'ProductAdditionlaDonationInfoReportOutput';
  id: Scalars['String'];
  poker?: Maybe<ProductDonationHolderReportOutput>;
  primaryHolder?: Maybe<ProductDonationHolderReportOutput>;
};

export type ProductDonationHolderReportOutput = {
  __typename?: 'ProductDonationHolderReportOutput';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type ProductDonationReportOutput = {
  __typename?: 'ProductDonationReportOutput';
  additionalInfo?: Maybe<ProductAdditionlaDonationInfoReportOutput>;
  donor?: Maybe<ProductDonorReportOutput>;
  id: Scalars['String'];
};

export type ProductDonorOwnerReportOutput = {
  __typename?: 'ProductDonorOwnerReportOutput';
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type ProductDonorReportOutput = {
  __typename?: 'ProductDonorReportOutput';
  id: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  user?: Maybe<ProductDonorOwnerReportOutput>;
};

export type ProductListFilterInput = {
  bloodType?: InputMaybe<Array<BloodType>>;
  orderId?: InputMaybe<Scalars['String']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<ProductStatus>>;
  woocommerceCategory?: InputMaybe<Array<Scalars['Float']>>;
};

export type ProductListInput = {
  filter?: InputMaybe<ProductListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<ProductListSortInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type ProductListOutput = {
  __typename?: 'ProductListOutput';
  data: Array<ProductOutput>;
  meta: MetaData;
};

export enum ProductListSort {
  DonorName = 'DonorName',
  ExpirationDate = 'ExpirationDate',
  OwnerLastName = 'OwnerLastName',
  ProductRegion = 'ProductRegion',
  Value = 'Value',
}

export type ProductListSortInput = {
  fieldName: ProductListSort;
  order: Order;
};

export type ProductOutput = {
  __typename?: 'ProductOutput';
  anticoagulant?: Maybe<Anticoagulant>;
  createdAt: Scalars['DateTime'];
  discardedProduct?: Maybe<DiscardedProductDataOutput>;
  donation: DonationOutput;
  expirationDate: Scalars['DateTime'];
  id: Scalars['String'];
  publishable: Scalars['Boolean'];
  published: Scalars['Boolean'];
  regionPreference?: Maybe<RegionOutput>;
  shortId: Scalars['Float'];
  status: ProductStatus;
  storageRequirements: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
  woocommerceCategoryId: Scalars['Float'];
  woocommerceOrderId?: Maybe<Scalars['Float']>;
  woocommerceProductId: Scalars['Float'];
};

export type ProductProcessorOutput = {
  __typename?: 'ProductProcessorOutput';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type ProductReportOutput = {
  __typename?: 'ProductReportOutput';
  anticoagulant?: Maybe<Anticoagulant>;
  createdAt: Scalars['DateTime'];
  discardedProduct?: Maybe<DiscardedProductDataReportOutput>;
  donation: ProductDonationReportOutput;
  expirationDate: Scalars['DateTime'];
  id: Scalars['String'];
  regionPreference?: Maybe<RegionOutput>;
  shortId: Scalars['Float'];
  status: ProductStatus;
  storageRequirements: Array<Scalars['String']>;
  value: Scalars['Float'];
  woocommerceCategoryId: Scalars['Float'];
  woocommerceProductId: Scalars['Float'];
};

export enum ProductStatus {
  Available = 'Available',
  Collected = 'Collected',
  Discarded = 'Discarded',
  Pending = 'Pending',
  Processed = 'Processed',
  Sold = 'Sold',
}

export type Query = {
  __typename?: 'Query';
  checkUserConnection: Scalars['Boolean'];
  currentUser: UserOutput;
  findAllGroup: GroupListOutput;
  findDonorsForTeam: DonorsListOutput;
  findGroup: GroupOutput;
  findTeam: TeamOutput;
  getAdditionalDonationInfo: AdditionalDonationInfoOutput;
  getAllIneligibleParticipants: AllIneligibleParticipantsOutput;
  getAppVersion: Scalars['String'];
  getAppointment: AppointmentOutput;
  getAppointments: Array<AppointmentOutput>;
  getAppointmentsDatesForDonor: Array<AppointmentDatesInfoOutput>;
  getAppointmentsDonorsList: Array<DonorOutput>;
  getAppointmentsForCurrentUser: Array<AppointmentOutput>;
  getChartsData: DonationListOutput;
  getCurrentUserDonorsList: DonorsListOutput;
  getCustomer: CustomerOutput;
  getDaycareAppointmentInfo: DaycareAppointmentDetailOutput;
  getDaycareAppointmentInfoForCurrentUser: UserDaycareAppointmentInfoOutput;
  getDonation: DonationOutput;
  getDonationReports: Array<DonationReportOutput>;
  getDonationsList: DonationListOutput;
  getDonor: DonorOutput;
  getDonorLivesSaved: LivesSavedOutput;
  getDonorNotes: Array<DonorNoteOutput>;
  getDonorPictures: Array<DonorPictureOutput>;
  getDonorsList: DonorsListOutput;
  getDonorsListByIds: Array<DonorOutput>;
  getDonorsReport: Array<DonorReportOutput>;
  getLabwork: LabworkOutput;
  getLabworkAttachments: Array<LabworkAttachmentOutput>;
  getLabworkList: LabworkListOutput;
  getLocationWithAvailableDaycare: Array<LocationOutput>;
  getLocations: PaginatedLocationsOutput;
  getMyPermissions: Array<PermissionsOutput>;
  getNotificationConfigList: Array<NotificationConfigOutput>;
  getNotificationsList: NotificationListOutput;
  getOrder: OrderOutput;
  getOrdersList: OrderListOutput;
  getOwner: OwnerOutput;
  getOwnerReports: Array<OwnerReportOutput>;
  getOwnersList: PaginatedUserListOutput;
  getPermissions: Array<PermissionsOutput>;
  getPreviousDonation: DonationOutput;
  getPrivateDaycareAppointmentForCurrentUser: Array<AppointmentOutput>;
  getProduct: ProductOutput;
  getProductReports: Array<ProductReportOutput>;
  getProducts: ProductListOutput;
  getProductsCount: Scalars['Float'];
  getPublicLocations: PaginatedLocationsOutput;
  getRegions: Array<RegionOutput>;
  getRequest: RequestOutput;
  getRequestList: RequestListOutput;
  getSpecificDonorDonationsList: DonationListOutput;
  getUser: UserOutput;
  getUserAttachments: Array<UserAttachmentOutput>;
  getUserContacts: Array<ContactOutput>;
  getUserDonorsList: DonorsListOutput;
  getUsersList: PaginatedUserListOutput;
  getVitalsParameterList: Array<VitalsParameterOutput>;
  getWoocommerceCategories: Array<WoocommerceProductCategoryOutput>;
  getWoocommerceProducts: WoocommerceProductListOutput;
  getWoocommerceTopSellers: Array<WoocommerceTopSellerOutput>;
  hasDonorRelatedConnections: Scalars['Boolean'];
};

export type QueryFindAllGroupArgs = {
  input: GroupListInput;
};

export type QueryFindDonorsForTeamArgs = {
  input: DonorsListForTeamInput;
};

export type QueryFindGroupArgs = {
  input: FindGroupInput;
};

export type QueryFindTeamArgs = {
  input: FindTeamInput;
};

export type QueryGetAdditionalDonationInfoArgs = {
  input: GetAdditionalDonationInfoInput;
};

export type QueryGetAppointmentArgs = {
  input: GetAppointmentInput;
};

export type QueryGetAppointmentsArgs = {
  input: GetAppointmentsInput;
};

export type QueryGetAppointmentsDatesForDonorArgs = {
  donorId: Scalars['String'];
};

export type QueryGetAppointmentsForCurrentUserArgs = {
  input: GetAppointmentsForCurrentUserInput;
};

export type QueryGetChartsDataArgs = {
  input: GetSpecificDonorDonationsListInput;
};

export type QueryGetCurrentUserDonorsListArgs = {
  input: CurrentUserDonorListInput;
};

export type QueryGetCustomerArgs = {
  input: GetCustomerInput;
};

export type QueryGetDaycareAppointmentInfoArgs = {
  input: Scalars['String'];
};

export type QueryGetDaycareAppointmentInfoForCurrentUserArgs = {
  appointmentId: Scalars['String'];
};

export type QueryGetDonationArgs = {
  input: GetDonationInput;
};

export type QueryGetDonationReportsArgs = {
  input: GetDonationReportsInput;
};

export type QueryGetDonationsListArgs = {
  input: GetDonationsListInput;
};

export type QueryGetDonorArgs = {
  input: GetDonorInput;
};

export type QueryGetDonorLivesSavedArgs = {
  input: GetLivesSavedByDonorInput;
};

export type QueryGetDonorNotesArgs = {
  donorId: Scalars['String'];
};

export type QueryGetDonorPicturesArgs = {
  input: GetDonorPicturesInput;
};

export type QueryGetDonorsListArgs = {
  input: DonorsListInput;
};

export type QueryGetDonorsListByIdsArgs = {
  input: GetDonorsByIdsInput;
};

export type QueryGetDonorsReportArgs = {
  input: GetDonorsReportInput;
};

export type QueryGetLabworkArgs = {
  input: GetLabworkInput;
};

export type QueryGetLabworkAttachmentsArgs = {
  input: GetLabworkAttachmentsListInput;
};

export type QueryGetLabworkListArgs = {
  input: LabworkListInput;
};

export type QueryGetLocationsArgs = {
  input: GetLocationsInput;
};

export type QueryGetNotificationsListArgs = {
  input: GetNotificationListInput;
};

export type QueryGetOrderArgs = {
  input: GetOrderInput;
};

export type QueryGetOrdersListArgs = {
  input: GetOrdersListInput;
};

export type QueryGetOwnerArgs = {
  input: GetUserInput;
};

export type QueryGetOwnerReportsArgs = {
  input: GetOwnersReportsInput;
};

export type QueryGetOwnersListArgs = {
  input: GetOwnerListInput;
};

export type QueryGetPermissionsArgs = {
  input: GetPermissionsInput;
};

export type QueryGetPreviousDonationArgs = {
  input: GetPreviousDonationInput;
};

export type QueryGetPrivateDaycareAppointmentForCurrentUserArgs = {
  input: GetAppointmentsForCurrentUserInput;
};

export type QueryGetProductArgs = {
  input: GetProductInput;
};

export type QueryGetProductReportsArgs = {
  input: GetProductReportsInput;
};

export type QueryGetProductsArgs = {
  input: ProductListInput;
};

export type QueryGetProductsCountArgs = {
  input: GetProductsCountInput;
};

export type QueryGetPublicLocationsArgs = {
  input: GetPublicLocationsInput;
};

export type QueryGetRequestArgs = {
  input: GetRequestInput;
};

export type QueryGetRequestListArgs = {
  input: RequestsListInput;
};

export type QueryGetSpecificDonorDonationsListArgs = {
  input: GetSpecificDonorDonationsListInput;
};

export type QueryGetUserArgs = {
  input: GetUserInput;
};

export type QueryGetUserAttachmentsArgs = {
  input: GetUserAttachmentsListInput;
};

export type QueryGetUserDonorsListArgs = {
  input: UserDonorListInput;
};

export type QueryGetUsersListArgs = {
  input: GetUserListInput;
};

export type QueryGetWoocommerceCategoriesArgs = {
  input: GetWoocommerceProductCategoriesInput;
};

export type QueryGetWoocommerceProductsArgs = {
  input: GetWoocommerceProductsInput;
};

export type QueryGetWoocommerceTopSellersArgs = {
  input: GetWoocommerceTopSellersInput;
};

export type QueryHasDonorRelatedConnectionsArgs = {
  input: DeleteDonorInput;
};

export enum ReasonDiscardProduct {
  Clots = 'Clots',
  Hemolyzed = 'Hemolyzed',
  LowVolume = 'LowVolume',
  Other = 'Other',
}

export type RegionOutput = {
  __typename?: 'RegionOutput';
  accountingCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RegisterOnDaycareAppointmentInput = {
  appointmentId: Scalars['String'];
  donorIds?: InputMaybe<Array<Scalars['String']>>;
  note?: InputMaybe<Scalars['String']>;
  ownerResponse: Scalars['Boolean'];
};

export type RemoveAdditionalDonationInfoInput = {
  id: Scalars['String'];
};

export type RemoveAppointmentInput = {
  id: Scalars['String'];
};

export type RemoveContactInput = {
  id: Scalars['String'];
};

export type RemoveDonationInput = {
  id: Scalars['String'];
};

export type RemoveGroupInput = {
  id: Scalars['String'];
};

export type RemoveLabworkInput = {
  id: Scalars['String'];
};

export type RemoveNotificationInput = {
  id: Scalars['String'];
};

export type RemoveRequestInput = {
  id: Scalars['String'];
};

export type RemoveTeamInput = {
  id: Scalars['String'];
};

export type RemoveUserInput = {
  id: Scalars['String'];
};

export type RequestListFilterInput = {
  number?: InputMaybe<Scalars['Float']>;
  ownerName?: InputMaybe<Scalars['String']>;
};

export type RequestListOutput = {
  __typename?: 'RequestListOutput';
  data: Array<RequestOutput>;
  meta: MetaData;
};

export enum RequestListSort {
  Date = 'Date',
  Owner = 'Owner',
}

export type RequestOutput = {
  __typename?: 'RequestOutput';
  donor: DonorOutput;
  id: Scalars['String'];
  number: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum RequestStatus {
  Approve = 'Approve',
  Reject = 'Reject',
}

export type RequestsListInput = {
  filter?: InputMaybe<RequestListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortRequestListInput>>;
  take?: InputMaybe<Scalars['Float']>;
};

export type ResendEmailConfirmationCodeInput = {
  email: Scalars['String'];
};

export type ReserveDonorNoteOutput = {
  __typename?: 'ReserveDonorNoteOutput';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  text: Scalars['String'];
};

export type ResetPasswordInput = {
  code: Scalars['String'];
  password: Scalars['String'];
};

export type ResetPrimaryPicturesInput = {
  donorId: Scalars['String'];
};

export enum RetiredReasons {
  Age = 'Age',
  Health = 'Health',
  Moving = 'Moving',
  Other = 'Other',
  Owner = 'Owner',
  Request = 'Request',
  Temperament = 'Temperament',
  Unresponsive = 'Unresponsive',
}

export type RoleOutput = {
  __typename?: 'RoleOutput';
  id: Scalars['String'];
  value: UserRole;
};

export type RoleWithoutPermissionsOutput = {
  __typename?: 'RoleWithoutPermissionsOutput';
  id: Scalars['String'];
  value: UserRole;
};

export type SendEmailInput = {
  html?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
  to: Array<Scalars['String']>;
};

export type SendLabworkExpirationEmailInput = {
  cronJobId: Scalars['String'];
  donorLink?: InputMaybe<Scalars['String']>;
  templateName: Scalars['String'];
  to: Array<Scalars['String']>;
};

export type ShareLabworkResultsWithOwnerInput = {
  fileId: Scalars['String'];
  labworkId: Scalars['String'];
};

export type ShippingLineOutput = {
  __typename?: 'ShippingLineOutput';
  id: Scalars['Float'];
  instanceId: Scalars['String'];
  methodId: Scalars['String'];
  methodTitle: Scalars['String'];
  total: Scalars['String'];
  totalTax: Scalars['String'];
};

export type ShippingOutput = {
  __typename?: 'ShippingOutput';
  city: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  firstAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  postcode: Scalars['String'];
  secondAddress?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export type SignUpInput = {
  contactPreferences?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpWithDonorInput = {
  donor: CreateDonorInput;
  user: SignUpWithoutPassword;
};

export type SignUpWithoutPassword = {
  contactPreferences?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locationPreferences?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type SortDonationListInput = {
  fieldName: DonationListSort;
  order: Order;
};

export type SortDonorListInput = {
  fieldName: DonorListSort;
  order: Order;
};

export type SortGroupListInput = {
  fieldName: GroupListSort;
  order: Order;
};

export type SortLabworkListInput = {
  fieldName: LabworkListSort;
  order: Order;
};

export type SortRequestListInput = {
  fieldName: RequestListSort;
  order: Order;
};

export type SortUserDonorListInput = {
  fieldName: UserDonorListSort;
  order: Order;
};

export type SortUserListInput = {
  fieldName: UserListSort;
  order: Order;
};

export type SpecificDonorDonationListFilter = {
  bloodTypes?: InputMaybe<Array<BloodType>>;
  donorId: Scalars['String'];
  fromDate?: InputMaybe<Scalars['DateTime']>;
  locationQuery?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<Scalars['String']>>;
  searchQuery?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  notificationAdded: NotificationWebSocketOutput;
};

export type SubscriptionNotificationAddedArgs = {
  input: NotificationAddedSubscriptionInput;
};

export enum TaxStatus {
  None = 'None',
  Taxable = 'Taxable',
}

export type TeamOutput = {
  __typename?: 'TeamOutput';
  donors?: Maybe<Array<DonorOutput>>;
  group?: Maybe<GroupOutput>;
  id: Scalars['String'];
  title: Scalars['String'];
};

export enum TopSellersPeriod {
  LastMonth = 'LastMonth',
  Month = 'Month',
  Week = 'Week',
  Year = 'Year',
}

export type UnenrollDonorInput = {
  donorId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateAdditionalDonationInfoInput = {
  dislikes?: InputMaybe<Array<Scalars['String']>>;
  donationId?: InputMaybe<Scalars['String']>;
  donationPosition?: InputMaybe<Scalars['String']>;
  donationPositionFreeText?: InputMaybe<Scalars['String']>;
  donorMovement?: InputMaybe<Scalars['String']>;
  emotionalSupport?: InputMaybe<Array<Scalars['String']>>;
  flowRate?: InputMaybe<FlowRate>;
  holdersPosition?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  likes?: InputMaybe<Array<Scalars['String']>>;
  numberOfCollectionUnitsUsed?: InputMaybe<CollectionUnitsUsed>;
  pokerId?: InputMaybe<Scalars['String']>;
  primaryHolderId?: InputMaybe<Scalars['String']>;
  secondaryHolderId?: InputMaybe<Scalars['String']>;
  totalCollectedMls?: InputMaybe<Scalars['Float']>;
  treatPreferences?: InputMaybe<Array<Scalars['String']>>;
  typeOfMovement?: InputMaybe<Scalars['String']>;
  vascularStructure?: InputMaybe<VascularStructure>;
  veinUsed?: InputMaybe<VeinUsed>;
};

export type UpdateAppointmentInput = {
  appointmentId: Scalars['String'];
  dates?: InputMaybe<Array<IntervalInput>>;
  donorId?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type: AppointmentType;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateContactInput = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateContactsInput = {
  contacts: Array<UpdateContactInput>;
};

export type UpdateDonationCommentInput = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateDonationDonorInput = {
  donorId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateDonationInput = {
  actual?: InputMaybe<Scalars['Float']>;
  approvedDonationsMl?: InputMaybe<Scalars['Float']>;
  capillaryRefillTime?: InputMaybe<CapillaryRefillTime>;
  collectionSet?: InputMaybe<CollectionSet>;
  comment?: InputMaybe<Scalars['String']>;
  donationDate?: InputMaybe<Scalars['DateTime']>;
  examinationNotes?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  ineligible?: InputMaybe<Scalars['Boolean']>;
  ineligibleReason?: InputMaybe<IneligibleReason>;
  ineligibleReasonDetails?: InputMaybe<Scalars['String']>;
  jugularVein?: InputMaybe<JugularVein>;
  maxLimit?: InputMaybe<Scalars['Float']>;
  mucousMembrane?: InputMaybe<Mm>;
  packedCellVolume?: InputMaybe<Scalars['Float']>;
  preferences?: InputMaybe<Scalars['String']>;
  pulse?: InputMaybe<Scalars['Float']>;
  respiration?: InputMaybe<Scalars['Float']>;
  shouldDonate?: InputMaybe<Scalars['Boolean']>;
  temperature?: InputMaybe<Scalars['Float']>;
  totalSolids?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<DonationUnit>;
  weatherTemperature?: InputMaybe<Scalars['Float']>;
  weatherTypes?: InputMaybe<Array<WeatherType>>;
  weight?: InputMaybe<Scalars['Float']>;
  working?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDonorInput = {
  acceptSimparicaTrio?: InputMaybe<Scalars['Boolean']>;
  approvedDonationML?: InputMaybe<Scalars['Float']>;
  attendDaycareDays?: InputMaybe<Array<Scalars['String']>>;
  bloodType?: InputMaybe<BloodType>;
  breed?: InputMaybe<Scalars['String']>;
  canRegularlyAttendDaycare?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  distemperDate?: InputMaybe<Scalars['DateTime']>;
  distinguishableMarks?: InputMaybe<Scalars['String']>;
  donationFrequency?: InputMaybe<Scalars['Float']>;
  donationMLApprovedAmount?: InputMaybe<Scalars['Float']>;
  firstCertificatePrinted?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<AnimalGender>;
  hasHeartMurmur?: InputMaybe<Scalars['Boolean']>;
  hasMicrochip?: InputMaybe<Scalars['Boolean']>;
  hasTransfusion?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isDaycare?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  medicationCourse?: InputMaybe<Scalars['String']>;
  microchipNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  neutered?: InputMaybe<Scalars['Boolean']>;
  primaryVeterinarian?: InputMaybe<Scalars['String']>;
  rabiesDate?: InputMaybe<Scalars['DateTime']>;
  retiredDate?: InputMaybe<Scalars['DateTime']>;
  retiredReason?: InputMaybe<RetiredReasons>;
  retiredReasonComment?: InputMaybe<Scalars['String']>;
  secondCertificatePrinted?: InputMaybe<Scalars['Boolean']>;
  species?: InputMaybe<AnimalSpecies>;
  status?: InputMaybe<DonorStatus>;
  takeMedications?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DonorType>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdateDonorNoteInput = {
  id: Scalars['String'];
  isPinned?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type UpdateDonorStatusInput = {
  id: Scalars['String'];
  status: DonorStatus;
};

export type UpdateDonorsOwnerInput = {
  id: Scalars['String'];
  ownerId?: InputMaybe<Scalars['String']>;
};

export type UpdateGroupInput = {
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateGroupOwnerInput = {
  id: Scalars['String'];
  ownerId: Scalars['String'];
};

export type UpdateLabworkInput = {
  DVMExam?: InputMaybe<Scalars['Boolean']>;
  collectionDate?: InputMaybe<Scalars['DateTime']>;
  donorId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateLabworkStatusInput = {
  id: Scalars['String'];
  status: LabworkStatus;
};

export type UpdateLocationInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<LocationType>;
};

export type UpdateNotificationConfigInput = {
  name: Notification;
  value: Scalars['Boolean'];
};

export type UpdateNotificationConfigListInput = {
  notifications: Array<UpdateNotificationConfigInput>;
};

export type UpdateOwnerInput = {
  additionalContact?: InputMaybe<ContactInput>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  city?: InputMaybe<Scalars['String']>;
  contactPreferences?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  locationPreferences?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  productRegionPreferenceId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateProductInput = {
  id: Scalars['String'];
  status?: InputMaybe<ProductStatus>;
  value?: InputMaybe<Scalars['Float']>;
  woocommerceOrderId?: InputMaybe<Scalars['Float']>;
};

export type UpdateProductStatusInput = {
  id: Scalars['String'];
  status: ProductStatus;
};

export type UpdateRegionInput = {
  accountingCode?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateRequestInput = {
  id: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  status: RequestStatus;
};

export type UpdateTeamInput = {
  donorIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateUserEmailInput = {
  email: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateUserInputPartial = {
  additionalContact?: InputMaybe<ContactInput>;
  city?: InputMaybe<Scalars['String']>;
  contactPreferences?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  productRegionPreferenceId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateUserRoleInput = {
  id: Scalars['String'];
  role: UserRole;
};

export type UpdateVitalsParameterInput = {
  criticalValues?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  percentageDifference?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<VitalsParametersType>;
};

export type UpdateVitalsParametersListInput = {
  vitalsParameters: Array<UpdateVitalsParameterInput>;
};

export type UserAttachmentOutput = {
  __typename?: 'UserAttachmentOutput';
  createdAt: Scalars['DateTime'];
  file: FileOutput;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UserDaycareAppointmentInfoOutput = {
  __typename?: 'UserDaycareAppointmentInfoOutput';
  daycareId?: Maybe<Scalars['String']>;
  registeredDonors: Array<DonorOutput>;
  requests: Array<DaycareAppointmentRequestOutput>;
  status: DaycareRegistrationStatus;
};

export type UserDonorListInput = {
  filter?: InputMaybe<DonorListFilterInput>;
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortDonorListInput>>;
  take?: InputMaybe<Scalars['Float']>;
  userId: Scalars['String'];
};

export enum UserDonorListSort {
  Gender = 'Gender',
  Name = 'Name',
}

export enum UserListSort {
  FirstName = 'FirstName',
  LastName = 'LastName',
  LocationPreferences = 'LocationPreferences',
}

export type UserOutput = {
  __typename?: 'UserOutput';
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  contactPreferences?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<ContactOutput>>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletionIntentionAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  lastName: Scalars['String'];
  locationPreferences?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  productRegionPreference?: Maybe<RegionOutput>;
  role: RoleOutput;
  shortId: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  type?: Maybe<OwnerType>;
  zipCode?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'Admin',
  CollectionTeam = 'CollectionTeam',
  DogHandler = 'DogHandler',
  InventoryTeam = 'InventoryTeam',
  MedicalDirector = 'MedicalDirector',
  Owner = 'Owner',
}

export type UsersListFilterInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  ownerTypes?: InputMaybe<Array<OwnerType>>;
  productRegionPreferenceId?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<UserRole>>;
  searchingQuery?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export enum VascularStructure {
  Deep = 'Deep',
  Lateral = 'Lateral',
  Medial = 'Medial',
  Superficial = 'Superficial',
}

export enum VeinUsed {
  Both = 'Both',
  LeftJugular = 'LeftJugular',
  NotApplicable = 'NotApplicable',
  RightJugular = 'RightJugular',
}

export type VitalsDonationInfoInput = {
  actual?: InputMaybe<Scalars['Float']>;
  capillaryRefillTime: CapillaryRefillTime;
  collectionSet: CollectionSet;
  comment?: InputMaybe<Scalars['String']>;
  donationDate?: InputMaybe<Scalars['DateTime']>;
  examinationNotes?: InputMaybe<Scalars['String']>;
  expected: Scalars['Float'];
  jugularVein: JugularVein;
  maxLimit: Scalars['Float'];
  mucousMembrane: Mm;
  packedCellVolume: Scalars['Float'];
  preferences?: InputMaybe<Scalars['String']>;
  pulse: Scalars['Float'];
  respiration: Scalars['Float'];
  shortDonorId: Scalars['String'];
  shouldDonate: Scalars['Boolean'];
  temperature: Scalars['Float'];
  totalSolids: Scalars['Float'];
  unit?: InputMaybe<DonationUnit>;
  weatherTemperature: Scalars['Float'];
  weatherTypes: Array<WeatherType>;
  weight: Scalars['Float'];
  working: Scalars['Boolean'];
};

export type VitalsParameterOutput = {
  __typename?: 'VitalsParameterOutput';
  criticalValues?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  percentageDifference?: Maybe<Scalars['Float']>;
  type: VitalsParametersType;
};

export enum VitalsParametersType {
  CapillaryRefillTimeValue = 'CapillaryRefillTimeValue',
  MucousMembrane = 'MucousMembrane',
  PackedCellVolume = 'PackedCellVolume',
  Pulse = 'Pulse',
  Respiration = 'Respiration',
  TemperatureToCheck = 'TemperatureToCheck',
  TemperatureToStop = 'TemperatureToStop',
  TotalSolids = 'TotalSolids',
  Weight = 'Weight',
}

export enum WeatherType {
  Clear = 'Clear',
  Cloudy = 'Cloudy',
  Drizzle = 'Drizzle',
  NotApplicable = 'NotApplicable',
  Overcast = 'Overcast',
  PartiallyCloudy = 'PartiallyCloudy',
  Rain = 'Rain',
  Snow = 'Snow',
  Stormy = 'Stormy',
  Sunny = 'Sunny',
}

export type WoocommerceProductCategoryOutput = {
  __typename?: 'WoocommerceProductCategoryOutput';
  count: Scalars['Float'];
  id: Scalars['Float'];
  name: Scalars['String'];
  parent: Scalars['Float'];
  slug: Scalars['String'];
};

export type WoocommerceProductListOutput = {
  __typename?: 'WoocommerceProductListOutput';
  data: Array<WoocommerceProductOutput>;
  meta: MetaData;
};

export type WoocommerceProductOutput = {
  __typename?: 'WoocommerceProductOutput';
  categories: Array<Category>;
  date_created: Scalars['String'];
  date_modified: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  price: Scalars['String'];
  slug: Scalars['String'];
  status: Scalars['String'];
  stock_quantity?: Maybe<Scalars['Float']>;
  stock_status: Scalars['String'];
  type: Scalars['String'];
};

export type WoocommerceTopSellerOutput = {
  __typename?: 'WoocommerceTopSellerOutput';
  name: Scalars['String'];
  product_id: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type UpdateAllPermissionsMutationVariables = Exact<{
  input: PermissionsInput;
}>;

export type UpdateAllPermissionsMutation = {
  __typename?: 'Mutation';
  updatePermissions: Array<{
    __typename?: 'PermissionsOutput';
    id: string;
    value: boolean;
    permission: PermissionsEnum;
  }>;
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteUser: boolean;
};

export type AcceptInviteMutationVariables = Exact<{
  input: AcceptInviteInput;
}>;

export type AcceptInviteMutation = {
  __typename?: 'Mutation';
  acceptInvite: { __typename?: 'UserOutput'; email: string };
};

export type RemoveUserMutationVariables = Exact<{
  input: RemoveUserInput;
}>;

export type RemoveUserMutation = {
  __typename?: 'Mutation';
  removeUser: boolean;
};

export type RemoveOwnerMutationVariables = Exact<{
  input: RemoveUserInput;
}>;

export type RemoveOwnerMutation = {
  __typename?: 'Mutation';
  removeOwner: boolean;
};

export type UpdateUserInfoMutationVariables = Exact<{
  input: UpdateUserInputPartial;
}>;

export type UpdateUserInfoMutation = {
  __typename?: 'Mutation';
  updateUserInfo: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type UpdateUserEmailMutationVariables = Exact<{
  input: UpdateUserEmailInput;
}>;

export type UpdateUserEmailMutation = {
  __typename?: 'Mutation';
  updateUserEmail: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type UpdateUserRoleMutationVariables = Exact<{
  input: UpdateUserRoleInput;
}>;

export type UpdateUserRoleMutation = {
  __typename?: 'Mutation';
  updateUserRole: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type GetPermissionsQueryVariables = Exact<{
  input: GetPermissionsInput;
}>;

export type GetPermissionsQuery = {
  __typename?: 'Query';
  getPermissions: Array<{
    __typename?: 'PermissionsOutput';
    id: string;
    value: boolean;
    permission: PermissionsEnum;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
  }>;
};

export type GetUserQueryVariables = Exact<{
  input: GetUserInput;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type AppointmentFragment = {
  __typename?: 'AppointmentOutput';
  id: string;
  createdAt: any;
  updatedAt: any;
  title: string;
  start: any;
  end: any;
  location: string;
  notes?: string | null;
  isDaycare?: boolean | null;
  isPrivate?: boolean | null;
};

export type AppointmentUserFragment = {
  __typename?: 'AppointmentUser';
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locationPreferences?: string | null;
  birthday?: any | null;
  shortId: number;
};

export type AppointmentGroupFragment = {
  __typename?: 'AppointmentGroupOutput';
  id: string;
  title: string;
};

export type AppointmentTeamFragment = {
  __typename?: 'AppointmentTeamOutput';
  id: string;
  title: string;
};

export type AppointmentDonorFragment = {
  __typename?: 'AppointmentDonor';
  id: string;
  age: number;
  gender: AnimalGender;
  hasTransfusion?: boolean | null;
  name: string;
  neutered: boolean;
  primaryVeterinarian: string;
  species: AnimalSpecies;
  takeMedications?: boolean | null;
  weight: number;
  bloodType?: BloodType | null;
  status: DonorStatus;
  type: DonorType;
  location: string;
  distinguishableMarks?: string | null;
  microchipNumber?: string | null;
  medicationCourse?: string | null;
  comment?: string | null;
  breed?: string | null;
  color?: string | null;
  createdAt: any;
  shortId: number;
  firstCertificatePrinted: boolean;
  secondCertificatePrinted: boolean;
};

export type CreateAppointmentsMutationVariables = Exact<{
  input: CreateEmptyAppointmentInput;
}>;

export type CreateAppointmentsMutation = {
  __typename?: 'Mutation';
  createAppointments: Array<{
    __typename?: 'AppointmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    start: any;
    end: any;
    location: string;
    notes?: string | null;
    isDaycare?: boolean | null;
    isPrivate?: boolean | null;
    users: Array<{
      __typename?: 'AppointmentUser';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    }>;
    donor?: {
      __typename?: 'AppointmentDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'AppointmentGroupOutput';
      id: string;
      title: string;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'AppointmentTeamOutput';
      id: string;
      title: string;
    } | null;
  }>;
};

export type CreateAppointmentsWithDonorMutationVariables = Exact<{
  input: CreateAppointmentWithDonorInput;
}>;

export type CreateAppointmentsWithDonorMutation = {
  __typename?: 'Mutation';
  createAppointmentsWithDonor: Array<{
    __typename?: 'AppointmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    start: any;
    end: any;
    location: string;
    notes?: string | null;
    isDaycare?: boolean | null;
    isPrivate?: boolean | null;
    users: Array<{
      __typename?: 'AppointmentUser';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    }>;
    donor?: {
      __typename?: 'AppointmentDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'AppointmentGroupOutput';
      id: string;
      title: string;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'AppointmentTeamOutput';
      id: string;
      title: string;
    } | null;
  }>;
};

export type CreateAppointmentsWithGroupMutationVariables = Exact<{
  input: CreateAppointmentWithGroupInput;
}>;

export type CreateAppointmentsWithGroupMutation = {
  __typename?: 'Mutation';
  createAppointmentsWithGroup: Array<{
    __typename?: 'AppointmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    start: any;
    end: any;
    location: string;
    notes?: string | null;
    isDaycare?: boolean | null;
    isPrivate?: boolean | null;
    users: Array<{
      __typename?: 'AppointmentUser';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    }>;
    donor?: {
      __typename?: 'AppointmentDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'AppointmentGroupOutput';
      id: string;
      title: string;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'AppointmentTeamOutput';
      id: string;
      title: string;
    } | null;
  }>;
};

export type EnrollDonorToAppointmentMutationVariables = Exact<{
  input: EnrollDonorInput;
}>;

export type EnrollDonorToAppointmentMutation = {
  __typename?: 'Mutation';
  enrollDonorToAppointment: boolean;
};

export type UnenrollMyDonorFromAppointmentMutationVariables = Exact<{
  input: UnenrollDonorInput;
}>;

export type UnenrollMyDonorFromAppointmentMutation = {
  __typename?: 'Mutation';
  unenrollMyDonorFromAppointment: boolean;
};

export type EnrollMyDonorToAppointmentMutationVariables = Exact<{
  input: EnrollDonorInput;
}>;

export type EnrollMyDonorToAppointmentMutation = {
  __typename?: 'Mutation';
  enrollMyDonorToAppointment: boolean;
};

export type RemoveAppointmentMutationVariables = Exact<{
  input: RemoveAppointmentInput;
}>;

export type RemoveAppointmentMutation = {
  __typename?: 'Mutation';
  removeAppointment: boolean;
};

export type UpdateAppointmentMutationVariables = Exact<{
  input: UpdateAppointmentInput;
}>;

export type UpdateAppointmentMutation = {
  __typename?: 'Mutation';
  updateAppointment: {
    __typename?: 'AppointmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    start: any;
    end: any;
    location: string;
    notes?: string | null;
    isDaycare?: boolean | null;
    isPrivate?: boolean | null;
    users: Array<{
      __typename?: 'AppointmentUser';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    }>;
    donor?: {
      __typename?: 'AppointmentDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'AppointmentGroupOutput';
      id: string;
      title: string;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'AppointmentTeamOutput';
      id: string;
      title: string;
    } | null;
  };
};

export type RegisterOnDaycareAppointmentMutationVariables = Exact<{
  input: RegisterOnDaycareAppointmentInput;
}>;

export type RegisterOnDaycareAppointmentMutation = {
  __typename?: 'Mutation';
  registerOnDaycareAppointment: boolean;
};

export type ApproveDaycareAppointmentRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type ApproveDaycareAppointmentRequestMutation = {
  __typename?: 'Mutation';
  approveDaycareAppointmentRequest: boolean;
};

export type DisproveDaycareAppointmentRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DisproveDaycareAppointmentRequestMutation = {
  __typename?: 'Mutation';
  disproveDaycareAppointmentRequest: boolean;
};

export type CancelDaycareAppointmentRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type CancelDaycareAppointmentRequestMutation = {
  __typename?: 'Mutation';
  cancelDaycareAppointmentRequest: boolean;
};

export type CreateDaycareAppointmentsWithDonorsMutationVariables = Exact<{
  input: CreateDaycareAppointmentWithDonorsInput;
}>;

export type CreateDaycareAppointmentsWithDonorsMutation = {
  __typename?: 'Mutation';
  createDaycareAppointmentsWithDonors: boolean;
};

export type UnenrollDonorFromDaycareMutationVariables = Exact<{
  input: DaycareUnenrollDonorInput;
}>;

export type UnenrollDonorFromDaycareMutation = {
  __typename?: 'Mutation';
  unenrollDonorFromDaycare: boolean;
};

export type AddDonorsToDaycareMutationVariables = Exact<{
  input: AddDonorsToDaycareInput;
}>;

export type AddDonorsToDaycareMutation = {
  __typename?: 'Mutation';
  addDonorsToDaycare: boolean;
};

export type GetAppointmentsQueryVariables = Exact<{
  input: GetAppointmentsInput;
}>;

export type GetAppointmentsQuery = {
  __typename?: 'Query';
  getAppointments: Array<{
    __typename?: 'AppointmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    start: any;
    end: any;
    location: string;
    notes?: string | null;
    isDaycare?: boolean | null;
    isPrivate?: boolean | null;
    users: Array<{
      __typename?: 'AppointmentUser';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    }>;
    donor?: {
      __typename?: 'AppointmentDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'AppointmentGroupOutput';
      id: string;
      title: string;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'AppointmentTeamOutput';
      id: string;
      title: string;
    } | null;
  }>;
};

export type GetAppointmentQueryVariables = Exact<{
  input: GetAppointmentInput;
}>;

export type GetAppointmentQuery = {
  __typename?: 'Query';
  getAppointment: {
    __typename?: 'AppointmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    start: any;
    end: any;
    location: string;
    notes?: string | null;
    isDaycare?: boolean | null;
    isPrivate?: boolean | null;
    users: Array<{
      __typename?: 'AppointmentUser';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    }>;
    donor?: {
      __typename?: 'AppointmentDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'AppointmentGroupOutput';
      id: string;
      title: string;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'AppointmentTeamOutput';
      id: string;
      title: string;
    } | null;
  };
};

export type GetAppointmentsForCurrentUserQueryVariables = Exact<{
  input: GetAppointmentsForCurrentUserInput;
}>;

export type GetAppointmentsForCurrentUserQuery = {
  __typename?: 'Query';
  getAppointmentsForCurrentUser: Array<{
    __typename?: 'AppointmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    start: any;
    end: any;
    location: string;
    notes?: string | null;
    isDaycare?: boolean | null;
    isPrivate?: boolean | null;
    users: Array<{
      __typename?: 'AppointmentUser';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    }>;
    donor?: {
      __typename?: 'AppointmentDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'AppointmentGroupOutput';
      id: string;
      title: string;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'AppointmentTeamOutput';
      id: string;
      title: string;
    } | null;
  }>;
};

export type GetPrivateDaycareAppointmentForCurrentUserQueryVariables = Exact<{
  input: GetAppointmentsForCurrentUserInput;
}>;

export type GetPrivateDaycareAppointmentForCurrentUserQuery = {
  __typename?: 'Query';
  getPrivateDaycareAppointmentForCurrentUser: Array<{
    __typename?: 'AppointmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    start: any;
    end: any;
    location: string;
    notes?: string | null;
    isDaycare?: boolean | null;
    isPrivate?: boolean | null;
    users: Array<{
      __typename?: 'AppointmentUser';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    }>;
    donor?: {
      __typename?: 'AppointmentDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    group?: {
      __typename?: 'AppointmentGroupOutput';
      id: string;
      title: string;
      user?: {
        __typename?: 'AppointmentUser';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'AppointmentTeamOutput';
      id: string;
      title: string;
    } | null;
  }>;
};

export type GetDaycareAppointmentInfoQueryVariables = Exact<{
  input: Scalars['String'];
}>;

export type GetDaycareAppointmentInfoQuery = {
  __typename?: 'Query';
  getDaycareAppointmentInfo: {
    __typename?: 'DaycareAppointmentDetailOutput';
    interactions: Array<{
      __typename?: 'DaycareAppointmentResponseOutput';
      id: string;
      note?: string | null;
      ownerResponse: boolean;
      owner: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
      };
      registeredDonors: Array<{
        __typename?: 'DonorOutput';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        approvedDonationML: number;
        donationMLApprovedAmount?: number | null;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        becameReservedDonorDate?: any | null;
        retiredDate?: any | null;
        distemperDate?: any | null;
        rabiesDate?: any | null;
        lastDonationDate?: any | null;
        nextDonationDate?: any | null;
        lastLabworkDate?: any | null;
        hasMicrochip?: boolean | null;
        acceptSimparicaTrio: boolean;
        isDaycare?: boolean | null;
        donationFrequency: number;
        canRegularlyAttendDaycare?: boolean | null;
        attendDaycareDays?: Array<string> | null;
        retiredReason?: RetiredReasons | null;
        retiredReasonComment?: string | null;
        dateOfBirth: any;
      }>;
    }>;
    meta: {
      __typename?: 'DaycareAppointmentMetaOutput';
      maxDonorsCapacity: number;
      registeredDonors: number;
    };
    requests: Array<{
      __typename?: 'DaycareAppointmentRequestOutput';
      id: string;
      note?: string | null;
      user: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
      };
      donor: {
        __typename?: 'DonorOutput';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        approvedDonationML: number;
        donationMLApprovedAmount?: number | null;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        becameReservedDonorDate?: any | null;
        retiredDate?: any | null;
        distemperDate?: any | null;
        rabiesDate?: any | null;
        lastDonationDate?: any | null;
        nextDonationDate?: any | null;
        lastLabworkDate?: any | null;
        hasMicrochip?: boolean | null;
        acceptSimparicaTrio: boolean;
        isDaycare?: boolean | null;
        donationFrequency: number;
        canRegularlyAttendDaycare?: boolean | null;
        attendDaycareDays?: Array<string> | null;
        retiredReason?: RetiredReasons | null;
        retiredReasonComment?: string | null;
        dateOfBirth: any;
      };
    }>;
  };
};

export type GetDaycareAppointmentInfoForCurrentUserQueryVariables = Exact<{
  appointmentId: Scalars['String'];
}>;

export type GetDaycareAppointmentInfoForCurrentUserQuery = {
  __typename?: 'Query';
  getDaycareAppointmentInfoForCurrentUser: {
    __typename?: 'UserDaycareAppointmentInfoOutput';
    status: DaycareRegistrationStatus;
    daycareId?: string | null;
    registeredDonors: Array<{
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
    }>;
    requests: Array<{
      __typename?: 'DaycareAppointmentRequestOutput';
      id: string;
      donor: {
        __typename?: 'DonorOutput';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        approvedDonationML: number;
        donationMLApprovedAmount?: number | null;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        becameReservedDonorDate?: any | null;
        retiredDate?: any | null;
        distemperDate?: any | null;
        rabiesDate?: any | null;
        lastDonationDate?: any | null;
        nextDonationDate?: any | null;
        lastLabworkDate?: any | null;
        hasMicrochip?: boolean | null;
        acceptSimparicaTrio: boolean;
        isDaycare?: boolean | null;
        donationFrequency: number;
        canRegularlyAttendDaycare?: boolean | null;
        attendDaycareDays?: Array<string> | null;
        retiredReason?: RetiredReasons | null;
        retiredReasonComment?: string | null;
        dateOfBirth: any;
      };
    }>;
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginOutput';
    accessToken: string;
    refreshToken: string;
  };
};

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken: {
    __typename?: 'LoginOutput';
    accessToken: string;
    refreshToken: string;
  };
};

export type Unnamed_1_MutationVariables = Exact<{ [key: string]: never }>;

export type Unnamed_1_Mutation = { __typename?: 'Mutation'; logout: boolean };

export type ResendEmailConfirmationCodeMutationVariables = Exact<{
  input: ResendEmailConfirmationCodeInput;
}>;

export type ResendEmailConfirmationCodeMutation = {
  __typename?: 'Mutation';
  resendEmailConfirmationCode: boolean;
};

export type GetAppVersionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppVersionQuery = {
  __typename?: 'Query';
  getAppVersion: string;
};

export type HolderFragment = {
  __typename?: 'HolderOutput';
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locationPreferences?: string | null;
  birthday?: any | null;
  shortId: number;
};

export type DonationProductFragment = {
  __typename?: 'DonationProductOutput';
  anticoagulant?: Anticoagulant | null;
  createdAt: any;
  id: string;
  shortId: number;
  status: ProductStatus;
  storageRequirements: Array<string>;
  updatedAt: any;
  value: number;
  woocommerceProductId: number;
  woocommerceCategoryId: number;
  expirationDate: any;
};

export type DonationDonorFragment = {
  __typename?: 'DonationDonor';
  id: string;
  age: number;
  gender: AnimalGender;
  hasTransfusion?: boolean | null;
  name: string;
  neutered: boolean;
  primaryVeterinarian: string;
  species: AnimalSpecies;
  takeMedications?: boolean | null;
  weight: number;
  bloodType?: BloodType | null;
  status: DonorStatus;
  type: DonorType;
  location: string;
  distinguishableMarks?: string | null;
  microchipNumber?: string | null;
  medicationCourse?: string | null;
  comment?: string | null;
  breed?: string | null;
  color?: string | null;
  createdAt: any;
  shortId: number;
  firstCertificatePrinted: boolean;
  secondCertificatePrinted: boolean;
  lastDonationDate?: any | null;
  lastLabworkDate?: any | null;
  nextDonationDate?: any | null;
};

export type AdditionalDonationInfoFragment = {
  __typename?: 'AdditionalDonationInfoOutput';
  id: string;
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  veinUsed: VeinUsed;
  vascularStructure?: VascularStructure | null;
  flowRate: FlowRate;
  donationPosition?: string | null;
  holdersPosition?: Array<string> | null;
  donorMovement: string;
  typeOfMovement?: string | null;
  emotionalSupport?: Array<string> | null;
  dislikes?: Array<string> | null;
  likes?: Array<string> | null;
  treatPreferences?: Array<string> | null;
  totalCollectedMls?: number | null;
  primaryHolder?: {
    __typename?: 'HolderOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
  } | null;
  secondaryHolder?: {
    __typename?: 'HolderOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
  } | null;
  poker?: {
    __typename?: 'HolderOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
  } | null;
};

export type DonationFragment = {
  __typename?: 'DonationOutput';
  id: string;
  collectionSet: CollectionSet;
  capillaryRefillTime: CapillaryRefillTime;
  approvedDonationsMl: number;
  jugularVein: JugularVein;
  maxLimit: number;
  mucousMembrane: Mm;
  packedCellVolume: number;
  pulse: number;
  respiration: number;
  shouldDonate?: boolean | null;
  temperature: number;
  totalSolids: number;
  unit?: DonationUnit | null;
  actual?: number | null;
  weatherTemperature: number;
  weatherTypes: Array<WeatherType>;
  weight: number;
  examinationNotes?: string | null;
  preferences?: string | null;
  createdAt: any;
  comment?: string | null;
  shortId: number;
  donationDate: any;
  ineligible: boolean;
  ineligibleReason?: IneligibleReason | null;
  ineligibleReasonDetails?: string | null;
  products?: Array<{
    __typename?: 'DonationProductOutput';
    anticoagulant?: Anticoagulant | null;
    createdAt: any;
    id: string;
    shortId: number;
    status: ProductStatus;
    storageRequirements: Array<string>;
    updatedAt: any;
    value: number;
    woocommerceProductId: number;
    woocommerceCategoryId: number;
    expirationDate: any;
    discardedProduct?: {
      __typename?: 'DiscardedProductDataOutput';
      processingType: ProcessingProductType;
      reason: ReasonDiscardProduct;
      notes?: string | null;
    } | null;
  }> | null;
  donor: {
    __typename?: 'DonationDonor';
    id: string;
    age: number;
    gender: AnimalGender;
    hasTransfusion?: boolean | null;
    name: string;
    neutered: boolean;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    takeMedications?: boolean | null;
    weight: number;
    bloodType?: BloodType | null;
    status: DonorStatus;
    type: DonorType;
    location: string;
    distinguishableMarks?: string | null;
    microchipNumber?: string | null;
    medicationCourse?: string | null;
    comment?: string | null;
    breed?: string | null;
    color?: string | null;
    createdAt: any;
    shortId: number;
    firstCertificatePrinted: boolean;
    secondCertificatePrinted: boolean;
    lastDonationDate?: any | null;
    lastLabworkDate?: any | null;
    nextDonationDate?: any | null;
    team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
    user?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
    pictures?: Array<{
      __typename?: 'DonorPictureOutput';
      id: string;
      isPrimary: boolean;
      file: { __typename?: 'FileOutput'; id: string; filename: string };
    }> | null;
  };
  additionalInfo?: {
    __typename?: 'AdditionalDonationInfoOutput';
    id: string;
    numberOfCollectionUnitsUsed: CollectionUnitsUsed;
    veinUsed: VeinUsed;
    vascularStructure?: VascularStructure | null;
    flowRate: FlowRate;
    donationPosition?: string | null;
    holdersPosition?: Array<string> | null;
    donorMovement: string;
    typeOfMovement?: string | null;
    emotionalSupport?: Array<string> | null;
    dislikes?: Array<string> | null;
    likes?: Array<string> | null;
    treatPreferences?: Array<string> | null;
    totalCollectedMls?: number | null;
    primaryHolder?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
    secondaryHolder?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
    poker?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
  } | null;
};

export type CreateDonationMutationVariables = Exact<{
  input: CreateDonationInput;
}>;

export type CreateDonationMutation = {
  __typename?: 'Mutation';
  createDonation: {
    __typename?: 'DonationOutput';
    id: string;
    collectionSet: CollectionSet;
    capillaryRefillTime: CapillaryRefillTime;
    approvedDonationsMl: number;
    jugularVein: JugularVein;
    maxLimit: number;
    mucousMembrane: Mm;
    packedCellVolume: number;
    pulse: number;
    respiration: number;
    shouldDonate?: boolean | null;
    temperature: number;
    totalSolids: number;
    unit?: DonationUnit | null;
    actual?: number | null;
    weatherTemperature: number;
    weatherTypes: Array<WeatherType>;
    weight: number;
    examinationNotes?: string | null;
    preferences?: string | null;
    createdAt: any;
    comment?: string | null;
    shortId: number;
    donationDate: any;
    ineligible: boolean;
    ineligibleReason?: IneligibleReason | null;
    ineligibleReasonDetails?: string | null;
    products?: Array<{
      __typename?: 'DonationProductOutput';
      anticoagulant?: Anticoagulant | null;
      createdAt: any;
      id: string;
      shortId: number;
      status: ProductStatus;
      storageRequirements: Array<string>;
      updatedAt: any;
      value: number;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      expirationDate: any;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
      } | null;
    }> | null;
    donor: {
      __typename?: 'DonationDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      lastDonationDate?: any | null;
      lastLabworkDate?: any | null;
      nextDonationDate?: any | null;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      pictures?: Array<{
        __typename?: 'DonorPictureOutput';
        id: string;
        isPrimary: boolean;
        file: { __typename?: 'FileOutput'; id: string; filename: string };
      }> | null;
    };
    additionalInfo?: {
      __typename?: 'AdditionalDonationInfoOutput';
      id: string;
      numberOfCollectionUnitsUsed: CollectionUnitsUsed;
      veinUsed: VeinUsed;
      vascularStructure?: VascularStructure | null;
      flowRate: FlowRate;
      donationPosition?: string | null;
      holdersPosition?: Array<string> | null;
      donorMovement: string;
      typeOfMovement?: string | null;
      emotionalSupport?: Array<string> | null;
      dislikes?: Array<string> | null;
      likes?: Array<string> | null;
      treatPreferences?: Array<string> | null;
      totalCollectedMls?: number | null;
      primaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      secondaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      poker?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
    } | null;
  };
};

export type UpdateDonationMutationVariables = Exact<{
  input: UpdateDonationInput;
}>;

export type UpdateDonationMutation = {
  __typename?: 'Mutation';
  updateDonation: {
    __typename?: 'DonationOutput';
    id: string;
    collectionSet: CollectionSet;
    capillaryRefillTime: CapillaryRefillTime;
    approvedDonationsMl: number;
    jugularVein: JugularVein;
    maxLimit: number;
    mucousMembrane: Mm;
    packedCellVolume: number;
    pulse: number;
    respiration: number;
    shouldDonate?: boolean | null;
    temperature: number;
    totalSolids: number;
    unit?: DonationUnit | null;
    actual?: number | null;
    weatherTemperature: number;
    weatherTypes: Array<WeatherType>;
    weight: number;
    examinationNotes?: string | null;
    preferences?: string | null;
    createdAt: any;
    comment?: string | null;
    shortId: number;
    donationDate: any;
    ineligible: boolean;
    ineligibleReason?: IneligibleReason | null;
    ineligibleReasonDetails?: string | null;
    products?: Array<{
      __typename?: 'DonationProductOutput';
      anticoagulant?: Anticoagulant | null;
      createdAt: any;
      id: string;
      shortId: number;
      status: ProductStatus;
      storageRequirements: Array<string>;
      updatedAt: any;
      value: number;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      expirationDate: any;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
      } | null;
    }> | null;
    donor: {
      __typename?: 'DonationDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      lastDonationDate?: any | null;
      lastLabworkDate?: any | null;
      nextDonationDate?: any | null;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      pictures?: Array<{
        __typename?: 'DonorPictureOutput';
        id: string;
        isPrimary: boolean;
        file: { __typename?: 'FileOutput'; id: string; filename: string };
      }> | null;
    };
    additionalInfo?: {
      __typename?: 'AdditionalDonationInfoOutput';
      id: string;
      numberOfCollectionUnitsUsed: CollectionUnitsUsed;
      veinUsed: VeinUsed;
      vascularStructure?: VascularStructure | null;
      flowRate: FlowRate;
      donationPosition?: string | null;
      holdersPosition?: Array<string> | null;
      donorMovement: string;
      typeOfMovement?: string | null;
      emotionalSupport?: Array<string> | null;
      dislikes?: Array<string> | null;
      likes?: Array<string> | null;
      treatPreferences?: Array<string> | null;
      totalCollectedMls?: number | null;
      primaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      secondaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      poker?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
    } | null;
  };
};

export type UpdateDonationCommentMutationVariables = Exact<{
  input: UpdateDonationCommentInput;
}>;

export type UpdateDonationCommentMutation = {
  __typename?: 'Mutation';
  updateDonationComment: {
    __typename?: 'DonationOutput';
    id: string;
    collectionSet: CollectionSet;
    capillaryRefillTime: CapillaryRefillTime;
    approvedDonationsMl: number;
    jugularVein: JugularVein;
    maxLimit: number;
    mucousMembrane: Mm;
    packedCellVolume: number;
    pulse: number;
    respiration: number;
    shouldDonate?: boolean | null;
    temperature: number;
    totalSolids: number;
    unit?: DonationUnit | null;
    actual?: number | null;
    weatherTemperature: number;
    weatherTypes: Array<WeatherType>;
    weight: number;
    examinationNotes?: string | null;
    preferences?: string | null;
    createdAt: any;
    comment?: string | null;
    shortId: number;
    donationDate: any;
    ineligible: boolean;
    ineligibleReason?: IneligibleReason | null;
    ineligibleReasonDetails?: string | null;
    products?: Array<{
      __typename?: 'DonationProductOutput';
      anticoagulant?: Anticoagulant | null;
      createdAt: any;
      id: string;
      shortId: number;
      status: ProductStatus;
      storageRequirements: Array<string>;
      updatedAt: any;
      value: number;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      expirationDate: any;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
      } | null;
    }> | null;
    donor: {
      __typename?: 'DonationDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      lastDonationDate?: any | null;
      lastLabworkDate?: any | null;
      nextDonationDate?: any | null;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      pictures?: Array<{
        __typename?: 'DonorPictureOutput';
        id: string;
        isPrimary: boolean;
        file: { __typename?: 'FileOutput'; id: string; filename: string };
      }> | null;
    };
    additionalInfo?: {
      __typename?: 'AdditionalDonationInfoOutput';
      id: string;
      numberOfCollectionUnitsUsed: CollectionUnitsUsed;
      veinUsed: VeinUsed;
      vascularStructure?: VascularStructure | null;
      flowRate: FlowRate;
      donationPosition?: string | null;
      holdersPosition?: Array<string> | null;
      donorMovement: string;
      typeOfMovement?: string | null;
      emotionalSupport?: Array<string> | null;
      dislikes?: Array<string> | null;
      likes?: Array<string> | null;
      treatPreferences?: Array<string> | null;
      totalCollectedMls?: number | null;
      primaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      secondaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      poker?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
    } | null;
  };
};

export type UpdateDonationDonorMutationVariables = Exact<{
  input: UpdateDonationDonorInput;
}>;

export type UpdateDonationDonorMutation = {
  __typename?: 'Mutation';
  updateDonationDonor: {
    __typename?: 'DonationOutput';
    id: string;
    collectionSet: CollectionSet;
    capillaryRefillTime: CapillaryRefillTime;
    approvedDonationsMl: number;
    jugularVein: JugularVein;
    maxLimit: number;
    mucousMembrane: Mm;
    packedCellVolume: number;
    pulse: number;
    respiration: number;
    shouldDonate?: boolean | null;
    temperature: number;
    totalSolids: number;
    unit?: DonationUnit | null;
    actual?: number | null;
    weatherTemperature: number;
    weatherTypes: Array<WeatherType>;
    weight: number;
    examinationNotes?: string | null;
    preferences?: string | null;
    createdAt: any;
    comment?: string | null;
    shortId: number;
    donationDate: any;
    ineligible: boolean;
    ineligibleReason?: IneligibleReason | null;
    ineligibleReasonDetails?: string | null;
    products?: Array<{
      __typename?: 'DonationProductOutput';
      anticoagulant?: Anticoagulant | null;
      createdAt: any;
      id: string;
      shortId: number;
      status: ProductStatus;
      storageRequirements: Array<string>;
      updatedAt: any;
      value: number;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      expirationDate: any;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
      } | null;
    }> | null;
    donor: {
      __typename?: 'DonationDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      lastDonationDate?: any | null;
      lastLabworkDate?: any | null;
      nextDonationDate?: any | null;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      pictures?: Array<{
        __typename?: 'DonorPictureOutput';
        id: string;
        isPrimary: boolean;
        file: { __typename?: 'FileOutput'; id: string; filename: string };
      }> | null;
    };
    additionalInfo?: {
      __typename?: 'AdditionalDonationInfoOutput';
      id: string;
      numberOfCollectionUnitsUsed: CollectionUnitsUsed;
      veinUsed: VeinUsed;
      vascularStructure?: VascularStructure | null;
      flowRate: FlowRate;
      donationPosition?: string | null;
      holdersPosition?: Array<string> | null;
      donorMovement: string;
      typeOfMovement?: string | null;
      emotionalSupport?: Array<string> | null;
      dislikes?: Array<string> | null;
      likes?: Array<string> | null;
      treatPreferences?: Array<string> | null;
      totalCollectedMls?: number | null;
      primaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      secondaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      poker?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
    } | null;
  };
};

export type CreateAdditionalDonationInfoMutationVariables = Exact<{
  input: CreateAdditionalDonationInfoInput;
}>;

export type CreateAdditionalDonationInfoMutation = {
  __typename?: 'Mutation';
  createAdditionalDonationInfo: {
    __typename?: 'AdditionalDonationInfoOutput';
    id: string;
    numberOfCollectionUnitsUsed: CollectionUnitsUsed;
    veinUsed: VeinUsed;
    vascularStructure?: VascularStructure | null;
    flowRate: FlowRate;
    donationPosition?: string | null;
    holdersPosition?: Array<string> | null;
    donorMovement: string;
    typeOfMovement?: string | null;
    emotionalSupport?: Array<string> | null;
    dislikes?: Array<string> | null;
    likes?: Array<string> | null;
    treatPreferences?: Array<string> | null;
    totalCollectedMls?: number | null;
    primaryHolder?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
    secondaryHolder?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
    poker?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
  };
};

export type UpdateAdditionalDonationInfoMutationVariables = Exact<{
  input: UpdateAdditionalDonationInfoInput;
}>;

export type UpdateAdditionalDonationInfoMutation = {
  __typename?: 'Mutation';
  updateAdditionalDonationInfo: {
    __typename?: 'AdditionalDonationInfoOutput';
    id: string;
    numberOfCollectionUnitsUsed: CollectionUnitsUsed;
    veinUsed: VeinUsed;
    vascularStructure?: VascularStructure | null;
    flowRate: FlowRate;
    donationPosition?: string | null;
    holdersPosition?: Array<string> | null;
    donorMovement: string;
    typeOfMovement?: string | null;
    emotionalSupport?: Array<string> | null;
    dislikes?: Array<string> | null;
    likes?: Array<string> | null;
    treatPreferences?: Array<string> | null;
    totalCollectedMls?: number | null;
    primaryHolder?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
    secondaryHolder?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
    poker?: {
      __typename?: 'HolderOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
    } | null;
  };
};

export type CreateDonationWithAdditionalInfoMutationVariables = Exact<{
  input: CreateDonationWithAdditionalInfoInput;
}>;

export type CreateDonationWithAdditionalInfoMutation = {
  __typename?: 'Mutation';
  createDonationWithAdditionalInfo: {
    __typename?: 'DonationOutput';
    id: string;
    collectionSet: CollectionSet;
    capillaryRefillTime: CapillaryRefillTime;
    approvedDonationsMl: number;
    jugularVein: JugularVein;
    maxLimit: number;
    mucousMembrane: Mm;
    packedCellVolume: number;
    pulse: number;
    respiration: number;
    shouldDonate?: boolean | null;
    temperature: number;
    totalSolids: number;
    unit?: DonationUnit | null;
    actual?: number | null;
    weatherTemperature: number;
    weatherTypes: Array<WeatherType>;
    weight: number;
    examinationNotes?: string | null;
    preferences?: string | null;
    createdAt: any;
    comment?: string | null;
    shortId: number;
    donationDate: any;
    ineligible: boolean;
    ineligibleReason?: IneligibleReason | null;
    ineligibleReasonDetails?: string | null;
    products?: Array<{
      __typename?: 'DonationProductOutput';
      anticoagulant?: Anticoagulant | null;
      createdAt: any;
      id: string;
      shortId: number;
      status: ProductStatus;
      storageRequirements: Array<string>;
      updatedAt: any;
      value: number;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      expirationDate: any;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
      } | null;
    }> | null;
    donor: {
      __typename?: 'DonationDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      lastDonationDate?: any | null;
      lastLabworkDate?: any | null;
      nextDonationDate?: any | null;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      pictures?: Array<{
        __typename?: 'DonorPictureOutput';
        id: string;
        isPrimary: boolean;
        file: { __typename?: 'FileOutput'; id: string; filename: string };
      }> | null;
    };
    additionalInfo?: {
      __typename?: 'AdditionalDonationInfoOutput';
      id: string;
      numberOfCollectionUnitsUsed: CollectionUnitsUsed;
      veinUsed: VeinUsed;
      vascularStructure?: VascularStructure | null;
      flowRate: FlowRate;
      donationPosition?: string | null;
      holdersPosition?: Array<string> | null;
      donorMovement: string;
      typeOfMovement?: string | null;
      emotionalSupport?: Array<string> | null;
      dislikes?: Array<string> | null;
      likes?: Array<string> | null;
      treatPreferences?: Array<string> | null;
      totalCollectedMls?: number | null;
      primaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      secondaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      poker?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
    } | null;
  };
};

export type CreateDonationsWithAdditionalInfoMutationVariables = Exact<{
  input: CreateDonationsWithAdditionalInfoInput;
}>;

export type CreateDonationsWithAdditionalInfoMutation = {
  __typename?: 'Mutation';
  createDonationsWithAdditionalInfo: boolean;
};

export type DeleteDonationMutationVariables = Exact<{
  donationId: Scalars['String'];
}>;

export type DeleteDonationMutation = {
  __typename?: 'Mutation';
  deleteDonation: boolean;
};

export type GetPreviousDonationQueryVariables = Exact<{
  input: GetPreviousDonationInput;
}>;

export type GetPreviousDonationQuery = {
  __typename?: 'Query';
  getPreviousDonation: {
    __typename?: 'DonationOutput';
    id: string;
    collectionSet: CollectionSet;
    capillaryRefillTime: CapillaryRefillTime;
    approvedDonationsMl: number;
    jugularVein: JugularVein;
    maxLimit: number;
    mucousMembrane: Mm;
    packedCellVolume: number;
    pulse: number;
    respiration: number;
    shouldDonate?: boolean | null;
    temperature: number;
    totalSolids: number;
    unit?: DonationUnit | null;
    actual?: number | null;
    weatherTemperature: number;
    weatherTypes: Array<WeatherType>;
    weight: number;
    examinationNotes?: string | null;
    preferences?: string | null;
    createdAt: any;
    comment?: string | null;
    shortId: number;
    donationDate: any;
    ineligible: boolean;
    ineligibleReason?: IneligibleReason | null;
    ineligibleReasonDetails?: string | null;
    products?: Array<{
      __typename?: 'DonationProductOutput';
      anticoagulant?: Anticoagulant | null;
      createdAt: any;
      id: string;
      shortId: number;
      status: ProductStatus;
      storageRequirements: Array<string>;
      updatedAt: any;
      value: number;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      expirationDate: any;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
      } | null;
    }> | null;
    donor: {
      __typename?: 'DonationDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      lastDonationDate?: any | null;
      lastLabworkDate?: any | null;
      nextDonationDate?: any | null;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      pictures?: Array<{
        __typename?: 'DonorPictureOutput';
        id: string;
        isPrimary: boolean;
        file: { __typename?: 'FileOutput'; id: string; filename: string };
      }> | null;
    };
    additionalInfo?: {
      __typename?: 'AdditionalDonationInfoOutput';
      id: string;
      numberOfCollectionUnitsUsed: CollectionUnitsUsed;
      veinUsed: VeinUsed;
      vascularStructure?: VascularStructure | null;
      flowRate: FlowRate;
      donationPosition?: string | null;
      holdersPosition?: Array<string> | null;
      donorMovement: string;
      typeOfMovement?: string | null;
      emotionalSupport?: Array<string> | null;
      dislikes?: Array<string> | null;
      likes?: Array<string> | null;
      treatPreferences?: Array<string> | null;
      totalCollectedMls?: number | null;
      primaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      secondaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      poker?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
    } | null;
  };
};

export type GetDonationQueryVariables = Exact<{
  input: GetDonationInput;
}>;

export type GetDonationQuery = {
  __typename?: 'Query';
  getDonation: {
    __typename?: 'DonationOutput';
    id: string;
    collectionSet: CollectionSet;
    capillaryRefillTime: CapillaryRefillTime;
    approvedDonationsMl: number;
    jugularVein: JugularVein;
    maxLimit: number;
    mucousMembrane: Mm;
    packedCellVolume: number;
    pulse: number;
    respiration: number;
    shouldDonate?: boolean | null;
    temperature: number;
    totalSolids: number;
    unit?: DonationUnit | null;
    actual?: number | null;
    weatherTemperature: number;
    weatherTypes: Array<WeatherType>;
    weight: number;
    examinationNotes?: string | null;
    preferences?: string | null;
    createdAt: any;
    comment?: string | null;
    shortId: number;
    donationDate: any;
    ineligible: boolean;
    ineligibleReason?: IneligibleReason | null;
    ineligibleReasonDetails?: string | null;
    products?: Array<{
      __typename?: 'DonationProductOutput';
      anticoagulant?: Anticoagulant | null;
      createdAt: any;
      id: string;
      shortId: number;
      status: ProductStatus;
      storageRequirements: Array<string>;
      updatedAt: any;
      value: number;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      expirationDate: any;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
      } | null;
    }> | null;
    donor: {
      __typename?: 'DonationDonor';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      lastDonationDate?: any | null;
      lastLabworkDate?: any | null;
      nextDonationDate?: any | null;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      pictures?: Array<{
        __typename?: 'DonorPictureOutput';
        id: string;
        isPrimary: boolean;
        file: { __typename?: 'FileOutput'; id: string; filename: string };
      }> | null;
    };
    additionalInfo?: {
      __typename?: 'AdditionalDonationInfoOutput';
      id: string;
      numberOfCollectionUnitsUsed: CollectionUnitsUsed;
      veinUsed: VeinUsed;
      vascularStructure?: VascularStructure | null;
      flowRate: FlowRate;
      donationPosition?: string | null;
      holdersPosition?: Array<string> | null;
      donorMovement: string;
      typeOfMovement?: string | null;
      emotionalSupport?: Array<string> | null;
      dislikes?: Array<string> | null;
      likes?: Array<string> | null;
      treatPreferences?: Array<string> | null;
      totalCollectedMls?: number | null;
      primaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      secondaryHolder?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
      poker?: {
        __typename?: 'HolderOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
      } | null;
    } | null;
  };
};

export type GetDonationListQueryVariables = Exact<{
  input: GetDonationsListInput;
}>;

export type GetDonationListQuery = {
  __typename?: 'Query';
  getDonationsList: {
    __typename?: 'DonationListOutput';
    data: Array<{
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetSpecificDonorDonationsListQueryVariables = Exact<{
  input: GetSpecificDonorDonationsListInput;
}>;

export type GetSpecificDonorDonationsListQuery = {
  __typename?: 'Query';
  getSpecificDonorDonationsList: {
    __typename?: 'DonationListOutput';
    data: Array<{
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type DonorFragment = {
  __typename?: 'DonorOutput';
  id: string;
  age: number;
  gender: AnimalGender;
  hasTransfusion?: boolean | null;
  name: string;
  neutered: boolean;
  primaryVeterinarian: string;
  species: AnimalSpecies;
  takeMedications?: boolean | null;
  weight: number;
  approvedDonationML: number;
  donationMLApprovedAmount?: number | null;
  bloodType?: BloodType | null;
  status: DonorStatus;
  type: DonorType;
  location: string;
  distinguishableMarks?: string | null;
  microchipNumber?: string | null;
  medicationCourse?: string | null;
  comment?: string | null;
  breed?: string | null;
  color?: string | null;
  createdAt: any;
  shortId: number;
  firstCertificatePrinted: boolean;
  secondCertificatePrinted: boolean;
  becameReservedDonorDate?: any | null;
  retiredDate?: any | null;
  distemperDate?: any | null;
  rabiesDate?: any | null;
  lastDonationDate?: any | null;
  nextDonationDate?: any | null;
  lastLabworkDate?: any | null;
  hasMicrochip?: boolean | null;
  acceptSimparicaTrio: boolean;
  isDaycare?: boolean | null;
  donationFrequency: number;
  canRegularlyAttendDaycare?: boolean | null;
  attendDaycareDays?: Array<string> | null;
  retiredReason?: RetiredReasons | null;
  retiredReasonComment?: string | null;
  dateOfBirth: any;
};

export type PartialDonorFragment = {
  __typename?: 'PartialDonorOutput';
  id?: string | null;
  age?: number | null;
  gender?: AnimalGender | null;
  hasTransfusion?: boolean | null;
  name?: string | null;
  neutered?: boolean | null;
  primaryVeterinarian?: string | null;
  species?: AnimalSpecies | null;
  takeMedications?: boolean | null;
  weight?: number | null;
  approvedDonationML?: number | null;
  donationMLApprovedAmount?: number | null;
  bloodType?: BloodType | null;
  status?: DonorStatus | null;
  type?: DonorType | null;
  location?: string | null;
  distinguishableMarks?: string | null;
  microchipNumber?: string | null;
  medicationCourse?: string | null;
  comment?: string | null;
  breed?: string | null;
  color?: string | null;
  createdAt?: any | null;
  shortId?: number | null;
  firstCertificatePrinted?: boolean | null;
  secondCertificatePrinted?: boolean | null;
  becameReservedDonorDate?: any | null;
  retiredDate?: any | null;
  distemperDate?: any | null;
  rabiesDate?: any | null;
  lastDonationDate?: any | null;
  nextDonationDate?: any | null;
  lastLabworkDate?: any | null;
  hasMicrochip?: boolean | null;
  acceptSimparicaTrio?: boolean | null;
  isDaycare?: boolean | null;
  donationFrequency?: number | null;
  canRegularlyAttendDaycare?: boolean | null;
  attendDaycareDays?: Array<string> | null;
  retiredReason?: RetiredReasons | null;
  retiredReasonComment?: string | null;
  dateOfBirth?: any | null;
};

export type TeamFragment = {
  __typename?: 'TeamOutput';
  id: string;
  title: string;
};

export type GroupTeamFragment = {
  __typename?: 'GroupTeamOutput';
  id: string;
  title: string;
};

export type GroupFragment = {
  __typename?: 'GroupOutput';
  id: string;
  title: string;
};

export type GroupListItemFragment = {
  __typename?: 'GroupListItemOutput';
  teamCount: number;
  donorCount?: number | null;
  title: string;
  id: string;
  user?: {
    __typename?: 'GroupListItemUserOutput';
    firstName: string;
    lastName: string;
    id: string;
  } | null;
};

export type ReserveDonorNoteFragment = {
  __typename?: 'ReserveDonorNoteOutput';
  id: string;
  text: string;
  createdAt: any;
};

export type DonorNoteFragment = {
  __typename?: 'DonorNoteOutput';
  id: string;
  text: string;
  isPinned: boolean;
  createdAt: any;
  updatedAt: any;
  files: Array<{
    __typename?: 'DonorNoteFileOutput';
    id: string;
    file: { __typename?: 'FileOutput'; id: string; filename: string };
  }>;
  user: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type LabworkFragment = {
  __typename?: 'LabworkOutput';
  id: string;
  collectionDate: any;
  status: LabworkStatus;
  DVMExam: boolean;
};

export type PictureFragment = {
  __typename?: 'DonorPictureOutput';
  id: string;
  isPrimary: boolean;
  file: { __typename?: 'FileOutput'; id: string; filename: string };
};

export type FileFragment = {
  __typename?: 'FileOutput';
  id: string;
  filename: string;
};

export type VitalsParameterFragment = {
  __typename?: 'VitalsParameterOutput';
  id: string;
  criticalValues?: Array<string> | null;
  type: VitalsParametersType;
  max?: number | null;
  min?: number | null;
  percentageDifference?: number | null;
};

export type LabworkAttachmentFragment = {
  __typename?: 'LabworkAttachmentOutput';
  id: string;
  createdAt: any;
  updatedAt: any;
};

export type CreateDonorsForUserMutationVariables = Exact<{
  input: CreateDonorsForUserInput;
}>;

export type CreateDonorsForUserMutation = {
  __typename?: 'Mutation';
  createDonorsForUser: boolean;
};

export type CreateDonorsMutationVariables = Exact<{
  input: CreateDonors;
}>;

export type CreateDonorsMutation = {
  __typename?: 'Mutation';
  createDonors: boolean;
};

export type CreateDonorMutationVariables = Exact<{
  input: CreateDonorInput;
}>;

export type CreateDonorMutation = {
  __typename?: 'Mutation';
  createDonor: {
    __typename?: 'DonorOutput';
    id: string;
    age: number;
    gender: AnimalGender;
    hasTransfusion?: boolean | null;
    name: string;
    neutered: boolean;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    takeMedications?: boolean | null;
    weight: number;
    approvedDonationML: number;
    donationMLApprovedAmount?: number | null;
    bloodType?: BloodType | null;
    status: DonorStatus;
    type: DonorType;
    location: string;
    distinguishableMarks?: string | null;
    microchipNumber?: string | null;
    medicationCourse?: string | null;
    comment?: string | null;
    breed?: string | null;
    color?: string | null;
    createdAt: any;
    shortId: number;
    firstCertificatePrinted: boolean;
    secondCertificatePrinted: boolean;
    becameReservedDonorDate?: any | null;
    retiredDate?: any | null;
    distemperDate?: any | null;
    rabiesDate?: any | null;
    lastDonationDate?: any | null;
    nextDonationDate?: any | null;
    lastLabworkDate?: any | null;
    hasMicrochip?: boolean | null;
    acceptSimparicaTrio: boolean;
    isDaycare?: boolean | null;
    donationFrequency: number;
    canRegularlyAttendDaycare?: boolean | null;
    attendDaycareDays?: Array<string> | null;
    retiredReason?: RetiredReasons | null;
    retiredReasonComment?: string | null;
    dateOfBirth: any;
    team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
    reserveNotes?: Array<{
      __typename?: 'ReserveDonorNoteOutput';
      id: string;
      text: string;
      createdAt: any;
    }> | null;
  };
};

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
}>;

export type UpdateGroupMutation = {
  __typename?: 'Mutation';
  updateGroup: { __typename?: 'GroupOutput'; title: string };
};

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput;
}>;

export type UpdateTeamMutation = {
  __typename?: 'Mutation';
  updateTeam: { __typename?: 'TeamOutput'; title: string };
};

export type RemoveGroupMutationVariables = Exact<{
  input: RemoveGroupInput;
}>;

export type RemoveGroupMutation = {
  __typename?: 'Mutation';
  removeGroup: boolean;
};

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createTeam: { __typename?: 'TeamOutput'; id: string };
};

export type RemoveTeamMutationVariables = Exact<{
  input: RemoveTeamInput;
}>;

export type RemoveTeamMutation = {
  __typename?: 'Mutation';
  removeTeam: boolean;
};

export type RemoveDonorMutationVariables = Exact<{
  input: DeleteDonorInput;
}>;

export type RemoveDonorMutation = {
  __typename?: 'Mutation';
  deleteDonor: boolean;
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;

export type CreateGroupMutation = {
  __typename?: 'Mutation';
  createGroup: { __typename?: 'GroupOutput'; id: string };
};

export type InviteOwnerWithDonorMutationVariables = Exact<{
  input: InviteOwnerWithDonorInput;
}>;

export type InviteOwnerWithDonorMutation = {
  __typename?: 'Mutation';
  inviteOwnerWithDonor: boolean;
};

export type CreateDonorForUserMutationVariables = Exact<{
  input: CreateDonorForUserInput;
}>;

export type CreateDonorForUserMutation = {
  __typename?: 'Mutation';
  createDonorForUser: {
    __typename?: 'DonorOutput';
    id: string;
    age: number;
    gender: AnimalGender;
    hasTransfusion?: boolean | null;
    name: string;
    neutered: boolean;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    takeMedications?: boolean | null;
    weight: number;
    approvedDonationML: number;
    donationMLApprovedAmount?: number | null;
    bloodType?: BloodType | null;
    status: DonorStatus;
    type: DonorType;
    location: string;
    distinguishableMarks?: string | null;
    microchipNumber?: string | null;
    medicationCourse?: string | null;
    comment?: string | null;
    breed?: string | null;
    color?: string | null;
    createdAt: any;
    shortId: number;
    firstCertificatePrinted: boolean;
    secondCertificatePrinted: boolean;
    becameReservedDonorDate?: any | null;
    retiredDate?: any | null;
    distemperDate?: any | null;
    rabiesDate?: any | null;
    lastDonationDate?: any | null;
    nextDonationDate?: any | null;
    lastLabworkDate?: any | null;
    hasMicrochip?: boolean | null;
    acceptSimparicaTrio: boolean;
    isDaycare?: boolean | null;
    donationFrequency: number;
    canRegularlyAttendDaycare?: boolean | null;
    attendDaycareDays?: Array<string> | null;
    retiredReason?: RetiredReasons | null;
    retiredReasonComment?: string | null;
    dateOfBirth: any;
    team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
    reserveNotes?: Array<{
      __typename?: 'ReserveDonorNoteOutput';
      id: string;
      text: string;
      createdAt: any;
    }> | null;
  };
};

export type UpdateDonorMutationVariables = Exact<{
  input: UpdateDonorInput;
}>;

export type UpdateDonorMutation = {
  __typename?: 'Mutation';
  updateDonor: {
    __typename?: 'DonorOutput';
    id: string;
    age: number;
    gender: AnimalGender;
    hasTransfusion?: boolean | null;
    name: string;
    neutered: boolean;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    takeMedications?: boolean | null;
    weight: number;
    approvedDonationML: number;
    donationMLApprovedAmount?: number | null;
    bloodType?: BloodType | null;
    status: DonorStatus;
    type: DonorType;
    location: string;
    distinguishableMarks?: string | null;
    microchipNumber?: string | null;
    medicationCourse?: string | null;
    comment?: string | null;
    breed?: string | null;
    color?: string | null;
    createdAt: any;
    shortId: number;
    firstCertificatePrinted: boolean;
    secondCertificatePrinted: boolean;
    becameReservedDonorDate?: any | null;
    retiredDate?: any | null;
    distemperDate?: any | null;
    rabiesDate?: any | null;
    lastDonationDate?: any | null;
    nextDonationDate?: any | null;
    lastLabworkDate?: any | null;
    hasMicrochip?: boolean | null;
    acceptSimparicaTrio: boolean;
    isDaycare?: boolean | null;
    donationFrequency: number;
    canRegularlyAttendDaycare?: boolean | null;
    attendDaycareDays?: Array<string> | null;
    retiredReason?: RetiredReasons | null;
    retiredReasonComment?: string | null;
    dateOfBirth: any;
    team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
    reserveNotes?: Array<{
      __typename?: 'ReserveDonorNoteOutput';
      id: string;
      text: string;
      createdAt: any;
    }> | null;
  };
};

export type AddNoteToReserveDonorMutationVariables = Exact<{
  input: CreateDonorNoteInput;
}>;

export type AddNoteToReserveDonorMutation = {
  __typename?: 'Mutation';
  addNoteToReserveDonor: {
    __typename?: 'ReserveDonorNoteOutput';
    id: string;
    text: string;
    createdAt: any;
  };
};

export type UpdateReserveDonorNoteMutationVariables = Exact<{
  input: UpdateDonorNoteInput;
}>;

export type UpdateReserveDonorNoteMutation = {
  __typename?: 'Mutation';
  updateReserveDonorNote: {
    __typename?: 'ReserveDonorNoteOutput';
    id: string;
    text: string;
    createdAt: any;
  };
};

export type DeleteReserveDonorNoteMutationVariables = Exact<{
  input: DeleteDonorNoteInput;
}>;

export type DeleteReserveDonorNoteMutation = {
  __typename?: 'Mutation';
  deleteReserveDonorNote: boolean;
};

export type UpdateLabworkMutationVariables = Exact<{
  input: UpdateLabworkInput;
}>;

export type UpdateLabworkMutation = {
  __typename?: 'Mutation';
  updateLabwork: {
    __typename?: 'LabworkOutput';
    id: string;
    collectionDate: any;
    status: LabworkStatus;
    DVMExam: boolean;
    donor: {
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
      } | null;
    };
  };
};

export type UpdateLabworkStatusMutationVariables = Exact<{
  input: UpdateLabworkStatusInput;
}>;

export type UpdateLabworkStatusMutation = {
  __typename?: 'Mutation';
  updateLabworkStatus: {
    __typename?: 'LabworkOutput';
    id: string;
    collectionDate: any;
    status: LabworkStatus;
    DVMExam: boolean;
    donor: {
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
      } | null;
    };
  };
};

export type CreateLabworkMutationVariables = Exact<{
  input: CreateLabworkInput;
}>;

export type CreateLabworkMutation = {
  __typename?: 'Mutation';
  createLabwork: {
    __typename?: 'LabworkOutput';
    id: string;
    collectionDate: any;
    status: LabworkStatus;
    DVMExam: boolean;
    donor: {
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
      } | null;
    };
  };
};

export type ShareLabworkResultsMutationVariables = Exact<{
  input: ShareLabworkResultsWithOwnerInput;
}>;

export type ShareLabworkResultsMutation = {
  __typename?: 'Mutation';
  shareLabworkResults: boolean;
};

export type RemoveLabworkMutationVariables = Exact<{
  input: RemoveLabworkInput;
}>;

export type RemoveLabworkMutation = {
  __typename?: 'Mutation';
  removeLabwork: boolean;
};

export type DetachLabworkFileMutationVariables = Exact<{
  input: DetachLabworkFileInput;
}>;

export type DetachLabworkFileMutation = {
  __typename?: 'Mutation';
  detachLabworkFile: boolean;
};

export type UpdateVitalsParametersListMutationVariables = Exact<{
  input: UpdateVitalsParametersListInput;
}>;

export type UpdateVitalsParametersListMutation = {
  __typename?: 'Mutation';
  updateVitalsParameterList: Array<{
    __typename?: 'VitalsParameterOutput';
    id: string;
    criticalValues?: Array<string> | null;
    type: VitalsParametersType;
    max?: number | null;
    min?: number | null;
    percentageDifference?: number | null;
  }>;
};

export type UpdateDonorsOwnerMutationVariables = Exact<{
  input: UpdateDonorsOwnerInput;
}>;

export type UpdateDonorsOwnerMutation = {
  __typename?: 'Mutation';
  updateDonorsOwner?: {
    __typename?: 'DonorOutput';
    id: string;
    age: number;
    gender: AnimalGender;
    hasTransfusion?: boolean | null;
    name: string;
    neutered: boolean;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    takeMedications?: boolean | null;
    weight: number;
    approvedDonationML: number;
    donationMLApprovedAmount?: number | null;
    bloodType?: BloodType | null;
    status: DonorStatus;
    type: DonorType;
    location: string;
    distinguishableMarks?: string | null;
    microchipNumber?: string | null;
    medicationCourse?: string | null;
    comment?: string | null;
    breed?: string | null;
    color?: string | null;
    createdAt: any;
    shortId: number;
    firstCertificatePrinted: boolean;
    secondCertificatePrinted: boolean;
    becameReservedDonorDate?: any | null;
    retiredDate?: any | null;
    distemperDate?: any | null;
    rabiesDate?: any | null;
    lastDonationDate?: any | null;
    nextDonationDate?: any | null;
    lastLabworkDate?: any | null;
    hasMicrochip?: boolean | null;
    acceptSimparicaTrio: boolean;
    isDaycare?: boolean | null;
    donationFrequency: number;
    canRegularlyAttendDaycare?: boolean | null;
    attendDaycareDays?: Array<string> | null;
    retiredReason?: RetiredReasons | null;
    retiredReasonComment?: string | null;
    dateOfBirth: any;
    team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
    reserveNotes?: Array<{
      __typename?: 'ReserveDonorNoteOutput';
      id: string;
      text: string;
      createdAt: any;
    }> | null;
  } | null;
};

export type UpdateGroupOwnerMutationVariables = Exact<{
  input: UpdateGroupOwnerInput;
}>;

export type UpdateGroupOwnerMutation = {
  __typename?: 'Mutation';
  updateGroupOwner?: {
    __typename?: 'GroupOutput';
    id: string;
    title: string;
    teams: Array<{
      __typename?: 'TeamOutput';
      id: string;
      title: string;
      donors?: Array<{
        __typename?: 'DonorOutput';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        approvedDonationML: number;
        donationMLApprovedAmount?: number | null;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        becameReservedDonorDate?: any | null;
        retiredDate?: any | null;
        distemperDate?: any | null;
        rabiesDate?: any | null;
        lastDonationDate?: any | null;
        nextDonationDate?: any | null;
        lastLabworkDate?: any | null;
        hasMicrochip?: boolean | null;
        acceptSimparicaTrio: boolean;
        isDaycare?: boolean | null;
        donationFrequency: number;
        canRegularlyAttendDaycare?: boolean | null;
        attendDaycareDays?: Array<string> | null;
        retiredReason?: RetiredReasons | null;
        retiredReasonComment?: string | null;
        dateOfBirth: any;
      }> | null;
    }>;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type ManageDonorsInTeamMutationVariables = Exact<{
  input: ManageDonorsInTeamInput;
}>;

export type ManageDonorsInTeamMutation = {
  __typename?: 'Mutation';
  manageDonorsInTeam: boolean;
};

export type AddNoteMutationVariables = Exact<{
  input: CreateDonorNoteInput;
}>;

export type AddNoteMutation = {
  __typename?: 'Mutation';
  addNote: {
    __typename?: 'DonorNoteOutput';
    id: string;
    text: string;
    isPinned: boolean;
    createdAt: any;
    updatedAt: any;
    files: Array<{
      __typename?: 'DonorNoteFileOutput';
      id: string;
      file: { __typename?: 'FileOutput'; id: string; filename: string };
    }>;
    user: {
      __typename?: 'UserOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      deletionIntentionAt?: any | null;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      productRegionPreference?: {
        __typename?: 'RegionOutput';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        accountingCode?: string | null;
      } | null;
    };
  };
};

export type UpdateNoteMutationVariables = Exact<{
  input: UpdateDonorNoteInput;
}>;

export type UpdateNoteMutation = {
  __typename?: 'Mutation';
  updateNote: {
    __typename?: 'DonorNoteOutput';
    id: string;
    text: string;
    isPinned: boolean;
    createdAt: any;
    updatedAt: any;
    files: Array<{
      __typename?: 'DonorNoteFileOutput';
      id: string;
      file: { __typename?: 'FileOutput'; id: string; filename: string };
    }>;
    user: {
      __typename?: 'UserOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      deletionIntentionAt?: any | null;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      productRegionPreference?: {
        __typename?: 'RegionOutput';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        accountingCode?: string | null;
      } | null;
    };
  };
};

export type DeleteNoteMutationVariables = Exact<{
  input: DeleteDonorNoteInput;
}>;

export type DeleteNoteMutation = {
  __typename?: 'Mutation';
  deleteNote: boolean;
};

export type MakeDonorPicturePrimaryMutationVariables = Exact<{
  input: MakePicturePrimaryInput;
}>;

export type MakeDonorPicturePrimaryMutation = {
  __typename?: 'Mutation';
  makeDonorPicturePrimary: boolean;
};

export type ResetPrimaryDonorPicturesMutationVariables = Exact<{
  input: ResetPrimaryPicturesInput;
}>;

export type ResetPrimaryDonorPicturesMutation = {
  __typename?: 'Mutation';
  resetPrimaryDonorPictures: boolean;
};

export type GetDonorsListQueryVariables = Exact<{
  input: DonorsListInput;
}>;

export type GetDonorsListQuery = {
  __typename?: 'Query';
  getDonorsList: {
    __typename?: 'DonorsListOutput';
    data: Array<{
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
      reserveNotes?: Array<{
        __typename?: 'ReserveDonorNoteOutput';
        id: string;
        text: string;
        createdAt: any;
      }> | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type FindDonorsForTeamQueryVariables = Exact<{
  input: DonorsListForTeamInput;
}>;

export type FindDonorsForTeamQuery = {
  __typename?: 'Query';
  findDonorsForTeam: {
    __typename?: 'DonorsListOutput';
    data: Array<{
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
      reserveNotes?: Array<{
        __typename?: 'ReserveDonorNoteOutput';
        id: string;
        text: string;
        createdAt: any;
      }> | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetDonorQueryVariables = Exact<{
  input: GetDonorInput;
}>;

export type GetDonorQuery = {
  __typename?: 'Query';
  getDonor: {
    __typename?: 'DonorOutput';
    id: string;
    age: number;
    gender: AnimalGender;
    hasTransfusion?: boolean | null;
    name: string;
    neutered: boolean;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    takeMedications?: boolean | null;
    weight: number;
    approvedDonationML: number;
    donationMLApprovedAmount?: number | null;
    bloodType?: BloodType | null;
    status: DonorStatus;
    type: DonorType;
    location: string;
    distinguishableMarks?: string | null;
    microchipNumber?: string | null;
    medicationCourse?: string | null;
    comment?: string | null;
    breed?: string | null;
    color?: string | null;
    createdAt: any;
    shortId: number;
    firstCertificatePrinted: boolean;
    secondCertificatePrinted: boolean;
    becameReservedDonorDate?: any | null;
    retiredDate?: any | null;
    distemperDate?: any | null;
    rabiesDate?: any | null;
    lastDonationDate?: any | null;
    nextDonationDate?: any | null;
    lastLabworkDate?: any | null;
    hasMicrochip?: boolean | null;
    acceptSimparicaTrio: boolean;
    isDaycare?: boolean | null;
    donationFrequency: number;
    canRegularlyAttendDaycare?: boolean | null;
    attendDaycareDays?: Array<string> | null;
    retiredReason?: RetiredReasons | null;
    retiredReasonComment?: string | null;
    dateOfBirth: any;
    lastLabworkFile?: Array<{
      __typename?: 'FileOutput';
      id: string;
      filename: string;
    }> | null;
    team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
    reserveNotes?: Array<{
      __typename?: 'ReserveDonorNoteOutput';
      id: string;
      text: string;
      createdAt: any;
    }> | null;
    pictures?: Array<{
      __typename?: 'DonorPictureOutput';
      id: string;
      isPrimary: boolean;
      file: { __typename?: 'FileOutput'; id: string; filename: string };
    }> | null;
  };
};

export type GetCurrentUserDonorsListQueryVariables = Exact<{
  input: CurrentUserDonorListInput;
}>;

export type GetCurrentUserDonorsListQuery = {
  __typename?: 'Query';
  getCurrentUserDonorsList: {
    __typename?: 'DonorsListOutput';
    data: Array<{
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      lastLabworkFile?: Array<{
        __typename?: 'FileOutput';
        id: string;
        filename: string;
      }> | null;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
      reserveNotes?: Array<{
        __typename?: 'ReserveDonorNoteOutput';
        id: string;
        text: string;
        createdAt: any;
      }> | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type FindAllGroupQueryVariables = Exact<{
  input: GroupListInput;
}>;

export type FindAllGroupQuery = {
  __typename?: 'Query';
  findAllGroup: {
    __typename?: 'GroupListOutput';
    data: Array<{
      __typename?: 'GroupListItemOutput';
      teamCount: number;
      donorCount?: number | null;
      title: string;
      id: string;
      teams?: Array<{
        __typename?: 'GroupTeamOutput';
        id: string;
        title: string;
      }> | null;
      user?: {
        __typename?: 'GroupListItemUserOutput';
        firstName: string;
        lastName: string;
        id: string;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type FindGroupQueryVariables = Exact<{
  input: FindGroupInput;
}>;

export type FindGroupQuery = {
  __typename?: 'Query';
  findGroup: {
    __typename?: 'GroupOutput';
    id: string;
    title: string;
    teams: Array<{
      __typename?: 'TeamOutput';
      id: string;
      title: string;
      donors?: Array<{
        __typename?: 'DonorOutput';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        approvedDonationML: number;
        donationMLApprovedAmount?: number | null;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        becameReservedDonorDate?: any | null;
        retiredDate?: any | null;
        distemperDate?: any | null;
        rabiesDate?: any | null;
        lastDonationDate?: any | null;
        nextDonationDate?: any | null;
        lastLabworkDate?: any | null;
        hasMicrochip?: boolean | null;
        acceptSimparicaTrio: boolean;
        isDaycare?: boolean | null;
        donationFrequency: number;
        canRegularlyAttendDaycare?: boolean | null;
        attendDaycareDays?: Array<string> | null;
        retiredReason?: RetiredReasons | null;
        retiredReasonComment?: string | null;
        dateOfBirth: any;
        user?: {
          __typename?: 'OwnerOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
          type?: OwnerType | null;
          isArchived: boolean;
          contactPreferences?: string | null;
          deletedAt?: any | null;
          role: {
            __typename?: 'RoleWithoutPermissionsOutput';
            id: string;
            value: UserRole;
          };
          contacts?: Array<{
            __typename?: 'ContactOutput';
            streetAddress?: string | null;
            city?: string | null;
            state?: string | null;
            zipCode?: string | null;
            email?: string | null;
            firstName?: string | null;
            id: string;
            lastName?: string | null;
            phoneNumber?: string | null;
          }> | null;
        } | null;
      }> | null;
    }>;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      role: {
        __typename?: 'RoleWithoutPermissionsOutput';
        id: string;
        value: UserRole;
      };
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
  };
};

export type GetDonorLivesSavedQueryVariables = Exact<{
  input: GetLivesSavedByDonorInput;
}>;

export type GetDonorLivesSavedQuery = {
  __typename?: 'Query';
  getDonorLivesSaved: { __typename?: 'LivesSavedOutput'; lives: number };
};

export type GetChartsDataQueryVariables = Exact<{
  input: GetSpecificDonorDonationsListInput;
}>;

export type GetChartsDataQuery = {
  __typename?: 'Query';
  getChartsData: {
    __typename?: 'DonationListOutput';
    data: Array<{
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetLabworkListQueryVariables = Exact<{
  input: LabworkListInput;
}>;

export type GetLabworkListQuery = {
  __typename?: 'Query';
  getLabworkList: {
    __typename?: 'LabworkListOutput';
    data: Array<{
      __typename?: 'LabworkOutput';
      id: string;
      collectionDate: any;
      status: LabworkStatus;
      DVMExam: boolean;
      donor: {
        __typename?: 'DonorOutput';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        approvedDonationML: number;
        donationMLApprovedAmount?: number | null;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        becameReservedDonorDate?: any | null;
        retiredDate?: any | null;
        distemperDate?: any | null;
        rabiesDate?: any | null;
        lastDonationDate?: any | null;
        nextDonationDate?: any | null;
        lastLabworkDate?: any | null;
        hasMicrochip?: boolean | null;
        acceptSimparicaTrio: boolean;
        isDaycare?: boolean | null;
        donationFrequency: number;
        canRegularlyAttendDaycare?: boolean | null;
        attendDaycareDays?: Array<string> | null;
        retiredReason?: RetiredReasons | null;
        retiredReasonComment?: string | null;
        dateOfBirth: any;
        user?: {
          __typename?: 'OwnerOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
          type?: OwnerType | null;
          isArchived: boolean;
          contactPreferences?: string | null;
          deletedAt?: any | null;
        } | null;
      };
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetLabworkQueryVariables = Exact<{
  input: GetLabworkInput;
}>;

export type GetLabworkQuery = {
  __typename?: 'Query';
  getLabwork: {
    __typename?: 'LabworkOutput';
    id: string;
    collectionDate: any;
    status: LabworkStatus;
    DVMExam: boolean;
    donor: {
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
      } | null;
    };
  };
};

export type GetVitalsParameterListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetVitalsParameterListQuery = {
  __typename?: 'Query';
  getVitalsParameterList: Array<{
    __typename?: 'VitalsParameterOutput';
    id: string;
    criticalValues?: Array<string> | null;
    type: VitalsParametersType;
    max?: number | null;
    min?: number | null;
    percentageDifference?: number | null;
  }>;
};

export type GetAppointmentsDonorsListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAppointmentsDonorsListQuery = {
  __typename?: 'Query';
  getAppointmentsDonorsList: Array<{
    __typename?: 'DonorOutput';
    id: string;
    age: number;
    gender: AnimalGender;
    hasTransfusion?: boolean | null;
    name: string;
    neutered: boolean;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    takeMedications?: boolean | null;
    weight: number;
    approvedDonationML: number;
    donationMLApprovedAmount?: number | null;
    bloodType?: BloodType | null;
    status: DonorStatus;
    type: DonorType;
    location: string;
    distinguishableMarks?: string | null;
    microchipNumber?: string | null;
    medicationCourse?: string | null;
    comment?: string | null;
    breed?: string | null;
    color?: string | null;
    createdAt: any;
    shortId: number;
    firstCertificatePrinted: boolean;
    secondCertificatePrinted: boolean;
    becameReservedDonorDate?: any | null;
    retiredDate?: any | null;
    distemperDate?: any | null;
    rabiesDate?: any | null;
    lastDonationDate?: any | null;
    nextDonationDate?: any | null;
    lastLabworkDate?: any | null;
    hasMicrochip?: boolean | null;
    acceptSimparicaTrio: boolean;
    isDaycare?: boolean | null;
    donationFrequency: number;
    canRegularlyAttendDaycare?: boolean | null;
    attendDaycareDays?: Array<string> | null;
    retiredReason?: RetiredReasons | null;
    retiredReasonComment?: string | null;
    dateOfBirth: any;
    team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
    reserveNotes?: Array<{
      __typename?: 'ReserveDonorNoteOutput';
      id: string;
      text: string;
      createdAt: any;
    }> | null;
  }>;
};

export type GetDonorsListByIdsQueryVariables = Exact<{
  input: GetDonorsByIdsInput;
}>;

export type GetDonorsListByIdsQuery = {
  __typename?: 'Query';
  getDonorsListByIds: Array<{
    __typename?: 'DonorOutput';
    id: string;
    age: number;
    gender: AnimalGender;
    hasTransfusion?: boolean | null;
    name: string;
    neutered: boolean;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    takeMedications?: boolean | null;
    weight: number;
    approvedDonationML: number;
    donationMLApprovedAmount?: number | null;
    bloodType?: BloodType | null;
    status: DonorStatus;
    type: DonorType;
    location: string;
    distinguishableMarks?: string | null;
    microchipNumber?: string | null;
    medicationCourse?: string | null;
    comment?: string | null;
    breed?: string | null;
    color?: string | null;
    createdAt: any;
    shortId: number;
    firstCertificatePrinted: boolean;
    secondCertificatePrinted: boolean;
    becameReservedDonorDate?: any | null;
    retiredDate?: any | null;
    distemperDate?: any | null;
    rabiesDate?: any | null;
    lastDonationDate?: any | null;
    nextDonationDate?: any | null;
    lastLabworkDate?: any | null;
    hasMicrochip?: boolean | null;
    acceptSimparicaTrio: boolean;
    isDaycare?: boolean | null;
    donationFrequency: number;
    canRegularlyAttendDaycare?: boolean | null;
    attendDaycareDays?: Array<string> | null;
    retiredReason?: RetiredReasons | null;
    retiredReasonComment?: string | null;
    dateOfBirth: any;
    team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
    user?: {
      __typename?: 'OwnerOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
    } | null;
    reserveNotes?: Array<{
      __typename?: 'ReserveDonorNoteOutput';
      id: string;
      text: string;
      createdAt: any;
    }> | null;
  }>;
};

export type FindTeamQueryVariables = Exact<{
  input: FindTeamInput;
}>;

export type FindTeamQuery = {
  __typename?: 'Query';
  findTeam: {
    __typename?: 'TeamOutput';
    id: string;
    title: string;
    donors?: Array<{
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
    }> | null;
  };
};

export type GetLabworkAttachmentsQueryVariables = Exact<{
  input: GetLabworkAttachmentsListInput;
}>;

export type GetLabworkAttachmentsQuery = {
  __typename?: 'Query';
  getLabworkAttachments: Array<{
    __typename?: 'LabworkAttachmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    file: { __typename?: 'FileOutput'; id: string; filename: string };
  }>;
};

export type HasDonorRelatedConnectionsQueryVariables = Exact<{
  input: DeleteDonorInput;
}>;

export type HasDonorRelatedConnectionsQuery = {
  __typename?: 'Query';
  hasDonorRelatedConnections: boolean;
};

export type GetAppointmentsDatesForDonorQueryVariables = Exact<{
  donorId: Scalars['String'];
}>;

export type GetAppointmentsDatesForDonorQuery = {
  __typename?: 'Query';
  getAppointmentsDatesForDonor: Array<{
    __typename?: 'AppointmentDatesInfoOutput';
    id: string;
    start: any;
    end: any;
  }>;
};

export type GetDonorNotesQueryVariables = Exact<{
  donorId: Scalars['String'];
}>;

export type GetDonorNotesQuery = {
  __typename?: 'Query';
  getDonorNotes: Array<{
    __typename?: 'DonorNoteOutput';
    id: string;
    text: string;
    isPinned: boolean;
    createdAt: any;
    updatedAt: any;
    files: Array<{
      __typename?: 'DonorNoteFileOutput';
      id: string;
      file: { __typename?: 'FileOutput'; id: string; filename: string };
    }>;
    user: {
      __typename?: 'UserOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      deletionIntentionAt?: any | null;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      productRegionPreference?: {
        __typename?: 'RegionOutput';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        accountingCode?: string | null;
      } | null;
    };
  }>;
};

export type GetDonorPicturesQueryVariables = Exact<{
  input: GetDonorPicturesInput;
}>;

export type GetDonorPicturesQuery = {
  __typename?: 'Query';
  getDonorPictures: Array<{
    __typename?: 'DonorPictureOutput';
    id: string;
    isPrimary: boolean;
    file: { __typename?: 'FileOutput'; id: string; filename: string };
  }>;
};

export type RegionFragment = {
  __typename?: 'RegionOutput';
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  accountingCode?: string | null;
};

export type LocationFragment = {
  __typename?: 'LocationOutput';
  id: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  type: LocationType;
  region?: {
    __typename?: 'RegionOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    accountingCode?: string | null;
  } | null;
};

export type CreateLocationMutationVariables = Exact<{
  input: CreateLocationInput;
}>;

export type CreateLocationMutation = {
  __typename?: 'Mutation';
  createLocation: Array<{
    __typename?: 'LocationOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    type: LocationType;
    region?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  }>;
};

export type UpdateLocationMutationVariables = Exact<{
  input: UpdateLocationInput;
}>;

export type UpdateLocationMutation = {
  __typename?: 'Mutation';
  updateLocation: Array<{
    __typename?: 'LocationOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    type: LocationType;
    region?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  }>;
};

export type DeleteLocationMutationVariables = Exact<{
  input: DeleteLocationInput;
}>;

export type DeleteLocationMutation = {
  __typename?: 'Mutation';
  deleteLocation: boolean;
};

export type CreateRegionMutationVariables = Exact<{
  input: CreateRegionInput;
}>;

export type CreateRegionMutation = {
  __typename?: 'Mutation';
  createRegion: Array<{
    __typename?: 'RegionOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    accountingCode?: string | null;
  }>;
};

export type UpdateRegionMutationVariables = Exact<{
  input: UpdateRegionInput;
}>;

export type UpdateRegionMutation = {
  __typename?: 'Mutation';
  updateRegion: Array<{
    __typename?: 'RegionOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    accountingCode?: string | null;
  }>;
};

export type DeleteRegionMutationVariables = Exact<{
  input: DeleteRegionInput;
}>;

export type DeleteRegionMutation = {
  __typename?: 'Mutation';
  deleteRegion: boolean;
};

export type GetLocationsQueryVariables = Exact<{
  input: GetLocationsInput;
}>;

export type GetLocationsQuery = {
  __typename?: 'Query';
  getLocations: {
    __typename?: 'PaginatedLocationsOutput';
    data: Array<{
      __typename?: 'LocationOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      type: LocationType;
      region?: {
        __typename?: 'RegionOutput';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        accountingCode?: string | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetPublicLocationsQueryVariables = Exact<{
  input: GetPublicLocationsInput;
}>;

export type GetPublicLocationsQuery = {
  __typename?: 'Query';
  getPublicLocations: {
    __typename?: 'PaginatedLocationsOutput';
    data: Array<{
      __typename?: 'LocationOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      type: LocationType;
      region?: {
        __typename?: 'RegionOutput';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        accountingCode?: string | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetLocationWithAvailableDaycareQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLocationWithAvailableDaycareQuery = {
  __typename?: 'Query';
  getLocationWithAvailableDaycare: Array<{
    __typename?: 'LocationOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    type: LocationType;
    region?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  }>;
};

export type GetRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegionsQuery = {
  __typename?: 'Query';
  getRegions: Array<{
    __typename?: 'RegionOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    accountingCode?: string | null;
  }>;
};

export type NotificationConfigFragment = {
  __typename?: 'NotificationConfigOutput';
  name: Notification;
  value: boolean;
};

export type NotificationFragment = {
  __typename?: 'NotificationOutput';
  id: string;
  type: NotificationType;
  isRead: boolean;
  createdAt: any;
};

export type NotificationSubscriptionFragment = {
  __typename?: 'NotificationWebSocketOutput';
  id: string;
  type: NotificationType;
  isRead: boolean;
};

export type UpdateNotificationConfigListMutationVariables = Exact<{
  input: UpdateNotificationConfigListInput;
}>;

export type UpdateNotificationConfigListMutation = {
  __typename?: 'Mutation';
  updateNotificationConfigList: Array<{
    __typename?: 'NotificationConfigOutput';
    name: Notification;
    value: boolean;
  }>;
};

export type GetNotificationConfigListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNotificationConfigListQuery = {
  __typename?: 'Query';
  getNotificationConfigList: Array<{
    __typename?: 'NotificationConfigOutput';
    name: Notification;
    value: boolean;
  }>;
};

export type GetNotificationListQueryVariables = Exact<{
  input: GetNotificationListInput;
}>;

export type GetNotificationListQuery = {
  __typename?: 'Query';
  getNotificationsList: {
    __typename?: 'NotificationListOutput';
    data: Array<{
      __typename?: 'NotificationOutput';
      id: string;
      type: NotificationType;
      isRead: boolean;
      createdAt: any;
      payload: {
        __typename?: 'NotificationPayloadOutput';
        appointment?: {
          __typename?: 'AppointmentOutput';
          id: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          start: any;
          end: any;
          location: string;
          notes?: string | null;
          isDaycare?: boolean | null;
          isPrivate?: boolean | null;
          users: Array<{
            __typename?: 'AppointmentUser';
            email: string;
            id: string;
            firstName: string;
            lastName: string;
            phoneNumber?: string | null;
            streetAddress?: string | null;
            city?: string | null;
            state?: string | null;
            zipCode?: string | null;
            locationPreferences?: string | null;
            birthday?: any | null;
            shortId: number;
            contacts?: Array<{
              __typename?: 'ContactOutput';
              streetAddress?: string | null;
              city?: string | null;
              state?: string | null;
              zipCode?: string | null;
              email?: string | null;
              firstName?: string | null;
              id: string;
              lastName?: string | null;
              phoneNumber?: string | null;
            }> | null;
          }>;
          donor?: {
            __typename?: 'AppointmentDonor';
            id: string;
            age: number;
            gender: AnimalGender;
            hasTransfusion?: boolean | null;
            name: string;
            neutered: boolean;
            primaryVeterinarian: string;
            species: AnimalSpecies;
            takeMedications?: boolean | null;
            weight: number;
            bloodType?: BloodType | null;
            status: DonorStatus;
            type: DonorType;
            location: string;
            distinguishableMarks?: string | null;
            microchipNumber?: string | null;
            medicationCourse?: string | null;
            comment?: string | null;
            breed?: string | null;
            color?: string | null;
            createdAt: any;
            shortId: number;
            firstCertificatePrinted: boolean;
            secondCertificatePrinted: boolean;
            user?: {
              __typename?: 'AppointmentUser';
              email: string;
              id: string;
              firstName: string;
              lastName: string;
              phoneNumber?: string | null;
              streetAddress?: string | null;
              city?: string | null;
              state?: string | null;
              zipCode?: string | null;
              locationPreferences?: string | null;
              birthday?: any | null;
              shortId: number;
              contacts?: Array<{
                __typename?: 'ContactOutput';
                streetAddress?: string | null;
                city?: string | null;
                state?: string | null;
                zipCode?: string | null;
                email?: string | null;
                firstName?: string | null;
                id: string;
                lastName?: string | null;
                phoneNumber?: string | null;
              }> | null;
            } | null;
          } | null;
          group?: {
            __typename?: 'AppointmentGroupOutput';
            id: string;
            title: string;
            user?: {
              __typename?: 'AppointmentUser';
              email: string;
              id: string;
              firstName: string;
              lastName: string;
              phoneNumber?: string | null;
              streetAddress?: string | null;
              city?: string | null;
              state?: string | null;
              zipCode?: string | null;
              locationPreferences?: string | null;
              birthday?: any | null;
              shortId: number;
              contacts?: Array<{
                __typename?: 'ContactOutput';
                streetAddress?: string | null;
                city?: string | null;
                state?: string | null;
                zipCode?: string | null;
                email?: string | null;
                firstName?: string | null;
                id: string;
                lastName?: string | null;
                phoneNumber?: string | null;
              }> | null;
            } | null;
          } | null;
          team?: {
            __typename?: 'AppointmentTeamOutput';
            id: string;
            title: string;
          } | null;
        } | null;
        request?: {
          __typename?: 'PartialRequestOutput';
          id: string;
          number: number;
          updatedAt: any;
          donor: {
            __typename?: 'PartialDonorOutput';
            id?: string | null;
            age?: number | null;
            gender?: AnimalGender | null;
            hasTransfusion?: boolean | null;
            name?: string | null;
            neutered?: boolean | null;
            primaryVeterinarian?: string | null;
            species?: AnimalSpecies | null;
            takeMedications?: boolean | null;
            weight?: number | null;
            approvedDonationML?: number | null;
            donationMLApprovedAmount?: number | null;
            bloodType?: BloodType | null;
            status?: DonorStatus | null;
            type?: DonorType | null;
            location?: string | null;
            distinguishableMarks?: string | null;
            microchipNumber?: string | null;
            medicationCourse?: string | null;
            comment?: string | null;
            breed?: string | null;
            color?: string | null;
            createdAt?: any | null;
            shortId?: number | null;
            firstCertificatePrinted?: boolean | null;
            secondCertificatePrinted?: boolean | null;
            becameReservedDonorDate?: any | null;
            retiredDate?: any | null;
            distemperDate?: any | null;
            rabiesDate?: any | null;
            lastDonationDate?: any | null;
            nextDonationDate?: any | null;
            lastLabworkDate?: any | null;
            hasMicrochip?: boolean | null;
            acceptSimparicaTrio?: boolean | null;
            isDaycare?: boolean | null;
            donationFrequency?: number | null;
            canRegularlyAttendDaycare?: boolean | null;
            attendDaycareDays?: Array<string> | null;
            retiredReason?: RetiredReasons | null;
            retiredReasonComment?: string | null;
            dateOfBirth?: any | null;
            team?: {
              __typename?: 'TeamOutput';
              id: string;
              title: string;
            } | null;
            user?: {
              __typename?: 'OwnerOutput';
              email: string;
              id: string;
              firstName: string;
              lastName: string;
              phoneNumber?: string | null;
              streetAddress?: string | null;
              city?: string | null;
              state?: string | null;
              zipCode?: string | null;
              locationPreferences?: string | null;
              birthday?: any | null;
              shortId: number;
              type?: OwnerType | null;
              isArchived: boolean;
              contactPreferences?: string | null;
              deletedAt?: any | null;
              contacts?: Array<{
                __typename?: 'ContactOutput';
                streetAddress?: string | null;
                city?: string | null;
                state?: string | null;
                zipCode?: string | null;
                email?: string | null;
                firstName?: string | null;
                id: string;
                lastName?: string | null;
                phoneNumber?: string | null;
              }> | null;
            } | null;
          };
        } | null;
      };
      user: {
        __typename?: 'NotificationUserOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
        role: {
          __typename?: 'RoleWithoutPermissionsOutput';
          id: string;
          value: UserRole;
        };
      };
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type NotificationAddedSubscriptionVariables = Exact<{
  input: NotificationAddedSubscriptionInput;
}>;

export type NotificationAddedSubscription = {
  __typename?: 'Subscription';
  notificationAdded: {
    __typename?: 'NotificationWebSocketOutput';
    id: string;
    type: NotificationType;
    isRead: boolean;
  };
};

export type OrderBillingFragment = {
  __typename?: 'BillingOutput';
  city: string;
  company: string;
  country: string;
  email: string;
  firstAddress: string;
  firstName: string;
  lastName: string;
  phone: string;
  postcode: string;
  secondAddress?: string | null;
  state: string;
};

export type OrderShippingFragment = {
  __typename?: 'ShippingOutput';
  city: string;
  company: string;
  country: string;
  firstAddress: string;
  firstName: string;
  lastName: string;
  phone: string;
  postcode: string;
  secondAddress?: string | null;
  state: string;
};

export type ShippingLineFragment = {
  __typename?: 'ShippingLineOutput';
  id: number;
  instanceId: string;
  methodId: string;
  methodTitle: string;
  total: string;
  totalTax: string;
};

export type FeeLineFragment = {
  __typename?: 'FeeLineOutput';
  amount: string;
  id: number;
  name: string;
  taxClass: string;
  taxStatus: TaxStatus;
  total: string;
  totalTax: string;
};

export type OrderItemFragment = {
  __typename?: 'OrderItemOutput';
  id: number;
  name: string;
  price: number;
  quantity: number;
  sku?: string | null;
};

export type GetOrdersListQueryVariables = Exact<{
  input: GetOrdersListInput;
}>;

export type GetOrdersListQuery = {
  __typename?: 'Query';
  getOrdersList: {
    __typename?: 'OrderListOutput';
    data: Array<{
      __typename?: 'OrderOutput';
      id: string;
      date: string;
      number: number;
      status: string;
      trackingCode?: string | null;
      paymentMethod: string;
      total: string;
      customerId: string;
      items: Array<{
        __typename?: 'OrderItemOutput';
        id: number;
        name: string;
        price: number;
        quantity: number;
        sku?: string | null;
      }>;
      billing: {
        __typename?: 'BillingOutput';
        city: string;
        company: string;
        country: string;
        email: string;
        firstAddress: string;
        firstName: string;
        lastName: string;
        phone: string;
        postcode: string;
        secondAddress?: string | null;
        state: string;
      };
      shipping: {
        __typename?: 'ShippingOutput';
        city: string;
        company: string;
        country: string;
        firstAddress: string;
        firstName: string;
        lastName: string;
        phone: string;
        postcode: string;
        secondAddress?: string | null;
        state: string;
      };
      shippingLines: Array<{
        __typename?: 'ShippingLineOutput';
        id: number;
        instanceId: string;
        methodId: string;
        methodTitle: string;
        total: string;
        totalTax: string;
      }>;
      feeLines: Array<{
        __typename?: 'FeeLineOutput';
        amount: string;
        id: number;
        name: string;
        taxClass: string;
        taxStatus: TaxStatus;
        total: string;
        totalTax: string;
      }>;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetCustomerQueryVariables = Exact<{
  input: GetCustomerInput;
}>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  getCustomer: {
    __typename?: 'CustomerOutput';
    email?: string | null;
    firstName: string;
    id: string;
    lastName: string;
    username: string;
    billing: {
      __typename?: 'BillingOutput';
      city: string;
      company: string;
      country: string;
      email: string;
      firstAddress: string;
      firstName: string;
      lastName: string;
      phone: string;
      postcode: string;
      secondAddress?: string | null;
      state: string;
    };
    shipping: {
      __typename?: 'ShippingOutput';
      city: string;
      company: string;
      country: string;
      firstAddress: string;
      firstName: string;
      lastName: string;
      phone: string;
      postcode: string;
      secondAddress?: string | null;
      state: string;
    };
  };
};

export type GetOrderQueryVariables = Exact<{
  input: GetOrderInput;
}>;

export type GetOrderQuery = {
  __typename?: 'Query';
  getOrder: {
    __typename?: 'OrderOutput';
    id: string;
    date: string;
    number: number;
    status: string;
    trackingCode?: string | null;
    paymentMethod: string;
    total: string;
    customerId: string;
    items: Array<{
      __typename?: 'OrderItemOutput';
      id: number;
      name: string;
      price: number;
      quantity: number;
      sku?: string | null;
    }>;
    billing: {
      __typename?: 'BillingOutput';
      city: string;
      company: string;
      country: string;
      email: string;
      firstAddress: string;
      firstName: string;
      lastName: string;
      phone: string;
      postcode: string;
      secondAddress?: string | null;
      state: string;
    };
    shipping: {
      __typename?: 'ShippingOutput';
      city: string;
      company: string;
      country: string;
      firstAddress: string;
      firstName: string;
      lastName: string;
      phone: string;
      postcode: string;
      secondAddress?: string | null;
      state: string;
    };
    shippingLines: Array<{
      __typename?: 'ShippingLineOutput';
      id: number;
      instanceId: string;
      methodId: string;
      methodTitle: string;
      total: string;
      totalTax: string;
    }>;
    feeLines: Array<{
      __typename?: 'FeeLineOutput';
      amount: string;
      id: number;
      name: string;
      taxClass: string;
      taxStatus: TaxStatus;
      total: string;
      totalTax: string;
    }>;
  };
};

export type OwnerFragment = {
  __typename?: 'OwnerOutput';
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locationPreferences?: string | null;
  birthday?: any | null;
  shortId: number;
  type?: OwnerType | null;
  isArchived: boolean;
  contactPreferences?: string | null;
  deletedAt?: any | null;
};

export type UserAttachmentFragment = {
  __typename?: 'UserAttachmentOutput';
  id: string;
  createdAt: any;
  updatedAt: any;
};

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact: {
    __typename?: 'ContactOutput';
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    email?: string | null;
    id: string;
    phoneNumber?: string | null;
  };
};

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact: {
    __typename?: 'ContactOutput';
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    email?: string | null;
    id: string;
    phoneNumber?: string | null;
  };
};

export type UpdateOwnerInfoMutationVariables = Exact<{
  input: UpdateOwnerInput;
}>;

export type UpdateOwnerInfoMutation = {
  __typename?: 'Mutation';
  updateOwnerInfo: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type UpdateOwnerEmailMutationVariables = Exact<{
  input: UpdateUserEmailInput;
}>;

export type UpdateOwnerEmailMutation = {
  __typename?: 'Mutation';
  updateOwnerEmail: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type UpdateCurrentOwnerInfoMutationVariables = Exact<{
  input: UpdateOwnerInput;
}>;

export type UpdateCurrentOwnerInfoMutation = {
  __typename?: 'Mutation';
  updateCurrentOwnerInfo: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type UpdateCurrentOwnerEmailMutationVariables = Exact<{
  input: UpdateUserEmailInput;
}>;

export type UpdateCurrentOwnerEmailMutation = {
  __typename?: 'Mutation';
  updateCurrentOwnerEmail: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type RemoveCurrentOwnerMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveCurrentOwnerMutation = {
  __typename?: 'Mutation';
  removeCurrentOwner: boolean;
};

export type CancelDeletingCurrentOwnerMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CancelDeletingCurrentOwnerMutation = {
  __typename?: 'Mutation';
  cancelDeletingCurrentOwner: boolean;
};

export type DetachUserFileMutationVariables = Exact<{
  input: DetachUserAttachmentInput;
}>;

export type DetachUserFileMutation = {
  __typename?: 'Mutation';
  detachUserFile: boolean;
};

export type GetUserContactsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserContactsQuery = {
  __typename?: 'Query';
  getUserContacts: Array<{
    __typename?: 'ContactOutput';
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    id: string;
  }>;
};

export type GetOwnerQueryVariables = Exact<{
  input: GetUserInput;
}>;

export type GetOwnerQuery = {
  __typename?: 'Query';
  getOwner: {
    __typename?: 'OwnerOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
  };
};

export type GetUserDonorsListQueryVariables = Exact<{
  input: UserDonorListInput;
}>;

export type GetUserDonorsListQuery = {
  __typename?: 'Query';
  getUserDonorsList: {
    __typename?: 'DonorsListOutput';
    data: Array<{
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetUserAttachmentsQueryVariables = Exact<{
  input: GetUserAttachmentsListInput;
}>;

export type GetUserAttachmentsQuery = {
  __typename?: 'Query';
  getUserAttachments: Array<{
    __typename?: 'UserAttachmentOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    file: { __typename?: 'FileOutput'; id: string; filename: string };
  }>;
};

export type ProductFragment = {
  __typename?: 'ProductOutput';
  anticoagulant?: Anticoagulant | null;
  createdAt: any;
  id: string;
  shortId: number;
  status: ProductStatus;
  storageRequirements: Array<string>;
  updatedAt: any;
  value: number;
  woocommerceProductId: number;
  woocommerceCategoryId: number;
  woocommerceOrderId?: number | null;
  expirationDate: any;
  published: boolean;
  publishable: boolean;
  regionPreference?: {
    __typename?: 'RegionOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    accountingCode?: string | null;
  } | null;
};

export type DiscardedProductDataFragment = {
  __typename?: 'DiscardedProductDataOutput';
  processingType: ProcessingProductType;
  reason: ReasonDiscardProduct;
  notes?: string | null;
};

export type ProductProcessorFragment = {
  __typename?: 'ProductProcessorOutput';
  id: string;
  firstName: string;
  lastName: string;
};

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;

export type CreateProductMutation = {
  __typename?: 'Mutation';
  createProduct: {
    __typename?: 'ProductOutput';
    anticoagulant?: Anticoagulant | null;
    createdAt: any;
    id: string;
    shortId: number;
    status: ProductStatus;
    storageRequirements: Array<string>;
    updatedAt: any;
    value: number;
    woocommerceProductId: number;
    woocommerceCategoryId: number;
    woocommerceOrderId?: number | null;
    expirationDate: any;
    published: boolean;
    publishable: boolean;
    discardedProduct?: {
      __typename?: 'DiscardedProductDataOutput';
      processingType: ProcessingProductType;
      reason: ReasonDiscardProduct;
      notes?: string | null;
      processor?: {
        __typename?: 'ProductProcessorOutput';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    donation: {
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    };
    regionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type CreateProductsMutationVariables = Exact<{
  input: CreateProductsInput;
}>;

export type CreateProductsMutation = {
  __typename?: 'Mutation';
  createProducts: Array<{
    __typename?: 'ProductOutput';
    anticoagulant?: Anticoagulant | null;
    createdAt: any;
    id: string;
    shortId: number;
    status: ProductStatus;
    storageRequirements: Array<string>;
    updatedAt: any;
    value: number;
    woocommerceProductId: number;
    woocommerceCategoryId: number;
    woocommerceOrderId?: number | null;
    expirationDate: any;
    published: boolean;
    publishable: boolean;
    discardedProduct?: {
      __typename?: 'DiscardedProductDataOutput';
      processingType: ProcessingProductType;
      reason: ReasonDiscardProduct;
      notes?: string | null;
      processor?: {
        __typename?: 'ProductProcessorOutput';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    donation: {
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    };
    regionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  }>;
};

export type MakeProductAvailableMutationVariables = Exact<{
  input: MakeProductAvailableInput;
}>;

export type MakeProductAvailableMutation = {
  __typename?: 'Mutation';
  makeProductAvailable: {
    __typename?: 'ProductOutput';
    anticoagulant?: Anticoagulant | null;
    createdAt: any;
    id: string;
    shortId: number;
    status: ProductStatus;
    storageRequirements: Array<string>;
    updatedAt: any;
    value: number;
    woocommerceProductId: number;
    woocommerceCategoryId: number;
    woocommerceOrderId?: number | null;
    expirationDate: any;
    published: boolean;
    publishable: boolean;
    donation: {
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    };
    regionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type UpdateProductStatusMutationVariables = Exact<{
  input: UpdateProductStatusInput;
}>;

export type UpdateProductStatusMutation = {
  __typename?: 'Mutation';
  updateProductStatus: {
    __typename?: 'ProductOutput';
    anticoagulant?: Anticoagulant | null;
    createdAt: any;
    id: string;
    shortId: number;
    status: ProductStatus;
    storageRequirements: Array<string>;
    updatedAt: any;
    value: number;
    woocommerceProductId: number;
    woocommerceCategoryId: number;
    woocommerceOrderId?: number | null;
    expirationDate: any;
    published: boolean;
    publishable: boolean;
    discardedProduct?: {
      __typename?: 'DiscardedProductDataOutput';
      processingType: ProcessingProductType;
      reason: ReasonDiscardProduct;
      notes?: string | null;
      processor?: {
        __typename?: 'ProductProcessorOutput';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    donation: {
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    };
    regionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;

export type UpdateProductMutation = {
  __typename?: 'Mutation';
  updateProduct: {
    __typename?: 'ProductOutput';
    anticoagulant?: Anticoagulant | null;
    createdAt: any;
    id: string;
    shortId: number;
    status: ProductStatus;
    storageRequirements: Array<string>;
    updatedAt: any;
    value: number;
    woocommerceProductId: number;
    woocommerceCategoryId: number;
    woocommerceOrderId?: number | null;
    expirationDate: any;
    published: boolean;
    publishable: boolean;
    discardedProduct?: {
      __typename?: 'DiscardedProductDataOutput';
      processingType: ProcessingProductType;
      reason: ReasonDiscardProduct;
      notes?: string | null;
      processor?: {
        __typename?: 'ProductProcessorOutput';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    donation: {
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    };
    regionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type DeleteProductMutationVariables = Exact<{
  input: DeleteProductInput;
}>;

export type DeleteProductMutation = {
  __typename?: 'Mutation';
  deleteProduct: boolean;
};

export type GetWoocommerceCategoriesQueryVariables = Exact<{
  input: GetWoocommerceProductCategoriesInput;
}>;

export type GetWoocommerceCategoriesQuery = {
  __typename?: 'Query';
  getWoocommerceCategories: Array<{
    __typename?: 'WoocommerceProductCategoryOutput';
    id: number;
    count: number;
    name: string;
    slug: string;
    parent: number;
  }>;
};

export type GetWoocommerceProductsQueryVariables = Exact<{
  input: GetWoocommerceProductsInput;
}>;

export type GetWoocommerceProductsQuery = {
  __typename?: 'Query';
  getWoocommerceProducts: {
    __typename?: 'WoocommerceProductListOutput';
    data: Array<{
      __typename?: 'WoocommerceProductOutput';
      date_created: string;
      date_modified: string;
      description: string;
      id: number;
      name: string;
      price: string;
      slug: string;
      status: string;
      stock_status: string;
      stock_quantity?: number | null;
      type: string;
      categories: Array<{
        __typename?: 'Category';
        id: number;
        name: string;
        slug: string;
      }>;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetProductsQueryVariables = Exact<{
  input: ProductListInput;
}>;

export type GetProductsQuery = {
  __typename?: 'Query';
  getProducts: {
    __typename?: 'ProductListOutput';
    data: Array<{
      __typename?: 'ProductOutput';
      anticoagulant?: Anticoagulant | null;
      createdAt: any;
      id: string;
      shortId: number;
      status: ProductStatus;
      storageRequirements: Array<string>;
      updatedAt: any;
      value: number;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      woocommerceOrderId?: number | null;
      expirationDate: any;
      published: boolean;
      publishable: boolean;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
        processor?: {
          __typename?: 'ProductProcessorOutput';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      donation: {
        __typename?: 'DonationOutput';
        id: string;
        collectionSet: CollectionSet;
        capillaryRefillTime: CapillaryRefillTime;
        approvedDonationsMl: number;
        jugularVein: JugularVein;
        maxLimit: number;
        mucousMembrane: Mm;
        packedCellVolume: number;
        pulse: number;
        respiration: number;
        shouldDonate?: boolean | null;
        temperature: number;
        totalSolids: number;
        unit?: DonationUnit | null;
        actual?: number | null;
        weatherTemperature: number;
        weatherTypes: Array<WeatherType>;
        weight: number;
        examinationNotes?: string | null;
        preferences?: string | null;
        createdAt: any;
        comment?: string | null;
        shortId: number;
        donationDate: any;
        ineligible: boolean;
        ineligibleReason?: IneligibleReason | null;
        donor: {
          __typename?: 'DonationDonor';
          id: string;
          age: number;
          gender: AnimalGender;
          hasTransfusion?: boolean | null;
          name: string;
          neutered: boolean;
          primaryVeterinarian: string;
          species: AnimalSpecies;
          takeMedications?: boolean | null;
          weight: number;
          bloodType?: BloodType | null;
          status: DonorStatus;
          type: DonorType;
          location: string;
          distinguishableMarks?: string | null;
          microchipNumber?: string | null;
          medicationCourse?: string | null;
          comment?: string | null;
          breed?: string | null;
          color?: string | null;
          createdAt: any;
          shortId: number;
          firstCertificatePrinted: boolean;
          secondCertificatePrinted: boolean;
          lastDonationDate?: any | null;
          lastLabworkDate?: any | null;
          nextDonationDate?: any | null;
          team?: {
            __typename?: 'TeamOutput';
            id: string;
            title: string;
          } | null;
          user?: {
            __typename?: 'HolderOutput';
            email: string;
            id: string;
            firstName: string;
            lastName: string;
            phoneNumber?: string | null;
            streetAddress?: string | null;
            city?: string | null;
            state?: string | null;
            zipCode?: string | null;
            locationPreferences?: string | null;
            birthday?: any | null;
            shortId: number;
          } | null;
          pictures?: Array<{
            __typename?: 'DonorPictureOutput';
            id: string;
            isPrimary: boolean;
            file: { __typename?: 'FileOutput'; id: string; filename: string };
          }> | null;
        };
        additionalInfo?: {
          __typename?: 'AdditionalDonationInfoOutput';
          id: string;
          numberOfCollectionUnitsUsed: CollectionUnitsUsed;
          veinUsed: VeinUsed;
          vascularStructure?: VascularStructure | null;
          flowRate: FlowRate;
          donationPosition?: string | null;
          holdersPosition?: Array<string> | null;
          donorMovement: string;
          typeOfMovement?: string | null;
          emotionalSupport?: Array<string> | null;
          dislikes?: Array<string> | null;
          likes?: Array<string> | null;
          treatPreferences?: Array<string> | null;
          totalCollectedMls?: number | null;
          primaryHolder?: {
            __typename?: 'HolderOutput';
            email: string;
            id: string;
            firstName: string;
            lastName: string;
            phoneNumber?: string | null;
            streetAddress?: string | null;
            city?: string | null;
            state?: string | null;
            zipCode?: string | null;
            locationPreferences?: string | null;
            birthday?: any | null;
            shortId: number;
          } | null;
          secondaryHolder?: {
            __typename?: 'HolderOutput';
            email: string;
            id: string;
            firstName: string;
            lastName: string;
            phoneNumber?: string | null;
            streetAddress?: string | null;
            city?: string | null;
            state?: string | null;
            zipCode?: string | null;
            locationPreferences?: string | null;
            birthday?: any | null;
            shortId: number;
          } | null;
          poker?: {
            __typename?: 'HolderOutput';
            email: string;
            id: string;
            firstName: string;
            lastName: string;
            phoneNumber?: string | null;
            streetAddress?: string | null;
            city?: string | null;
            state?: string | null;
            zipCode?: string | null;
            locationPreferences?: string | null;
            birthday?: any | null;
            shortId: number;
          } | null;
        } | null;
      };
      regionPreference?: {
        __typename?: 'RegionOutput';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        accountingCode?: string | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetProductQueryVariables = Exact<{
  input: GetProductInput;
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  getProduct: {
    __typename?: 'ProductOutput';
    anticoagulant?: Anticoagulant | null;
    createdAt: any;
    id: string;
    shortId: number;
    status: ProductStatus;
    storageRequirements: Array<string>;
    updatedAt: any;
    value: number;
    woocommerceProductId: number;
    woocommerceCategoryId: number;
    woocommerceOrderId?: number | null;
    expirationDate: any;
    published: boolean;
    publishable: boolean;
    donation: {
      __typename?: 'DonationOutput';
      id: string;
      collectionSet: CollectionSet;
      capillaryRefillTime: CapillaryRefillTime;
      approvedDonationsMl: number;
      jugularVein: JugularVein;
      maxLimit: number;
      mucousMembrane: Mm;
      packedCellVolume: number;
      pulse: number;
      respiration: number;
      shouldDonate?: boolean | null;
      temperature: number;
      totalSolids: number;
      unit?: DonationUnit | null;
      actual?: number | null;
      weatherTemperature: number;
      weatherTypes: Array<WeatherType>;
      weight: number;
      examinationNotes?: string | null;
      preferences?: string | null;
      createdAt: any;
      comment?: string | null;
      shortId: number;
      donationDate: any;
      ineligible: boolean;
      ineligibleReason?: IneligibleReason | null;
      ineligibleReasonDetails?: string | null;
      products?: Array<{
        __typename?: 'DonationProductOutput';
        anticoagulant?: Anticoagulant | null;
        createdAt: any;
        id: string;
        shortId: number;
        status: ProductStatus;
        storageRequirements: Array<string>;
        updatedAt: any;
        value: number;
        woocommerceProductId: number;
        woocommerceCategoryId: number;
        expirationDate: any;
        discardedProduct?: {
          __typename?: 'DiscardedProductDataOutput';
          processingType: ProcessingProductType;
          reason: ReasonDiscardProduct;
          notes?: string | null;
        } | null;
      }> | null;
      donor: {
        __typename?: 'DonationDonor';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        lastDonationDate?: any | null;
        lastLabworkDate?: any | null;
        nextDonationDate?: any | null;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        pictures?: Array<{
          __typename?: 'DonorPictureOutput';
          id: string;
          isPrimary: boolean;
          file: { __typename?: 'FileOutput'; id: string; filename: string };
        }> | null;
      };
      additionalInfo?: {
        __typename?: 'AdditionalDonationInfoOutput';
        id: string;
        numberOfCollectionUnitsUsed: CollectionUnitsUsed;
        veinUsed: VeinUsed;
        vascularStructure?: VascularStructure | null;
        flowRate: FlowRate;
        donationPosition?: string | null;
        holdersPosition?: Array<string> | null;
        donorMovement: string;
        typeOfMovement?: string | null;
        emotionalSupport?: Array<string> | null;
        dislikes?: Array<string> | null;
        likes?: Array<string> | null;
        treatPreferences?: Array<string> | null;
        totalCollectedMls?: number | null;
        primaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        secondaryHolder?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
        poker?: {
          __typename?: 'HolderOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
        } | null;
      } | null;
    };
    regionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type GetProductsCountQueryVariables = Exact<{
  input: GetProductsCountInput;
}>;

export type GetProductsCountQuery = {
  __typename?: 'Query';
  getProductsCount: number;
};

export type GetWoocommerceTopSellersQueryVariables = Exact<{
  input: GetWoocommerceTopSellersInput;
}>;

export type GetWoocommerceTopSellersQuery = {
  __typename?: 'Query';
  getWoocommerceTopSellers: Array<{
    __typename?: 'WoocommerceTopSellerOutput';
    name: string;
    product_id: number;
    quantity: number;
  }>;
};

export type DonorReportFragment = {
  __typename?: 'DonorReportOutput';
  id: string;
  name: string;
  location: string;
  region?: string | null;
  age: number;
  neutered: boolean;
  weight: number;
  primaryVeterinarian: string;
  species: AnimalSpecies;
  gender: AnimalGender;
  type: DonorType;
  bloodType?: BloodType | null;
  status: DonorStatus;
  breed?: string | null;
  color?: string | null;
  shortId: number;
  becameReservedDonorDate?: any | null;
  rabiesDate?: any | null;
  distemperDate?: any | null;
  retiredDate?: any | null;
  nextDonationDate?: any | null;
  microchipNumber?: string | null;
  donationFrequency: number;
  approvedDonationML: number;
  donationMLApprovedAmount?: number | null;
  acceptSimparicaTrio: boolean;
  labWorkDue?: any | null;
  annualDVMComplete?: boolean | null;
  isDaycare: boolean;
  canRegularlyAttendDaycare?: boolean | null;
  donorStatusUpdatedAt: any;
};

export type DonationReportFragment = {
  __typename?: 'DonationReportOutput';
  id: string;
  shortId: number;
  temperature: number;
  pulse: number;
  respiration: number;
  capillaryRefillTime: CapillaryRefillTime;
  mucousMembrane: Mm;
  packedCellVolume: number;
  totalSolids: number;
  weight: number;
  unit?: DonationUnit | null;
  actual?: number | null;
  approvedDonationsMl: number;
  donationDate: any;
  jugularVein: JugularVein;
  comment?: string | null;
  ineligibleReason?: IneligibleReason | null;
  donationNumber: number;
};

export type HolderReportDataFragment = {
  __typename?: 'HolderOutput';
  firstName: string;
  lastName: string;
};

export type AdditionalDonationInfoReportFragment = {
  __typename?: 'AdditionalDonationInfoReportOutput';
  id: string;
  numberOfCollectionUnitsUsed: CollectionUnitsUsed;
  totalCollectedMls?: number | null;
};

export type ProductReportFragment = {
  __typename?: 'ProductReportOutput';
  id: string;
  shortId: number;
  createdAt: any;
  woocommerceProductId: number;
  woocommerceCategoryId: number;
  status: ProductStatus;
  value: number;
  storageRequirements: Array<string>;
  anticoagulant?: Anticoagulant | null;
  expirationDate: any;
  regionPreference?: { __typename?: 'RegionOutput'; name: string } | null;
  discardedProduct?: {
    __typename?: 'DiscardedProductDataReportOutput';
    processingType: ProcessingProductType;
    reason: ReasonDiscardProduct;
    notes?: string | null;
    processor?: {
      __typename?: 'ProductProcessorOutput';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  } | null;
  donation: {
    __typename?: 'ProductDonationReportOutput';
    id: string;
    donor?: {
      __typename?: 'ProductDonorReportOutput';
      id: string;
      name: string;
      location: string;
      user?: {
        __typename?: 'ProductDonorOwnerReportOutput';
        id: string;
        lastName: string;
      } | null;
    } | null;
    additionalInfo?: {
      __typename?: 'ProductAdditionlaDonationInfoReportOutput';
      id: string;
      poker?: {
        __typename?: 'ProductDonationHolderReportOutput';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      primaryHolder?: {
        __typename?: 'ProductDonationHolderReportOutput';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
  };
};

export type OwnerReportFragment = {
  __typename?: 'OwnerReportOutput';
  id: string;
  shortId: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locationPreferences?: string | null;
  regionPreferences?: string | null;
  birthday?: any | null;
  type?: OwnerType | null;
  isArchived: boolean;
  contactPreferences?: string | null;
};

export type DonorOwnerReportFragment = {
  __typename?: 'DonorOwnerReportOutput';
  id: string;
  shortId: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locationPreferences?: string | null;
  birthday?: any | null;
  type?: OwnerType | null;
  isArchived: boolean;
};

export type DonationDonorReportFragment = {
  __typename?: 'DonationDonorReportOutput';
  id: string;
  name: string;
  location: string;
  region?: string | null;
  age: number;
  neutered: boolean;
  weight: number;
  primaryVeterinarian: string;
  species: AnimalSpecies;
  gender: AnimalGender;
  type: DonorType;
  bloodType?: BloodType | null;
  status: DonorStatus;
  breed?: string | null;
  color?: string | null;
  shortId: number;
  becameReservedDonorDate?: any | null;
  rabiesDate?: any | null;
  distemperDate?: any | null;
  retiredDate?: any | null;
  nextDonationDate?: any | null;
};

export type DonationOwnerReportFragment = {
  __typename?: 'DonationOwnerReportOutput';
  id: string;
  shortId: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locationPreferences?: string | null;
  birthday?: any | null;
  type?: OwnerType | null;
  isArchived: boolean;
};

export type GetDonorsReportQueryVariables = Exact<{
  input: GetDonorsReportInput;
}>;

export type GetDonorsReportQuery = {
  __typename?: 'Query';
  getDonorsReport: Array<{
    __typename?: 'DonorReportOutput';
    totalCollected?: number | null;
    id: string;
    name: string;
    location: string;
    region?: string | null;
    age: number;
    neutered: boolean;
    weight: number;
    primaryVeterinarian: string;
    species: AnimalSpecies;
    gender: AnimalGender;
    type: DonorType;
    bloodType?: BloodType | null;
    status: DonorStatus;
    breed?: string | null;
    color?: string | null;
    shortId: number;
    becameReservedDonorDate?: any | null;
    rabiesDate?: any | null;
    distemperDate?: any | null;
    retiredDate?: any | null;
    nextDonationDate?: any | null;
    microchipNumber?: string | null;
    donationFrequency: number;
    approvedDonationML: number;
    donationMLApprovedAmount?: number | null;
    acceptSimparicaTrio: boolean;
    labWorkDue?: any | null;
    annualDVMComplete?: boolean | null;
    isDaycare: boolean;
    canRegularlyAttendDaycare?: boolean | null;
    donorStatusUpdatedAt: any;
    user?: {
      __typename?: 'DonorOwnerReportOutput';
      id: string;
      shortId: number;
      email: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      type?: OwnerType | null;
      isArchived: boolean;
    } | null;
    notes: Array<{
      __typename?: 'DonorNoteOutput';
      id: string;
      text: string;
      isPinned: boolean;
      createdAt: any;
      updatedAt: any;
      files: Array<{
        __typename?: 'DonorNoteFileOutput';
        id: string;
        file: { __typename?: 'FileOutput'; id: string; filename: string };
      }>;
      user: {
        __typename?: 'UserOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        deletionIntentionAt?: any | null;
        contactPreferences?: string | null;
        deletedAt?: any | null;
        productRegionPreference?: {
          __typename?: 'RegionOutput';
          id: string;
          createdAt: any;
          updatedAt: any;
          name: string;
          accountingCode?: string | null;
        } | null;
      };
    }>;
  }>;
};

export type GetDonationReportQueryVariables = Exact<{
  input: GetDonationReportsInput;
}>;

export type GetDonationReportQuery = {
  __typename?: 'Query';
  getDonationReports: Array<{
    __typename?: 'DonationReportOutput';
    id: string;
    shortId: number;
    temperature: number;
    pulse: number;
    respiration: number;
    capillaryRefillTime: CapillaryRefillTime;
    mucousMembrane: Mm;
    packedCellVolume: number;
    totalSolids: number;
    weight: number;
    unit?: DonationUnit | null;
    actual?: number | null;
    approvedDonationsMl: number;
    donationDate: any;
    jugularVein: JugularVein;
    comment?: string | null;
    ineligibleReason?: IneligibleReason | null;
    donationNumber: number;
    donor?: {
      __typename?: 'DonationDonorReportOutput';
      id: string;
      name: string;
      location: string;
      region?: string | null;
      age: number;
      neutered: boolean;
      weight: number;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      gender: AnimalGender;
      type: DonorType;
      bloodType?: BloodType | null;
      status: DonorStatus;
      breed?: string | null;
      color?: string | null;
      shortId: number;
      becameReservedDonorDate?: any | null;
      rabiesDate?: any | null;
      distemperDate?: any | null;
      retiredDate?: any | null;
      nextDonationDate?: any | null;
      user?: {
        __typename?: 'DonationOwnerReportOutput';
        id: string;
        shortId: number;
        email: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        type?: OwnerType | null;
        isArchived: boolean;
      } | null;
    } | null;
    additionalInfo?: {
      __typename?: 'AdditionalDonationInfoReportOutput';
      id: string;
      numberOfCollectionUnitsUsed: CollectionUnitsUsed;
      totalCollectedMls?: number | null;
      primaryHolder?: {
        __typename?: 'HolderOutput';
        firstName: string;
        lastName: string;
      } | null;
      poker?: {
        __typename?: 'HolderOutput';
        firstName: string;
        lastName: string;
      } | null;
    } | null;
    products?: Array<{
      __typename?: 'ProductReportOutput';
      id: string;
      shortId: number;
      createdAt: any;
      woocommerceProductId: number;
      woocommerceCategoryId: number;
      status: ProductStatus;
      value: number;
      storageRequirements: Array<string>;
      anticoagulant?: Anticoagulant | null;
      expirationDate: any;
      regionPreference?: { __typename?: 'RegionOutput'; name: string } | null;
      discardedProduct?: {
        __typename?: 'DiscardedProductDataReportOutput';
        processingType: ProcessingProductType;
        reason: ReasonDiscardProduct;
        notes?: string | null;
        processor?: {
          __typename?: 'ProductProcessorOutput';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
      donation: {
        __typename?: 'ProductDonationReportOutput';
        id: string;
        donor?: {
          __typename?: 'ProductDonorReportOutput';
          id: string;
          name: string;
          location: string;
          user?: {
            __typename?: 'ProductDonorOwnerReportOutput';
            id: string;
            lastName: string;
          } | null;
        } | null;
        additionalInfo?: {
          __typename?: 'ProductAdditionlaDonationInfoReportOutput';
          id: string;
          poker?: {
            __typename?: 'ProductDonationHolderReportOutput';
            id: string;
            firstName: string;
            lastName: string;
          } | null;
          primaryHolder?: {
            __typename?: 'ProductDonationHolderReportOutput';
            id: string;
            firstName: string;
            lastName: string;
          } | null;
        } | null;
      };
    }> | null;
  }>;
};

export type GetProductReportsQueryVariables = Exact<{
  input: GetProductReportsInput;
}>;

export type GetProductReportsQuery = {
  __typename?: 'Query';
  getProductReports: Array<{
    __typename?: 'ProductReportOutput';
    id: string;
    shortId: number;
    createdAt: any;
    woocommerceProductId: number;
    woocommerceCategoryId: number;
    status: ProductStatus;
    value: number;
    storageRequirements: Array<string>;
    anticoagulant?: Anticoagulant | null;
    expirationDate: any;
    donation: {
      __typename?: 'ProductDonationReportOutput';
      id: string;
      donor?: {
        __typename?: 'ProductDonorReportOutput';
        id: string;
        name: string;
        location: string;
        user?: {
          __typename?: 'ProductDonorOwnerReportOutput';
          id: string;
          lastName: string;
        } | null;
      } | null;
      additionalInfo?: {
        __typename?: 'ProductAdditionlaDonationInfoReportOutput';
        id: string;
        poker?: {
          __typename?: 'ProductDonationHolderReportOutput';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
        primaryHolder?: {
          __typename?: 'ProductDonationHolderReportOutput';
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
    };
    regionPreference?: { __typename?: 'RegionOutput'; name: string } | null;
    discardedProduct?: {
      __typename?: 'DiscardedProductDataReportOutput';
      processingType: ProcessingProductType;
      reason: ReasonDiscardProduct;
      notes?: string | null;
      processor?: {
        __typename?: 'ProductProcessorOutput';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
  }>;
};

export type GetOwnerReportsQueryVariables = Exact<{
  input: GetOwnersReportsInput;
}>;

export type GetOwnerReportsQuery = {
  __typename?: 'Query';
  getOwnerReports: Array<{
    __typename?: 'OwnerReportOutput';
    id: string;
    shortId: number;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    regionPreferences?: string | null;
    birthday?: any | null;
    type?: OwnerType | null;
    isArchived: boolean;
    contactPreferences?: string | null;
    donors?: Array<{
      __typename?: 'OwnerDonorReportOutput';
      name: string;
      status: DonorStatus;
    }> | null;
  }>;
};

export type ProcessRequestMutationVariables = Exact<{
  input: UpdateRequestInput;
}>;

export type ProcessRequestMutation = {
  __typename?: 'Mutation';
  processRequest: boolean;
};

export type RemoveRequestMutationVariables = Exact<{
  input: RemoveRequestInput;
}>;

export type RemoveRequestMutation = {
  __typename?: 'Mutation';
  removeRequest: boolean;
};

export type GetRequestListQueryVariables = Exact<{
  input: RequestsListInput;
}>;

export type GetRequestListQuery = {
  __typename?: 'Query';
  getRequestList: {
    __typename?: 'RequestListOutput';
    data: Array<{
      __typename?: 'RequestOutput';
      id: string;
      number: number;
      updatedAt: any;
      donor: {
        __typename?: 'DonorOutput';
        id: string;
        age: number;
        gender: AnimalGender;
        hasTransfusion?: boolean | null;
        name: string;
        neutered: boolean;
        primaryVeterinarian: string;
        species: AnimalSpecies;
        takeMedications?: boolean | null;
        weight: number;
        approvedDonationML: number;
        donationMLApprovedAmount?: number | null;
        bloodType?: BloodType | null;
        status: DonorStatus;
        type: DonorType;
        location: string;
        distinguishableMarks?: string | null;
        microchipNumber?: string | null;
        medicationCourse?: string | null;
        comment?: string | null;
        breed?: string | null;
        color?: string | null;
        createdAt: any;
        shortId: number;
        firstCertificatePrinted: boolean;
        secondCertificatePrinted: boolean;
        becameReservedDonorDate?: any | null;
        retiredDate?: any | null;
        distemperDate?: any | null;
        rabiesDate?: any | null;
        lastDonationDate?: any | null;
        nextDonationDate?: any | null;
        lastLabworkDate?: any | null;
        hasMicrochip?: boolean | null;
        acceptSimparicaTrio: boolean;
        isDaycare?: boolean | null;
        donationFrequency: number;
        canRegularlyAttendDaycare?: boolean | null;
        attendDaycareDays?: Array<string> | null;
        retiredReason?: RetiredReasons | null;
        retiredReasonComment?: string | null;
        dateOfBirth: any;
        team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
        user?: {
          __typename?: 'OwnerOutput';
          email: string;
          id: string;
          firstName: string;
          lastName: string;
          phoneNumber?: string | null;
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          locationPreferences?: string | null;
          birthday?: any | null;
          shortId: number;
          type?: OwnerType | null;
          isArchived: boolean;
          contactPreferences?: string | null;
          deletedAt?: any | null;
          contacts?: Array<{
            __typename?: 'ContactOutput';
            streetAddress?: string | null;
            city?: string | null;
            state?: string | null;
            zipCode?: string | null;
            email?: string | null;
            firstName?: string | null;
            id: string;
            lastName?: string | null;
            phoneNumber?: string | null;
          }> | null;
        } | null;
      };
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetRequestQueryVariables = Exact<{
  input: GetRequestInput;
}>;

export type GetRequestQuery = {
  __typename?: 'Query';
  getRequest: {
    __typename?: 'RequestOutput';
    id: string;
    number: number;
    updatedAt: any;
    donor: {
      __typename?: 'DonorOutput';
      id: string;
      age: number;
      gender: AnimalGender;
      hasTransfusion?: boolean | null;
      name: string;
      neutered: boolean;
      primaryVeterinarian: string;
      species: AnimalSpecies;
      takeMedications?: boolean | null;
      weight: number;
      approvedDonationML: number;
      donationMLApprovedAmount?: number | null;
      bloodType?: BloodType | null;
      status: DonorStatus;
      type: DonorType;
      location: string;
      distinguishableMarks?: string | null;
      microchipNumber?: string | null;
      medicationCourse?: string | null;
      comment?: string | null;
      breed?: string | null;
      color?: string | null;
      createdAt: any;
      shortId: number;
      firstCertificatePrinted: boolean;
      secondCertificatePrinted: boolean;
      becameReservedDonorDate?: any | null;
      retiredDate?: any | null;
      distemperDate?: any | null;
      rabiesDate?: any | null;
      lastDonationDate?: any | null;
      nextDonationDate?: any | null;
      lastLabworkDate?: any | null;
      hasMicrochip?: boolean | null;
      acceptSimparicaTrio: boolean;
      isDaycare?: boolean | null;
      donationFrequency: number;
      canRegularlyAttendDaycare?: boolean | null;
      attendDaycareDays?: Array<string> | null;
      retiredReason?: RetiredReasons | null;
      retiredReasonComment?: string | null;
      dateOfBirth: any;
      team?: { __typename?: 'TeamOutput'; id: string; title: string } | null;
      user?: {
        __typename?: 'OwnerOutput';
        email: string;
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        locationPreferences?: string | null;
        birthday?: any | null;
        shortId: number;
        type?: OwnerType | null;
        isArchived: boolean;
        contactPreferences?: string | null;
        deletedAt?: any | null;
        contacts?: Array<{
          __typename?: 'ContactOutput';
          streetAddress?: string | null;
          city?: string | null;
          state?: string | null;
          zipCode?: string | null;
          email?: string | null;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
          phoneNumber?: string | null;
        }> | null;
      } | null;
    };
  };
};

export type PermissionFragment = {
  __typename?: 'PermissionsOutput';
  id: string;
  value: boolean;
  permission: PermissionsEnum;
};

export type RoleFragment = {
  __typename?: 'RoleOutput';
  id: string;
  value: UserRole;
};

export type RoleWithoutPermissionsFragment = {
  __typename?: 'RoleWithoutPermissionsOutput';
  id: string;
  value: UserRole;
};

export type UserFragment = {
  __typename?: 'UserOutput';
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locationPreferences?: string | null;
  birthday?: any | null;
  shortId: number;
  type?: OwnerType | null;
  isArchived: boolean;
  deletionIntentionAt?: any | null;
  contactPreferences?: string | null;
  deletedAt?: any | null;
  productRegionPreference?: {
    __typename?: 'RegionOutput';
    id: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    accountingCode?: string | null;
  } | null;
};

export type NotificationUserFragment = {
  __typename?: 'NotificationUserOutput';
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  locationPreferences?: string | null;
  birthday?: any | null;
  shortId: number;
  type?: OwnerType | null;
  isArchived: boolean;
  contactPreferences?: string | null;
};

export type ContactFragment = {
  __typename?: 'ContactOutput';
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phoneNumber?: string | null;
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: boolean;
};

export type ConfirmEmailMutationVariables = Exact<{
  input: EmailConfirmationInput;
}>;

export type ConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail: boolean;
};

export type MakeNotificationsReadMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MakeNotificationsReadMutation = {
  __typename?: 'Mutation';
  makeNotificationsRead: boolean;
};

export type DeleteNotificationMutationVariables = Exact<{
  input: RemoveNotificationInput;
}>;

export type DeleteNotificationMutation = {
  __typename?: 'Mutation';
  deleteNotification: boolean;
};

export type ArchiveUserMutationVariables = Exact<{
  input: ArchiveUserInput;
}>;

export type ArchiveUserMutation = {
  __typename?: 'Mutation';
  archiveUser: boolean;
};

export type UnArchiveUserMutationVariables = Exact<{
  input: ArchiveUserInput;
}>;

export type UnArchiveUserMutation = {
  __typename?: 'Mutation';
  unArchiveUser: boolean;
};

export type SignUpWithDonorMutationVariables = Exact<{
  input: SignUpWithDonorInput;
}>;

export type SignUpWithDonorMutation = {
  __typename?: 'Mutation';
  signUpWithDonor: boolean;
};

export type GetAllIneligibleQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllIneligibleQuery = {
  __typename?: 'Query';
  getAllIneligibleParticipants: {
    __typename?: 'AllIneligibleParticipantsOutput';
    ineligible: Array<{
      __typename?: 'IneligibleParticipantOutput';
      id: string;
      createdAt: any;
      ownerData: string;
      donorData: string;
    }>;
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'UserOutput';
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    locationPreferences?: string | null;
    birthday?: any | null;
    shortId: number;
    type?: OwnerType | null;
    isArchived: boolean;
    deletionIntentionAt?: any | null;
    contactPreferences?: string | null;
    deletedAt?: any | null;
    contacts?: Array<{
      __typename?: 'ContactOutput';
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      phoneNumber?: string | null;
    }> | null;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
    productRegionPreference?: {
      __typename?: 'RegionOutput';
      id: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      accountingCode?: string | null;
    } | null;
  };
};

export type GetMyPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyPermissionsQuery = {
  __typename?: 'Query';
  getMyPermissions: Array<{
    __typename?: 'PermissionsOutput';
    id: string;
    value: boolean;
    permission: PermissionsEnum;
    role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
  }>;
};

export type GetUsersListQueryVariables = Exact<{
  input: GetUserListInput;
}>;

export type GetUsersListQuery = {
  __typename?: 'Query';
  getUsersList: {
    __typename?: 'PaginatedUserListOutput';
    data: Array<{
      __typename?: 'UserOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      deletionIntentionAt?: any | null;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
      role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
      productRegionPreference?: {
        __typename?: 'RegionOutput';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        accountingCode?: string | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type GetOwnersListQueryVariables = Exact<{
  input: GetOwnerListInput;
}>;

export type GetOwnersListQuery = {
  __typename?: 'Query';
  getOwnersList: {
    __typename?: 'PaginatedUserListOutput';
    data: Array<{
      __typename?: 'UserOutput';
      email: string;
      id: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      locationPreferences?: string | null;
      birthday?: any | null;
      shortId: number;
      type?: OwnerType | null;
      isArchived: boolean;
      deletionIntentionAt?: any | null;
      contactPreferences?: string | null;
      deletedAt?: any | null;
      contacts?: Array<{
        __typename?: 'ContactOutput';
        streetAddress?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phoneNumber?: string | null;
      }> | null;
      role: { __typename?: 'RoleOutput'; id: string; value: UserRole };
      productRegionPreference?: {
        __typename?: 'RegionOutput';
        id: string;
        createdAt: any;
        updatedAt: any;
        name: string;
        accountingCode?: string | null;
      } | null;
    }>;
    meta: { __typename?: 'MetaData'; total: number };
  };
};

export type CheckUserConnectionQueryVariables = Exact<{ [key: string]: never }>;

export type CheckUserConnectionQuery = {
  __typename?: 'Query';
  checkUserConnection: boolean;
};

export const AppointmentFragmentDoc = gql`
  fragment Appointment on AppointmentOutput {
    id
    createdAt
    updatedAt
    title
    start
    end
    location
    notes
    isDaycare
    isPrivate
  }
`;
export const AppointmentUserFragmentDoc = gql`
  fragment AppointmentUser on AppointmentUser {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    shortId
  }
`;
export const AppointmentGroupFragmentDoc = gql`
  fragment AppointmentGroup on AppointmentGroupOutput {
    id
    title
  }
`;
export const AppointmentTeamFragmentDoc = gql`
  fragment AppointmentTeam on AppointmentTeamOutput {
    id
    title
  }
`;
export const AppointmentDonorFragmentDoc = gql`
  fragment AppointmentDonor on AppointmentDonor {
    id
    age
    gender
    hasTransfusion
    name
    neutered
    primaryVeterinarian
    species
    takeMedications
    weight
    bloodType
    status
    type
    location
    distinguishableMarks
    microchipNumber
    medicationCourse
    comment
    breed
    color
    createdAt
    shortId
    firstCertificatePrinted
    secondCertificatePrinted
    shortId
  }
`;
export const DonationProductFragmentDoc = gql`
  fragment DonationProduct on DonationProductOutput {
    anticoagulant
    createdAt
    id
    shortId
    status
    storageRequirements
    updatedAt
    value
    woocommerceProductId
    woocommerceCategoryId
    expirationDate
  }
`;
export const DiscardedProductDataFragmentDoc = gql`
  fragment DiscardedProductData on DiscardedProductDataOutput {
    processingType
    reason
    notes
  }
`;
export const DonationDonorFragmentDoc = gql`
  fragment DonationDonor on DonationDonor {
    id
    age
    gender
    hasTransfusion
    name
    neutered
    primaryVeterinarian
    species
    takeMedications
    weight
    bloodType
    status
    type
    location
    distinguishableMarks
    microchipNumber
    medicationCourse
    comment
    breed
    color
    createdAt
    shortId
    firstCertificatePrinted
    secondCertificatePrinted
    shortId
    lastDonationDate
    lastLabworkDate
    nextDonationDate
  }
`;
export const HolderFragmentDoc = gql`
  fragment Holder on HolderOutput {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    shortId
  }
`;
export const PictureFragmentDoc = gql`
  fragment Picture on DonorPictureOutput {
    id
    isPrimary
    file {
      id
      filename
    }
  }
`;
export const AdditionalDonationInfoFragmentDoc = gql`
  fragment AdditionalDonationInfo on AdditionalDonationInfoOutput {
    id
    numberOfCollectionUnitsUsed
    veinUsed
    vascularStructure
    flowRate
    donationPosition
    holdersPosition
    donorMovement
    typeOfMovement
    emotionalSupport
    dislikes
    likes
    treatPreferences
    totalCollectedMls
    primaryHolder {
      ...Holder
    }
    secondaryHolder {
      ...Holder
    }
    poker {
      ...Holder
    }
  }
  ${HolderFragmentDoc}
`;
export const DonationFragmentDoc = gql`
  fragment Donation on DonationOutput {
    id
    collectionSet
    capillaryRefillTime
    approvedDonationsMl
    jugularVein
    maxLimit
    mucousMembrane
    packedCellVolume
    pulse
    respiration
    shouldDonate
    temperature
    totalSolids
    unit
    actual
    weatherTemperature
    weatherTypes
    weight
    examinationNotes
    preferences
    createdAt
    comment
    shortId
    donationDate
    ineligible
    ineligibleReason
    ineligibleReasonDetails
    products {
      ...DonationProduct
      discardedProduct {
        ...DiscardedProductData
      }
    }
    donor {
      ...DonationDonor
      team {
        id
        title
      }
      user {
        ...Holder
      }
      pictures {
        ...Picture
      }
    }
    additionalInfo {
      ...AdditionalDonationInfo
    }
  }
  ${DonationProductFragmentDoc}
  ${DiscardedProductDataFragmentDoc}
  ${DonationDonorFragmentDoc}
  ${HolderFragmentDoc}
  ${PictureFragmentDoc}
  ${AdditionalDonationInfoFragmentDoc}
`;
export const DonorFragmentDoc = gql`
  fragment Donor on DonorOutput {
    id
    age
    gender
    hasTransfusion
    name
    neutered
    primaryVeterinarian
    species
    takeMedications
    weight
    approvedDonationML
    donationMLApprovedAmount
    bloodType
    status
    type
    location
    distinguishableMarks
    microchipNumber
    medicationCourse
    comment
    breed
    color
    createdAt
    shortId
    firstCertificatePrinted
    secondCertificatePrinted
    becameReservedDonorDate
    retiredDate
    distemperDate
    rabiesDate
    lastDonationDate
    nextDonationDate
    lastLabworkDate
    hasMicrochip
    acceptSimparicaTrio
    isDaycare
    donationFrequency
    canRegularlyAttendDaycare
    attendDaycareDays
    retiredReason
    retiredReasonComment
    dateOfBirth
  }
`;
export const PartialDonorFragmentDoc = gql`
  fragment PartialDonor on PartialDonorOutput {
    id
    age
    gender
    hasTransfusion
    name
    neutered
    primaryVeterinarian
    species
    takeMedications
    weight
    approvedDonationML
    donationMLApprovedAmount
    bloodType
    status
    type
    location
    distinguishableMarks
    microchipNumber
    medicationCourse
    comment
    breed
    color
    createdAt
    shortId
    firstCertificatePrinted
    secondCertificatePrinted
    becameReservedDonorDate
    retiredDate
    distemperDate
    rabiesDate
    lastDonationDate
    nextDonationDate
    lastLabworkDate
    hasMicrochip
    acceptSimparicaTrio
    isDaycare
    donationFrequency
    canRegularlyAttendDaycare
    attendDaycareDays
    retiredReason
    retiredReasonComment
    dateOfBirth
  }
`;
export const TeamFragmentDoc = gql`
  fragment Team on TeamOutput {
    id
    title
  }
`;
export const GroupTeamFragmentDoc = gql`
  fragment GroupTeam on GroupTeamOutput {
    id
    title
  }
`;
export const GroupFragmentDoc = gql`
  fragment Group on GroupOutput {
    id
    title
  }
`;
export const GroupListItemFragmentDoc = gql`
  fragment GroupListItem on GroupListItemOutput {
    teamCount
    donorCount
    title
    user {
      firstName
      lastName
      id
    }
    id
  }
`;
export const ReserveDonorNoteFragmentDoc = gql`
  fragment ReserveDonorNote on ReserveDonorNoteOutput {
    id
    text
    createdAt
  }
`;
export const RegionFragmentDoc = gql`
  fragment Region on RegionOutput {
    id
    createdAt
    updatedAt
    name
    accountingCode
  }
`;
export const UserFragmentDoc = gql`
  fragment User on UserOutput {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    productRegionPreference {
      ...Region
    }
    birthday
    shortId
    type
    isArchived
    deletionIntentionAt
    contactPreferences
    deletedAt
  }
  ${RegionFragmentDoc}
`;
export const DonorNoteFragmentDoc = gql`
  fragment DonorNote on DonorNoteOutput {
    id
    text
    isPinned
    files {
      id
      file {
        id
        filename
      }
    }
    createdAt
    updatedAt
    user {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export const LabworkFragmentDoc = gql`
  fragment Labwork on LabworkOutput {
    id
    collectionDate
    status
    DVMExam
  }
`;
export const FileFragmentDoc = gql`
  fragment File on FileOutput {
    id
    filename
  }
`;
export const VitalsParameterFragmentDoc = gql`
  fragment VitalsParameter on VitalsParameterOutput {
    id
    criticalValues
    type
    max
    min
    percentageDifference
  }
`;
export const LabworkAttachmentFragmentDoc = gql`
  fragment LabworkAttachment on LabworkAttachmentOutput {
    id
    createdAt
    updatedAt
  }
`;
export const LocationFragmentDoc = gql`
  fragment Location on LocationOutput {
    id
    createdAt
    updatedAt
    name
    type
    region {
      ...Region
    }
  }
  ${RegionFragmentDoc}
`;
export const NotificationConfigFragmentDoc = gql`
  fragment NotificationConfig on NotificationConfigOutput {
    name
    value
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on NotificationOutput {
    id
    type
    isRead
    createdAt
  }
`;
export const NotificationSubscriptionFragmentDoc = gql`
  fragment NotificationSubscription on NotificationWebSocketOutput {
    id
    type
    isRead
  }
`;
export const OrderBillingFragmentDoc = gql`
  fragment orderBilling on BillingOutput {
    city
    company
    country
    email
    firstAddress
    firstName
    lastName
    phone
    postcode
    secondAddress
    state
  }
`;
export const OrderShippingFragmentDoc = gql`
  fragment orderShipping on ShippingOutput {
    city
    company
    country
    firstAddress
    firstName
    lastName
    phone
    postcode
    secondAddress
    state
  }
`;
export const ShippingLineFragmentDoc = gql`
  fragment shippingLine on ShippingLineOutput {
    id
    instanceId
    methodId
    methodTitle
    total
    totalTax
  }
`;
export const FeeLineFragmentDoc = gql`
  fragment feeLine on FeeLineOutput {
    amount
    id
    name
    taxClass
    taxStatus
    total
    totalTax
  }
`;
export const OrderItemFragmentDoc = gql`
  fragment orderItem on OrderItemOutput {
    id
    name
    price
    quantity
    sku
  }
`;
export const OwnerFragmentDoc = gql`
  fragment Owner on OwnerOutput {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    shortId
    type
    isArchived
    contactPreferences
    deletedAt
  }
`;
export const UserAttachmentFragmentDoc = gql`
  fragment UserAttachment on UserAttachmentOutput {
    id
    createdAt
    updatedAt
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on ProductOutput {
    anticoagulant
    createdAt
    id
    shortId
    status
    storageRequirements
    updatedAt
    value
    woocommerceProductId
    woocommerceCategoryId
    woocommerceOrderId
    expirationDate
    published
    publishable
    regionPreference {
      ...Region
    }
  }
  ${RegionFragmentDoc}
`;
export const ProductProcessorFragmentDoc = gql`
  fragment ProductProcessor on ProductProcessorOutput {
    id
    firstName
    lastName
  }
`;
export const DonorReportFragmentDoc = gql`
  fragment DonorReport on DonorReportOutput {
    id
    name
    location
    region
    age
    neutered
    weight
    primaryVeterinarian
    species
    gender
    type
    bloodType
    status
    breed
    color
    shortId
    becameReservedDonorDate
    rabiesDate
    distemperDate
    retiredDate
    nextDonationDate
    microchipNumber
    donationFrequency
    approvedDonationML
    donationMLApprovedAmount
    acceptSimparicaTrio
    labWorkDue
    annualDVMComplete
    isDaycare
    canRegularlyAttendDaycare
    donorStatusUpdatedAt
  }
`;
export const DonationReportFragmentDoc = gql`
  fragment DonationReport on DonationReportOutput {
    id
    shortId
    temperature
    pulse
    respiration
    capillaryRefillTime
    mucousMembrane
    packedCellVolume
    totalSolids
    weight
    unit
    actual
    approvedDonationsMl
    donationDate
    jugularVein
    comment
    ineligibleReason
    donationNumber
  }
`;
export const HolderReportDataFragmentDoc = gql`
  fragment HolderReportData on HolderOutput {
    firstName
    lastName
  }
`;
export const AdditionalDonationInfoReportFragmentDoc = gql`
  fragment AdditionalDonationInfoReport on AdditionalDonationInfoReportOutput {
    id
    numberOfCollectionUnitsUsed
    totalCollectedMls
  }
`;
export const ProductReportFragmentDoc = gql`
  fragment ProductReport on ProductReportOutput {
    id
    shortId
    createdAt
    woocommerceProductId
    woocommerceCategoryId
    status
    value
    storageRequirements
    anticoagulant
    expirationDate
    regionPreference {
      name
    }
    discardedProduct {
      processingType
      reason
      notes
      processor {
        id
        firstName
        lastName
      }
    }
    donation {
      id
      donor {
        id
        name
        location
        user {
          id
          lastName
        }
      }
      additionalInfo {
        id
        poker {
          id
          firstName
          lastName
        }
        primaryHolder {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export const OwnerReportFragmentDoc = gql`
  fragment OwnerReport on OwnerReportOutput {
    id
    shortId
    email
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    regionPreferences
    birthday
    type
    isArchived
    contactPreferences
  }
`;
export const DonorOwnerReportFragmentDoc = gql`
  fragment DonorOwnerReport on DonorOwnerReportOutput {
    id
    shortId
    email
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    type
    isArchived
  }
`;
export const DonationDonorReportFragmentDoc = gql`
  fragment DonationDonorReport on DonationDonorReportOutput {
    id
    name
    location
    region
    age
    neutered
    weight
    primaryVeterinarian
    species
    gender
    type
    bloodType
    status
    breed
    color
    shortId
    becameReservedDonorDate
    rabiesDate
    distemperDate
    retiredDate
    nextDonationDate
  }
`;
export const DonationOwnerReportFragmentDoc = gql`
  fragment DonationOwnerReport on DonationOwnerReportOutput {
    id
    shortId
    email
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    type
    isArchived
  }
`;
export const PermissionFragmentDoc = gql`
  fragment Permission on PermissionsOutput {
    id
    value
    permission
  }
`;
export const RoleFragmentDoc = gql`
  fragment Role on RoleOutput {
    id
    value
  }
`;
export const RoleWithoutPermissionsFragmentDoc = gql`
  fragment RoleWithoutPermissions on RoleWithoutPermissionsOutput {
    id
    value
  }
`;
export const NotificationUserFragmentDoc = gql`
  fragment NotificationUser on NotificationUserOutput {
    email
    id
    firstName
    lastName
    phoneNumber
    streetAddress
    city
    state
    zipCode
    locationPreferences
    birthday
    shortId
    type
    isArchived
    contactPreferences
  }
`;
export const ContactFragmentDoc = gql`
  fragment Contact on ContactOutput {
    streetAddress
    city
    state
    zipCode
    email
    firstName
    id
    lastName
    phoneNumber
  }
`;
export const UpdateAllPermissionsDocument = gql`
  mutation updateAllPermissions($input: PermissionsInput!) {
    updatePermissions(input: $input) {
      ...Permission
    }
  }
  ${PermissionFragmentDoc}
`;
export type UpdateAllPermissionsMutationFn = Apollo.MutationFunction<
  UpdateAllPermissionsMutation,
  UpdateAllPermissionsMutationVariables
>;

/**
 * __useUpdateAllPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateAllPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllPermissionsMutation, { data, loading, error }] = useUpdateAllPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAllPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAllPermissionsMutation,
    UpdateAllPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAllPermissionsMutation,
    UpdateAllPermissionsMutationVariables
  >(UpdateAllPermissionsDocument, options);
}
export type UpdateAllPermissionsMutationHookResult = ReturnType<
  typeof useUpdateAllPermissionsMutation
>;
export type UpdateAllPermissionsMutationResult =
  Apollo.MutationResult<UpdateAllPermissionsMutation>;
export type UpdateAllPermissionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAllPermissionsMutation,
  UpdateAllPermissionsMutationVariables
>;
export const InviteUserDocument = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input)
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserMutation,
    InviteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<
  typeof useInviteUserMutation
>;
export type InviteUserMutationResult =
  Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const AcceptInviteDocument = gql`
  mutation acceptInvite($input: AcceptInviteInput!) {
    acceptInvite(input: $input) {
      email
    }
  }
`;
export type AcceptInviteMutationFn = Apollo.MutationFunction<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >(AcceptInviteDocument, options);
}
export type AcceptInviteMutationHookResult = ReturnType<
  typeof useAcceptInviteMutation
>;
export type AcceptInviteMutationResult =
  Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;
export const RemoveUserDocument = gql`
  mutation removeUser($input: RemoveUserInput!) {
    removeUser(input: $input)
  }
`;
export type RemoveUserMutationFn = Apollo.MutationFunction<
  RemoveUserMutation,
  RemoveUserMutationVariables
>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserMutation,
    RemoveUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(
    RemoveUserDocument,
    options
  );
}
export type RemoveUserMutationHookResult = ReturnType<
  typeof useRemoveUserMutation
>;
export type RemoveUserMutationResult =
  Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserMutation,
  RemoveUserMutationVariables
>;
export const RemoveOwnerDocument = gql`
  mutation removeOwner($input: RemoveUserInput!) {
    removeOwner(input: $input)
  }
`;
export type RemoveOwnerMutationFn = Apollo.MutationFunction<
  RemoveOwnerMutation,
  RemoveOwnerMutationVariables
>;

/**
 * __useRemoveOwnerMutation__
 *
 * To run a mutation, you first call `useRemoveOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOwnerMutation, { data, loading, error }] = useRemoveOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOwnerMutation,
    RemoveOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveOwnerMutation, RemoveOwnerMutationVariables>(
    RemoveOwnerDocument,
    options
  );
}
export type RemoveOwnerMutationHookResult = ReturnType<
  typeof useRemoveOwnerMutation
>;
export type RemoveOwnerMutationResult =
  Apollo.MutationResult<RemoveOwnerMutation>;
export type RemoveOwnerMutationOptions = Apollo.BaseMutationOptions<
  RemoveOwnerMutation,
  RemoveOwnerMutationVariables
>;
export const UpdateUserInfoDocument = gql`
  mutation updateUserInfo($input: UpdateUserInputPartial!) {
    updateUserInfo(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables
>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserInfoMutation,
    UpdateUserInfoMutationVariables
  >(UpdateUserInfoDocument, options);
}
export type UpdateUserInfoMutationHookResult = ReturnType<
  typeof useUpdateUserInfoMutation
>;
export type UpdateUserInfoMutationResult =
  Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables
>;
export const UpdateUserEmailDocument = gql`
  mutation updateUserEmail($input: UpdateUserEmailInput!) {
    updateUserEmail(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >(UpdateUserEmailDocument, options);
}
export type UpdateUserEmailMutationHookResult = ReturnType<
  typeof useUpdateUserEmailMutation
>;
export type UpdateUserEmailMutationResult =
  Apollo.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;
export const UpdateUserRoleDocument = gql`
  mutation updateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables
  >(UpdateUserRoleDocument, options);
}
export type UpdateUserRoleMutationHookResult = ReturnType<
  typeof useUpdateUserRoleMutation
>;
export type UpdateUserRoleMutationResult =
  Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>;
export const GetPermissionsDocument = gql`
  query getPermissions($input: GetPermissionsInput!) {
    getPermissions(input: $input) {
      ...Permission
      role {
        ...Role
      }
    }
  }
  ${PermissionFragmentDoc}
  ${RoleFragmentDoc}
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const GetUserDocument = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const CreateAppointmentsDocument = gql`
  mutation createAppointments($input: CreateEmptyAppointmentInput!) {
    createAppointments(input: $input) {
      ...Appointment
      users {
        ...AppointmentUser
        contacts {
          ...Contact
        }
      }
      donor {
        ...AppointmentDonor
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      group {
        ...AppointmentGroup
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      team {
        ...AppointmentTeam
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
`;
export type CreateAppointmentsMutationFn = Apollo.MutationFunction<
  CreateAppointmentsMutation,
  CreateAppointmentsMutationVariables
>;

/**
 * __useCreateAppointmentsMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentsMutation, { data, loading, error }] = useCreateAppointmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentsMutation,
    CreateAppointmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentsMutation,
    CreateAppointmentsMutationVariables
  >(CreateAppointmentsDocument, options);
}
export type CreateAppointmentsMutationHookResult = ReturnType<
  typeof useCreateAppointmentsMutation
>;
export type CreateAppointmentsMutationResult =
  Apollo.MutationResult<CreateAppointmentsMutation>;
export type CreateAppointmentsMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentsMutation,
  CreateAppointmentsMutationVariables
>;
export const CreateAppointmentsWithDonorDocument = gql`
  mutation createAppointmentsWithDonor(
    $input: CreateAppointmentWithDonorInput!
  ) {
    createAppointmentsWithDonor(input: $input) {
      ...Appointment
      users {
        ...AppointmentUser
        contacts {
          ...Contact
        }
      }
      donor {
        ...AppointmentDonor
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      group {
        ...AppointmentGroup
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      team {
        ...AppointmentTeam
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
`;
export type CreateAppointmentsWithDonorMutationFn = Apollo.MutationFunction<
  CreateAppointmentsWithDonorMutation,
  CreateAppointmentsWithDonorMutationVariables
>;

/**
 * __useCreateAppointmentsWithDonorMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentsWithDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentsWithDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentsWithDonorMutation, { data, loading, error }] = useCreateAppointmentsWithDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentsWithDonorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentsWithDonorMutation,
    CreateAppointmentsWithDonorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentsWithDonorMutation,
    CreateAppointmentsWithDonorMutationVariables
  >(CreateAppointmentsWithDonorDocument, options);
}
export type CreateAppointmentsWithDonorMutationHookResult = ReturnType<
  typeof useCreateAppointmentsWithDonorMutation
>;
export type CreateAppointmentsWithDonorMutationResult =
  Apollo.MutationResult<CreateAppointmentsWithDonorMutation>;
export type CreateAppointmentsWithDonorMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAppointmentsWithDonorMutation,
    CreateAppointmentsWithDonorMutationVariables
  >;
export const CreateAppointmentsWithGroupDocument = gql`
  mutation createAppointmentsWithGroup(
    $input: CreateAppointmentWithGroupInput!
  ) {
    createAppointmentsWithGroup(input: $input) {
      ...Appointment
      users {
        ...AppointmentUser
        contacts {
          ...Contact
        }
      }
      donor {
        ...AppointmentDonor
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      group {
        ...AppointmentGroup
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      team {
        ...AppointmentTeam
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
`;
export type CreateAppointmentsWithGroupMutationFn = Apollo.MutationFunction<
  CreateAppointmentsWithGroupMutation,
  CreateAppointmentsWithGroupMutationVariables
>;

/**
 * __useCreateAppointmentsWithGroupMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentsWithGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentsWithGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentsWithGroupMutation, { data, loading, error }] = useCreateAppointmentsWithGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentsWithGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentsWithGroupMutation,
    CreateAppointmentsWithGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentsWithGroupMutation,
    CreateAppointmentsWithGroupMutationVariables
  >(CreateAppointmentsWithGroupDocument, options);
}
export type CreateAppointmentsWithGroupMutationHookResult = ReturnType<
  typeof useCreateAppointmentsWithGroupMutation
>;
export type CreateAppointmentsWithGroupMutationResult =
  Apollo.MutationResult<CreateAppointmentsWithGroupMutation>;
export type CreateAppointmentsWithGroupMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAppointmentsWithGroupMutation,
    CreateAppointmentsWithGroupMutationVariables
  >;
export const EnrollDonorToAppointmentDocument = gql`
  mutation enrollDonorToAppointment($input: EnrollDonorInput!) {
    enrollDonorToAppointment(input: $input)
  }
`;
export type EnrollDonorToAppointmentMutationFn = Apollo.MutationFunction<
  EnrollDonorToAppointmentMutation,
  EnrollDonorToAppointmentMutationVariables
>;

/**
 * __useEnrollDonorToAppointmentMutation__
 *
 * To run a mutation, you first call `useEnrollDonorToAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollDonorToAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollDonorToAppointmentMutation, { data, loading, error }] = useEnrollDonorToAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollDonorToAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnrollDonorToAppointmentMutation,
    EnrollDonorToAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnrollDonorToAppointmentMutation,
    EnrollDonorToAppointmentMutationVariables
  >(EnrollDonorToAppointmentDocument, options);
}
export type EnrollDonorToAppointmentMutationHookResult = ReturnType<
  typeof useEnrollDonorToAppointmentMutation
>;
export type EnrollDonorToAppointmentMutationResult =
  Apollo.MutationResult<EnrollDonorToAppointmentMutation>;
export type EnrollDonorToAppointmentMutationOptions =
  Apollo.BaseMutationOptions<
    EnrollDonorToAppointmentMutation,
    EnrollDonorToAppointmentMutationVariables
  >;
export const UnenrollMyDonorFromAppointmentDocument = gql`
  mutation unenrollMyDonorFromAppointment($input: UnenrollDonorInput!) {
    unenrollMyDonorFromAppointment(input: $input)
  }
`;
export type UnenrollMyDonorFromAppointmentMutationFn = Apollo.MutationFunction<
  UnenrollMyDonorFromAppointmentMutation,
  UnenrollMyDonorFromAppointmentMutationVariables
>;

/**
 * __useUnenrollMyDonorFromAppointmentMutation__
 *
 * To run a mutation, you first call `useUnenrollMyDonorFromAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnenrollMyDonorFromAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unenrollMyDonorFromAppointmentMutation, { data, loading, error }] = useUnenrollMyDonorFromAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnenrollMyDonorFromAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnenrollMyDonorFromAppointmentMutation,
    UnenrollMyDonorFromAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnenrollMyDonorFromAppointmentMutation,
    UnenrollMyDonorFromAppointmentMutationVariables
  >(UnenrollMyDonorFromAppointmentDocument, options);
}
export type UnenrollMyDonorFromAppointmentMutationHookResult = ReturnType<
  typeof useUnenrollMyDonorFromAppointmentMutation
>;
export type UnenrollMyDonorFromAppointmentMutationResult =
  Apollo.MutationResult<UnenrollMyDonorFromAppointmentMutation>;
export type UnenrollMyDonorFromAppointmentMutationOptions =
  Apollo.BaseMutationOptions<
    UnenrollMyDonorFromAppointmentMutation,
    UnenrollMyDonorFromAppointmentMutationVariables
  >;
export const EnrollMyDonorToAppointmentDocument = gql`
  mutation enrollMyDonorToAppointment($input: EnrollDonorInput!) {
    enrollMyDonorToAppointment(input: $input)
  }
`;
export type EnrollMyDonorToAppointmentMutationFn = Apollo.MutationFunction<
  EnrollMyDonorToAppointmentMutation,
  EnrollMyDonorToAppointmentMutationVariables
>;

/**
 * __useEnrollMyDonorToAppointmentMutation__
 *
 * To run a mutation, you first call `useEnrollMyDonorToAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollMyDonorToAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollMyDonorToAppointmentMutation, { data, loading, error }] = useEnrollMyDonorToAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollMyDonorToAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnrollMyDonorToAppointmentMutation,
    EnrollMyDonorToAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnrollMyDonorToAppointmentMutation,
    EnrollMyDonorToAppointmentMutationVariables
  >(EnrollMyDonorToAppointmentDocument, options);
}
export type EnrollMyDonorToAppointmentMutationHookResult = ReturnType<
  typeof useEnrollMyDonorToAppointmentMutation
>;
export type EnrollMyDonorToAppointmentMutationResult =
  Apollo.MutationResult<EnrollMyDonorToAppointmentMutation>;
export type EnrollMyDonorToAppointmentMutationOptions =
  Apollo.BaseMutationOptions<
    EnrollMyDonorToAppointmentMutation,
    EnrollMyDonorToAppointmentMutationVariables
  >;
export const RemoveAppointmentDocument = gql`
  mutation removeAppointment($input: RemoveAppointmentInput!) {
    removeAppointment(input: $input)
  }
`;
export type RemoveAppointmentMutationFn = Apollo.MutationFunction<
  RemoveAppointmentMutation,
  RemoveAppointmentMutationVariables
>;

/**
 * __useRemoveAppointmentMutation__
 *
 * To run a mutation, you first call `useRemoveAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAppointmentMutation, { data, loading, error }] = useRemoveAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAppointmentMutation,
    RemoveAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAppointmentMutation,
    RemoveAppointmentMutationVariables
  >(RemoveAppointmentDocument, options);
}
export type RemoveAppointmentMutationHookResult = ReturnType<
  typeof useRemoveAppointmentMutation
>;
export type RemoveAppointmentMutationResult =
  Apollo.MutationResult<RemoveAppointmentMutation>;
export type RemoveAppointmentMutationOptions = Apollo.BaseMutationOptions<
  RemoveAppointmentMutation,
  RemoveAppointmentMutationVariables
>;
export const UpdateAppointmentDocument = gql`
  mutation updateAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(updateAppointmentInput: $input) {
      ...Appointment
      users {
        ...AppointmentUser
        contacts {
          ...Contact
        }
      }
      donor {
        ...AppointmentDonor
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      group {
        ...AppointmentGroup
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      team {
        ...AppointmentTeam
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
`;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >(UpdateAppointmentDocument, options);
}
export type UpdateAppointmentMutationHookResult = ReturnType<
  typeof useUpdateAppointmentMutation
>;
export type UpdateAppointmentMutationResult =
  Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;
export const RegisterOnDaycareAppointmentDocument = gql`
  mutation registerOnDaycareAppointment(
    $input: RegisterOnDaycareAppointmentInput!
  ) {
    registerOnDaycareAppointment(input: $input)
  }
`;
export type RegisterOnDaycareAppointmentMutationFn = Apollo.MutationFunction<
  RegisterOnDaycareAppointmentMutation,
  RegisterOnDaycareAppointmentMutationVariables
>;

/**
 * __useRegisterOnDaycareAppointmentMutation__
 *
 * To run a mutation, you first call `useRegisterOnDaycareAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterOnDaycareAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerOnDaycareAppointmentMutation, { data, loading, error }] = useRegisterOnDaycareAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterOnDaycareAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterOnDaycareAppointmentMutation,
    RegisterOnDaycareAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterOnDaycareAppointmentMutation,
    RegisterOnDaycareAppointmentMutationVariables
  >(RegisterOnDaycareAppointmentDocument, options);
}
export type RegisterOnDaycareAppointmentMutationHookResult = ReturnType<
  typeof useRegisterOnDaycareAppointmentMutation
>;
export type RegisterOnDaycareAppointmentMutationResult =
  Apollo.MutationResult<RegisterOnDaycareAppointmentMutation>;
export type RegisterOnDaycareAppointmentMutationOptions =
  Apollo.BaseMutationOptions<
    RegisterOnDaycareAppointmentMutation,
    RegisterOnDaycareAppointmentMutationVariables
  >;
export const ApproveDaycareAppointmentRequestDocument = gql`
  mutation approveDaycareAppointmentRequest($id: String!) {
    approveDaycareAppointmentRequest(requestId: $id)
  }
`;
export type ApproveDaycareAppointmentRequestMutationFn =
  Apollo.MutationFunction<
    ApproveDaycareAppointmentRequestMutation,
    ApproveDaycareAppointmentRequestMutationVariables
  >;

/**
 * __useApproveDaycareAppointmentRequestMutation__
 *
 * To run a mutation, you first call `useApproveDaycareAppointmentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveDaycareAppointmentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveDaycareAppointmentRequestMutation, { data, loading, error }] = useApproveDaycareAppointmentRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveDaycareAppointmentRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveDaycareAppointmentRequestMutation,
    ApproveDaycareAppointmentRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveDaycareAppointmentRequestMutation,
    ApproveDaycareAppointmentRequestMutationVariables
  >(ApproveDaycareAppointmentRequestDocument, options);
}
export type ApproveDaycareAppointmentRequestMutationHookResult = ReturnType<
  typeof useApproveDaycareAppointmentRequestMutation
>;
export type ApproveDaycareAppointmentRequestMutationResult =
  Apollo.MutationResult<ApproveDaycareAppointmentRequestMutation>;
export type ApproveDaycareAppointmentRequestMutationOptions =
  Apollo.BaseMutationOptions<
    ApproveDaycareAppointmentRequestMutation,
    ApproveDaycareAppointmentRequestMutationVariables
  >;
export const DisproveDaycareAppointmentRequestDocument = gql`
  mutation disproveDaycareAppointmentRequest($id: String!) {
    disproveDaycareAppointmentRequest(requestId: $id)
  }
`;
export type DisproveDaycareAppointmentRequestMutationFn =
  Apollo.MutationFunction<
    DisproveDaycareAppointmentRequestMutation,
    DisproveDaycareAppointmentRequestMutationVariables
  >;

/**
 * __useDisproveDaycareAppointmentRequestMutation__
 *
 * To run a mutation, you first call `useDisproveDaycareAppointmentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisproveDaycareAppointmentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disproveDaycareAppointmentRequestMutation, { data, loading, error }] = useDisproveDaycareAppointmentRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisproveDaycareAppointmentRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisproveDaycareAppointmentRequestMutation,
    DisproveDaycareAppointmentRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisproveDaycareAppointmentRequestMutation,
    DisproveDaycareAppointmentRequestMutationVariables
  >(DisproveDaycareAppointmentRequestDocument, options);
}
export type DisproveDaycareAppointmentRequestMutationHookResult = ReturnType<
  typeof useDisproveDaycareAppointmentRequestMutation
>;
export type DisproveDaycareAppointmentRequestMutationResult =
  Apollo.MutationResult<DisproveDaycareAppointmentRequestMutation>;
export type DisproveDaycareAppointmentRequestMutationOptions =
  Apollo.BaseMutationOptions<
    DisproveDaycareAppointmentRequestMutation,
    DisproveDaycareAppointmentRequestMutationVariables
  >;
export const CancelDaycareAppointmentRequestDocument = gql`
  mutation cancelDaycareAppointmentRequest($id: String!) {
    cancelDaycareAppointmentRequest(requestId: $id)
  }
`;
export type CancelDaycareAppointmentRequestMutationFn = Apollo.MutationFunction<
  CancelDaycareAppointmentRequestMutation,
  CancelDaycareAppointmentRequestMutationVariables
>;

/**
 * __useCancelDaycareAppointmentRequestMutation__
 *
 * To run a mutation, you first call `useCancelDaycareAppointmentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDaycareAppointmentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDaycareAppointmentRequestMutation, { data, loading, error }] = useCancelDaycareAppointmentRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelDaycareAppointmentRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelDaycareAppointmentRequestMutation,
    CancelDaycareAppointmentRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelDaycareAppointmentRequestMutation,
    CancelDaycareAppointmentRequestMutationVariables
  >(CancelDaycareAppointmentRequestDocument, options);
}
export type CancelDaycareAppointmentRequestMutationHookResult = ReturnType<
  typeof useCancelDaycareAppointmentRequestMutation
>;
export type CancelDaycareAppointmentRequestMutationResult =
  Apollo.MutationResult<CancelDaycareAppointmentRequestMutation>;
export type CancelDaycareAppointmentRequestMutationOptions =
  Apollo.BaseMutationOptions<
    CancelDaycareAppointmentRequestMutation,
    CancelDaycareAppointmentRequestMutationVariables
  >;
export const CreateDaycareAppointmentsWithDonorsDocument = gql`
  mutation createDaycareAppointmentsWithDonors(
    $input: CreateDaycareAppointmentWithDonorsInput!
  ) {
    createDaycareAppointmentsWithDonors(input: $input)
  }
`;
export type CreateDaycareAppointmentsWithDonorsMutationFn =
  Apollo.MutationFunction<
    CreateDaycareAppointmentsWithDonorsMutation,
    CreateDaycareAppointmentsWithDonorsMutationVariables
  >;

/**
 * __useCreateDaycareAppointmentsWithDonorsMutation__
 *
 * To run a mutation, you first call `useCreateDaycareAppointmentsWithDonorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDaycareAppointmentsWithDonorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDaycareAppointmentsWithDonorsMutation, { data, loading, error }] = useCreateDaycareAppointmentsWithDonorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDaycareAppointmentsWithDonorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDaycareAppointmentsWithDonorsMutation,
    CreateDaycareAppointmentsWithDonorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDaycareAppointmentsWithDonorsMutation,
    CreateDaycareAppointmentsWithDonorsMutationVariables
  >(CreateDaycareAppointmentsWithDonorsDocument, options);
}
export type CreateDaycareAppointmentsWithDonorsMutationHookResult = ReturnType<
  typeof useCreateDaycareAppointmentsWithDonorsMutation
>;
export type CreateDaycareAppointmentsWithDonorsMutationResult =
  Apollo.MutationResult<CreateDaycareAppointmentsWithDonorsMutation>;
export type CreateDaycareAppointmentsWithDonorsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDaycareAppointmentsWithDonorsMutation,
    CreateDaycareAppointmentsWithDonorsMutationVariables
  >;
export const UnenrollDonorFromDaycareDocument = gql`
  mutation unenrollDonorFromDaycare($input: DaycareUnenrollDonorInput!) {
    unenrollDonorFromDaycare(input: $input)
  }
`;
export type UnenrollDonorFromDaycareMutationFn = Apollo.MutationFunction<
  UnenrollDonorFromDaycareMutation,
  UnenrollDonorFromDaycareMutationVariables
>;

/**
 * __useUnenrollDonorFromDaycareMutation__
 *
 * To run a mutation, you first call `useUnenrollDonorFromDaycareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnenrollDonorFromDaycareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unenrollDonorFromDaycareMutation, { data, loading, error }] = useUnenrollDonorFromDaycareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnenrollDonorFromDaycareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnenrollDonorFromDaycareMutation,
    UnenrollDonorFromDaycareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnenrollDonorFromDaycareMutation,
    UnenrollDonorFromDaycareMutationVariables
  >(UnenrollDonorFromDaycareDocument, options);
}
export type UnenrollDonorFromDaycareMutationHookResult = ReturnType<
  typeof useUnenrollDonorFromDaycareMutation
>;
export type UnenrollDonorFromDaycareMutationResult =
  Apollo.MutationResult<UnenrollDonorFromDaycareMutation>;
export type UnenrollDonorFromDaycareMutationOptions =
  Apollo.BaseMutationOptions<
    UnenrollDonorFromDaycareMutation,
    UnenrollDonorFromDaycareMutationVariables
  >;
export const AddDonorsToDaycareDocument = gql`
  mutation addDonorsToDaycare($input: AddDonorsToDaycareInput!) {
    addDonorsToDaycare(input: $input)
  }
`;
export type AddDonorsToDaycareMutationFn = Apollo.MutationFunction<
  AddDonorsToDaycareMutation,
  AddDonorsToDaycareMutationVariables
>;

/**
 * __useAddDonorsToDaycareMutation__
 *
 * To run a mutation, you first call `useAddDonorsToDaycareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDonorsToDaycareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDonorsToDaycareMutation, { data, loading, error }] = useAddDonorsToDaycareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDonorsToDaycareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDonorsToDaycareMutation,
    AddDonorsToDaycareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddDonorsToDaycareMutation,
    AddDonorsToDaycareMutationVariables
  >(AddDonorsToDaycareDocument, options);
}
export type AddDonorsToDaycareMutationHookResult = ReturnType<
  typeof useAddDonorsToDaycareMutation
>;
export type AddDonorsToDaycareMutationResult =
  Apollo.MutationResult<AddDonorsToDaycareMutation>;
export type AddDonorsToDaycareMutationOptions = Apollo.BaseMutationOptions<
  AddDonorsToDaycareMutation,
  AddDonorsToDaycareMutationVariables
>;
export const GetAppointmentsDocument = gql`
  query getAppointments($input: GetAppointmentsInput!) {
    getAppointments(input: $input) {
      ...Appointment
      users {
        ...AppointmentUser
        contacts {
          ...Contact
        }
      }
      donor {
        ...AppointmentDonor
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      group {
        ...AppointmentGroup
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      team {
        ...AppointmentTeam
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
`;

/**
 * __useGetAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentsQuery,
    GetAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(
    GetAppointmentsDocument,
    options
  );
}
export function useGetAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentsQuery,
    GetAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentsQuery,
    GetAppointmentsQueryVariables
  >(GetAppointmentsDocument, options);
}
export type GetAppointmentsQueryHookResult = ReturnType<
  typeof useGetAppointmentsQuery
>;
export type GetAppointmentsLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentsLazyQuery
>;
export type GetAppointmentsQueryResult = Apollo.QueryResult<
  GetAppointmentsQuery,
  GetAppointmentsQueryVariables
>;
export const GetAppointmentDocument = gql`
  query getAppointment($input: GetAppointmentInput!) {
    getAppointment(input: $input) {
      ...Appointment
      users {
        ...AppointmentUser
        contacts {
          ...Contact
        }
      }
      donor {
        ...AppointmentDonor
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      group {
        ...AppointmentGroup
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      team {
        ...AppointmentTeam
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
`;

/**
 * __useGetAppointmentQuery__
 *
 * To run a query within a React component, call `useGetAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentQuery,
    GetAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(
    GetAppointmentDocument,
    options
  );
}
export function useGetAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentQuery,
    GetAppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(
    GetAppointmentDocument,
    options
  );
}
export type GetAppointmentQueryHookResult = ReturnType<
  typeof useGetAppointmentQuery
>;
export type GetAppointmentLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentLazyQuery
>;
export type GetAppointmentQueryResult = Apollo.QueryResult<
  GetAppointmentQuery,
  GetAppointmentQueryVariables
>;
export const GetAppointmentsForCurrentUserDocument = gql`
  query getAppointmentsForCurrentUser(
    $input: GetAppointmentsForCurrentUserInput!
  ) {
    getAppointmentsForCurrentUser(input: $input) {
      ...Appointment
      users {
        ...AppointmentUser
        contacts {
          ...Contact
        }
      }
      donor {
        ...AppointmentDonor
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      group {
        ...AppointmentGroup
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      team {
        ...AppointmentTeam
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
`;

/**
 * __useGetAppointmentsForCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsForCurrentUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAppointmentsForCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentsForCurrentUserQuery,
    GetAppointmentsForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppointmentsForCurrentUserQuery,
    GetAppointmentsForCurrentUserQueryVariables
  >(GetAppointmentsForCurrentUserDocument, options);
}
export function useGetAppointmentsForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentsForCurrentUserQuery,
    GetAppointmentsForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentsForCurrentUserQuery,
    GetAppointmentsForCurrentUserQueryVariables
  >(GetAppointmentsForCurrentUserDocument, options);
}
export type GetAppointmentsForCurrentUserQueryHookResult = ReturnType<
  typeof useGetAppointmentsForCurrentUserQuery
>;
export type GetAppointmentsForCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentsForCurrentUserLazyQuery
>;
export type GetAppointmentsForCurrentUserQueryResult = Apollo.QueryResult<
  GetAppointmentsForCurrentUserQuery,
  GetAppointmentsForCurrentUserQueryVariables
>;
export const GetPrivateDaycareAppointmentForCurrentUserDocument = gql`
  query getPrivateDaycareAppointmentForCurrentUser(
    $input: GetAppointmentsForCurrentUserInput!
  ) {
    getPrivateDaycareAppointmentForCurrentUser(input: $input) {
      ...Appointment
      users {
        ...AppointmentUser
        contacts {
          ...Contact
        }
      }
      donor {
        ...AppointmentDonor
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      group {
        ...AppointmentGroup
        user {
          ...AppointmentUser
          contacts {
            ...Contact
          }
        }
      }
      team {
        ...AppointmentTeam
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
`;

/**
 * __useGetPrivateDaycareAppointmentForCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetPrivateDaycareAppointmentForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivateDaycareAppointmentForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivateDaycareAppointmentForCurrentUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPrivateDaycareAppointmentForCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrivateDaycareAppointmentForCurrentUserQuery,
    GetPrivateDaycareAppointmentForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPrivateDaycareAppointmentForCurrentUserQuery,
    GetPrivateDaycareAppointmentForCurrentUserQueryVariables
  >(GetPrivateDaycareAppointmentForCurrentUserDocument, options);
}
export function useGetPrivateDaycareAppointmentForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrivateDaycareAppointmentForCurrentUserQuery,
    GetPrivateDaycareAppointmentForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPrivateDaycareAppointmentForCurrentUserQuery,
    GetPrivateDaycareAppointmentForCurrentUserQueryVariables
  >(GetPrivateDaycareAppointmentForCurrentUserDocument, options);
}
export type GetPrivateDaycareAppointmentForCurrentUserQueryHookResult =
  ReturnType<typeof useGetPrivateDaycareAppointmentForCurrentUserQuery>;
export type GetPrivateDaycareAppointmentForCurrentUserLazyQueryHookResult =
  ReturnType<typeof useGetPrivateDaycareAppointmentForCurrentUserLazyQuery>;
export type GetPrivateDaycareAppointmentForCurrentUserQueryResult =
  Apollo.QueryResult<
    GetPrivateDaycareAppointmentForCurrentUserQuery,
    GetPrivateDaycareAppointmentForCurrentUserQueryVariables
  >;
export const GetDaycareAppointmentInfoDocument = gql`
  query getDaycareAppointmentInfo($input: String!) {
    getDaycareAppointmentInfo(input: $input) {
      interactions {
        id
        note
        owner {
          ...Owner
        }
        ownerResponse
        registeredDonors {
          ...Donor
        }
      }
      meta {
        maxDonorsCapacity
        registeredDonors
      }
      requests {
        id
        user {
          ...Owner
        }
        donor {
          ...Donor
        }
        note
      }
    }
  }
  ${OwnerFragmentDoc}
  ${DonorFragmentDoc}
`;

/**
 * __useGetDaycareAppointmentInfoQuery__
 *
 * To run a query within a React component, call `useGetDaycareAppointmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDaycareAppointmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDaycareAppointmentInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDaycareAppointmentInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDaycareAppointmentInfoQuery,
    GetDaycareAppointmentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDaycareAppointmentInfoQuery,
    GetDaycareAppointmentInfoQueryVariables
  >(GetDaycareAppointmentInfoDocument, options);
}
export function useGetDaycareAppointmentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDaycareAppointmentInfoQuery,
    GetDaycareAppointmentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDaycareAppointmentInfoQuery,
    GetDaycareAppointmentInfoQueryVariables
  >(GetDaycareAppointmentInfoDocument, options);
}
export type GetDaycareAppointmentInfoQueryHookResult = ReturnType<
  typeof useGetDaycareAppointmentInfoQuery
>;
export type GetDaycareAppointmentInfoLazyQueryHookResult = ReturnType<
  typeof useGetDaycareAppointmentInfoLazyQuery
>;
export type GetDaycareAppointmentInfoQueryResult = Apollo.QueryResult<
  GetDaycareAppointmentInfoQuery,
  GetDaycareAppointmentInfoQueryVariables
>;
export const GetDaycareAppointmentInfoForCurrentUserDocument = gql`
  query getDaycareAppointmentInfoForCurrentUser($appointmentId: String!) {
    getDaycareAppointmentInfoForCurrentUser(appointmentId: $appointmentId) {
      status
      daycareId
      registeredDonors {
        ...Donor
      }
      requests {
        id
        donor {
          ...Donor
        }
      }
    }
  }
  ${DonorFragmentDoc}
`;

/**
 * __useGetDaycareAppointmentInfoForCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetDaycareAppointmentInfoForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDaycareAppointmentInfoForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDaycareAppointmentInfoForCurrentUserQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useGetDaycareAppointmentInfoForCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDaycareAppointmentInfoForCurrentUserQuery,
    GetDaycareAppointmentInfoForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDaycareAppointmentInfoForCurrentUserQuery,
    GetDaycareAppointmentInfoForCurrentUserQueryVariables
  >(GetDaycareAppointmentInfoForCurrentUserDocument, options);
}
export function useGetDaycareAppointmentInfoForCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDaycareAppointmentInfoForCurrentUserQuery,
    GetDaycareAppointmentInfoForCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDaycareAppointmentInfoForCurrentUserQuery,
    GetDaycareAppointmentInfoForCurrentUserQueryVariables
  >(GetDaycareAppointmentInfoForCurrentUserDocument, options);
}
export type GetDaycareAppointmentInfoForCurrentUserQueryHookResult = ReturnType<
  typeof useGetDaycareAppointmentInfoForCurrentUserQuery
>;
export type GetDaycareAppointmentInfoForCurrentUserLazyQueryHookResult =
  ReturnType<typeof useGetDaycareAppointmentInfoForCurrentUserLazyQuery>;
export type GetDaycareAppointmentInfoForCurrentUserQueryResult =
  Apollo.QueryResult<
    GetDaycareAppointmentInfoForCurrentUserQuery,
    GetDaycareAppointmentInfoForCurrentUserQueryVariables
  >;
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation refreshToken {
    refreshToken {
      accessToken
      refreshToken
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const Document = gql`
  mutation {
    logout
  }
`;
export type MutationFn = Apollo.MutationFunction<Mutation, MutationVariables>;

/**
 * __useMutation__
 *
 * To run a mutation, you first call `useMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutation, { data, loading, error }] = useMutation({
 *   variables: {
 *   },
 * });
 */
export function useMutation(
  baseOptions?: Apollo.MutationHookOptions<Mutation, MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Mutation, MutationVariables>(Document, options);
}
export type MutationHookResult = ReturnType<typeof useMutation>;
export type MutationResult = Apollo.MutationResult<Mutation>;
export type MutationOptions = Apollo.BaseMutationOptions<
  Mutation,
  MutationVariables
>;
export const ResendEmailConfirmationCodeDocument = gql`
  mutation resendEmailConfirmationCode(
    $input: ResendEmailConfirmationCodeInput!
  ) {
    resendEmailConfirmationCode(input: $input)
  }
`;
export type ResendEmailConfirmationCodeMutationFn = Apollo.MutationFunction<
  ResendEmailConfirmationCodeMutation,
  ResendEmailConfirmationCodeMutationVariables
>;

/**
 * __useResendEmailConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useResendEmailConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailConfirmationCodeMutation, { data, loading, error }] = useResendEmailConfirmationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendEmailConfirmationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendEmailConfirmationCodeMutation,
    ResendEmailConfirmationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendEmailConfirmationCodeMutation,
    ResendEmailConfirmationCodeMutationVariables
  >(ResendEmailConfirmationCodeDocument, options);
}
export type ResendEmailConfirmationCodeMutationHookResult = ReturnType<
  typeof useResendEmailConfirmationCodeMutation
>;
export type ResendEmailConfirmationCodeMutationResult =
  Apollo.MutationResult<ResendEmailConfirmationCodeMutation>;
export type ResendEmailConfirmationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    ResendEmailConfirmationCodeMutation,
    ResendEmailConfirmationCodeMutationVariables
  >;
export const GetAppVersionDocument = gql`
  query getAppVersion {
    getAppVersion
  }
`;

/**
 * __useGetAppVersionQuery__
 *
 * To run a query within a React component, call `useGetAppVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppVersionQuery,
    GetAppVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppVersionQuery, GetAppVersionQueryVariables>(
    GetAppVersionDocument,
    options
  );
}
export function useGetAppVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppVersionQuery,
    GetAppVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppVersionQuery, GetAppVersionQueryVariables>(
    GetAppVersionDocument,
    options
  );
}
export type GetAppVersionQueryHookResult = ReturnType<
  typeof useGetAppVersionQuery
>;
export type GetAppVersionLazyQueryHookResult = ReturnType<
  typeof useGetAppVersionLazyQuery
>;
export type GetAppVersionQueryResult = Apollo.QueryResult<
  GetAppVersionQuery,
  GetAppVersionQueryVariables
>;
export const CreateDonationDocument = gql`
  mutation createDonation($input: CreateDonationInput!) {
    createDonation(input: $input) {
      ...Donation
    }
  }
  ${DonationFragmentDoc}
`;
export type CreateDonationMutationFn = Apollo.MutationFunction<
  CreateDonationMutation,
  CreateDonationMutationVariables
>;

/**
 * __useCreateDonationMutation__
 *
 * To run a mutation, you first call `useCreateDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonationMutation, { data, loading, error }] = useCreateDonationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDonationMutation,
    CreateDonationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDonationMutation,
    CreateDonationMutationVariables
  >(CreateDonationDocument, options);
}
export type CreateDonationMutationHookResult = ReturnType<
  typeof useCreateDonationMutation
>;
export type CreateDonationMutationResult =
  Apollo.MutationResult<CreateDonationMutation>;
export type CreateDonationMutationOptions = Apollo.BaseMutationOptions<
  CreateDonationMutation,
  CreateDonationMutationVariables
>;
export const UpdateDonationDocument = gql`
  mutation updateDonation($input: UpdateDonationInput!) {
    updateDonation(input: $input) {
      ...Donation
    }
  }
  ${DonationFragmentDoc}
`;
export type UpdateDonationMutationFn = Apollo.MutationFunction<
  UpdateDonationMutation,
  UpdateDonationMutationVariables
>;

/**
 * __useUpdateDonationMutation__
 *
 * To run a mutation, you first call `useUpdateDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonationMutation, { data, loading, error }] = useUpdateDonationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDonationMutation,
    UpdateDonationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDonationMutation,
    UpdateDonationMutationVariables
  >(UpdateDonationDocument, options);
}
export type UpdateDonationMutationHookResult = ReturnType<
  typeof useUpdateDonationMutation
>;
export type UpdateDonationMutationResult =
  Apollo.MutationResult<UpdateDonationMutation>;
export type UpdateDonationMutationOptions = Apollo.BaseMutationOptions<
  UpdateDonationMutation,
  UpdateDonationMutationVariables
>;
export const UpdateDonationCommentDocument = gql`
  mutation updateDonationComment($input: UpdateDonationCommentInput!) {
    updateDonationComment(input: $input) {
      ...Donation
    }
  }
  ${DonationFragmentDoc}
`;
export type UpdateDonationCommentMutationFn = Apollo.MutationFunction<
  UpdateDonationCommentMutation,
  UpdateDonationCommentMutationVariables
>;

/**
 * __useUpdateDonationCommentMutation__
 *
 * To run a mutation, you first call `useUpdateDonationCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonationCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonationCommentMutation, { data, loading, error }] = useUpdateDonationCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDonationCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDonationCommentMutation,
    UpdateDonationCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDonationCommentMutation,
    UpdateDonationCommentMutationVariables
  >(UpdateDonationCommentDocument, options);
}
export type UpdateDonationCommentMutationHookResult = ReturnType<
  typeof useUpdateDonationCommentMutation
>;
export type UpdateDonationCommentMutationResult =
  Apollo.MutationResult<UpdateDonationCommentMutation>;
export type UpdateDonationCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateDonationCommentMutation,
  UpdateDonationCommentMutationVariables
>;
export const UpdateDonationDonorDocument = gql`
  mutation updateDonationDonor($input: UpdateDonationDonorInput!) {
    updateDonationDonor(input: $input) {
      ...Donation
    }
  }
  ${DonationFragmentDoc}
`;
export type UpdateDonationDonorMutationFn = Apollo.MutationFunction<
  UpdateDonationDonorMutation,
  UpdateDonationDonorMutationVariables
>;

/**
 * __useUpdateDonationDonorMutation__
 *
 * To run a mutation, you first call `useUpdateDonationDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonationDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonationDonorMutation, { data, loading, error }] = useUpdateDonationDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDonationDonorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDonationDonorMutation,
    UpdateDonationDonorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDonationDonorMutation,
    UpdateDonationDonorMutationVariables
  >(UpdateDonationDonorDocument, options);
}
export type UpdateDonationDonorMutationHookResult = ReturnType<
  typeof useUpdateDonationDonorMutation
>;
export type UpdateDonationDonorMutationResult =
  Apollo.MutationResult<UpdateDonationDonorMutation>;
export type UpdateDonationDonorMutationOptions = Apollo.BaseMutationOptions<
  UpdateDonationDonorMutation,
  UpdateDonationDonorMutationVariables
>;
export const CreateAdditionalDonationInfoDocument = gql`
  mutation createAdditionalDonationInfo(
    $input: CreateAdditionalDonationInfoInput!
  ) {
    createAdditionalDonationInfo(input: $input) {
      ...AdditionalDonationInfo
    }
  }
  ${AdditionalDonationInfoFragmentDoc}
`;
export type CreateAdditionalDonationInfoMutationFn = Apollo.MutationFunction<
  CreateAdditionalDonationInfoMutation,
  CreateAdditionalDonationInfoMutationVariables
>;

/**
 * __useCreateAdditionalDonationInfoMutation__
 *
 * To run a mutation, you first call `useCreateAdditionalDonationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdditionalDonationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdditionalDonationInfoMutation, { data, loading, error }] = useCreateAdditionalDonationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdditionalDonationInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdditionalDonationInfoMutation,
    CreateAdditionalDonationInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAdditionalDonationInfoMutation,
    CreateAdditionalDonationInfoMutationVariables
  >(CreateAdditionalDonationInfoDocument, options);
}
export type CreateAdditionalDonationInfoMutationHookResult = ReturnType<
  typeof useCreateAdditionalDonationInfoMutation
>;
export type CreateAdditionalDonationInfoMutationResult =
  Apollo.MutationResult<CreateAdditionalDonationInfoMutation>;
export type CreateAdditionalDonationInfoMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAdditionalDonationInfoMutation,
    CreateAdditionalDonationInfoMutationVariables
  >;
export const UpdateAdditionalDonationInfoDocument = gql`
  mutation updateAdditionalDonationInfo(
    $input: UpdateAdditionalDonationInfoInput!
  ) {
    updateAdditionalDonationInfo(input: $input) {
      ...AdditionalDonationInfo
    }
  }
  ${AdditionalDonationInfoFragmentDoc}
`;
export type UpdateAdditionalDonationInfoMutationFn = Apollo.MutationFunction<
  UpdateAdditionalDonationInfoMutation,
  UpdateAdditionalDonationInfoMutationVariables
>;

/**
 * __useUpdateAdditionalDonationInfoMutation__
 *
 * To run a mutation, you first call `useUpdateAdditionalDonationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdditionalDonationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdditionalDonationInfoMutation, { data, loading, error }] = useUpdateAdditionalDonationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdditionalDonationInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdditionalDonationInfoMutation,
    UpdateAdditionalDonationInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAdditionalDonationInfoMutation,
    UpdateAdditionalDonationInfoMutationVariables
  >(UpdateAdditionalDonationInfoDocument, options);
}
export type UpdateAdditionalDonationInfoMutationHookResult = ReturnType<
  typeof useUpdateAdditionalDonationInfoMutation
>;
export type UpdateAdditionalDonationInfoMutationResult =
  Apollo.MutationResult<UpdateAdditionalDonationInfoMutation>;
export type UpdateAdditionalDonationInfoMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAdditionalDonationInfoMutation,
    UpdateAdditionalDonationInfoMutationVariables
  >;
export const CreateDonationWithAdditionalInfoDocument = gql`
  mutation createDonationWithAdditionalInfo(
    $input: CreateDonationWithAdditionalInfoInput!
  ) {
    createDonationWithAdditionalInfo(input: $input) {
      ...Donation
    }
  }
  ${DonationFragmentDoc}
`;
export type CreateDonationWithAdditionalInfoMutationFn =
  Apollo.MutationFunction<
    CreateDonationWithAdditionalInfoMutation,
    CreateDonationWithAdditionalInfoMutationVariables
  >;

/**
 * __useCreateDonationWithAdditionalInfoMutation__
 *
 * To run a mutation, you first call `useCreateDonationWithAdditionalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonationWithAdditionalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonationWithAdditionalInfoMutation, { data, loading, error }] = useCreateDonationWithAdditionalInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonationWithAdditionalInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDonationWithAdditionalInfoMutation,
    CreateDonationWithAdditionalInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDonationWithAdditionalInfoMutation,
    CreateDonationWithAdditionalInfoMutationVariables
  >(CreateDonationWithAdditionalInfoDocument, options);
}
export type CreateDonationWithAdditionalInfoMutationHookResult = ReturnType<
  typeof useCreateDonationWithAdditionalInfoMutation
>;
export type CreateDonationWithAdditionalInfoMutationResult =
  Apollo.MutationResult<CreateDonationWithAdditionalInfoMutation>;
export type CreateDonationWithAdditionalInfoMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDonationWithAdditionalInfoMutation,
    CreateDonationWithAdditionalInfoMutationVariables
  >;
export const CreateDonationsWithAdditionalInfoDocument = gql`
  mutation createDonationsWithAdditionalInfo(
    $input: CreateDonationsWithAdditionalInfoInput!
  ) {
    createDonationsWithAdditionalInfo(input: $input)
  }
`;
export type CreateDonationsWithAdditionalInfoMutationFn =
  Apollo.MutationFunction<
    CreateDonationsWithAdditionalInfoMutation,
    CreateDonationsWithAdditionalInfoMutationVariables
  >;

/**
 * __useCreateDonationsWithAdditionalInfoMutation__
 *
 * To run a mutation, you first call `useCreateDonationsWithAdditionalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonationsWithAdditionalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonationsWithAdditionalInfoMutation, { data, loading, error }] = useCreateDonationsWithAdditionalInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonationsWithAdditionalInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDonationsWithAdditionalInfoMutation,
    CreateDonationsWithAdditionalInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDonationsWithAdditionalInfoMutation,
    CreateDonationsWithAdditionalInfoMutationVariables
  >(CreateDonationsWithAdditionalInfoDocument, options);
}
export type CreateDonationsWithAdditionalInfoMutationHookResult = ReturnType<
  typeof useCreateDonationsWithAdditionalInfoMutation
>;
export type CreateDonationsWithAdditionalInfoMutationResult =
  Apollo.MutationResult<CreateDonationsWithAdditionalInfoMutation>;
export type CreateDonationsWithAdditionalInfoMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDonationsWithAdditionalInfoMutation,
    CreateDonationsWithAdditionalInfoMutationVariables
  >;
export const DeleteDonationDocument = gql`
  mutation deleteDonation($donationId: String!) {
    deleteDonation(donationId: $donationId)
  }
`;
export type DeleteDonationMutationFn = Apollo.MutationFunction<
  DeleteDonationMutation,
  DeleteDonationMutationVariables
>;

/**
 * __useDeleteDonationMutation__
 *
 * To run a mutation, you first call `useDeleteDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonationMutation, { data, loading, error }] = useDeleteDonationMutation({
 *   variables: {
 *      donationId: // value for 'donationId'
 *   },
 * });
 */
export function useDeleteDonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDonationMutation,
    DeleteDonationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDonationMutation,
    DeleteDonationMutationVariables
  >(DeleteDonationDocument, options);
}
export type DeleteDonationMutationHookResult = ReturnType<
  typeof useDeleteDonationMutation
>;
export type DeleteDonationMutationResult =
  Apollo.MutationResult<DeleteDonationMutation>;
export type DeleteDonationMutationOptions = Apollo.BaseMutationOptions<
  DeleteDonationMutation,
  DeleteDonationMutationVariables
>;
export const GetPreviousDonationDocument = gql`
  query getPreviousDonation($input: GetPreviousDonationInput!) {
    getPreviousDonation(input: $input) {
      ...Donation
    }
  }
  ${DonationFragmentDoc}
`;

/**
 * __useGetPreviousDonationQuery__
 *
 * To run a query within a React component, call `useGetPreviousDonationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviousDonationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviousDonationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPreviousDonationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPreviousDonationQuery,
    GetPreviousDonationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPreviousDonationQuery,
    GetPreviousDonationQueryVariables
  >(GetPreviousDonationDocument, options);
}
export function useGetPreviousDonationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPreviousDonationQuery,
    GetPreviousDonationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPreviousDonationQuery,
    GetPreviousDonationQueryVariables
  >(GetPreviousDonationDocument, options);
}
export type GetPreviousDonationQueryHookResult = ReturnType<
  typeof useGetPreviousDonationQuery
>;
export type GetPreviousDonationLazyQueryHookResult = ReturnType<
  typeof useGetPreviousDonationLazyQuery
>;
export type GetPreviousDonationQueryResult = Apollo.QueryResult<
  GetPreviousDonationQuery,
  GetPreviousDonationQueryVariables
>;
export const GetDonationDocument = gql`
  query getDonation($input: GetDonationInput!) {
    getDonation(input: $input) {
      ...Donation
    }
  }
  ${DonationFragmentDoc}
`;

/**
 * __useGetDonationQuery__
 *
 * To run a query within a React component, call `useGetDonationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonationQuery,
    GetDonationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDonationQuery, GetDonationQueryVariables>(
    GetDonationDocument,
    options
  );
}
export function useGetDonationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonationQuery,
    GetDonationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDonationQuery, GetDonationQueryVariables>(
    GetDonationDocument,
    options
  );
}
export type GetDonationQueryHookResult = ReturnType<typeof useGetDonationQuery>;
export type GetDonationLazyQueryHookResult = ReturnType<
  typeof useGetDonationLazyQuery
>;
export type GetDonationQueryResult = Apollo.QueryResult<
  GetDonationQuery,
  GetDonationQueryVariables
>;
export const GetDonationListDocument = gql`
  query getDonationList($input: GetDonationsListInput!) {
    getDonationsList(input: $input) {
      data {
        ...Donation
      }
      meta {
        total
      }
    }
  }
  ${DonationFragmentDoc}
`;

/**
 * __useGetDonationListQuery__
 *
 * To run a query within a React component, call `useGetDonationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonationListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonationListQuery,
    GetDonationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDonationListQuery, GetDonationListQueryVariables>(
    GetDonationListDocument,
    options
  );
}
export function useGetDonationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonationListQuery,
    GetDonationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDonationListQuery,
    GetDonationListQueryVariables
  >(GetDonationListDocument, options);
}
export type GetDonationListQueryHookResult = ReturnType<
  typeof useGetDonationListQuery
>;
export type GetDonationListLazyQueryHookResult = ReturnType<
  typeof useGetDonationListLazyQuery
>;
export type GetDonationListQueryResult = Apollo.QueryResult<
  GetDonationListQuery,
  GetDonationListQueryVariables
>;
export const GetSpecificDonorDonationsListDocument = gql`
  query getSpecificDonorDonationsList(
    $input: GetSpecificDonorDonationsListInput!
  ) {
    getSpecificDonorDonationsList(input: $input) {
      data {
        ...Donation
      }
      meta {
        total
      }
    }
  }
  ${DonationFragmentDoc}
`;

/**
 * __useGetSpecificDonorDonationsListQuery__
 *
 * To run a query within a React component, call `useGetSpecificDonorDonationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecificDonorDonationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecificDonorDonationsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSpecificDonorDonationsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSpecificDonorDonationsListQuery,
    GetSpecificDonorDonationsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSpecificDonorDonationsListQuery,
    GetSpecificDonorDonationsListQueryVariables
  >(GetSpecificDonorDonationsListDocument, options);
}
export function useGetSpecificDonorDonationsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpecificDonorDonationsListQuery,
    GetSpecificDonorDonationsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSpecificDonorDonationsListQuery,
    GetSpecificDonorDonationsListQueryVariables
  >(GetSpecificDonorDonationsListDocument, options);
}
export type GetSpecificDonorDonationsListQueryHookResult = ReturnType<
  typeof useGetSpecificDonorDonationsListQuery
>;
export type GetSpecificDonorDonationsListLazyQueryHookResult = ReturnType<
  typeof useGetSpecificDonorDonationsListLazyQuery
>;
export type GetSpecificDonorDonationsListQueryResult = Apollo.QueryResult<
  GetSpecificDonorDonationsListQuery,
  GetSpecificDonorDonationsListQueryVariables
>;
export const CreateDonorsForUserDocument = gql`
  mutation createDonorsForUser($input: CreateDonorsForUserInput!) {
    createDonorsForUser(input: $input)
  }
`;
export type CreateDonorsForUserMutationFn = Apollo.MutationFunction<
  CreateDonorsForUserMutation,
  CreateDonorsForUserMutationVariables
>;

/**
 * __useCreateDonorsForUserMutation__
 *
 * To run a mutation, you first call `useCreateDonorsForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorsForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorsForUserMutation, { data, loading, error }] = useCreateDonorsForUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonorsForUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDonorsForUserMutation,
    CreateDonorsForUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDonorsForUserMutation,
    CreateDonorsForUserMutationVariables
  >(CreateDonorsForUserDocument, options);
}
export type CreateDonorsForUserMutationHookResult = ReturnType<
  typeof useCreateDonorsForUserMutation
>;
export type CreateDonorsForUserMutationResult =
  Apollo.MutationResult<CreateDonorsForUserMutation>;
export type CreateDonorsForUserMutationOptions = Apollo.BaseMutationOptions<
  CreateDonorsForUserMutation,
  CreateDonorsForUserMutationVariables
>;
export const CreateDonorsDocument = gql`
  mutation createDonors($input: CreateDonors!) {
    createDonors(input: $input)
  }
`;
export type CreateDonorsMutationFn = Apollo.MutationFunction<
  CreateDonorsMutation,
  CreateDonorsMutationVariables
>;

/**
 * __useCreateDonorsMutation__
 *
 * To run a mutation, you first call `useCreateDonorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorsMutation, { data, loading, error }] = useCreateDonorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDonorsMutation,
    CreateDonorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDonorsMutation,
    CreateDonorsMutationVariables
  >(CreateDonorsDocument, options);
}
export type CreateDonorsMutationHookResult = ReturnType<
  typeof useCreateDonorsMutation
>;
export type CreateDonorsMutationResult =
  Apollo.MutationResult<CreateDonorsMutation>;
export type CreateDonorsMutationOptions = Apollo.BaseMutationOptions<
  CreateDonorsMutation,
  CreateDonorsMutationVariables
>;
export const CreateDonorDocument = gql`
  mutation createDonor($input: CreateDonorInput!) {
    createDonor(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;
export type CreateDonorMutationFn = Apollo.MutationFunction<
  CreateDonorMutation,
  CreateDonorMutationVariables
>;

/**
 * __useCreateDonorMutation__
 *
 * To run a mutation, you first call `useCreateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorMutation, { data, loading, error }] = useCreateDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDonorMutation,
    CreateDonorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDonorMutation, CreateDonorMutationVariables>(
    CreateDonorDocument,
    options
  );
}
export type CreateDonorMutationHookResult = ReturnType<
  typeof useCreateDonorMutation
>;
export type CreateDonorMutationResult =
  Apollo.MutationResult<CreateDonorMutation>;
export type CreateDonorMutationOptions = Apollo.BaseMutationOptions<
  CreateDonorMutation,
  CreateDonorMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation updateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      title
    }
  }
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    options
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
  Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      title
    }
  }
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options
  );
}
export type UpdateTeamMutationHookResult = ReturnType<
  typeof useUpdateTeamMutation
>;
export type UpdateTeamMutationResult =
  Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const RemoveGroupDocument = gql`
  mutation removeGroup($input: RemoveGroupInput!) {
    removeGroup(input: $input)
  }
`;
export type RemoveGroupMutationFn = Apollo.MutationFunction<
  RemoveGroupMutation,
  RemoveGroupMutationVariables
>;

/**
 * __useRemoveGroupMutation__
 *
 * To run a mutation, you first call `useRemoveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupMutation, { data, loading, error }] = useRemoveGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupMutation,
    RemoveGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveGroupMutation, RemoveGroupMutationVariables>(
    RemoveGroupDocument,
    options
  );
}
export type RemoveGroupMutationHookResult = ReturnType<
  typeof useRemoveGroupMutation
>;
export type RemoveGroupMutationResult =
  Apollo.MutationResult<RemoveGroupMutation>;
export type RemoveGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupMutation,
  RemoveGroupMutationVariables
>;
export const CreateTeamDocument = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
    }
  }
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamMutation,
    CreateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options
  );
}
export type CreateTeamMutationHookResult = ReturnType<
  typeof useCreateTeamMutation
>;
export type CreateTeamMutationResult =
  Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const RemoveTeamDocument = gql`
  mutation removeTeam($input: RemoveTeamInput!) {
    removeTeam(input: $input)
  }
`;
export type RemoveTeamMutationFn = Apollo.MutationFunction<
  RemoveTeamMutation,
  RemoveTeamMutationVariables
>;

/**
 * __useRemoveTeamMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMutation, { data, loading, error }] = useRemoveTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTeamMutation,
    RemoveTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTeamMutation, RemoveTeamMutationVariables>(
    RemoveTeamDocument,
    options
  );
}
export type RemoveTeamMutationHookResult = ReturnType<
  typeof useRemoveTeamMutation
>;
export type RemoveTeamMutationResult =
  Apollo.MutationResult<RemoveTeamMutation>;
export type RemoveTeamMutationOptions = Apollo.BaseMutationOptions<
  RemoveTeamMutation,
  RemoveTeamMutationVariables
>;
export const RemoveDonorDocument = gql`
  mutation removeDonor($input: DeleteDonorInput!) {
    deleteDonor(input: $input)
  }
`;
export type RemoveDonorMutationFn = Apollo.MutationFunction<
  RemoveDonorMutation,
  RemoveDonorMutationVariables
>;

/**
 * __useRemoveDonorMutation__
 *
 * To run a mutation, you first call `useRemoveDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDonorMutation, { data, loading, error }] = useRemoveDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDonorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDonorMutation,
    RemoveDonorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDonorMutation, RemoveDonorMutationVariables>(
    RemoveDonorDocument,
    options
  );
}
export type RemoveDonorMutationHookResult = ReturnType<
  typeof useRemoveDonorMutation
>;
export type RemoveDonorMutationResult =
  Apollo.MutationResult<RemoveDonorMutation>;
export type RemoveDonorMutationOptions = Apollo.BaseMutationOptions<
  RemoveDonorMutation,
  RemoveDonorMutationVariables
>;
export const CreateGroupDocument = gql`
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
    }
  }
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
  Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const InviteOwnerWithDonorDocument = gql`
  mutation inviteOwnerWithDonor($input: InviteOwnerWithDonorInput!) {
    inviteOwnerWithDonor(input: $input)
  }
`;
export type InviteOwnerWithDonorMutationFn = Apollo.MutationFunction<
  InviteOwnerWithDonorMutation,
  InviteOwnerWithDonorMutationVariables
>;

/**
 * __useInviteOwnerWithDonorMutation__
 *
 * To run a mutation, you first call `useInviteOwnerWithDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOwnerWithDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOwnerWithDonorMutation, { data, loading, error }] = useInviteOwnerWithDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteOwnerWithDonorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteOwnerWithDonorMutation,
    InviteOwnerWithDonorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteOwnerWithDonorMutation,
    InviteOwnerWithDonorMutationVariables
  >(InviteOwnerWithDonorDocument, options);
}
export type InviteOwnerWithDonorMutationHookResult = ReturnType<
  typeof useInviteOwnerWithDonorMutation
>;
export type InviteOwnerWithDonorMutationResult =
  Apollo.MutationResult<InviteOwnerWithDonorMutation>;
export type InviteOwnerWithDonorMutationOptions = Apollo.BaseMutationOptions<
  InviteOwnerWithDonorMutation,
  InviteOwnerWithDonorMutationVariables
>;
export const CreateDonorForUserDocument = gql`
  mutation createDonorForUser($input: CreateDonorForUserInput!) {
    createDonorForUser(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;
export type CreateDonorForUserMutationFn = Apollo.MutationFunction<
  CreateDonorForUserMutation,
  CreateDonorForUserMutationVariables
>;

/**
 * __useCreateDonorForUserMutation__
 *
 * To run a mutation, you first call `useCreateDonorForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorForUserMutation, { data, loading, error }] = useCreateDonorForUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonorForUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDonorForUserMutation,
    CreateDonorForUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDonorForUserMutation,
    CreateDonorForUserMutationVariables
  >(CreateDonorForUserDocument, options);
}
export type CreateDonorForUserMutationHookResult = ReturnType<
  typeof useCreateDonorForUserMutation
>;
export type CreateDonorForUserMutationResult =
  Apollo.MutationResult<CreateDonorForUserMutation>;
export type CreateDonorForUserMutationOptions = Apollo.BaseMutationOptions<
  CreateDonorForUserMutation,
  CreateDonorForUserMutationVariables
>;
export const UpdateDonorDocument = gql`
  mutation updateDonor($input: UpdateDonorInput!) {
    updateDonor(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;
export type UpdateDonorMutationFn = Apollo.MutationFunction<
  UpdateDonorMutation,
  UpdateDonorMutationVariables
>;

/**
 * __useUpdateDonorMutation__
 *
 * To run a mutation, you first call `useUpdateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonorMutation, { data, loading, error }] = useUpdateDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDonorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDonorMutation,
    UpdateDonorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDonorMutation, UpdateDonorMutationVariables>(
    UpdateDonorDocument,
    options
  );
}
export type UpdateDonorMutationHookResult = ReturnType<
  typeof useUpdateDonorMutation
>;
export type UpdateDonorMutationResult =
  Apollo.MutationResult<UpdateDonorMutation>;
export type UpdateDonorMutationOptions = Apollo.BaseMutationOptions<
  UpdateDonorMutation,
  UpdateDonorMutationVariables
>;
export const AddNoteToReserveDonorDocument = gql`
  mutation addNoteToReserveDonor($input: CreateDonorNoteInput!) {
    addNoteToReserveDonor(input: $input) {
      ...ReserveDonorNote
    }
  }
  ${ReserveDonorNoteFragmentDoc}
`;
export type AddNoteToReserveDonorMutationFn = Apollo.MutationFunction<
  AddNoteToReserveDonorMutation,
  AddNoteToReserveDonorMutationVariables
>;

/**
 * __useAddNoteToReserveDonorMutation__
 *
 * To run a mutation, you first call `useAddNoteToReserveDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteToReserveDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteToReserveDonorMutation, { data, loading, error }] = useAddNoteToReserveDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNoteToReserveDonorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNoteToReserveDonorMutation,
    AddNoteToReserveDonorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddNoteToReserveDonorMutation,
    AddNoteToReserveDonorMutationVariables
  >(AddNoteToReserveDonorDocument, options);
}
export type AddNoteToReserveDonorMutationHookResult = ReturnType<
  typeof useAddNoteToReserveDonorMutation
>;
export type AddNoteToReserveDonorMutationResult =
  Apollo.MutationResult<AddNoteToReserveDonorMutation>;
export type AddNoteToReserveDonorMutationOptions = Apollo.BaseMutationOptions<
  AddNoteToReserveDonorMutation,
  AddNoteToReserveDonorMutationVariables
>;
export const UpdateReserveDonorNoteDocument = gql`
  mutation updateReserveDonorNote($input: UpdateDonorNoteInput!) {
    updateReserveDonorNote(input: $input) {
      ...ReserveDonorNote
    }
  }
  ${ReserveDonorNoteFragmentDoc}
`;
export type UpdateReserveDonorNoteMutationFn = Apollo.MutationFunction<
  UpdateReserveDonorNoteMutation,
  UpdateReserveDonorNoteMutationVariables
>;

/**
 * __useUpdateReserveDonorNoteMutation__
 *
 * To run a mutation, you first call `useUpdateReserveDonorNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReserveDonorNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReserveDonorNoteMutation, { data, loading, error }] = useUpdateReserveDonorNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReserveDonorNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReserveDonorNoteMutation,
    UpdateReserveDonorNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReserveDonorNoteMutation,
    UpdateReserveDonorNoteMutationVariables
  >(UpdateReserveDonorNoteDocument, options);
}
export type UpdateReserveDonorNoteMutationHookResult = ReturnType<
  typeof useUpdateReserveDonorNoteMutation
>;
export type UpdateReserveDonorNoteMutationResult =
  Apollo.MutationResult<UpdateReserveDonorNoteMutation>;
export type UpdateReserveDonorNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateReserveDonorNoteMutation,
  UpdateReserveDonorNoteMutationVariables
>;
export const DeleteReserveDonorNoteDocument = gql`
  mutation deleteReserveDonorNote($input: DeleteDonorNoteInput!) {
    deleteReserveDonorNote(input: $input)
  }
`;
export type DeleteReserveDonorNoteMutationFn = Apollo.MutationFunction<
  DeleteReserveDonorNoteMutation,
  DeleteReserveDonorNoteMutationVariables
>;

/**
 * __useDeleteReserveDonorNoteMutation__
 *
 * To run a mutation, you first call `useDeleteReserveDonorNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReserveDonorNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReserveDonorNoteMutation, { data, loading, error }] = useDeleteReserveDonorNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReserveDonorNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteReserveDonorNoteMutation,
    DeleteReserveDonorNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteReserveDonorNoteMutation,
    DeleteReserveDonorNoteMutationVariables
  >(DeleteReserveDonorNoteDocument, options);
}
export type DeleteReserveDonorNoteMutationHookResult = ReturnType<
  typeof useDeleteReserveDonorNoteMutation
>;
export type DeleteReserveDonorNoteMutationResult =
  Apollo.MutationResult<DeleteReserveDonorNoteMutation>;
export type DeleteReserveDonorNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteReserveDonorNoteMutation,
  DeleteReserveDonorNoteMutationVariables
>;
export const UpdateLabworkDocument = gql`
  mutation updateLabwork($input: UpdateLabworkInput!) {
    updateLabwork(input: $input) {
      ...Labwork
      donor {
        ...Donor
        user {
          ...Owner
        }
      }
    }
  }
  ${LabworkFragmentDoc}
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
`;
export type UpdateLabworkMutationFn = Apollo.MutationFunction<
  UpdateLabworkMutation,
  UpdateLabworkMutationVariables
>;

/**
 * __useUpdateLabworkMutation__
 *
 * To run a mutation, you first call `useUpdateLabworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabworkMutation, { data, loading, error }] = useUpdateLabworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLabworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLabworkMutation,
    UpdateLabworkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLabworkMutation,
    UpdateLabworkMutationVariables
  >(UpdateLabworkDocument, options);
}
export type UpdateLabworkMutationHookResult = ReturnType<
  typeof useUpdateLabworkMutation
>;
export type UpdateLabworkMutationResult =
  Apollo.MutationResult<UpdateLabworkMutation>;
export type UpdateLabworkMutationOptions = Apollo.BaseMutationOptions<
  UpdateLabworkMutation,
  UpdateLabworkMutationVariables
>;
export const UpdateLabworkStatusDocument = gql`
  mutation updateLabworkStatus($input: UpdateLabworkStatusInput!) {
    updateLabworkStatus(input: $input) {
      ...Labwork
      donor {
        ...Donor
        user {
          ...Owner
        }
      }
    }
  }
  ${LabworkFragmentDoc}
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
`;
export type UpdateLabworkStatusMutationFn = Apollo.MutationFunction<
  UpdateLabworkStatusMutation,
  UpdateLabworkStatusMutationVariables
>;

/**
 * __useUpdateLabworkStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLabworkStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabworkStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabworkStatusMutation, { data, loading, error }] = useUpdateLabworkStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLabworkStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLabworkStatusMutation,
    UpdateLabworkStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLabworkStatusMutation,
    UpdateLabworkStatusMutationVariables
  >(UpdateLabworkStatusDocument, options);
}
export type UpdateLabworkStatusMutationHookResult = ReturnType<
  typeof useUpdateLabworkStatusMutation
>;
export type UpdateLabworkStatusMutationResult =
  Apollo.MutationResult<UpdateLabworkStatusMutation>;
export type UpdateLabworkStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateLabworkStatusMutation,
  UpdateLabworkStatusMutationVariables
>;
export const CreateLabworkDocument = gql`
  mutation createLabwork($input: CreateLabworkInput!) {
    createLabwork(input: $input) {
      ...Labwork
      donor {
        ...Donor
        user {
          ...Owner
        }
      }
    }
  }
  ${LabworkFragmentDoc}
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
`;
export type CreateLabworkMutationFn = Apollo.MutationFunction<
  CreateLabworkMutation,
  CreateLabworkMutationVariables
>;

/**
 * __useCreateLabworkMutation__
 *
 * To run a mutation, you first call `useCreateLabworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabworkMutation, { data, loading, error }] = useCreateLabworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLabworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLabworkMutation,
    CreateLabworkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLabworkMutation,
    CreateLabworkMutationVariables
  >(CreateLabworkDocument, options);
}
export type CreateLabworkMutationHookResult = ReturnType<
  typeof useCreateLabworkMutation
>;
export type CreateLabworkMutationResult =
  Apollo.MutationResult<CreateLabworkMutation>;
export type CreateLabworkMutationOptions = Apollo.BaseMutationOptions<
  CreateLabworkMutation,
  CreateLabworkMutationVariables
>;
export const ShareLabworkResultsDocument = gql`
  mutation shareLabworkResults($input: ShareLabworkResultsWithOwnerInput!) {
    shareLabworkResults(input: $input)
  }
`;
export type ShareLabworkResultsMutationFn = Apollo.MutationFunction<
  ShareLabworkResultsMutation,
  ShareLabworkResultsMutationVariables
>;

/**
 * __useShareLabworkResultsMutation__
 *
 * To run a mutation, you first call `useShareLabworkResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareLabworkResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareLabworkResultsMutation, { data, loading, error }] = useShareLabworkResultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareLabworkResultsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareLabworkResultsMutation,
    ShareLabworkResultsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ShareLabworkResultsMutation,
    ShareLabworkResultsMutationVariables
  >(ShareLabworkResultsDocument, options);
}
export type ShareLabworkResultsMutationHookResult = ReturnType<
  typeof useShareLabworkResultsMutation
>;
export type ShareLabworkResultsMutationResult =
  Apollo.MutationResult<ShareLabworkResultsMutation>;
export type ShareLabworkResultsMutationOptions = Apollo.BaseMutationOptions<
  ShareLabworkResultsMutation,
  ShareLabworkResultsMutationVariables
>;
export const RemoveLabworkDocument = gql`
  mutation removeLabwork($input: RemoveLabworkInput!) {
    removeLabwork(input: $input)
  }
`;
export type RemoveLabworkMutationFn = Apollo.MutationFunction<
  RemoveLabworkMutation,
  RemoveLabworkMutationVariables
>;

/**
 * __useRemoveLabworkMutation__
 *
 * To run a mutation, you first call `useRemoveLabworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLabworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLabworkMutation, { data, loading, error }] = useRemoveLabworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLabworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLabworkMutation,
    RemoveLabworkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveLabworkMutation,
    RemoveLabworkMutationVariables
  >(RemoveLabworkDocument, options);
}
export type RemoveLabworkMutationHookResult = ReturnType<
  typeof useRemoveLabworkMutation
>;
export type RemoveLabworkMutationResult =
  Apollo.MutationResult<RemoveLabworkMutation>;
export type RemoveLabworkMutationOptions = Apollo.BaseMutationOptions<
  RemoveLabworkMutation,
  RemoveLabworkMutationVariables
>;
export const DetachLabworkFileDocument = gql`
  mutation detachLabworkFile($input: DetachLabworkFileInput!) {
    detachLabworkFile(input: $input)
  }
`;
export type DetachLabworkFileMutationFn = Apollo.MutationFunction<
  DetachLabworkFileMutation,
  DetachLabworkFileMutationVariables
>;

/**
 * __useDetachLabworkFileMutation__
 *
 * To run a mutation, you first call `useDetachLabworkFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachLabworkFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachLabworkFileMutation, { data, loading, error }] = useDetachLabworkFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachLabworkFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachLabworkFileMutation,
    DetachLabworkFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachLabworkFileMutation,
    DetachLabworkFileMutationVariables
  >(DetachLabworkFileDocument, options);
}
export type DetachLabworkFileMutationHookResult = ReturnType<
  typeof useDetachLabworkFileMutation
>;
export type DetachLabworkFileMutationResult =
  Apollo.MutationResult<DetachLabworkFileMutation>;
export type DetachLabworkFileMutationOptions = Apollo.BaseMutationOptions<
  DetachLabworkFileMutation,
  DetachLabworkFileMutationVariables
>;
export const UpdateVitalsParametersListDocument = gql`
  mutation updateVitalsParametersList(
    $input: UpdateVitalsParametersListInput!
  ) {
    updateVitalsParameterList(input: $input) {
      ...VitalsParameter
    }
  }
  ${VitalsParameterFragmentDoc}
`;
export type UpdateVitalsParametersListMutationFn = Apollo.MutationFunction<
  UpdateVitalsParametersListMutation,
  UpdateVitalsParametersListMutationVariables
>;

/**
 * __useUpdateVitalsParametersListMutation__
 *
 * To run a mutation, you first call `useUpdateVitalsParametersListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVitalsParametersListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVitalsParametersListMutation, { data, loading, error }] = useUpdateVitalsParametersListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVitalsParametersListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVitalsParametersListMutation,
    UpdateVitalsParametersListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVitalsParametersListMutation,
    UpdateVitalsParametersListMutationVariables
  >(UpdateVitalsParametersListDocument, options);
}
export type UpdateVitalsParametersListMutationHookResult = ReturnType<
  typeof useUpdateVitalsParametersListMutation
>;
export type UpdateVitalsParametersListMutationResult =
  Apollo.MutationResult<UpdateVitalsParametersListMutation>;
export type UpdateVitalsParametersListMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateVitalsParametersListMutation,
    UpdateVitalsParametersListMutationVariables
  >;
export const UpdateDonorsOwnerDocument = gql`
  mutation updateDonorsOwner($input: UpdateDonorsOwnerInput!) {
    updateDonorsOwner(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;
export type UpdateDonorsOwnerMutationFn = Apollo.MutationFunction<
  UpdateDonorsOwnerMutation,
  UpdateDonorsOwnerMutationVariables
>;

/**
 * __useUpdateDonorsOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateDonorsOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonorsOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonorsOwnerMutation, { data, loading, error }] = useUpdateDonorsOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDonorsOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDonorsOwnerMutation,
    UpdateDonorsOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDonorsOwnerMutation,
    UpdateDonorsOwnerMutationVariables
  >(UpdateDonorsOwnerDocument, options);
}
export type UpdateDonorsOwnerMutationHookResult = ReturnType<
  typeof useUpdateDonorsOwnerMutation
>;
export type UpdateDonorsOwnerMutationResult =
  Apollo.MutationResult<UpdateDonorsOwnerMutation>;
export type UpdateDonorsOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateDonorsOwnerMutation,
  UpdateDonorsOwnerMutationVariables
>;
export const UpdateGroupOwnerDocument = gql`
  mutation updateGroupOwner($input: UpdateGroupOwnerInput!) {
    updateGroupOwner(input: $input) {
      ...Group
      teams {
        ...Team
        donors {
          ...Donor
        }
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
    }
  }
  ${GroupFragmentDoc}
  ${TeamFragmentDoc}
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
`;
export type UpdateGroupOwnerMutationFn = Apollo.MutationFunction<
  UpdateGroupOwnerMutation,
  UpdateGroupOwnerMutationVariables
>;

/**
 * __useUpdateGroupOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateGroupOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupOwnerMutation, { data, loading, error }] = useUpdateGroupOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupOwnerMutation,
    UpdateGroupOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupOwnerMutation,
    UpdateGroupOwnerMutationVariables
  >(UpdateGroupOwnerDocument, options);
}
export type UpdateGroupOwnerMutationHookResult = ReturnType<
  typeof useUpdateGroupOwnerMutation
>;
export type UpdateGroupOwnerMutationResult =
  Apollo.MutationResult<UpdateGroupOwnerMutation>;
export type UpdateGroupOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupOwnerMutation,
  UpdateGroupOwnerMutationVariables
>;
export const ManageDonorsInTeamDocument = gql`
  mutation manageDonorsInTeam($input: ManageDonorsInTeamInput!) {
    manageDonorsInTeam(input: $input)
  }
`;
export type ManageDonorsInTeamMutationFn = Apollo.MutationFunction<
  ManageDonorsInTeamMutation,
  ManageDonorsInTeamMutationVariables
>;

/**
 * __useManageDonorsInTeamMutation__
 *
 * To run a mutation, you first call `useManageDonorsInTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageDonorsInTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageDonorsInTeamMutation, { data, loading, error }] = useManageDonorsInTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageDonorsInTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManageDonorsInTeamMutation,
    ManageDonorsInTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ManageDonorsInTeamMutation,
    ManageDonorsInTeamMutationVariables
  >(ManageDonorsInTeamDocument, options);
}
export type ManageDonorsInTeamMutationHookResult = ReturnType<
  typeof useManageDonorsInTeamMutation
>;
export type ManageDonorsInTeamMutationResult =
  Apollo.MutationResult<ManageDonorsInTeamMutation>;
export type ManageDonorsInTeamMutationOptions = Apollo.BaseMutationOptions<
  ManageDonorsInTeamMutation,
  ManageDonorsInTeamMutationVariables
>;
export const AddNoteDocument = gql`
  mutation addNote($input: CreateDonorNoteInput!) {
    addNote(input: $input) {
      ...DonorNote
    }
  }
  ${DonorNoteFragmentDoc}
`;
export type AddNoteMutationFn = Apollo.MutationFunction<
  AddNoteMutation,
  AddNoteMutationVariables
>;

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNoteMutation,
    AddNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddNoteMutation, AddNoteMutationVariables>(
    AddNoteDocument,
    options
  );
}
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = Apollo.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = Apollo.BaseMutationOptions<
  AddNoteMutation,
  AddNoteMutationVariables
>;
export const UpdateNoteDocument = gql`
  mutation updateNote($input: UpdateDonorNoteInput!) {
    updateNote(input: $input) {
      ...DonorNote
    }
  }
  ${DonorNoteFragmentDoc}
`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNoteMutation,
    UpdateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(
    UpdateNoteDocument,
    options
  );
}
export type UpdateNoteMutationHookResult = ReturnType<
  typeof useUpdateNoteMutation
>;
export type UpdateNoteMutationResult =
  Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateNoteMutation,
  UpdateNoteMutationVariables
>;
export const DeleteNoteDocument = gql`
  mutation deleteNote($input: DeleteDonorNoteInput!) {
    deleteNote(input: $input)
  }
`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNoteMutation,
    DeleteNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(
    DeleteNoteDocument,
    options
  );
}
export type DeleteNoteMutationHookResult = ReturnType<
  typeof useDeleteNoteMutation
>;
export type DeleteNoteMutationResult =
  Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteNoteMutation,
  DeleteNoteMutationVariables
>;
export const MakeDonorPicturePrimaryDocument = gql`
  mutation makeDonorPicturePrimary($input: MakePicturePrimaryInput!) {
    makeDonorPicturePrimary(input: $input)
  }
`;
export type MakeDonorPicturePrimaryMutationFn = Apollo.MutationFunction<
  MakeDonorPicturePrimaryMutation,
  MakeDonorPicturePrimaryMutationVariables
>;

/**
 * __useMakeDonorPicturePrimaryMutation__
 *
 * To run a mutation, you first call `useMakeDonorPicturePrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeDonorPicturePrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeDonorPicturePrimaryMutation, { data, loading, error }] = useMakeDonorPicturePrimaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeDonorPicturePrimaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeDonorPicturePrimaryMutation,
    MakeDonorPicturePrimaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeDonorPicturePrimaryMutation,
    MakeDonorPicturePrimaryMutationVariables
  >(MakeDonorPicturePrimaryDocument, options);
}
export type MakeDonorPicturePrimaryMutationHookResult = ReturnType<
  typeof useMakeDonorPicturePrimaryMutation
>;
export type MakeDonorPicturePrimaryMutationResult =
  Apollo.MutationResult<MakeDonorPicturePrimaryMutation>;
export type MakeDonorPicturePrimaryMutationOptions = Apollo.BaseMutationOptions<
  MakeDonorPicturePrimaryMutation,
  MakeDonorPicturePrimaryMutationVariables
>;
export const ResetPrimaryDonorPicturesDocument = gql`
  mutation resetPrimaryDonorPictures($input: ResetPrimaryPicturesInput!) {
    resetPrimaryDonorPictures(input: $input)
  }
`;
export type ResetPrimaryDonorPicturesMutationFn = Apollo.MutationFunction<
  ResetPrimaryDonorPicturesMutation,
  ResetPrimaryDonorPicturesMutationVariables
>;

/**
 * __useResetPrimaryDonorPicturesMutation__
 *
 * To run a mutation, you first call `useResetPrimaryDonorPicturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPrimaryDonorPicturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPrimaryDonorPicturesMutation, { data, loading, error }] = useResetPrimaryDonorPicturesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPrimaryDonorPicturesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPrimaryDonorPicturesMutation,
    ResetPrimaryDonorPicturesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPrimaryDonorPicturesMutation,
    ResetPrimaryDonorPicturesMutationVariables
  >(ResetPrimaryDonorPicturesDocument, options);
}
export type ResetPrimaryDonorPicturesMutationHookResult = ReturnType<
  typeof useResetPrimaryDonorPicturesMutation
>;
export type ResetPrimaryDonorPicturesMutationResult =
  Apollo.MutationResult<ResetPrimaryDonorPicturesMutation>;
export type ResetPrimaryDonorPicturesMutationOptions =
  Apollo.BaseMutationOptions<
    ResetPrimaryDonorPicturesMutation,
    ResetPrimaryDonorPicturesMutationVariables
  >;
export const GetDonorsListDocument = gql`
  query getDonorsList($input: DonorsListInput!) {
    getDonorsList(input: $input) {
      data {
        ...Donor
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
        reserveNotes {
          ...ReserveDonorNote
        }
      }
      meta {
        total
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;

/**
 * __useGetDonorsListQuery__
 *
 * To run a query within a React component, call `useGetDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonorsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonorsListQuery,
    GetDonorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDonorsListQuery, GetDonorsListQueryVariables>(
    GetDonorsListDocument,
    options
  );
}
export function useGetDonorsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonorsListQuery,
    GetDonorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDonorsListQuery, GetDonorsListQueryVariables>(
    GetDonorsListDocument,
    options
  );
}
export type GetDonorsListQueryHookResult = ReturnType<
  typeof useGetDonorsListQuery
>;
export type GetDonorsListLazyQueryHookResult = ReturnType<
  typeof useGetDonorsListLazyQuery
>;
export type GetDonorsListQueryResult = Apollo.QueryResult<
  GetDonorsListQuery,
  GetDonorsListQueryVariables
>;
export const FindDonorsForTeamDocument = gql`
  query findDonorsForTeam($input: DonorsListForTeamInput!) {
    findDonorsForTeam(input: $input) {
      data {
        ...Donor
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
        reserveNotes {
          ...ReserveDonorNote
        }
      }
      meta {
        total
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;

/**
 * __useFindDonorsForTeamQuery__
 *
 * To run a query within a React component, call `useFindDonorsForTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDonorsForTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDonorsForTeamQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindDonorsForTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDonorsForTeamQuery,
    FindDonorsForTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindDonorsForTeamQuery,
    FindDonorsForTeamQueryVariables
  >(FindDonorsForTeamDocument, options);
}
export function useFindDonorsForTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDonorsForTeamQuery,
    FindDonorsForTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindDonorsForTeamQuery,
    FindDonorsForTeamQueryVariables
  >(FindDonorsForTeamDocument, options);
}
export type FindDonorsForTeamQueryHookResult = ReturnType<
  typeof useFindDonorsForTeamQuery
>;
export type FindDonorsForTeamLazyQueryHookResult = ReturnType<
  typeof useFindDonorsForTeamLazyQuery
>;
export type FindDonorsForTeamQueryResult = Apollo.QueryResult<
  FindDonorsForTeamQuery,
  FindDonorsForTeamQueryVariables
>;
export const GetDonorDocument = gql`
  query getDonor($input: GetDonorInput!) {
    getDonor(input: $input) {
      ...Donor
      lastLabworkFile {
        ...File
      }
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
      pictures {
        ...Picture
      }
    }
  }
  ${DonorFragmentDoc}
  ${FileFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
  ${PictureFragmentDoc}
`;

/**
 * __useGetDonorQuery__
 *
 * To run a query within a React component, call `useGetDonorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonorQuery(
  baseOptions: Apollo.QueryHookOptions<GetDonorQuery, GetDonorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDonorQuery, GetDonorQueryVariables>(
    GetDonorDocument,
    options
  );
}
export function useGetDonorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonorQuery,
    GetDonorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDonorQuery, GetDonorQueryVariables>(
    GetDonorDocument,
    options
  );
}
export type GetDonorQueryHookResult = ReturnType<typeof useGetDonorQuery>;
export type GetDonorLazyQueryHookResult = ReturnType<
  typeof useGetDonorLazyQuery
>;
export type GetDonorQueryResult = Apollo.QueryResult<
  GetDonorQuery,
  GetDonorQueryVariables
>;
export const GetCurrentUserDonorsListDocument = gql`
  query getCurrentUserDonorsList($input: CurrentUserDonorListInput!) {
    getCurrentUserDonorsList(input: $input) {
      data {
        ...Donor
        lastLabworkFile {
          ...File
        }
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
        reserveNotes {
          ...ReserveDonorNote
        }
      }
      meta {
        total
      }
    }
  }
  ${DonorFragmentDoc}
  ${FileFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;

/**
 * __useGetCurrentUserDonorsListQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserDonorsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCurrentUserDonorsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentUserDonorsListQuery,
    GetCurrentUserDonorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentUserDonorsListQuery,
    GetCurrentUserDonorsListQueryVariables
  >(GetCurrentUserDonorsListDocument, options);
}
export function useGetCurrentUserDonorsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserDonorsListQuery,
    GetCurrentUserDonorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentUserDonorsListQuery,
    GetCurrentUserDonorsListQueryVariables
  >(GetCurrentUserDonorsListDocument, options);
}
export type GetCurrentUserDonorsListQueryHookResult = ReturnType<
  typeof useGetCurrentUserDonorsListQuery
>;
export type GetCurrentUserDonorsListLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserDonorsListLazyQuery
>;
export type GetCurrentUserDonorsListQueryResult = Apollo.QueryResult<
  GetCurrentUserDonorsListQuery,
  GetCurrentUserDonorsListQueryVariables
>;
export const FindAllGroupDocument = gql`
  query findAllGroup($input: GroupListInput!) {
    findAllGroup(input: $input) {
      data {
        ...GroupListItem
        teams {
          ...GroupTeam
        }
      }
      meta {
        total
      }
    }
  }
  ${GroupListItemFragmentDoc}
  ${GroupTeamFragmentDoc}
`;

/**
 * __useFindAllGroupQuery__
 *
 * To run a query within a React component, call `useFindAllGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllGroupQuery,
    FindAllGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllGroupQuery, FindAllGroupQueryVariables>(
    FindAllGroupDocument,
    options
  );
}
export function useFindAllGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllGroupQuery,
    FindAllGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllGroupQuery, FindAllGroupQueryVariables>(
    FindAllGroupDocument,
    options
  );
}
export type FindAllGroupQueryHookResult = ReturnType<
  typeof useFindAllGroupQuery
>;
export type FindAllGroupLazyQueryHookResult = ReturnType<
  typeof useFindAllGroupLazyQuery
>;
export type FindAllGroupQueryResult = Apollo.QueryResult<
  FindAllGroupQuery,
  FindAllGroupQueryVariables
>;
export const FindGroupDocument = gql`
  query findGroup($input: FindGroupInput!) {
    findGroup(input: $input) {
      ...Group
      teams {
        ...Team
        donors {
          ...Donor
          user {
            ...Owner
            role {
              ...RoleWithoutPermissions
            }
            contacts {
              ...Contact
            }
          }
        }
      }
      user {
        ...Owner
        role {
          ...RoleWithoutPermissions
        }
        contacts {
          ...Contact
        }
      }
    }
  }
  ${GroupFragmentDoc}
  ${TeamFragmentDoc}
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${RoleWithoutPermissionsFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useFindGroupQuery__
 *
 * To run a query within a React component, call `useFindGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindGroupQuery(
  baseOptions: Apollo.QueryHookOptions<FindGroupQuery, FindGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindGroupQuery, FindGroupQueryVariables>(
    FindGroupDocument,
    options
  );
}
export function useFindGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindGroupQuery,
    FindGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindGroupQuery, FindGroupQueryVariables>(
    FindGroupDocument,
    options
  );
}
export type FindGroupQueryHookResult = ReturnType<typeof useFindGroupQuery>;
export type FindGroupLazyQueryHookResult = ReturnType<
  typeof useFindGroupLazyQuery
>;
export type FindGroupQueryResult = Apollo.QueryResult<
  FindGroupQuery,
  FindGroupQueryVariables
>;
export const GetDonorLivesSavedDocument = gql`
  query getDonorLivesSaved($input: GetLivesSavedByDonorInput!) {
    getDonorLivesSaved(input: $input) {
      lives
    }
  }
`;

/**
 * __useGetDonorLivesSavedQuery__
 *
 * To run a query within a React component, call `useGetDonorLivesSavedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorLivesSavedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorLivesSavedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonorLivesSavedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonorLivesSavedQuery,
    GetDonorLivesSavedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDonorLivesSavedQuery,
    GetDonorLivesSavedQueryVariables
  >(GetDonorLivesSavedDocument, options);
}
export function useGetDonorLivesSavedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonorLivesSavedQuery,
    GetDonorLivesSavedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDonorLivesSavedQuery,
    GetDonorLivesSavedQueryVariables
  >(GetDonorLivesSavedDocument, options);
}
export type GetDonorLivesSavedQueryHookResult = ReturnType<
  typeof useGetDonorLivesSavedQuery
>;
export type GetDonorLivesSavedLazyQueryHookResult = ReturnType<
  typeof useGetDonorLivesSavedLazyQuery
>;
export type GetDonorLivesSavedQueryResult = Apollo.QueryResult<
  GetDonorLivesSavedQuery,
  GetDonorLivesSavedQueryVariables
>;
export const GetChartsDataDocument = gql`
  query getChartsData($input: GetSpecificDonorDonationsListInput!) {
    getChartsData(input: $input) {
      data {
        ...Donation
      }
      meta {
        total
      }
    }
  }
  ${DonationFragmentDoc}
`;

/**
 * __useGetChartsDataQuery__
 *
 * To run a query within a React component, call `useGetChartsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartsDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChartsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChartsDataQuery,
    GetChartsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChartsDataQuery, GetChartsDataQueryVariables>(
    GetChartsDataDocument,
    options
  );
}
export function useGetChartsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChartsDataQuery,
    GetChartsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChartsDataQuery, GetChartsDataQueryVariables>(
    GetChartsDataDocument,
    options
  );
}
export type GetChartsDataQueryHookResult = ReturnType<
  typeof useGetChartsDataQuery
>;
export type GetChartsDataLazyQueryHookResult = ReturnType<
  typeof useGetChartsDataLazyQuery
>;
export type GetChartsDataQueryResult = Apollo.QueryResult<
  GetChartsDataQuery,
  GetChartsDataQueryVariables
>;
export const GetLabworkListDocument = gql`
  query getLabworkList($input: LabworkListInput!) {
    getLabworkList(input: $input) {
      data {
        ...Labwork
        donor {
          ...Donor
          user {
            ...Owner
          }
        }
      }
      meta {
        total
      }
    }
  }
  ${LabworkFragmentDoc}
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
`;

/**
 * __useGetLabworkListQuery__
 *
 * To run a query within a React component, call `useGetLabworkListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabworkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabworkListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLabworkListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabworkListQuery,
    GetLabworkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabworkListQuery, GetLabworkListQueryVariables>(
    GetLabworkListDocument,
    options
  );
}
export function useGetLabworkListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabworkListQuery,
    GetLabworkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLabworkListQuery, GetLabworkListQueryVariables>(
    GetLabworkListDocument,
    options
  );
}
export type GetLabworkListQueryHookResult = ReturnType<
  typeof useGetLabworkListQuery
>;
export type GetLabworkListLazyQueryHookResult = ReturnType<
  typeof useGetLabworkListLazyQuery
>;
export type GetLabworkListQueryResult = Apollo.QueryResult<
  GetLabworkListQuery,
  GetLabworkListQueryVariables
>;
export const GetLabworkDocument = gql`
  query getLabwork($input: GetLabworkInput!) {
    getLabwork(input: $input) {
      ...Labwork
      donor {
        ...Donor
        user {
          ...Owner
        }
      }
    }
  }
  ${LabworkFragmentDoc}
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
`;

/**
 * __useGetLabworkQuery__
 *
 * To run a query within a React component, call `useGetLabworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabworkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLabworkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabworkQuery,
    GetLabworkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLabworkQuery, GetLabworkQueryVariables>(
    GetLabworkDocument,
    options
  );
}
export function useGetLabworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabworkQuery,
    GetLabworkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLabworkQuery, GetLabworkQueryVariables>(
    GetLabworkDocument,
    options
  );
}
export type GetLabworkQueryHookResult = ReturnType<typeof useGetLabworkQuery>;
export type GetLabworkLazyQueryHookResult = ReturnType<
  typeof useGetLabworkLazyQuery
>;
export type GetLabworkQueryResult = Apollo.QueryResult<
  GetLabworkQuery,
  GetLabworkQueryVariables
>;
export const GetVitalsParameterListDocument = gql`
  query getVitalsParameterList {
    getVitalsParameterList {
      ...VitalsParameter
    }
  }
  ${VitalsParameterFragmentDoc}
`;

/**
 * __useGetVitalsParameterListQuery__
 *
 * To run a query within a React component, call `useGetVitalsParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVitalsParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVitalsParameterListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVitalsParameterListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVitalsParameterListQuery,
    GetVitalsParameterListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVitalsParameterListQuery,
    GetVitalsParameterListQueryVariables
  >(GetVitalsParameterListDocument, options);
}
export function useGetVitalsParameterListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVitalsParameterListQuery,
    GetVitalsParameterListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVitalsParameterListQuery,
    GetVitalsParameterListQueryVariables
  >(GetVitalsParameterListDocument, options);
}
export type GetVitalsParameterListQueryHookResult = ReturnType<
  typeof useGetVitalsParameterListQuery
>;
export type GetVitalsParameterListLazyQueryHookResult = ReturnType<
  typeof useGetVitalsParameterListLazyQuery
>;
export type GetVitalsParameterListQueryResult = Apollo.QueryResult<
  GetVitalsParameterListQuery,
  GetVitalsParameterListQueryVariables
>;
export const GetAppointmentsDonorsListDocument = gql`
  query getAppointmentsDonorsList {
    getAppointmentsDonorsList {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;

/**
 * __useGetAppointmentsDonorsListQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsDonorsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppointmentsDonorsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppointmentsDonorsListQuery,
    GetAppointmentsDonorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppointmentsDonorsListQuery,
    GetAppointmentsDonorsListQueryVariables
  >(GetAppointmentsDonorsListDocument, options);
}
export function useGetAppointmentsDonorsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentsDonorsListQuery,
    GetAppointmentsDonorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentsDonorsListQuery,
    GetAppointmentsDonorsListQueryVariables
  >(GetAppointmentsDonorsListDocument, options);
}
export type GetAppointmentsDonorsListQueryHookResult = ReturnType<
  typeof useGetAppointmentsDonorsListQuery
>;
export type GetAppointmentsDonorsListLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentsDonorsListLazyQuery
>;
export type GetAppointmentsDonorsListQueryResult = Apollo.QueryResult<
  GetAppointmentsDonorsListQuery,
  GetAppointmentsDonorsListQueryVariables
>;
export const GetDonorsListByIdsDocument = gql`
  query getDonorsListByIds($input: GetDonorsByIdsInput!) {
    getDonorsListByIds(input: $input) {
      ...Donor
      team {
        id
        title
      }
      user {
        ...Owner
        contacts {
          ...Contact
        }
      }
      reserveNotes {
        ...ReserveDonorNote
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
  ${ReserveDonorNoteFragmentDoc}
`;

/**
 * __useGetDonorsListByIdsQuery__
 *
 * To run a query within a React component, call `useGetDonorsListByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorsListByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorsListByIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonorsListByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonorsListByIdsQuery,
    GetDonorsListByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDonorsListByIdsQuery,
    GetDonorsListByIdsQueryVariables
  >(GetDonorsListByIdsDocument, options);
}
export function useGetDonorsListByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonorsListByIdsQuery,
    GetDonorsListByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDonorsListByIdsQuery,
    GetDonorsListByIdsQueryVariables
  >(GetDonorsListByIdsDocument, options);
}
export type GetDonorsListByIdsQueryHookResult = ReturnType<
  typeof useGetDonorsListByIdsQuery
>;
export type GetDonorsListByIdsLazyQueryHookResult = ReturnType<
  typeof useGetDonorsListByIdsLazyQuery
>;
export type GetDonorsListByIdsQueryResult = Apollo.QueryResult<
  GetDonorsListByIdsQuery,
  GetDonorsListByIdsQueryVariables
>;
export const FindTeamDocument = gql`
  query findTeam($input: FindTeamInput!) {
    findTeam(input: $input) {
      ...Team
      donors {
        ...Donor
      }
    }
  }
  ${TeamFragmentDoc}
  ${DonorFragmentDoc}
`;

/**
 * __useFindTeamQuery__
 *
 * To run a query within a React component, call `useFindTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTeamQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindTeamQuery(
  baseOptions: Apollo.QueryHookOptions<FindTeamQuery, FindTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindTeamQuery, FindTeamQueryVariables>(
    FindTeamDocument,
    options
  );
}
export function useFindTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindTeamQuery,
    FindTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindTeamQuery, FindTeamQueryVariables>(
    FindTeamDocument,
    options
  );
}
export type FindTeamQueryHookResult = ReturnType<typeof useFindTeamQuery>;
export type FindTeamLazyQueryHookResult = ReturnType<
  typeof useFindTeamLazyQuery
>;
export type FindTeamQueryResult = Apollo.QueryResult<
  FindTeamQuery,
  FindTeamQueryVariables
>;
export const GetLabworkAttachmentsDocument = gql`
  query getLabworkAttachments($input: GetLabworkAttachmentsListInput!) {
    getLabworkAttachments(input: $input) {
      ...LabworkAttachment
      file {
        ...File
      }
    }
  }
  ${LabworkAttachmentFragmentDoc}
  ${FileFragmentDoc}
`;

/**
 * __useGetLabworkAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetLabworkAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabworkAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabworkAttachmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLabworkAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabworkAttachmentsQuery,
    GetLabworkAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLabworkAttachmentsQuery,
    GetLabworkAttachmentsQueryVariables
  >(GetLabworkAttachmentsDocument, options);
}
export function useGetLabworkAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabworkAttachmentsQuery,
    GetLabworkAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLabworkAttachmentsQuery,
    GetLabworkAttachmentsQueryVariables
  >(GetLabworkAttachmentsDocument, options);
}
export type GetLabworkAttachmentsQueryHookResult = ReturnType<
  typeof useGetLabworkAttachmentsQuery
>;
export type GetLabworkAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetLabworkAttachmentsLazyQuery
>;
export type GetLabworkAttachmentsQueryResult = Apollo.QueryResult<
  GetLabworkAttachmentsQuery,
  GetLabworkAttachmentsQueryVariables
>;
export const HasDonorRelatedConnectionsDocument = gql`
  query hasDonorRelatedConnections($input: DeleteDonorInput!) {
    hasDonorRelatedConnections(input: $input)
  }
`;

/**
 * __useHasDonorRelatedConnectionsQuery__
 *
 * To run a query within a React component, call `useHasDonorRelatedConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasDonorRelatedConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasDonorRelatedConnectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHasDonorRelatedConnectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HasDonorRelatedConnectionsQuery,
    HasDonorRelatedConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HasDonorRelatedConnectionsQuery,
    HasDonorRelatedConnectionsQueryVariables
  >(HasDonorRelatedConnectionsDocument, options);
}
export function useHasDonorRelatedConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasDonorRelatedConnectionsQuery,
    HasDonorRelatedConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HasDonorRelatedConnectionsQuery,
    HasDonorRelatedConnectionsQueryVariables
  >(HasDonorRelatedConnectionsDocument, options);
}
export type HasDonorRelatedConnectionsQueryHookResult = ReturnType<
  typeof useHasDonorRelatedConnectionsQuery
>;
export type HasDonorRelatedConnectionsLazyQueryHookResult = ReturnType<
  typeof useHasDonorRelatedConnectionsLazyQuery
>;
export type HasDonorRelatedConnectionsQueryResult = Apollo.QueryResult<
  HasDonorRelatedConnectionsQuery,
  HasDonorRelatedConnectionsQueryVariables
>;
export const GetAppointmentsDatesForDonorDocument = gql`
  query getAppointmentsDatesForDonor($donorId: String!) {
    getAppointmentsDatesForDonor(donorId: $donorId) {
      id
      start
      end
    }
  }
`;

/**
 * __useGetAppointmentsDatesForDonorQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsDatesForDonorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsDatesForDonorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsDatesForDonorQuery({
 *   variables: {
 *      donorId: // value for 'donorId'
 *   },
 * });
 */
export function useGetAppointmentsDatesForDonorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentsDatesForDonorQuery,
    GetAppointmentsDatesForDonorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppointmentsDatesForDonorQuery,
    GetAppointmentsDatesForDonorQueryVariables
  >(GetAppointmentsDatesForDonorDocument, options);
}
export function useGetAppointmentsDatesForDonorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentsDatesForDonorQuery,
    GetAppointmentsDatesForDonorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentsDatesForDonorQuery,
    GetAppointmentsDatesForDonorQueryVariables
  >(GetAppointmentsDatesForDonorDocument, options);
}
export type GetAppointmentsDatesForDonorQueryHookResult = ReturnType<
  typeof useGetAppointmentsDatesForDonorQuery
>;
export type GetAppointmentsDatesForDonorLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentsDatesForDonorLazyQuery
>;
export type GetAppointmentsDatesForDonorQueryResult = Apollo.QueryResult<
  GetAppointmentsDatesForDonorQuery,
  GetAppointmentsDatesForDonorQueryVariables
>;
export const GetDonorNotesDocument = gql`
  query getDonorNotes($donorId: String!) {
    getDonorNotes(donorId: $donorId) {
      ...DonorNote
    }
  }
  ${DonorNoteFragmentDoc}
`;

/**
 * __useGetDonorNotesQuery__
 *
 * To run a query within a React component, call `useGetDonorNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorNotesQuery({
 *   variables: {
 *      donorId: // value for 'donorId'
 *   },
 * });
 */
export function useGetDonorNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonorNotesQuery,
    GetDonorNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDonorNotesQuery, GetDonorNotesQueryVariables>(
    GetDonorNotesDocument,
    options
  );
}
export function useGetDonorNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonorNotesQuery,
    GetDonorNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDonorNotesQuery, GetDonorNotesQueryVariables>(
    GetDonorNotesDocument,
    options
  );
}
export type GetDonorNotesQueryHookResult = ReturnType<
  typeof useGetDonorNotesQuery
>;
export type GetDonorNotesLazyQueryHookResult = ReturnType<
  typeof useGetDonorNotesLazyQuery
>;
export type GetDonorNotesQueryResult = Apollo.QueryResult<
  GetDonorNotesQuery,
  GetDonorNotesQueryVariables
>;
export const GetDonorPicturesDocument = gql`
  query getDonorPictures($input: GetDonorPicturesInput!) {
    getDonorPictures(input: $input) {
      ...Picture
    }
  }
  ${PictureFragmentDoc}
`;

/**
 * __useGetDonorPicturesQuery__
 *
 * To run a query within a React component, call `useGetDonorPicturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorPicturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorPicturesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonorPicturesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonorPicturesQuery,
    GetDonorPicturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDonorPicturesQuery, GetDonorPicturesQueryVariables>(
    GetDonorPicturesDocument,
    options
  );
}
export function useGetDonorPicturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonorPicturesQuery,
    GetDonorPicturesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDonorPicturesQuery,
    GetDonorPicturesQueryVariables
  >(GetDonorPicturesDocument, options);
}
export type GetDonorPicturesQueryHookResult = ReturnType<
  typeof useGetDonorPicturesQuery
>;
export type GetDonorPicturesLazyQueryHookResult = ReturnType<
  typeof useGetDonorPicturesLazyQuery
>;
export type GetDonorPicturesQueryResult = Apollo.QueryResult<
  GetDonorPicturesQuery,
  GetDonorPicturesQueryVariables
>;
export const CreateLocationDocument = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ...Location
    }
  }
  ${LocationFragmentDoc}
`;
export type CreateLocationMutationFn = Apollo.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >(CreateLocationDocument, options);
}
export type CreateLocationMutationHookResult = ReturnType<
  typeof useCreateLocationMutation
>;
export type CreateLocationMutationResult =
  Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export const UpdateLocationDocument = gql`
  mutation updateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      ...Location
    }
  }
  ${LocationFragmentDoc}
`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >(UpdateLocationDocument, options);
}
export type UpdateLocationMutationHookResult = ReturnType<
  typeof useUpdateLocationMutation
>;
export type UpdateLocationMutationResult =
  Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;
export const DeleteLocationDocument = gql`
  mutation deleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input)
  }
`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >(DeleteLocationDocument, options);
}
export type DeleteLocationMutationHookResult = ReturnType<
  typeof useDeleteLocationMutation
>;
export type DeleteLocationMutationResult =
  Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;
export const CreateRegionDocument = gql`
  mutation createRegion($input: CreateRegionInput!) {
    createRegion(input: $input) {
      ...Region
    }
  }
  ${RegionFragmentDoc}
`;
export type CreateRegionMutationFn = Apollo.MutationFunction<
  CreateRegionMutation,
  CreateRegionMutationVariables
>;

/**
 * __useCreateRegionMutation__
 *
 * To run a mutation, you first call `useCreateRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegionMutation, { data, loading, error }] = useCreateRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRegionMutation,
    CreateRegionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRegionMutation,
    CreateRegionMutationVariables
  >(CreateRegionDocument, options);
}
export type CreateRegionMutationHookResult = ReturnType<
  typeof useCreateRegionMutation
>;
export type CreateRegionMutationResult =
  Apollo.MutationResult<CreateRegionMutation>;
export type CreateRegionMutationOptions = Apollo.BaseMutationOptions<
  CreateRegionMutation,
  CreateRegionMutationVariables
>;
export const UpdateRegionDocument = gql`
  mutation updateRegion($input: UpdateRegionInput!) {
    updateRegion(input: $input) {
      ...Region
    }
  }
  ${RegionFragmentDoc}
`;
export type UpdateRegionMutationFn = Apollo.MutationFunction<
  UpdateRegionMutation,
  UpdateRegionMutationVariables
>;

/**
 * __useUpdateRegionMutation__
 *
 * To run a mutation, you first call `useUpdateRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegionMutation, { data, loading, error }] = useUpdateRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRegionMutation,
    UpdateRegionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRegionMutation,
    UpdateRegionMutationVariables
  >(UpdateRegionDocument, options);
}
export type UpdateRegionMutationHookResult = ReturnType<
  typeof useUpdateRegionMutation
>;
export type UpdateRegionMutationResult =
  Apollo.MutationResult<UpdateRegionMutation>;
export type UpdateRegionMutationOptions = Apollo.BaseMutationOptions<
  UpdateRegionMutation,
  UpdateRegionMutationVariables
>;
export const DeleteRegionDocument = gql`
  mutation deleteRegion($input: DeleteRegionInput!) {
    deleteRegion(input: $input)
  }
`;
export type DeleteRegionMutationFn = Apollo.MutationFunction<
  DeleteRegionMutation,
  DeleteRegionMutationVariables
>;

/**
 * __useDeleteRegionMutation__
 *
 * To run a mutation, you first call `useDeleteRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegionMutation, { data, loading, error }] = useDeleteRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRegionMutation,
    DeleteRegionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRegionMutation,
    DeleteRegionMutationVariables
  >(DeleteRegionDocument, options);
}
export type DeleteRegionMutationHookResult = ReturnType<
  typeof useDeleteRegionMutation
>;
export type DeleteRegionMutationResult =
  Apollo.MutationResult<DeleteRegionMutation>;
export type DeleteRegionMutationOptions = Apollo.BaseMutationOptions<
  DeleteRegionMutation,
  DeleteRegionMutationVariables
>;
export const GetLocationsDocument = gql`
  query getLocations($input: GetLocationsInput!) {
    getLocations(input: $input) {
      data {
        ...Location
      }
      meta {
        total
      }
    }
  }
  ${LocationFragmentDoc}
`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  );
}
export function useGetLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  );
}
export type GetLocationsQueryHookResult = ReturnType<
  typeof useGetLocationsQuery
>;
export type GetLocationsLazyQueryHookResult = ReturnType<
  typeof useGetLocationsLazyQuery
>;
export type GetLocationsQueryResult = Apollo.QueryResult<
  GetLocationsQuery,
  GetLocationsQueryVariables
>;
export const GetPublicLocationsDocument = gql`
  query getPublicLocations($input: GetPublicLocationsInput!) {
    getPublicLocations(input: $input) {
      data {
        ...Location
      }
      meta {
        total
      }
    }
  }
  ${LocationFragmentDoc}
`;

/**
 * __useGetPublicLocationsQuery__
 *
 * To run a query within a React component, call `useGetPublicLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPublicLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicLocationsQuery,
    GetPublicLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPublicLocationsQuery,
    GetPublicLocationsQueryVariables
  >(GetPublicLocationsDocument, options);
}
export function useGetPublicLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicLocationsQuery,
    GetPublicLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicLocationsQuery,
    GetPublicLocationsQueryVariables
  >(GetPublicLocationsDocument, options);
}
export type GetPublicLocationsQueryHookResult = ReturnType<
  typeof useGetPublicLocationsQuery
>;
export type GetPublicLocationsLazyQueryHookResult = ReturnType<
  typeof useGetPublicLocationsLazyQuery
>;
export type GetPublicLocationsQueryResult = Apollo.QueryResult<
  GetPublicLocationsQuery,
  GetPublicLocationsQueryVariables
>;
export const GetLocationWithAvailableDaycareDocument = gql`
  query getLocationWithAvailableDaycare {
    getLocationWithAvailableDaycare {
      ...Location
    }
  }
  ${LocationFragmentDoc}
`;

/**
 * __useGetLocationWithAvailableDaycareQuery__
 *
 * To run a query within a React component, call `useGetLocationWithAvailableDaycareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationWithAvailableDaycareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationWithAvailableDaycareQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationWithAvailableDaycareQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationWithAvailableDaycareQuery,
    GetLocationWithAvailableDaycareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLocationWithAvailableDaycareQuery,
    GetLocationWithAvailableDaycareQueryVariables
  >(GetLocationWithAvailableDaycareDocument, options);
}
export function useGetLocationWithAvailableDaycareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationWithAvailableDaycareQuery,
    GetLocationWithAvailableDaycareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLocationWithAvailableDaycareQuery,
    GetLocationWithAvailableDaycareQueryVariables
  >(GetLocationWithAvailableDaycareDocument, options);
}
export type GetLocationWithAvailableDaycareQueryHookResult = ReturnType<
  typeof useGetLocationWithAvailableDaycareQuery
>;
export type GetLocationWithAvailableDaycareLazyQueryHookResult = ReturnType<
  typeof useGetLocationWithAvailableDaycareLazyQuery
>;
export type GetLocationWithAvailableDaycareQueryResult = Apollo.QueryResult<
  GetLocationWithAvailableDaycareQuery,
  GetLocationWithAvailableDaycareQueryVariables
>;
export const GetRegionsDocument = gql`
  query getRegions {
    getRegions {
      ...Region
    }
  }
  ${RegionFragmentDoc}
`;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRegionsQuery,
    GetRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    options
  );
}
export function useGetRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegionsQuery,
    GetRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    options
  );
}
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<
  typeof useGetRegionsLazyQuery
>;
export type GetRegionsQueryResult = Apollo.QueryResult<
  GetRegionsQuery,
  GetRegionsQueryVariables
>;
export const UpdateNotificationConfigListDocument = gql`
  mutation updateNotificationConfigList(
    $input: UpdateNotificationConfigListInput!
  ) {
    updateNotificationConfigList(input: $input) {
      ...NotificationConfig
    }
  }
  ${NotificationConfigFragmentDoc}
`;
export type UpdateNotificationConfigListMutationFn = Apollo.MutationFunction<
  UpdateNotificationConfigListMutation,
  UpdateNotificationConfigListMutationVariables
>;

/**
 * __useUpdateNotificationConfigListMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationConfigListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationConfigListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationConfigListMutation, { data, loading, error }] = useUpdateNotificationConfigListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationConfigListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationConfigListMutation,
    UpdateNotificationConfigListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationConfigListMutation,
    UpdateNotificationConfigListMutationVariables
  >(UpdateNotificationConfigListDocument, options);
}
export type UpdateNotificationConfigListMutationHookResult = ReturnType<
  typeof useUpdateNotificationConfigListMutation
>;
export type UpdateNotificationConfigListMutationResult =
  Apollo.MutationResult<UpdateNotificationConfigListMutation>;
export type UpdateNotificationConfigListMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNotificationConfigListMutation,
    UpdateNotificationConfigListMutationVariables
  >;
export const GetNotificationConfigListDocument = gql`
  query getNotificationConfigList {
    getNotificationConfigList {
      ...NotificationConfig
    }
  }
  ${NotificationConfigFragmentDoc}
`;

/**
 * __useGetNotificationConfigListQuery__
 *
 * To run a query within a React component, call `useGetNotificationConfigListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationConfigListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationConfigListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationConfigListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationConfigListQuery,
    GetNotificationConfigListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotificationConfigListQuery,
    GetNotificationConfigListQueryVariables
  >(GetNotificationConfigListDocument, options);
}
export function useGetNotificationConfigListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationConfigListQuery,
    GetNotificationConfigListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationConfigListQuery,
    GetNotificationConfigListQueryVariables
  >(GetNotificationConfigListDocument, options);
}
export type GetNotificationConfigListQueryHookResult = ReturnType<
  typeof useGetNotificationConfigListQuery
>;
export type GetNotificationConfigListLazyQueryHookResult = ReturnType<
  typeof useGetNotificationConfigListLazyQuery
>;
export type GetNotificationConfigListQueryResult = Apollo.QueryResult<
  GetNotificationConfigListQuery,
  GetNotificationConfigListQueryVariables
>;
export const GetNotificationListDocument = gql`
  query getNotificationList($input: GetNotificationListInput!) {
    getNotificationsList(input: $input) {
      data {
        ...Notification
        payload {
          appointment {
            ...Appointment
            users {
              ...AppointmentUser
              contacts {
                ...Contact
              }
            }
            donor {
              ...AppointmentDonor
              user {
                ...AppointmentUser
                contacts {
                  ...Contact
                }
              }
            }
            group {
              ...AppointmentGroup
              user {
                ...AppointmentUser
                contacts {
                  ...Contact
                }
              }
            }
            team {
              ...AppointmentTeam
            }
          }
          request {
            donor {
              ...PartialDonor
              team {
                id
                title
              }
              user {
                ...Owner
                contacts {
                  ...Contact
                }
              }
            }
            id
            number
            updatedAt
          }
        }
        user {
          ...NotificationUser
          contacts {
            ...Contact
          }
          role {
            ...RoleWithoutPermissions
          }
        }
      }
      meta {
        total
      }
    }
  }
  ${NotificationFragmentDoc}
  ${AppointmentFragmentDoc}
  ${AppointmentUserFragmentDoc}
  ${ContactFragmentDoc}
  ${AppointmentDonorFragmentDoc}
  ${AppointmentGroupFragmentDoc}
  ${AppointmentTeamFragmentDoc}
  ${PartialDonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${NotificationUserFragmentDoc}
  ${RoleWithoutPermissionsFragmentDoc}
`;

/**
 * __useGetNotificationListQuery__
 *
 * To run a query within a React component, call `useGetNotificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNotificationListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationListQuery,
    GetNotificationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotificationListQuery,
    GetNotificationListQueryVariables
  >(GetNotificationListDocument, options);
}
export function useGetNotificationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationListQuery,
    GetNotificationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationListQuery,
    GetNotificationListQueryVariables
  >(GetNotificationListDocument, options);
}
export type GetNotificationListQueryHookResult = ReturnType<
  typeof useGetNotificationListQuery
>;
export type GetNotificationListLazyQueryHookResult = ReturnType<
  typeof useGetNotificationListLazyQuery
>;
export type GetNotificationListQueryResult = Apollo.QueryResult<
  GetNotificationListQuery,
  GetNotificationListQueryVariables
>;
export const NotificationAddedDocument = gql`
  subscription notificationAdded($input: NotificationAddedSubscriptionInput!) {
    notificationAdded(input: $input) {
      ...NotificationSubscription
    }
  }
  ${NotificationSubscriptionFragmentDoc}
`;

/**
 * __useNotificationAddedSubscription__
 *
 * To run a query within a React component, call `useNotificationAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationAddedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NotificationAddedSubscription,
    NotificationAddedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NotificationAddedSubscription,
    NotificationAddedSubscriptionVariables
  >(NotificationAddedDocument, options);
}
export type NotificationAddedSubscriptionHookResult = ReturnType<
  typeof useNotificationAddedSubscription
>;
export type NotificationAddedSubscriptionResult =
  Apollo.SubscriptionResult<NotificationAddedSubscription>;
export const GetOrdersListDocument = gql`
  query getOrdersList($input: GetOrdersListInput!) {
    getOrdersList(input: $input) {
      data {
        id
        date
        number
        status
        trackingCode
        paymentMethod
        total
        customerId
        items {
          ...orderItem
        }
        billing {
          ...orderBilling
        }
        shipping {
          ...orderShipping
        }
        shippingLines {
          ...shippingLine
        }
        feeLines {
          ...feeLine
        }
      }
      meta {
        total
      }
    }
  }
  ${OrderItemFragmentDoc}
  ${OrderBillingFragmentDoc}
  ${OrderShippingFragmentDoc}
  ${ShippingLineFragmentDoc}
  ${FeeLineFragmentDoc}
`;

/**
 * __useGetOrdersListQuery__
 *
 * To run a query within a React component, call `useGetOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrdersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrdersListQuery,
    GetOrdersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersListQuery, GetOrdersListQueryVariables>(
    GetOrdersListDocument,
    options
  );
}
export function useGetOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersListQuery,
    GetOrdersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersListQuery, GetOrdersListQueryVariables>(
    GetOrdersListDocument,
    options
  );
}
export type GetOrdersListQueryHookResult = ReturnType<
  typeof useGetOrdersListQuery
>;
export type GetOrdersListLazyQueryHookResult = ReturnType<
  typeof useGetOrdersListLazyQuery
>;
export type GetOrdersListQueryResult = Apollo.QueryResult<
  GetOrdersListQuery,
  GetOrdersListQueryVariables
>;
export const GetCustomerDocument = gql`
  query getCustomer($input: GetCustomerInput!) {
    getCustomer(input: $input) {
      email
      firstName
      id
      lastName
      username
      billing {
        ...orderBilling
      }
      shipping {
        ...orderShipping
      }
    }
  }
  ${OrderBillingFragmentDoc}
  ${OrderShippingFragmentDoc}
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCustomerLazyQuery
>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export const GetOrderDocument = gql`
  query getOrder($input: GetOrderInput!) {
    getOrder(input: $input) {
      id
      date
      number
      status
      trackingCode
      paymentMethod
      total
      customerId
      items {
        ...orderItem
      }
      billing {
        ...orderBilling
      }
      shipping {
        ...orderShipping
      }
      shippingLines {
        ...shippingLine
      }
      feeLines {
        ...feeLine
      }
    }
  }
  ${OrderItemFragmentDoc}
  ${OrderBillingFragmentDoc}
  ${OrderShippingFragmentDoc}
  ${ShippingLineFragmentDoc}
  ${FeeLineFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(
    GetOrderDocument,
    options
  );
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderQuery,
    GetOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(
    GetOrderDocument,
    options
  );
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<
  typeof useGetOrderLazyQuery
>;
export type GetOrderQueryResult = Apollo.QueryResult<
  GetOrderQuery,
  GetOrderQueryVariables
>;
export const UpdateContactDocument = gql`
  mutation updateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      streetAddress
      city
      state
      zipCode
      lastName
      firstName
      email
      id
      phoneNumber
    }
  }
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<
  typeof useUpdateContactMutation
>;
export type UpdateContactMutationResult =
  Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const CreateContactDocument = gql`
  mutation createContact($input: CreateContactInput!) {
    createContact(input: $input) {
      streetAddress
      city
      state
      zipCode
      lastName
      firstName
      email
      id
      phoneNumber
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>;
export type CreateContactMutationResult =
  Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const UpdateOwnerInfoDocument = gql`
  mutation updateOwnerInfo($input: UpdateOwnerInput!) {
    updateOwnerInfo(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;
export type UpdateOwnerInfoMutationFn = Apollo.MutationFunction<
  UpdateOwnerInfoMutation,
  UpdateOwnerInfoMutationVariables
>;

/**
 * __useUpdateOwnerInfoMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerInfoMutation, { data, loading, error }] = useUpdateOwnerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerInfoMutation,
    UpdateOwnerInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOwnerInfoMutation,
    UpdateOwnerInfoMutationVariables
  >(UpdateOwnerInfoDocument, options);
}
export type UpdateOwnerInfoMutationHookResult = ReturnType<
  typeof useUpdateOwnerInfoMutation
>;
export type UpdateOwnerInfoMutationResult =
  Apollo.MutationResult<UpdateOwnerInfoMutation>;
export type UpdateOwnerInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerInfoMutation,
  UpdateOwnerInfoMutationVariables
>;
export const UpdateOwnerEmailDocument = gql`
  mutation updateOwnerEmail($input: UpdateUserEmailInput!) {
    updateOwnerEmail(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;
export type UpdateOwnerEmailMutationFn = Apollo.MutationFunction<
  UpdateOwnerEmailMutation,
  UpdateOwnerEmailMutationVariables
>;

/**
 * __useUpdateOwnerEmailMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerEmailMutation, { data, loading, error }] = useUpdateOwnerEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerEmailMutation,
    UpdateOwnerEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOwnerEmailMutation,
    UpdateOwnerEmailMutationVariables
  >(UpdateOwnerEmailDocument, options);
}
export type UpdateOwnerEmailMutationHookResult = ReturnType<
  typeof useUpdateOwnerEmailMutation
>;
export type UpdateOwnerEmailMutationResult =
  Apollo.MutationResult<UpdateOwnerEmailMutation>;
export type UpdateOwnerEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerEmailMutation,
  UpdateOwnerEmailMutationVariables
>;
export const UpdateCurrentOwnerInfoDocument = gql`
  mutation updateCurrentOwnerInfo($input: UpdateOwnerInput!) {
    updateCurrentOwnerInfo(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;
export type UpdateCurrentOwnerInfoMutationFn = Apollo.MutationFunction<
  UpdateCurrentOwnerInfoMutation,
  UpdateCurrentOwnerInfoMutationVariables
>;

/**
 * __useUpdateCurrentOwnerInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentOwnerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentOwnerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentOwnerInfoMutation, { data, loading, error }] = useUpdateCurrentOwnerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentOwnerInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentOwnerInfoMutation,
    UpdateCurrentOwnerInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentOwnerInfoMutation,
    UpdateCurrentOwnerInfoMutationVariables
  >(UpdateCurrentOwnerInfoDocument, options);
}
export type UpdateCurrentOwnerInfoMutationHookResult = ReturnType<
  typeof useUpdateCurrentOwnerInfoMutation
>;
export type UpdateCurrentOwnerInfoMutationResult =
  Apollo.MutationResult<UpdateCurrentOwnerInfoMutation>;
export type UpdateCurrentOwnerInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentOwnerInfoMutation,
  UpdateCurrentOwnerInfoMutationVariables
>;
export const UpdateCurrentOwnerEmailDocument = gql`
  mutation updateCurrentOwnerEmail($input: UpdateUserEmailInput!) {
    updateCurrentOwnerEmail(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;
export type UpdateCurrentOwnerEmailMutationFn = Apollo.MutationFunction<
  UpdateCurrentOwnerEmailMutation,
  UpdateCurrentOwnerEmailMutationVariables
>;

/**
 * __useUpdateCurrentOwnerEmailMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentOwnerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentOwnerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentOwnerEmailMutation, { data, loading, error }] = useUpdateCurrentOwnerEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentOwnerEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentOwnerEmailMutation,
    UpdateCurrentOwnerEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentOwnerEmailMutation,
    UpdateCurrentOwnerEmailMutationVariables
  >(UpdateCurrentOwnerEmailDocument, options);
}
export type UpdateCurrentOwnerEmailMutationHookResult = ReturnType<
  typeof useUpdateCurrentOwnerEmailMutation
>;
export type UpdateCurrentOwnerEmailMutationResult =
  Apollo.MutationResult<UpdateCurrentOwnerEmailMutation>;
export type UpdateCurrentOwnerEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentOwnerEmailMutation,
  UpdateCurrentOwnerEmailMutationVariables
>;
export const RemoveCurrentOwnerDocument = gql`
  mutation removeCurrentOwner {
    removeCurrentOwner
  }
`;
export type RemoveCurrentOwnerMutationFn = Apollo.MutationFunction<
  RemoveCurrentOwnerMutation,
  RemoveCurrentOwnerMutationVariables
>;

/**
 * __useRemoveCurrentOwnerMutation__
 *
 * To run a mutation, you first call `useRemoveCurrentOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCurrentOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCurrentOwnerMutation, { data, loading, error }] = useRemoveCurrentOwnerMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveCurrentOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCurrentOwnerMutation,
    RemoveCurrentOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCurrentOwnerMutation,
    RemoveCurrentOwnerMutationVariables
  >(RemoveCurrentOwnerDocument, options);
}
export type RemoveCurrentOwnerMutationHookResult = ReturnType<
  typeof useRemoveCurrentOwnerMutation
>;
export type RemoveCurrentOwnerMutationResult =
  Apollo.MutationResult<RemoveCurrentOwnerMutation>;
export type RemoveCurrentOwnerMutationOptions = Apollo.BaseMutationOptions<
  RemoveCurrentOwnerMutation,
  RemoveCurrentOwnerMutationVariables
>;
export const CancelDeletingCurrentOwnerDocument = gql`
  mutation cancelDeletingCurrentOwner {
    cancelDeletingCurrentOwner
  }
`;
export type CancelDeletingCurrentOwnerMutationFn = Apollo.MutationFunction<
  CancelDeletingCurrentOwnerMutation,
  CancelDeletingCurrentOwnerMutationVariables
>;

/**
 * __useCancelDeletingCurrentOwnerMutation__
 *
 * To run a mutation, you first call `useCancelDeletingCurrentOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDeletingCurrentOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDeletingCurrentOwnerMutation, { data, loading, error }] = useCancelDeletingCurrentOwnerMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelDeletingCurrentOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelDeletingCurrentOwnerMutation,
    CancelDeletingCurrentOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelDeletingCurrentOwnerMutation,
    CancelDeletingCurrentOwnerMutationVariables
  >(CancelDeletingCurrentOwnerDocument, options);
}
export type CancelDeletingCurrentOwnerMutationHookResult = ReturnType<
  typeof useCancelDeletingCurrentOwnerMutation
>;
export type CancelDeletingCurrentOwnerMutationResult =
  Apollo.MutationResult<CancelDeletingCurrentOwnerMutation>;
export type CancelDeletingCurrentOwnerMutationOptions =
  Apollo.BaseMutationOptions<
    CancelDeletingCurrentOwnerMutation,
    CancelDeletingCurrentOwnerMutationVariables
  >;
export const DetachUserFileDocument = gql`
  mutation detachUserFile($input: DetachUserAttachmentInput!) {
    detachUserFile(input: $input)
  }
`;
export type DetachUserFileMutationFn = Apollo.MutationFunction<
  DetachUserFileMutation,
  DetachUserFileMutationVariables
>;

/**
 * __useDetachUserFileMutation__
 *
 * To run a mutation, you first call `useDetachUserFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachUserFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachUserFileMutation, { data, loading, error }] = useDetachUserFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachUserFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachUserFileMutation,
    DetachUserFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachUserFileMutation,
    DetachUserFileMutationVariables
  >(DetachUserFileDocument, options);
}
export type DetachUserFileMutationHookResult = ReturnType<
  typeof useDetachUserFileMutation
>;
export type DetachUserFileMutationResult =
  Apollo.MutationResult<DetachUserFileMutation>;
export type DetachUserFileMutationOptions = Apollo.BaseMutationOptions<
  DetachUserFileMutation,
  DetachUserFileMutationVariables
>;
export const GetUserContactsDocument = gql`
  query getUserContacts {
    getUserContacts {
      streetAddress
      city
      state
      zipCode
      email
      firstName
      lastName
      phoneNumber
      id
    }
  }
`;

/**
 * __useGetUserContactsQuery__
 *
 * To run a query within a React component, call `useGetUserContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserContactsQuery,
    GetUserContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserContactsQuery, GetUserContactsQueryVariables>(
    GetUserContactsDocument,
    options
  );
}
export function useGetUserContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserContactsQuery,
    GetUserContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserContactsQuery,
    GetUserContactsQueryVariables
  >(GetUserContactsDocument, options);
}
export type GetUserContactsQueryHookResult = ReturnType<
  typeof useGetUserContactsQuery
>;
export type GetUserContactsLazyQueryHookResult = ReturnType<
  typeof useGetUserContactsLazyQuery
>;
export type GetUserContactsQueryResult = Apollo.QueryResult<
  GetUserContactsQuery,
  GetUserContactsQueryVariables
>;
export const GetOwnerDocument = gql`
  query getOwner($input: GetUserInput!) {
    getOwner(input: $input) {
      ...Owner
      contacts {
        ...Contact
      }
    }
  }
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetOwnerQuery__
 *
 * To run a query within a React component, call `useGetOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<GetOwnerQuery, GetOwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerQuery, GetOwnerQueryVariables>(
    GetOwnerDocument,
    options
  );
}
export function useGetOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnerQuery,
    GetOwnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnerQuery, GetOwnerQueryVariables>(
    GetOwnerDocument,
    options
  );
}
export type GetOwnerQueryHookResult = ReturnType<typeof useGetOwnerQuery>;
export type GetOwnerLazyQueryHookResult = ReturnType<
  typeof useGetOwnerLazyQuery
>;
export type GetOwnerQueryResult = Apollo.QueryResult<
  GetOwnerQuery,
  GetOwnerQueryVariables
>;
export const GetUserDonorsListDocument = gql`
  query getUserDonorsList($input: UserDonorListInput!) {
    getUserDonorsList(input: $input) {
      data {
        ...Donor
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
      }
      meta {
        total
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetUserDonorsListQuery__
 *
 * To run a query within a React component, call `useGetUserDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDonorsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserDonorsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserDonorsListQuery,
    GetUserDonorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserDonorsListQuery,
    GetUserDonorsListQueryVariables
  >(GetUserDonorsListDocument, options);
}
export function useGetUserDonorsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDonorsListQuery,
    GetUserDonorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserDonorsListQuery,
    GetUserDonorsListQueryVariables
  >(GetUserDonorsListDocument, options);
}
export type GetUserDonorsListQueryHookResult = ReturnType<
  typeof useGetUserDonorsListQuery
>;
export type GetUserDonorsListLazyQueryHookResult = ReturnType<
  typeof useGetUserDonorsListLazyQuery
>;
export type GetUserDonorsListQueryResult = Apollo.QueryResult<
  GetUserDonorsListQuery,
  GetUserDonorsListQueryVariables
>;
export const GetUserAttachmentsDocument = gql`
  query getUserAttachments($input: GetUserAttachmentsListInput!) {
    getUserAttachments(input: $input) {
      ...UserAttachment
      file {
        ...File
      }
    }
  }
  ${UserAttachmentFragmentDoc}
  ${FileFragmentDoc}
`;

/**
 * __useGetUserAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetUserAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAttachmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAttachmentsQuery,
    GetUserAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserAttachmentsQuery,
    GetUserAttachmentsQueryVariables
  >(GetUserAttachmentsDocument, options);
}
export function useGetUserAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAttachmentsQuery,
    GetUserAttachmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAttachmentsQuery,
    GetUserAttachmentsQueryVariables
  >(GetUserAttachmentsDocument, options);
}
export type GetUserAttachmentsQueryHookResult = ReturnType<
  typeof useGetUserAttachmentsQuery
>;
export type GetUserAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetUserAttachmentsLazyQuery
>;
export type GetUserAttachmentsQueryResult = Apollo.QueryResult<
  GetUserAttachmentsQuery,
  GetUserAttachmentsQueryVariables
>;
export const CreateProductDocument = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      ...Product
      discardedProduct {
        ...DiscardedProductData
        processor {
          ...ProductProcessor
        }
      }
      donation {
        ...Donation
      }
    }
  }
  ${ProductFragmentDoc}
  ${DiscardedProductDataFragmentDoc}
  ${ProductProcessorFragmentDoc}
  ${DonationFragmentDoc}
`;
export type CreateProductMutationFn = Apollo.MutationFunction<
  CreateProductMutation,
  CreateProductMutationVariables
>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductMutation,
    CreateProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProductMutation,
    CreateProductMutationVariables
  >(CreateProductDocument, options);
}
export type CreateProductMutationHookResult = ReturnType<
  typeof useCreateProductMutation
>;
export type CreateProductMutationResult =
  Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>;
export const CreateProductsDocument = gql`
  mutation createProducts($input: CreateProductsInput!) {
    createProducts(input: $input) {
      ...Product
      discardedProduct {
        ...DiscardedProductData
        processor {
          ...ProductProcessor
        }
      }
      donation {
        ...Donation
      }
    }
  }
  ${ProductFragmentDoc}
  ${DiscardedProductDataFragmentDoc}
  ${ProductProcessorFragmentDoc}
  ${DonationFragmentDoc}
`;
export type CreateProductsMutationFn = Apollo.MutationFunction<
  CreateProductsMutation,
  CreateProductsMutationVariables
>;

/**
 * __useCreateProductsMutation__
 *
 * To run a mutation, you first call `useCreateProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductsMutation, { data, loading, error }] = useCreateProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductsMutation,
    CreateProductsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProductsMutation,
    CreateProductsMutationVariables
  >(CreateProductsDocument, options);
}
export type CreateProductsMutationHookResult = ReturnType<
  typeof useCreateProductsMutation
>;
export type CreateProductsMutationResult =
  Apollo.MutationResult<CreateProductsMutation>;
export type CreateProductsMutationOptions = Apollo.BaseMutationOptions<
  CreateProductsMutation,
  CreateProductsMutationVariables
>;
export const MakeProductAvailableDocument = gql`
  mutation makeProductAvailable($input: MakeProductAvailableInput!) {
    makeProductAvailable(input: $input) {
      ...Product
      donation {
        ...Donation
      }
    }
  }
  ${ProductFragmentDoc}
  ${DonationFragmentDoc}
`;
export type MakeProductAvailableMutationFn = Apollo.MutationFunction<
  MakeProductAvailableMutation,
  MakeProductAvailableMutationVariables
>;

/**
 * __useMakeProductAvailableMutation__
 *
 * To run a mutation, you first call `useMakeProductAvailableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeProductAvailableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeProductAvailableMutation, { data, loading, error }] = useMakeProductAvailableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeProductAvailableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeProductAvailableMutation,
    MakeProductAvailableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeProductAvailableMutation,
    MakeProductAvailableMutationVariables
  >(MakeProductAvailableDocument, options);
}
export type MakeProductAvailableMutationHookResult = ReturnType<
  typeof useMakeProductAvailableMutation
>;
export type MakeProductAvailableMutationResult =
  Apollo.MutationResult<MakeProductAvailableMutation>;
export type MakeProductAvailableMutationOptions = Apollo.BaseMutationOptions<
  MakeProductAvailableMutation,
  MakeProductAvailableMutationVariables
>;
export const UpdateProductStatusDocument = gql`
  mutation updateProductStatus($input: UpdateProductStatusInput!) {
    updateProductStatus(input: $input) {
      ...Product
      discardedProduct {
        ...DiscardedProductData
        processor {
          ...ProductProcessor
        }
      }
      donation {
        ...Donation
      }
    }
  }
  ${ProductFragmentDoc}
  ${DiscardedProductDataFragmentDoc}
  ${ProductProcessorFragmentDoc}
  ${DonationFragmentDoc}
`;
export type UpdateProductStatusMutationFn = Apollo.MutationFunction<
  UpdateProductStatusMutation,
  UpdateProductStatusMutationVariables
>;

/**
 * __useUpdateProductStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductStatusMutation, { data, loading, error }] = useUpdateProductStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductStatusMutation,
    UpdateProductStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProductStatusMutation,
    UpdateProductStatusMutationVariables
  >(UpdateProductStatusDocument, options);
}
export type UpdateProductStatusMutationHookResult = ReturnType<
  typeof useUpdateProductStatusMutation
>;
export type UpdateProductStatusMutationResult =
  Apollo.MutationResult<UpdateProductStatusMutation>;
export type UpdateProductStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductStatusMutation,
  UpdateProductStatusMutationVariables
>;
export const UpdateProductDocument = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ...Product
      discardedProduct {
        ...DiscardedProductData
        processor {
          ...ProductProcessor
        }
      }
      donation {
        ...Donation
      }
    }
  }
  ${ProductFragmentDoc}
  ${DiscardedProductDataFragmentDoc}
  ${ProductProcessorFragmentDoc}
  ${DonationFragmentDoc}
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >(UpdateProductDocument, options);
}
export type UpdateProductMutationHookResult = ReturnType<
  typeof useUpdateProductMutation
>;
export type UpdateProductMutationResult =
  Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
export const DeleteProductDocument = gql`
  mutation deleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input)
  }
`;
export type DeleteProductMutationFn = Apollo.MutationFunction<
  DeleteProductMutation,
  DeleteProductMutationVariables
>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProductMutation,
    DeleteProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProductMutation,
    DeleteProductMutationVariables
  >(DeleteProductDocument, options);
}
export type DeleteProductMutationHookResult = ReturnType<
  typeof useDeleteProductMutation
>;
export type DeleteProductMutationResult =
  Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductMutation,
  DeleteProductMutationVariables
>;
export const GetWoocommerceCategoriesDocument = gql`
  query getWoocommerceCategories(
    $input: GetWoocommerceProductCategoriesInput!
  ) {
    getWoocommerceCategories(input: $input) {
      id
      count
      name
      slug
      parent
    }
  }
`;

/**
 * __useGetWoocommerceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetWoocommerceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWoocommerceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWoocommerceCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetWoocommerceCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWoocommerceCategoriesQuery,
    GetWoocommerceCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWoocommerceCategoriesQuery,
    GetWoocommerceCategoriesQueryVariables
  >(GetWoocommerceCategoriesDocument, options);
}
export function useGetWoocommerceCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWoocommerceCategoriesQuery,
    GetWoocommerceCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWoocommerceCategoriesQuery,
    GetWoocommerceCategoriesQueryVariables
  >(GetWoocommerceCategoriesDocument, options);
}
export type GetWoocommerceCategoriesQueryHookResult = ReturnType<
  typeof useGetWoocommerceCategoriesQuery
>;
export type GetWoocommerceCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetWoocommerceCategoriesLazyQuery
>;
export type GetWoocommerceCategoriesQueryResult = Apollo.QueryResult<
  GetWoocommerceCategoriesQuery,
  GetWoocommerceCategoriesQueryVariables
>;
export const GetWoocommerceProductsDocument = gql`
  query getWoocommerceProducts($input: GetWoocommerceProductsInput!) {
    getWoocommerceProducts(input: $input) {
      data {
        categories {
          id
          name
          slug
        }
        date_created
        date_modified
        description
        id
        name
        price
        slug
        status
        stock_status
        stock_quantity
        type
      }
      meta {
        total
      }
    }
  }
`;

/**
 * __useGetWoocommerceProductsQuery__
 *
 * To run a query within a React component, call `useGetWoocommerceProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWoocommerceProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWoocommerceProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetWoocommerceProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWoocommerceProductsQuery,
    GetWoocommerceProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWoocommerceProductsQuery,
    GetWoocommerceProductsQueryVariables
  >(GetWoocommerceProductsDocument, options);
}
export function useGetWoocommerceProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWoocommerceProductsQuery,
    GetWoocommerceProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWoocommerceProductsQuery,
    GetWoocommerceProductsQueryVariables
  >(GetWoocommerceProductsDocument, options);
}
export type GetWoocommerceProductsQueryHookResult = ReturnType<
  typeof useGetWoocommerceProductsQuery
>;
export type GetWoocommerceProductsLazyQueryHookResult = ReturnType<
  typeof useGetWoocommerceProductsLazyQuery
>;
export type GetWoocommerceProductsQueryResult = Apollo.QueryResult<
  GetWoocommerceProductsQuery,
  GetWoocommerceProductsQueryVariables
>;
export const GetProductsDocument = gql`
  query getProducts($input: ProductListInput!) {
    getProducts(input: $input) {
      data {
        ...Product
        discardedProduct {
          ...DiscardedProductData
          processor {
            ...ProductProcessor
          }
        }
        donation {
          id
          collectionSet
          capillaryRefillTime
          approvedDonationsMl
          jugularVein
          maxLimit
          mucousMembrane
          packedCellVolume
          pulse
          respiration
          shouldDonate
          temperature
          totalSolids
          unit
          actual
          weatherTemperature
          weatherTypes
          weight
          examinationNotes
          preferences
          createdAt
          comment
          shortId
          donationDate
          ineligible
          ineligibleReason
          donor {
            ...DonationDonor
            team {
              id
              title
            }
            user {
              ...Holder
            }
            pictures {
              ...Picture
            }
          }
          additionalInfo {
            ...AdditionalDonationInfo
          }
        }
      }
      meta {
        total
      }
    }
  }
  ${ProductFragmentDoc}
  ${DiscardedProductDataFragmentDoc}
  ${ProductProcessorFragmentDoc}
  ${DonationDonorFragmentDoc}
  ${HolderFragmentDoc}
  ${PictureFragmentDoc}
  ${AdditionalDonationInfoFragmentDoc}
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options
  );
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<
  typeof useGetProductsLazyQuery
>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const GetProductDocument = gql`
  query getProduct($input: GetProductInput!) {
    getProduct(input: $input) {
      ...Product
      donation {
        ...Donation
      }
    }
  }
  ${ProductFragmentDoc}
  ${DonationFragmentDoc}
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options
  );
}
export function useGetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options
  );
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<
  typeof useGetProductLazyQuery
>;
export type GetProductQueryResult = Apollo.QueryResult<
  GetProductQuery,
  GetProductQueryVariables
>;
export const GetProductsCountDocument = gql`
  query getProductsCount($input: GetProductsCountInput!) {
    getProductsCount(input: $input)
  }
`;

/**
 * __useGetProductsCountQuery__
 *
 * To run a query within a React component, call `useGetProductsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsCountQuery,
    GetProductsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsCountQuery, GetProductsCountQueryVariables>(
    GetProductsCountDocument,
    options
  );
}
export function useGetProductsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsCountQuery,
    GetProductsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsCountQuery,
    GetProductsCountQueryVariables
  >(GetProductsCountDocument, options);
}
export type GetProductsCountQueryHookResult = ReturnType<
  typeof useGetProductsCountQuery
>;
export type GetProductsCountLazyQueryHookResult = ReturnType<
  typeof useGetProductsCountLazyQuery
>;
export type GetProductsCountQueryResult = Apollo.QueryResult<
  GetProductsCountQuery,
  GetProductsCountQueryVariables
>;
export const GetWoocommerceTopSellersDocument = gql`
  query getWoocommerceTopSellers($input: GetWoocommerceTopSellersInput!) {
    getWoocommerceTopSellers(input: $input) {
      name
      product_id
      quantity
    }
  }
`;

/**
 * __useGetWoocommerceTopSellersQuery__
 *
 * To run a query within a React component, call `useGetWoocommerceTopSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWoocommerceTopSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWoocommerceTopSellersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetWoocommerceTopSellersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWoocommerceTopSellersQuery,
    GetWoocommerceTopSellersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWoocommerceTopSellersQuery,
    GetWoocommerceTopSellersQueryVariables
  >(GetWoocommerceTopSellersDocument, options);
}
export function useGetWoocommerceTopSellersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWoocommerceTopSellersQuery,
    GetWoocommerceTopSellersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWoocommerceTopSellersQuery,
    GetWoocommerceTopSellersQueryVariables
  >(GetWoocommerceTopSellersDocument, options);
}
export type GetWoocommerceTopSellersQueryHookResult = ReturnType<
  typeof useGetWoocommerceTopSellersQuery
>;
export type GetWoocommerceTopSellersLazyQueryHookResult = ReturnType<
  typeof useGetWoocommerceTopSellersLazyQuery
>;
export type GetWoocommerceTopSellersQueryResult = Apollo.QueryResult<
  GetWoocommerceTopSellersQuery,
  GetWoocommerceTopSellersQueryVariables
>;
export const GetDonorsReportDocument = gql`
  query getDonorsReport($input: GetDonorsReportInput!) {
    getDonorsReport(input: $input) {
      ...DonorReport
      user {
        ...DonorOwnerReport
      }
      notes {
        ...DonorNote
      }
      totalCollected
    }
  }
  ${DonorReportFragmentDoc}
  ${DonorOwnerReportFragmentDoc}
  ${DonorNoteFragmentDoc}
`;

/**
 * __useGetDonorsReportQuery__
 *
 * To run a query within a React component, call `useGetDonorsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorsReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonorsReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonorsReportQuery,
    GetDonorsReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDonorsReportQuery, GetDonorsReportQueryVariables>(
    GetDonorsReportDocument,
    options
  );
}
export function useGetDonorsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonorsReportQuery,
    GetDonorsReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDonorsReportQuery,
    GetDonorsReportQueryVariables
  >(GetDonorsReportDocument, options);
}
export type GetDonorsReportQueryHookResult = ReturnType<
  typeof useGetDonorsReportQuery
>;
export type GetDonorsReportLazyQueryHookResult = ReturnType<
  typeof useGetDonorsReportLazyQuery
>;
export type GetDonorsReportQueryResult = Apollo.QueryResult<
  GetDonorsReportQuery,
  GetDonorsReportQueryVariables
>;
export const GetDonationReportDocument = gql`
  query getDonationReport($input: GetDonationReportsInput!) {
    getDonationReports(input: $input) {
      ...DonationReport
      donor {
        ...DonationDonorReport
        user {
          ...DonationOwnerReport
        }
      }
      additionalInfo {
        ...AdditionalDonationInfoReport
        primaryHolder {
          ...HolderReportData
        }
        poker {
          ...HolderReportData
        }
      }
      products {
        ...ProductReport
      }
    }
  }
  ${DonationReportFragmentDoc}
  ${DonationDonorReportFragmentDoc}
  ${DonationOwnerReportFragmentDoc}
  ${AdditionalDonationInfoReportFragmentDoc}
  ${HolderReportDataFragmentDoc}
  ${ProductReportFragmentDoc}
`;

/**
 * __useGetDonationReportQuery__
 *
 * To run a query within a React component, call `useGetDonationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDonationReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDonationReportQuery,
    GetDonationReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDonationReportQuery,
    GetDonationReportQueryVariables
  >(GetDonationReportDocument, options);
}
export function useGetDonationReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDonationReportQuery,
    GetDonationReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDonationReportQuery,
    GetDonationReportQueryVariables
  >(GetDonationReportDocument, options);
}
export type GetDonationReportQueryHookResult = ReturnType<
  typeof useGetDonationReportQuery
>;
export type GetDonationReportLazyQueryHookResult = ReturnType<
  typeof useGetDonationReportLazyQuery
>;
export type GetDonationReportQueryResult = Apollo.QueryResult<
  GetDonationReportQuery,
  GetDonationReportQueryVariables
>;
export const GetProductReportsDocument = gql`
  query getProductReports($input: GetProductReportsInput!) {
    getProductReports(input: $input) {
      ...ProductReport
      donation {
        id
      }
    }
  }
  ${ProductReportFragmentDoc}
`;

/**
 * __useGetProductReportsQuery__
 *
 * To run a query within a React component, call `useGetProductReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductReportsQuery,
    GetProductReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductReportsQuery,
    GetProductReportsQueryVariables
  >(GetProductReportsDocument, options);
}
export function useGetProductReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductReportsQuery,
    GetProductReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductReportsQuery,
    GetProductReportsQueryVariables
  >(GetProductReportsDocument, options);
}
export type GetProductReportsQueryHookResult = ReturnType<
  typeof useGetProductReportsQuery
>;
export type GetProductReportsLazyQueryHookResult = ReturnType<
  typeof useGetProductReportsLazyQuery
>;
export type GetProductReportsQueryResult = Apollo.QueryResult<
  GetProductReportsQuery,
  GetProductReportsQueryVariables
>;
export const GetOwnerReportsDocument = gql`
  query getOwnerReports($input: GetOwnersReportsInput!) {
    getOwnerReports(input: $input) {
      ...OwnerReport
      donors {
        name
        status
      }
    }
  }
  ${OwnerReportFragmentDoc}
`;

/**
 * __useGetOwnerReportsQuery__
 *
 * To run a query within a React component, call `useGetOwnerReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOwnerReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOwnerReportsQuery,
    GetOwnerReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnerReportsQuery, GetOwnerReportsQueryVariables>(
    GetOwnerReportsDocument,
    options
  );
}
export function useGetOwnerReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnerReportsQuery,
    GetOwnerReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOwnerReportsQuery,
    GetOwnerReportsQueryVariables
  >(GetOwnerReportsDocument, options);
}
export type GetOwnerReportsQueryHookResult = ReturnType<
  typeof useGetOwnerReportsQuery
>;
export type GetOwnerReportsLazyQueryHookResult = ReturnType<
  typeof useGetOwnerReportsLazyQuery
>;
export type GetOwnerReportsQueryResult = Apollo.QueryResult<
  GetOwnerReportsQuery,
  GetOwnerReportsQueryVariables
>;
export const ProcessRequestDocument = gql`
  mutation processRequest($input: UpdateRequestInput!) {
    processRequest(input: $input)
  }
`;
export type ProcessRequestMutationFn = Apollo.MutationFunction<
  ProcessRequestMutation,
  ProcessRequestMutationVariables
>;

/**
 * __useProcessRequestMutation__
 *
 * To run a mutation, you first call `useProcessRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processRequestMutation, { data, loading, error }] = useProcessRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessRequestMutation,
    ProcessRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProcessRequestMutation,
    ProcessRequestMutationVariables
  >(ProcessRequestDocument, options);
}
export type ProcessRequestMutationHookResult = ReturnType<
  typeof useProcessRequestMutation
>;
export type ProcessRequestMutationResult =
  Apollo.MutationResult<ProcessRequestMutation>;
export type ProcessRequestMutationOptions = Apollo.BaseMutationOptions<
  ProcessRequestMutation,
  ProcessRequestMutationVariables
>;
export const RemoveRequestDocument = gql`
  mutation removeRequest($input: RemoveRequestInput!) {
    removeRequest(input: $input)
  }
`;
export type RemoveRequestMutationFn = Apollo.MutationFunction<
  RemoveRequestMutation,
  RemoveRequestMutationVariables
>;

/**
 * __useRemoveRequestMutation__
 *
 * To run a mutation, you first call `useRemoveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRequestMutation, { data, loading, error }] = useRemoveRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRequestMutation,
    RemoveRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRequestMutation,
    RemoveRequestMutationVariables
  >(RemoveRequestDocument, options);
}
export type RemoveRequestMutationHookResult = ReturnType<
  typeof useRemoveRequestMutation
>;
export type RemoveRequestMutationResult =
  Apollo.MutationResult<RemoveRequestMutation>;
export type RemoveRequestMutationOptions = Apollo.BaseMutationOptions<
  RemoveRequestMutation,
  RemoveRequestMutationVariables
>;
export const GetRequestListDocument = gql`
  query getRequestList($input: RequestsListInput!) {
    getRequestList(input: $input) {
      data {
        donor {
          ...Donor
          team {
            id
            title
          }
          user {
            ...Owner
            contacts {
              ...Contact
            }
          }
        }
        id
        number
        updatedAt
      }
      meta {
        total
      }
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetRequestListQuery__
 *
 * To run a query within a React component, call `useGetRequestListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRequestListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRequestListQuery,
    GetRequestListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestListQuery, GetRequestListQueryVariables>(
    GetRequestListDocument,
    options
  );
}
export function useGetRequestListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequestListQuery,
    GetRequestListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestListQuery, GetRequestListQueryVariables>(
    GetRequestListDocument,
    options
  );
}
export type GetRequestListQueryHookResult = ReturnType<
  typeof useGetRequestListQuery
>;
export type GetRequestListLazyQueryHookResult = ReturnType<
  typeof useGetRequestListLazyQuery
>;
export type GetRequestListQueryResult = Apollo.QueryResult<
  GetRequestListQuery,
  GetRequestListQueryVariables
>;
export const GetRequestDocument = gql`
  query getRequest($input: GetRequestInput!) {
    getRequest(input: $input) {
      donor {
        ...Donor
        team {
          id
          title
        }
        user {
          ...Owner
          contacts {
            ...Contact
          }
        }
      }
      id
      number
      updatedAt
    }
  }
  ${DonorFragmentDoc}
  ${OwnerFragmentDoc}
  ${ContactFragmentDoc}
`;

/**
 * __useGetRequestQuery__
 *
 * To run a query within a React component, call `useGetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRequestQuery,
    GetRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRequestQuery, GetRequestQueryVariables>(
    GetRequestDocument,
    options
  );
}
export function useGetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRequestQuery,
    GetRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRequestQuery, GetRequestQueryVariables>(
    GetRequestDocument,
    options
  );
}
export type GetRequestQueryHookResult = ReturnType<typeof useGetRequestQuery>;
export type GetRequestLazyQueryHookResult = ReturnType<
  typeof useGetRequestLazyQuery
>;
export type GetRequestQueryResult = Apollo.QueryResult<
  GetRequestQuery,
  GetRequestQueryVariables
>;
export const SignUpDocument = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input)
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ConfirmEmailDocument = gql`
  mutation confirmEmail($input: EmailConfirmationInput!) {
    confirmEmail(input: $input)
  }
`;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(ConfirmEmailDocument, options);
}
export type ConfirmEmailMutationHookResult = ReturnType<
  typeof useConfirmEmailMutation
>;
export type ConfirmEmailMutationResult =
  Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const MakeNotificationsReadDocument = gql`
  mutation makeNotificationsRead {
    makeNotificationsRead
  }
`;
export type MakeNotificationsReadMutationFn = Apollo.MutationFunction<
  MakeNotificationsReadMutation,
  MakeNotificationsReadMutationVariables
>;

/**
 * __useMakeNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMakeNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeNotificationsReadMutation, { data, loading, error }] = useMakeNotificationsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMakeNotificationsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeNotificationsReadMutation,
    MakeNotificationsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeNotificationsReadMutation,
    MakeNotificationsReadMutationVariables
  >(MakeNotificationsReadDocument, options);
}
export type MakeNotificationsReadMutationHookResult = ReturnType<
  typeof useMakeNotificationsReadMutation
>;
export type MakeNotificationsReadMutationResult =
  Apollo.MutationResult<MakeNotificationsReadMutation>;
export type MakeNotificationsReadMutationOptions = Apollo.BaseMutationOptions<
  MakeNotificationsReadMutation,
  MakeNotificationsReadMutationVariables
>;
export const DeleteNotificationDocument = gql`
  mutation deleteNotification($input: RemoveNotificationInput!) {
    deleteNotification(input: $input)
  }
`;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >(DeleteNotificationDocument, options);
}
export type DeleteNotificationMutationHookResult = ReturnType<
  typeof useDeleteNotificationMutation
>;
export type DeleteNotificationMutationResult =
  Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;
export const ArchiveUserDocument = gql`
  mutation archiveUser($input: ArchiveUserInput!) {
    archiveUser(input: $input)
  }
`;
export type ArchiveUserMutationFn = Apollo.MutationFunction<
  ArchiveUserMutation,
  ArchiveUserMutationVariables
>;

/**
 * __useArchiveUserMutation__
 *
 * To run a mutation, you first call `useArchiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveUserMutation, { data, loading, error }] = useArchiveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveUserMutation,
    ArchiveUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveUserMutation, ArchiveUserMutationVariables>(
    ArchiveUserDocument,
    options
  );
}
export type ArchiveUserMutationHookResult = ReturnType<
  typeof useArchiveUserMutation
>;
export type ArchiveUserMutationResult =
  Apollo.MutationResult<ArchiveUserMutation>;
export type ArchiveUserMutationOptions = Apollo.BaseMutationOptions<
  ArchiveUserMutation,
  ArchiveUserMutationVariables
>;
export const UnArchiveUserDocument = gql`
  mutation unArchiveUser($input: ArchiveUserInput!) {
    unArchiveUser(input: $input)
  }
`;
export type UnArchiveUserMutationFn = Apollo.MutationFunction<
  UnArchiveUserMutation,
  UnArchiveUserMutationVariables
>;

/**
 * __useUnArchiveUserMutation__
 *
 * To run a mutation, you first call `useUnArchiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnArchiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unArchiveUserMutation, { data, loading, error }] = useUnArchiveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnArchiveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnArchiveUserMutation,
    UnArchiveUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnArchiveUserMutation,
    UnArchiveUserMutationVariables
  >(UnArchiveUserDocument, options);
}
export type UnArchiveUserMutationHookResult = ReturnType<
  typeof useUnArchiveUserMutation
>;
export type UnArchiveUserMutationResult =
  Apollo.MutationResult<UnArchiveUserMutation>;
export type UnArchiveUserMutationOptions = Apollo.BaseMutationOptions<
  UnArchiveUserMutation,
  UnArchiveUserMutationVariables
>;
export const SignUpWithDonorDocument = gql`
  mutation signUpWithDonor($input: SignUpWithDonorInput!) {
    signUpWithDonor(input: $input)
  }
`;
export type SignUpWithDonorMutationFn = Apollo.MutationFunction<
  SignUpWithDonorMutation,
  SignUpWithDonorMutationVariables
>;

/**
 * __useSignUpWithDonorMutation__
 *
 * To run a mutation, you first call `useSignUpWithDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWithDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWithDonorMutation, { data, loading, error }] = useSignUpWithDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpWithDonorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpWithDonorMutation,
    SignUpWithDonorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignUpWithDonorMutation,
    SignUpWithDonorMutationVariables
  >(SignUpWithDonorDocument, options);
}
export type SignUpWithDonorMutationHookResult = ReturnType<
  typeof useSignUpWithDonorMutation
>;
export type SignUpWithDonorMutationResult =
  Apollo.MutationResult<SignUpWithDonorMutation>;
export type SignUpWithDonorMutationOptions = Apollo.BaseMutationOptions<
  SignUpWithDonorMutation,
  SignUpWithDonorMutationVariables
>;
export const GetAllIneligibleDocument = gql`
  query getAllIneligible {
    getAllIneligibleParticipants {
      ineligible {
        id
        createdAt
        ownerData
        donorData
      }
    }
  }
`;

/**
 * __useGetAllIneligibleQuery__
 *
 * To run a query within a React component, call `useGetAllIneligibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIneligibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIneligibleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllIneligibleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllIneligibleQuery,
    GetAllIneligibleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllIneligibleQuery, GetAllIneligibleQueryVariables>(
    GetAllIneligibleDocument,
    options
  );
}
export function useGetAllIneligibleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllIneligibleQuery,
    GetAllIneligibleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllIneligibleQuery,
    GetAllIneligibleQueryVariables
  >(GetAllIneligibleDocument, options);
}
export type GetAllIneligibleQueryHookResult = ReturnType<
  typeof useGetAllIneligibleQuery
>;
export type GetAllIneligibleLazyQueryHookResult = ReturnType<
  typeof useGetAllIneligibleLazyQuery
>;
export type GetAllIneligibleQueryResult = Apollo.QueryResult<
  GetAllIneligibleQuery,
  GetAllIneligibleQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      ...User
      contacts {
        ...Contact
      }
      role {
        ...Role
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetMyPermissionsDocument = gql`
  query getMyPermissions {
    getMyPermissions {
      ...Permission
      role {
        ...Role
      }
    }
  }
  ${PermissionFragmentDoc}
  ${RoleFragmentDoc}
`;

/**
 * __useGetMyPermissionsQuery__
 *
 * To run a query within a React component, call `useGetMyPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPermissionsQuery,
    GetMyPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyPermissionsQuery, GetMyPermissionsQueryVariables>(
    GetMyPermissionsDocument,
    options
  );
}
export function useGetMyPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPermissionsQuery,
    GetMyPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPermissionsQuery,
    GetMyPermissionsQueryVariables
  >(GetMyPermissionsDocument, options);
}
export type GetMyPermissionsQueryHookResult = ReturnType<
  typeof useGetMyPermissionsQuery
>;
export type GetMyPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetMyPermissionsLazyQuery
>;
export type GetMyPermissionsQueryResult = Apollo.QueryResult<
  GetMyPermissionsQuery,
  GetMyPermissionsQueryVariables
>;
export const GetUsersListDocument = gql`
  query getUsersList($input: GetUserListInput!) {
    getUsersList(input: $input) {
      data {
        ...User
        contacts {
          ...Contact
        }
        role {
          ...Role
        }
      }
      meta {
        total
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;

/**
 * __useGetUsersListQuery__
 *
 * To run a query within a React component, call `useGetUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersListQuery,
    GetUsersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersListQuery, GetUsersListQueryVariables>(
    GetUsersListDocument,
    options
  );
}
export function useGetUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersListQuery,
    GetUsersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersListQuery, GetUsersListQueryVariables>(
    GetUsersListDocument,
    options
  );
}
export type GetUsersListQueryHookResult = ReturnType<
  typeof useGetUsersListQuery
>;
export type GetUsersListLazyQueryHookResult = ReturnType<
  typeof useGetUsersListLazyQuery
>;
export type GetUsersListQueryResult = Apollo.QueryResult<
  GetUsersListQuery,
  GetUsersListQueryVariables
>;
export const GetOwnersListDocument = gql`
  query getOwnersList($input: GetOwnerListInput!) {
    getOwnersList(input: $input) {
      data {
        ...User
        contacts {
          ...Contact
        }
        role {
          ...Role
        }
      }
      meta {
        total
      }
    }
  }
  ${UserFragmentDoc}
  ${ContactFragmentDoc}
  ${RoleFragmentDoc}
`;

/**
 * __useGetOwnersListQuery__
 *
 * To run a query within a React component, call `useGetOwnersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnersListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOwnersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOwnersListQuery,
    GetOwnersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOwnersListQuery, GetOwnersListQueryVariables>(
    GetOwnersListDocument,
    options
  );
}
export function useGetOwnersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnersListQuery,
    GetOwnersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOwnersListQuery, GetOwnersListQueryVariables>(
    GetOwnersListDocument,
    options
  );
}
export type GetOwnersListQueryHookResult = ReturnType<
  typeof useGetOwnersListQuery
>;
export type GetOwnersListLazyQueryHookResult = ReturnType<
  typeof useGetOwnersListLazyQuery
>;
export type GetOwnersListQueryResult = Apollo.QueryResult<
  GetOwnersListQuery,
  GetOwnersListQueryVariables
>;
export const CheckUserConnectionDocument = gql`
  query checkUserConnection {
    checkUserConnection
  }
`;

/**
 * __useCheckUserConnectionQuery__
 *
 * To run a query within a React component, call `useCheckUserConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserConnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckUserConnectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckUserConnectionQuery,
    CheckUserConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckUserConnectionQuery,
    CheckUserConnectionQueryVariables
  >(CheckUserConnectionDocument, options);
}
export function useCheckUserConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckUserConnectionQuery,
    CheckUserConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckUserConnectionQuery,
    CheckUserConnectionQueryVariables
  >(CheckUserConnectionDocument, options);
}
export type CheckUserConnectionQueryHookResult = ReturnType<
  typeof useCheckUserConnectionQuery
>;
export type CheckUserConnectionLazyQueryHookResult = ReturnType<
  typeof useCheckUserConnectionLazyQuery
>;
export type CheckUserConnectionQueryResult = Apollo.QueryResult<
  CheckUserConnectionQuery,
  CheckUserConnectionQueryVariables
>;
