import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';
import { getEntries } from 'utils/object/getEntries';

const productHeadersConfig = {
  id: 'id',
  shortId: 'shortId',
  createdAt: 'createdAt',
  donation: {
    id: 'donationId',
    donor: {
      location: 'donorLocation',
      name: 'donorFirstName',
      user: {
        lastName: 'donorLastName',
      },
    },
  },
  poker: 'poker',
  primaryHolder: 'primaryHolder',
  anticoagulant: 'anticoagulant',
  expirationDate: 'expirationDate',
  status: 'status',
  storageRequirements: 'storageRequirements',
  value: 'value',
  woocommerceCategoryId: 'woocommerceCategoryId',
  woocommerceProductId: 'woocommerceProductId',
  regionPreference: { name: 'productRegion' },
  discarded: 'discarded',
  processor: 'processor',
  discardedProduct: {
    processor: {
      id: 'processorId',
    },
    processingType: 'discardedProdcessingType',
    reason: 'discardedReason',
    notes: 'discardedNotes',
  },
};

const productHeadersConfigEntries = getEntries(productHeadersConfig);

export const productReportHeaders: LabelKeyObject[] =
  productHeadersConfigEntries.map(([key, label]) => ({ label, key }));
