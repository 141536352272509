import { Col, message, Row } from 'antd';
import config from 'config';
import { format } from 'date-fns';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { OwnerReportOutput, useGetOwnerReportsLazyQuery } from 'types.d';

const OwnerReports: FC = () => {
  const { t } = useTranslation('reports.OwnerReports');
  const fileName = `owners_${format(new Date(), config.SHORT_DATE_FORMAT)}`;

  const [getOwnerReports, getOwnerReportQuery] = useGetOwnerReportsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        limit: 10000,
      },
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const isLoading = getOwnerReportQuery.loading;
  const receivedOwnersReport = (getOwnerReportQuery.data?.getOwnerReports ||
    []) as OwnerReportOutput[];

  const mappedOwnerReport = receivedOwnersReport.map(
    ({ donors, birthday, __typename, ...restOwnerData }) => {
      return {
        ...restOwnerData,
        birthday: birthday ? format(new Date(birthday), 'MM/dd/yyyy') : '',
        donors: donors
          ?.map(({ name, status }) => `${name}__${status}`)
          .join(', '),
      };
    }
  );

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Button
          onClick={() => getOwnerReports()}
          loading={isLoading}
          disabled={isLoading}
          type="primary"
        >
          {t('generateCsvReport')}
        </Button>
      </Col>
      <Col span={24}>
        {Boolean(receivedOwnersReport.length) && (
          <CSVLink data={mappedOwnerReport} filename={fileName}>
            {`${fileName}.csv`}
          </CSVLink>
        )}
      </Col>
    </Row>
  );
};

export default OwnerReports;
