import { UploadOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Row, Col, message } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { Routes } from 'config/routes';
import { Button, Spin } from 'modules/common/components';
import { DonorPicturesList } from 'modules/donor/components/DonorPicturesList';
import UploadDonorPhoto from 'modules/donor/components/UploadDonorPhoto';
import { useCurrentUser } from 'modules/user/hooks/useCurrentUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetDonorQuery,
  DonorOutput,
  DonorStatus,
  PermissionsEnum,
} from 'types.d';

const DonorPictures: FC = () => {
  const params = useParams();
  const id = params.id || '';
  const { t } = useTranslation('donor.DonorPictures');
  const apolloClient = useApolloClient();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const canUploadDonorPictures = currentUser.isCan(
    PermissionsEnum.UploadDonorsPictures
  );

  const { data, loading: getDonorLoading } = useGetDonorQuery({
    variables: {
      input: {
        id,
      },
    },
    onError: (err) => {
      message.error(err.message);
      if (err.message === 'Not Found') {
        navigate(Routes.NotFound);
        return;
      }

      navigate(Routes.IndividualDonors);
    },
  });

  const donor = data?.getDonor as DonorOutput | undefined;
  const isDonorArchived = donor?.status === DonorStatus.Archived;

  const onChangeUploadFile = ({ file }: UploadChangeParam) => {
    if (file.status === 'done') {
      apolloClient.refetchQueries({ include: ['getDonorPictures'] });
    }
    if (file.status === 'error') {
      message.error(file.response?.message);
    }
  };

  return (
    <Spin spinning={getDonorLoading}>
      <Row justify="end">
        {canUploadDonorPictures && (
          <UploadDonorPhoto
            onChange={onChangeUploadFile}
            data={{ donorId: donor?.id }}
          >
            <Button
              title={isDonorArchived ? t('donorArchived') : undefined}
              disabled={isDonorArchived}
              icon={<UploadOutlined />}
            >
              {t('addPicture')}
            </Button>
          </UploadDonorPhoto>
        )}
        <Col span={24}>{donor && <DonorPicturesList donorId={donor.id} />}</Col>
      </Row>
    </Spin>
  );
};
export default DonorPictures;
