import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import React, { FC } from 'react';

type PropTypes = {
  filled?: boolean;
  onClick?: (filled: boolean) => void;
};

const PushpinIcon: FC<PropTypes> = ({ filled, onClick }) => {
  const handleOnClick = (filled: boolean) => () => {
    onClick?.(filled);
  };

  return filled ? (
    <PushpinFilled onClick={handleOnClick(true)} />
  ) : (
    <PushpinOutlined onClick={handleOnClick(false)} />
  );
};

export default PushpinIcon;
