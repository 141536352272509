export default {
  ProfilePage: {
    profile: 'Profile',
    contact: 'My contact information',
    account: 'My account',
    email: 'Email',
    enterEmail: 'Enter email',
    email2: 'Email 2',
    enterEmail2: 'Enter email 2',
    firstName: 'First name',
    enterFirstName: 'Enter first name',
    firstName2: 'First name 2 ',
    enterFirstName2: 'Enter first name 2',
    lastName: 'Last name',
    enterLastName: 'Enter last name',
    lastName2: 'Last name 2',
    enterLastName2: 'Enter last name 2',
    phone: 'Phone number',
    enterPhone: 'Enter phone number',
    phone2: 'Phone number 2',
    enterPhone2: 'Enter phone number 2',
    address: 'Address',
    enterAddress: 'Enter address (ZIP Code, City, Street) ',
    address2: 'Address 2',
    enterAddress2: 'Enter address 2 (ZIP Code, City, Street) ',
    enterAddressName: 'Enter address name',
    enterStreetAddress: 'Enter street address',
    enterCity: 'Enter city',
    enterState: 'Enter state',
    enterZipCode: 'Enter zip code',
    streetAddress: 'Street address',
    city: 'City',
    state: 'State',
    zipCode: 'Zip code',
    changePassword: 'Change password',
    enterChangePassword: 'Enter new password',
    save: 'Save',
    close: 'Close',
    pageTitle: 'Profile',
    saved: 'Successfully saved!',
    deleteYourAccount: 'Delete your account',
    requestOnDeletingSent: 'Account deletion initiated',
    notifications: 'Notifications',
    locationPreferences: 'Preferences of location',
    birthday: 'Birthday',
    selectBirthdayDate: 'Select birthday date ',
    enterLocationPreferences: 'Enter location preferences',
    edit: 'Edit',
    successfullyUpdated: 'Successfully updated',
    accountDeleted: 'Account almost deleted',
    accountWillBeDeleted: 'Your account will be deleted on {{deletingDate}}',
    accountDeletingCanceled: 'Account deleting has been canceled',
    cancelDeleting: 'Cancel deleting',
  },
  EditProfileForm: {
    saved: 'Successfully saved!',
    save: 'Save',
    close: 'Close',
    edit: 'Edit',
    firstName: 'First name',
    enterFirstName: 'Enter first name',
    lastName: 'Last name',
    enterLastName: 'Enter last name',
    streetAddress: 'Street address',
    enterStreetAddress: 'Enter street address',
    city: 'City',
    enterCity: 'Enter city',
    state: 'State',
    enterState: 'Enter state',
    zipCode: 'Zip code',
    enterZipCode: 'Enter zip code',
    email: 'Email',
    enterEmail: 'Enter email',
    phone: 'Phone number',
    enterPhone: 'Enter phone number',
    locationPreferences: 'Preferences of location',
    enterLocationPreferences: 'Enter location preferences',
    birthday: 'Birthday',
    selectBirthdayDate: 'Select birthday date ',
    contactPreferences: 'Contact preferences',
  },
  DonorsPage: {
    pageTitle: "Donors' page",
    donorId: 'Donor ID',
    petName: "Pet's name",
    species: 'Species',
    gender: 'Gender',
    age: 'Age',
    weight: 'Weight',
    sterilized: 'Is your pet spayed or neutered?',
    nextDonationDate: 'Next eligible date',
    cat: 'Cat',
    dog: 'Dog',
    male: 'Male',
    female: 'Female',
    yes: 'Yes',
    no: 'No',
    createDonor: 'Create donor',
    uploadCsv: 'Upload CSV',
    pdfLatestLabwork: 'Pdf of latest labwork',
    status: 'Status',
    active: 'Active',
    affiliate: 'Affiliate',
    pending: 'Pending',
    requested: 'Requested',
    retired: 'Retired',
    trp: 'TRP',
    registered: 'Registered',
    labworkPending: 'Labwork Pending',
    approved: 'Approved',
    annualLabworkDue: 'Annual Labwork Due',
    notAccepted: 'Not Accepted',
    archived: 'Archived',
  },
  OwnerListPage: {
    firstName: 'First name',
    lastName: 'Last name',
    ownerType: 'Owner type',
    email: 'Email',
    title: 'List of owners',
    addNewOwner: 'Add new owner',
    searchOwner: 'Search by first name or last name or location',
    group: 'Grouped',
    individual: 'Individual',
    phoneNumber: 'Phone number',
    locationPreferences: 'Location preferences',
    active: 'Active',
    deleted: 'Deleted',
    deletedAt: 'Deleted at',
  },
  CreateOwnerPage: {
    pageTitle: 'Inviting a new owner',
    email: 'Email',
    enterEmail: 'Enter email',
    firstName: 'First name',
    enterFirstName: 'Enter first name',
    lastName: 'Last name',
    enterLastName: 'Enter last name',
    phone: 'Phone number',
    enterPhone: 'Enter phone number',
    enterAddressName: 'Enter address name',
    enterStreetAddress: 'Enter street address',
    enterCity: 'Enter city',
    enterState: 'Enter state',
    enterZipCode: 'Enter zip code',
    enterAddress: 'Enter address (ZIP Code, City, Street) ',
    streetAddress: 'Street address',
    city: 'City',
    state: 'State',
    zipCode: 'Zip code',
    address: 'Address',
    create: 'Create',
    close: 'Close',
    successfullyInvited: 'New owner has been successfully invited!',
    additionalContact: 'Additional contact',
    personalInfo: 'Personal info',
    locationPreferences: 'Preferences of location',
    enterLocationPreferences: 'Enter location preferences',
    birthday: 'Birthday',
    selectBirthdayDate: 'Select birthday date ',
  },
  EditOwnerPage: {
    pageTitle: 'Owner profile',
    email: 'Email',
    enterEmail: 'Enter email',
    firstName: 'First name',
    enterFirstName: 'Enter first name',
    lastName: 'Last name',
    enterLastName: 'Enter last name',
    phone: 'Phone number',
    enterPhone: 'Enter phone number',
    address: 'Address',
    enterAddress: 'Enter address (ZIP Code, City, Street) ',
    save: 'Save',
    edit: 'Edit',
    close: 'Close',
    successfullySaved: 'New data has been successfully saved!',
    letterHasBeenSentToNewEmail:
      'A letter with confirmation link has been sent to a new email!',
    additionalContact: 'Additional contact',
    personalInfo: 'Personal info',
    locationPreferences: 'Preferences of location',
    birthday: 'Birthday',
    selectBirthdayDate: 'Select birthday date ',
  },
  NotificationsTable: {
    appointmentsCancellation: 'Appointments cancellations',
    makingAppointments: 'Making Appointments',
    appointmentsReminders: 'Appointments reminders',
    remindersToTakePartAgain: 'Reminders to take part again',
    notifications: 'Notifications',
    donorCreation: 'Donor creation',
    ownerCreation: 'Owner creation',
    requestCreation: 'Request creation',
    donorUnenrolled: 'Donor unenrolled',
    userDeleting: 'Owner deleting',
    values: 'Values',
    save: 'Save',
    successfullySaved: 'Successfully saved',
    daycareAppointmentUnenrolled: 'Daycare donor unenrolled',
    daycareAppointmentRequestCreation: 'Daycare request creation',
    daycareAppointmentRequestRejected: 'Daycare appointment rejected',
    wasRegisteredOnDaycare: 'Registered on daycare',
  },
  ValidationErrors: {
    required: '{{field}} is required!',
    locationPreferences: 'Preferences of location',
    contactPreferences: 'Preferences of contact',
    date: 'Date should be in correct format!',
    moreThan: 'It must be more than {{min}} characters',
    lessThan: 'It must be less than {{max}} characters',
    address: 'Address',
    streetAddress: 'Street address',
    city: 'City',
    state: 'State',
    zipCode: 'Zip code',
    mustBeExactLength: '{{field}} must be exact {{value}} characters!',
    onlyNumbers: 'Only numbers',
    phoneNumberDoesNotMatch: 'Phone number must be like (123) 456-7890',
    invalidZip: 'Zip code must be exact 5 characters and contain only numbers!',
  },
  EditOwnerProfileForm: {
    userDeleted: 'User deleted',
    userDeletedAt: 'User was deleted at',
    AdditionalContactsSection: {
      additionalContact: 'Additional Contact',
      firstName: 'First name',
      enterFirstName: 'Enter first name',
      lastName: 'Last name',
      enterLastName: 'Enter last name',
      streetAddress: 'Street address',
      enterStreetAddress: 'Enter street address',
      city: 'City',
      enterCity: 'Enter city',
      state: 'State',
      enterState: 'Enter state',
      zipCode: 'ZIP code',
      enterZipCode: 'Enter ZIP code',
      email: 'Email',
      enterEmail: 'Enter email',
      phone: 'Phone',
      enterPhone: 'Enter phone',
    },
    DonorsTable: {
      petName: 'Pet name',
    },
    deleteButton: {
      deleteOwner: 'Delete owner',
      sureToDeleteOwner:
        'Are you sure you want to delete the owner and all information associated with them?',
      yes: 'Yes',
      no: 'No',
      userDeleted: 'User successfully deleted.',
      deleteUserError: 'Error occurred while deleting the user.',
    },
    PersonalInfoSection: {
      personalInfo: 'Personal information',
      birthday: 'Birthday',
      selectBirthdayDate: 'Select birthday date',
      firstName: 'First name',
      enterFirstName: 'Enter first name',
      lastName: 'Last name',
      enterLastName: 'Enter last name',
      streetAddress: 'Street address',
      enterStreetAddress: 'Enter street address',
      city: 'City',
      enterCity: 'Enter city',
      state: 'State',
      enterState: 'Enter state',
      zipCode: 'ZIP code',
      enterZipCode: 'Enter ZIP code',
      phone: 'Phone',
      enterPhone: 'Enter phone',
      locationPreferences: 'Location preferences',
      enterLocationPreferences: 'Enter location preferences',
      contactPreferences: 'Contact preferences',
      location: 'Location',
    },
    letterHasBeenSentToNewEmail:
      'A letter with confirmation link has been sent to a new email!',
    successfullySaved: 'New data has been successfully saved!',
    close: 'Close',
    save: 'Save',
    edit: 'Edit',
  },
  ContactPreferences: {
    phone: 'Phone',
    email: 'Email',
    text: 'Text',
  },
  OwnerAttachmentsPage: {
    attachments: 'Attachments',
  },
  EditOwnerProfileCardTabs: {
    attachments: 'Attachments',
    profile: 'Profile',
  },
  OwnerAttachmentsTable: {
    upload: 'Upload',
    file: 'File',
    deleteFile: 'Delete file',
    sureToDeleteAttachment: 'Are you sure to delete attachment?',
    fileUploaded: 'File has been uploaded!',
    fileDeleted: 'File has been deleted',
    fileMustBeLessThan: 'The file must be less than {{size}}mb',
    userDeleted: 'User deleted',
    userDeletedAt: 'User was deleted at',
  },
};
