import { message, Upload, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAccessToken } from 'utils/getAccessToken';

type PropTypes = {
  action?: string;
} & UploadProps;

const UploadDonorPhoto: FC<PropTypes> = ({
  data,
  action = 'upload',
  onChange,
  ...restProps
}) => {
  const token = getAccessToken();
  const { t } = useTranslation('donor.UploadDonorPhoto');
  const [showUploadList, setShowUploadList] = useState(true);
  const beforeUpload = (file: RcFile) => {
    const isLessThan5M = file.size / 1024 / 1024 < 5;
    if (!isLessThan5M) {
      message.error(t('mustBeLessThan', { mb: 5 }));
    }
    return isLessThan5M;
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    onChange?.(info);
    if (info.file.status === 'done') {
      setShowUploadList(false);
    }
    if (info.file.status === 'uploading') {
      setShowUploadList(true);
    }
  };

  const defaultProps: UploadProps = {
    name: 'file',
    maxCount: 1,
    action: `${process.env.REACT_APP_SERVER_URI}/donor/${action}`,
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
    data,
    accept: 'image/*',
    onChange: handleChange,
    showUploadList,
    beforeUpload,
  };

  return <Upload {...restProps} {...defaultProps} />;
};

export default UploadDonorPhoto;
